/* eslint-disable no-unused-vars */
import React from "react";
import { FormControl, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import InputMask from "react-input-mask";

/**
 * @param {{
 *  label?: string;
 *  name: string;
 *  control: any;
 *  rules: any;
 *  defaultValue?: string;
 *  type?: string;
 *  size?: "small" | "medium";
 *  mask?: string | Array<(string | RegExp)>;
 *  [key: string]: any;
 * }} props
 */
export const FormMaskedTextField = ({
  label,
  name,
  control,
  rules = {},
  defaultValue = "",
  type = "text",
  size = "small",
  options,
  mask,
  maskChar,
  maskPlaceholder,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { ref, value, ...inputProps },
        fieldState: { error },
      }) => (
        <InputMask
          value={value}
          {...inputProps}
          mask={mask}
          maskChar={maskChar}
          maskPlaceholder={maskPlaceholder}
        >
          {
            // @ts-ignore
            () => (
              <FormControl fullWidth error={!!error}>
                <TextField
                  value={value}
                  size={size}
                  type={type}
                  label={label}
                  error={!!error}
                  helperText={error?.message}
                  inputRef={ref}
                  {...inputProps}
                  {...props}
                  // variant={props.disabled ? "filled" : "outlined"}
                  sx={{ input: { letterSpacing: "1px" } }}
                />
              </FormControl>
            )
          }
        </InputMask>
      )}
    />
  );
};
