import React from "react";
import { IconButton, Stack, styled, useTheme } from "@mui/material";
import { useDroppable } from "@dnd-kit/core";
import { customColors } from "models/customColors";
import CloseIcon from "@mui/icons-material/Close";

const Container = styled(Stack)`
  padding: 4px 4px 4px 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
  min-height: 22px;

  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].strokeColor};
  border-radius: 20px;
  gap: 4px;
`;

export const Droppable = ({
  id,
  flex = 1,
  children,
  sourceData = [],
  handleClearField,
}) => {
  const theme = useTheme();
  const { isOver, setNodeRef } = useDroppable({
    id,
  });

  const style = {
    backgroundColor: isOver
      ? customColors[theme.palette.mode].enRoute
      : undefined,
  };

  const isDataPresent = sourceData.length > 0;

  return (
    <Container flex={flex} ref={setNodeRef} style={style}>
      <Stack flex={1} direction={"row"} gap={"4px"} flexWrap={"wrap"}>
        {children}
      </Stack>
      {isDataPresent && (
        <IconButton
          sx={{
            width: "22px",
            height: "22px",
            "&:hover": {
              color: customColors[theme.palette.mode].enRoute,
              backgroundColor:
                customColors[theme.palette.mode].additionalStroke,
            },
          }}
          onClick={handleClearField}
        >
          <CloseIcon sx={{ width: "18px" }} />
        </IconButton>
      )}
    </Container>
  );
};
