import React, { useEffect, useState } from "react";
import { Box, Divider, Stack, styled } from "@mui/material";
import { useClient } from "hooks/useClient";
import { useClientUser } from "hooks/useClientUser";
import { DataRow, SectionTitle } from "pages/ClientDetails";
import { convertToReadable } from "utils";

const Value = styled(Box)`
  word-break: break-word;
`;

const Container = styled(Stack)`
  gap: 40px;

  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const CompanyUserDetails = ({ userId }) => {
  const [companyId, setCompanyId] = useState(null);
  const { user } = useClientUser({ id: userId });
  const { client } = useClient({ id: companyId });

  useEffect(() => {
    if (user) {
      setCompanyId(user?.companyId);
    }
  }, [user]);

  return client?.id ? (
    <Container>
      <Stack gap={1}>
        <SectionTitle>User Details</SectionTitle>
        <Divider />
        <DataRow>
          <SectionTitle>User Name:</SectionTitle>
          <Value>{user?.firstName} {user?.lastName}</Value>
        </DataRow>
        <DataRow>
          <SectionTitle>Role:</SectionTitle>
          <Value>{convertToReadable(user?.role)}</Value>
        </DataRow>
        <DataRow>
          <SectionTitle>Email:</SectionTitle>
          <Value>{user?.email}</Value>
        </DataRow>
        <DataRow>
          <SectionTitle>Phone:</SectionTitle>
          <Value>{user?.phone}</Value>
        </DataRow>
      </Stack>
      <Stack gap={1}>
        <SectionTitle>Company Details</SectionTitle>
        <Divider />
        <DataRow>
          <SectionTitle>Name:</SectionTitle>
          <Value>{client?.companyName}</Value>
        </DataRow>
        <DataRow>
          <SectionTitle>Email:</SectionTitle>
          <Value>{client?.email}</Value>
        </DataRow>
        <DataRow>
          <SectionTitle>Phone:</SectionTitle>
          <Value>{client?.phoneNumber}</Value>
        </DataRow>
        <DataRow>
          <SectionTitle>Address:</SectionTitle>
          <Value>{client?.label}</Value>
        </DataRow>
        <DataRow>
          <SectionTitle>Facility Type:</SectionTitle>
          <Value>{client?.speciality}</Value>
        </DataRow>
      </Stack>
    </Container>
  ) : null;
};
