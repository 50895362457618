import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, InputAdornment, InputLabel, Stack } from "@mui/material";
import { DialogWindow } from "UI/DialogWindow";
import { notNullPriceField } from "models/validations";
import { useApiRequest } from "api/useApiRequest";
import { useFormDataSync } from "hooks/useFormDataSync";
import { FormMoneyField } from "UI/FormMoneyField";
import { getPayToDriverHandler } from "api/invoiceApi";

export const AddCashoutDialog = ({
  open,
  handleClose,
  refreshData,
  defaultValue,
  driverId,
  driverName,
}) => {
  const { callApiRequest } = useApiRequest();

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm({
    values: {
      amount: Math.round(defaultValue * 100) / 100,
    },
  });

  const { handleConfirmedClose, handleForcedClose } = useFormDataSync({
    isDirty,
    handleClose,
    open,
    reset,
  });

  const onSubmit = async () => {
    const params = getValues();

    callApiRequest({
      apiRequest: getPayToDriverHandler(driverId),
      params,
      onSuccess: () => {
        refreshData();
        handleForcedClose();
      },
    });
  };

  useEffect(() => {
    setValue("amount", defaultValue, { shouldDirty: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <DialogWindow
      open={open}
      withCloseButton
      handleClose={handleConfirmedClose}
      title={`Pay the wage to ${driverName}`}
      content={
        <form>
          <Stack>
            <Stack mt={2} gap={3} width={"100%"}>
              <Stack gap={2}>
                <Stack alignItems={"flex-start"}>
                  <InputLabel htmlFor="amount">Amount</InputLabel>
                  <FormMoneyField
                    id="amount"
                    name="amount"
                    control={control}
                    rules={notNullPriceField}
                    InputProps={{
                      autoComplete: "new-password",
                      inputProps: {
                        min: 0,
                        max: 100,
                        step: 0.1,
                      },
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Stack>
                <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                  Pay
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      }
    />
  );
};
