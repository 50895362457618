import React, { useContext } from "react";
import { Box, Button, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { openChatList, selectChat } from "store/reducers/chatsReducer";
import { DialogWindow } from "UI";
import * as SC from "./AuthCommonLayout.style";
import { ReactComponent as BackIcon } from "assets/img/backIcon.svg";
import {
  getReadableChatName,
  isAutomaticChatName,
  reduceAutomaticDisplayName,
} from "utils";
import { useNavigate } from "react-router-dom";
import { Messenger } from "./Messenger";
import { useConfirmedClose } from "hooks/useConfirmedClose";
import { GlobalContext } from "store/globalContext";
import { useApiRequest } from "api/useApiRequest";
import { deleteUserFromChat } from "api/messageApi";

export const ChatMessages = ({ id }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { openConfirmationWithCallback } = useContext(GlobalContext);
  const { callApiRequest } = useApiRequest();

  const chat = useAppSelector((state) =>
    state.chats.new?.find((chat) => chat.id === id)
  );

  const mattermostUserId = useAppSelector(
    (state) => state.user.mattermostUserId
  );

  const name = useAppSelector(
    (state) => state.chats.new?.find((chat) => chat.id === id)?.name
  );

  const purpose = useAppSelector(
    (state) => state.chats.new?.find((chat) => chat.id === id)?.purpose
  );

  const displayName = useAppSelector(
    (state) => state.chats.new?.find((chat) => chat.id === id)?.display_name
  );

  const handleClose = () => {
    dispatch(selectChat(undefined));
  };

  const { handleConfirmedClose, handleConfirmedAction } = useConfirmedClose({
    handleClose,
  });

  const isAutomaticName = isAutomaticChatName(name);

  const handleOpenClick = () => {
    const [type, idFromName] = name.split("_");
    if (!type || !idFromName) return;

    if (type === "ride") {
      navigate(`/ride_chat/${idFromName}`);
    } else if (type === "driver") {
      navigate(`/driver_chat/${idFromName}/${id}`);
    } else if (type === "client") {
      navigate(`/client_chat/${idFromName}/${id}`);
    }

    dispatch(selectChat(undefined));
  };

  const handleLeaveClick = () => {
    openConfirmationWithCallback({
      title: "Leave Chat",
      message: (
        <>
          Leaving the "{getReadableChatName(chat)}" chat. <br />
          Are you sure?
        </>
      ),
      buttonLabels: ["Cancel", "Continue"],
      callbackFn: () => {
        callApiRequest({
          apiRequest: deleteUserFromChat,
          params: {
            chatId: id,
            userId: mattermostUserId,
          },
          onSuccess: () => {
            dispatch(openChatList());
            dispatch(selectChat(undefined));
          },
        });
      },
    });
  };

  const userRole = useAppSelector((state) => state.user.role);
  const isClientUserInClientChat =
    chat?.name?.startsWith("client_") &&
    ["CUSTOMER_ADMIN", "CUSTOMER_MANAGER"].includes(userRole);

  return (
    <DialogWindow
      open={true}
      title={
        <Stack direction={"row"} alignItems={"center"}>
          <SC.BackButton
            onClick={() =>
              handleConfirmedAction(() => {
                dispatch(openChatList());
                dispatch(selectChat(undefined));
              })
            }
          >
            <SC.BackButtonContent sx={SC.BackButtonContentSx}>
              <BackIcon />
            </SC.BackButtonContent>
          </SC.BackButton>
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <Stack gap={0} alignItems={"flex-start"}>
              <Box>
                {isClientUserInClientChat ? "Support for " : ""}
                {isAutomaticName
                  ? reduceAutomaticDisplayName(displayName)
                  : displayName}
              </Box>
              <Box sx={{ fontSize: "12px", fontWeight: "normal" }}>
                {purpose}
              </Box>
            </Stack>
            {isAutomaticName && !isClientUserInClientChat && (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleOpenClick}
              >
                Open
              </Button>
            )}
            {isAutomaticName && !isClientUserInClientChat && (
              <Button
                variant="outlined"
                color="warning"
                onClick={handleLeaveClick}
              >
                Leave Chat
              </Button>
            )}
          </Stack>
        </Stack>
      }
      content={
        <Stack width={"35vw"} minWidth={"400px"} maxHeight={"60vh"}>
          <Messenger chatId={id} />
        </Stack>
      }
      handleClose={handleConfirmedClose}
      withCloseButton
    />
  );
};
