import React from "react";
import * as SC from "./DataGrid.style";
import { useAppSelector } from "store/hooks";
import { customColors } from "models/customColors";
import * as FP from "utils/fp-js";
import { DataGridSelector } from "./DataGridSelector";

export const DataGridRecords = ({
  columns,
  rows,
  handleRowClick = (row) => null,
  visibility = [],
  selectedId = "",
  keyName = "id",
  selectedColor = null,
  withSelector = false,
  selectedRows = [],
  setSelectedRows = () => null,
  selectorSize = 0.5,
}) => {
  const mode = useAppSelector((state) => state.layout.themeMode);
  const visibleSize = withSelector
    ? {
        xs: 12 + selectorSize,
        sm: 12 + selectorSize,
        md: 12 + selectorSize,
      }
    : {
        xs: 12,
        sm: 12,
        md: 12,
      };

  const getMdByField = (field) =>
    columns.find((item) => item.field === field)?.md ?? 0;

  if (visibility?.length > 0) {
    visibleSize.md = visibility.reduce(
      (acc, next) => acc + getMdByField(next),
      0
    );
  }

  const selectedBackgroudColor =
    selectedColor ?? customColors[mode].selectedRecord;

  return (
    <SC.RecordsHolder>
      <SC.RecordsGrid>
        {
          // @ts-ignore
          rows?.map((row, rowIndex) => {
            const isSelected = selectedRows.some(
              (selectedRow) => selectedRow === row[keyName]
            );

            return (
              <SC.Record
                key={`${row[keyName]}-${rowIndex}`}
                container
                wrap={"wrap"}
                columns={visibleSize}
                onClick={() => handleRowClick(row)}
                sx={{
                  ...FP.match(true)
                    .on(selectedId === row?.id, {
                      backgroundColor: `${selectedBackgroudColor}1A`,
                    })
                    .on(selectedId === row?.userId, {
                      backgroundColor: `${selectedBackgroudColor}1A`,
                    })
                    .on(selectedId === row?.carId, {
                      backgroundColor: `${selectedBackgroudColor}1A`,
                    })
                    .otherwise({}),
                  padding: "0.25rem 1.5rem",
                }}
                className={isSelected ? "selected" : ""}
              >
                {withSelector && (
                  <DataGridSelector
                    row={row}
                    keyName={keyName}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                  />
                )}
                {columns.map(
                  (
                    { label, field, renderer, xs, sm, md, align = "left" },
                    index
                  ) =>
                    (visibility.length === 0 || visibility.includes(field)) && (
                      <SC.RecordItem
                        key={`${row[keyName]}-${label}-${field}-${index}`}
                        item
                        xs={xs}
                        sm={sm}
                        md={md}
                        sx={{
                          textAlign: align,
                        }}
                      >
                        {renderer ? renderer(row) : row[field]}
                      </SC.RecordItem>
                    )
                )}
              </SC.Record>
            );
          })
        }
        {rows?.length === 0 && <SC.NoDataHolder>No data</SC.NoDataHolder>}
      </SC.RecordsGrid>
    </SC.RecordsHolder>
  );
};
