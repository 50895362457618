import React from "react";
import { TextFieldWithEllipsis } from "components/TextFiledWithEllipsis";
import { Box, Stack, styled } from "@mui/material";
import { customColors } from "models/customColors";
import { convertToReadable } from "utils";

export const Content = styled(Stack)`
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 10px;

  height: 100%;

  overflow-y: auto;
  overflow-x: hidden;
`;

const Unactive = styled(Box)`
  width: 16px;
  height: 16px;
  border: 1px solid
    ${({ theme }) => customColors[theme.palette.mode].placeHolder};
  border-radius: 16px;
`;

const ActiveSelected = styled(Unactive)`
  background-color: ${({ theme }) => customColors[theme.palette.mode].actionColor};
  border-color: transparent;
`;

export const StatusColorNames = {
  INACTIVE: "error",
  AWAITING: "confirmation",
};

export const Status = styled(Stack)`
  justify-content: center;
  align-items: center;

  padding: 4px 10px;
  border-radius: 8px;

  color: ${({
    // @ts-ignore
    row,
    theme,
  }) =>
    customColors[theme.palette.mode][
      StatusColorNames[row?.fleetStatus] ?? "greyText"
    ]};
  background-color: ${({
    // @ts-ignore
    row,
    theme,
  }) =>
    `${
      customColors[theme.palette.mode][
        StatusColorNames[row?.fleetStatus] ?? "greyText"
      ]
    }29`};
`;

export const Selector = ({ selectedCarId, row }) => (
  <Stack justifyContent={"center"} alignItems={"center"} width={"100%"}>
    {selectedCarId === row?.carId ? <ActiveSelected /> : <Unactive />}
  </Stack>
);

export const privateCarsColumns = [
  {
    label: "Car Make",
    field: "carLabel",
    xs: 5,
    sm: 5,
    md: 5,
    sortableName: "CAR",
    renderer: ({ carLabel }) => (
      <TextFieldWithEllipsis>{carLabel}</TextFieldWithEllipsis>
    ),
  },
  {
    label: "Driver",
    field: "userFullName",
    xs: 3,
    sm: 3,
    md: 3,
    sortableName: "FULL_NAME",
  },
  {
    label: "Region Name",
    field: "geofenceLabel",
    xs: 2,
    sm: 2,
    md: 2,
    sortableName: "REGION",
  },
  {
    xs: 2,
    sm: 2,
    md: 2,
    renderer: (row) => <Selector selectedCarId={null} row={row} />,
  },
];

export const getPrivateCarsShortColumns = (selectedCarId) => [
  {
    label: "Car Make",
    field: "carLabel",
    xs: 6,
    sm: 6,
    md: 6,
    sortableName: "CAR",
  },
  {
    label: "Driver",
    field: "userFullName",
    xs: 4,
    sm: 4,
    md: 4,
    sortableName: "FULL_NAME",
  },
  {
    xs: 2,
    sm: 2,
    md: 2,
    renderer: (row) => <Selector selectedCarId={selectedCarId} row={row} />,
  },
];

export const kericoCarsColumns = [
  {
    label: "Car Make",
    field: "carLabel",
    xs: 5,
    sm: 5,
    md: 5,
    sortableName: "CAR",
    renderer: ({ carLabel }) => (
      <TextFieldWithEllipsis>{carLabel}</TextFieldWithEllipsis>
    ),
  },
  {
    label: "Driver",
    field: "userFullName",
    xs: 3,
    sm: 3,
    md: 3,
    sortableName: "FULL_NAME",
  },
  {
    label: "Region Name",
    field: "geofenceLabel",
    xs: 2,
    sm: 2,
    md: 2,
    sortableName: "REGION",
  },
  {
    xs: 2,
    sm: 2,
    md: 2,
    renderer: (row) => <Selector selectedCarId={null} row={row} />,
  },
];

export const getKericoCarsShortColumns = (selectedCarId) => [
  {
    label: "Car Make",
    field: "carLabel",
    xs: 6,
    sm: 6,
    md: 6,
    sortableName: "CAR",
  },
  {
    label: "Driver",
    field: "userFullName",
    xs: 4,
    sm: 4,
    md: 4,
    sortableName: "FULL_NAME",
  },
  {
    xs: 2,
    sm: 2,
    md: 2,
    renderer: (row) => <Selector selectedCarId={selectedCarId} row={row} />,
  },
];

export const inactiveCarsColumns = [
  {
    label: "Car Make",
    field: "carLabel",
    xs: 4,
    sm: 4,
    md: 4,
    sortableName: "CAR",
    renderer: ({ carLabel }) => (
      <TextFieldWithEllipsis>{carLabel}</TextFieldWithEllipsis>
    ),
  },
  {
    label: "Driver",
    field: "userFullName",
    xs: 4,
    sm: 4,
    md: 4,
    sortableName: "FULL_NAME",
  },
  {
    label: "Region Name",
    field: "geofenceLabel",
    xs: 2,
    sm: 2,
    md: 2,
    sortableName: "REGION",
  },
  {
    label: "Fleet Status",
    field: "fleetStatus",
    xs: 2,
    sm: 2,
    md: 2,
    sortableName: "FLEET_STATUS",
    renderer: (row) => (
      <Status
        // @ts-ignore
        row={row}
      >
        {convertToReadable(row?.fleetStatus ?? "")}
      </Status>
    ),
  },
];

export const getInactiveCarsShortColumns = (selectedCarId) => [
  {
    label: "Car Make",
    field: "carLabel",
    xs: 4,
    sm: 4,
    md: 4,
    sortableName: "CAR",
    renderer: ({ carLabel }) => (
      <TextFieldWithEllipsis>{carLabel}</TextFieldWithEllipsis>
    ),
  },
  {
    label: "Driver",
    field: "userFullName",
    xs: 4,
    sm: 4,
    md: 4,
    sortableName: "FULL_NAME",
    renderer: ({ userFullName }) => (
      <TextFieldWithEllipsis>{userFullName}</TextFieldWithEllipsis>
    ),
  },
  {
    label: "Fleet Status",
    field: "fleetStatus",
    xs: 2,
    sm: 2,
    md: 2.5,
    sortableName: "FLEET_STATUS",
    renderer: (row) => (
      <Status
        // @ts-ignore
        row={row}
      >
        {convertToReadable(row?.fleetStatus ?? "")}
      </Status>
    ),
  },
  {
    xs: 6,
    sm: 6,
    md: 1.5,
    renderer: (row) => <Selector selectedCarId={selectedCarId} row={row} />,
  },
];
