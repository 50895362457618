import { Box, Button, Divider, InputLabel, Stack, styled } from "@mui/material";
import { DialogWindow } from "UI/DialogWindow";
import { useFormDataSync } from "hooks/useFormDataSync";
import { customColors } from "models/customColors";
import { Info } from "pages/ImportCsvFilePage.style";
import { useState } from "react";
import { useForm } from "react-hook-form";
import LocationSelector from "./LocationSelector";

export const ImportedAddress = styled(Box)`
  justify-content: center;
  align-items: center;

  padding: 6px 18px;
  font-size: 0.9rem;
  border: 1px solid ${({ theme }) => customColors[theme.palette.mode].textBody};
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].tableBackground};
  border-radius: 22px;
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) =>
      customColors[theme.palette.mode].actionColor};
    color: ${({ theme }) => customColors[theme.palette.mode].actionColor};
  }
`;

const defaultFormData = {
  placeId: "",
  awsId: "",
};

export const EditLocationDialog = ({
  location,
  setLocation,
  addressType,
  handleClose,
}) => {
  const {
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: defaultFormData,
  });

  const [locationLabel, setLocationLabel] = useState(null);

  const { handleConfirmedClose, handleForcedClose } = useFormDataSync({
    isDirty: isDirty && !!locationLabel,
    handleClose,
    open: !!addressType,
    reset,
  });

  const onSubmit = async (data) => {
    if (!locationLabel) {
      return;
    }
    setLocation({
      ...data,
      label: locationLabel,
      addressType,
    });
    handleForcedClose();
  };

  const handleLocationSelect = (selectedLocation) => {
    if (selectedLocation === null) {
      // reset location
      setValue("awsId", "", { shouldDirty: false });
      setValue("placeId", "", { shouldDirty: false });
      setLocationLabel(null);
    } else {
      // set new location
      setValue("awsId", selectedLocation?.awsId ?? "", {
        shouldDirty: !!selectedLocation?.awsId,
      });
      setValue("placeId", selectedLocation?.id ?? "", {
        shouldDirty: !!selectedLocation?.id,
      });
      setLocationLabel(selectedLocation?.suggestion ?? "");
    }
  };

  const importedAddress = [
    location?.address,
    location?.city,
    location?.state,
    location?.zipCode,
  ]
    .filter(Boolean)
    .join(", ");

  const handleDragAddress = (e) => {
    // e.preventDefault();
    e.dataTransfer.setData("text/plain", importedAddress);
  };

  return (
    <DialogWindow
      open={!!addressType}
      withCloseButton
      handleClose={handleConfirmedClose}
      title={`Edit ${addressType} Location`}
      content={
        <Stack
          mt={2}
          gap={2}
          direction={"column"}
          justifyContent={"space-between"}
          width={"90vw"}
          maxWidth={"640px"}
        >
          {!!importedAddress && (
            <>
              <Stack direction={"column"} alignItems={"flex-start"} gap={1}>
                <InputLabel>Imported address:</InputLabel>
                <ImportedAddress
                  draggable={true}
                  onDragStart={handleDragAddress}
                >
                  {importedAddress}
                </ImportedAddress>
                <Info sx={{ textAlign: "left", lineHeight: 1.3 }}>
                  Drag-and-drop the imported address into autocomplete field
                  below or start typing the right address to get the list of
                  possible locations for selecting.
                </Info>
              </Stack>
              <Divider />
            </>
          )}
          <Stack alignItems={"flex-start"} width={"100%"}>
            <InputLabel htmlFor="location">{addressType} Address</InputLabel>
            <LocationSelector
              id="location"
              setValue={handleLocationSelect}
              defaultValue={{ id: "", suggestion: location?.place?.label ?? "" }}
              sx={{ width: "100%" }}
            />
          </Stack>
          <Stack mt={2} direction={"row"} justifyContent={"center"} gap={2}>
            <Button
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={!isDirty || !locationLabel}
              sx={{ minWidth: "200px" }}
            >
              Select Location
            </Button>
          </Stack>
        </Stack>
      }
    />
  );
};
