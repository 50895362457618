/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unused-vars */
import React from "react";
import { Controller } from "react-hook-form";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

export const FormDateTimeSelector = ({
  label = "",
  name,
  control,
  rules = {},
  onSelect = (e) => null,
  disabled = false,
  size = "small",
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, onBlur, ref, value, ...inputProps },
        fieldState: { error },
      }) => {
        return (
          <DateTimePicker
            value={value ? new Date(value) : value}
            onChange={(newValue) => {
              onChange(newValue);
              try {
                const newDateTime =
                  new Date(newValue)?.toISOString()?.replace("T", " ") ?? "";
                onSelect(newDateTime);
              } catch (e) {
                onSelect("");
              }
            }}
            inputRef={ref}
            // @ts-ignore
            slotProps={{
              textField: {
                fullWidth: true,
                variant: "outlined",
                error: !!error,
                helperText: error?.message,
                // @ts-ignore
                size,
              },
            }}
            {...inputProps}
            {...props}
            disabled={disabled}
            disableOpenPicker={disabled}
          />
        );
      }}
    />
  );
};
