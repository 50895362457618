import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Divider, Stack, styled } from "@mui/material";
import { useApiRequest } from "api/useApiRequest";
import { useAppDispatch } from "store/hooks";
import { showError } from "store/reducers/errorReducer";
import { customColors } from "models/customColors";
import { getInvoiceById, updateInvoiceStatusById } from "api/invoiceApi";
import { Title } from "./DriverDetails.style";
import { StatusColorNames } from "pages/CompanyInvoicesPage";
import { downloadPdf } from "./InvoiceDetails.utils";
import {
  USDollar,
  convertToReadable,
  unixTimeToUSDate,
  unixTimeToUSShortDate,
} from "utils";
import { Prop, Value, Label } from "./RideData";
import { FormSelectField } from "UI/FormSelectField";
import { useForm } from "react-hook-form";
import { ReactComponent as CompleteIcon } from "assets/img/complete-icon.svg";
import { GlobalContext } from "store/globalContext";

const Container = styled(Stack)`
  padding: 0 20px;
  max-width: calc(100% - 40px);
  height: 100%;
  overflow: hidden;
`;

const RidesHolder = styled(Stack)`
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
`;

const RideProp = styled(Stack)`
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
`;

const RideLabel = styled(Label)`
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
`;

const RideInfo = styled(Box)`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
`;

const TotalLabel = styled(Box)`
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;

  color: ${({ theme }) => customColors[theme.palette.mode].greyText};
`;

const Total = styled(Box)`
  padding-right: 10px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: right;

  color: ${({ theme }) => customColors[theme.palette.mode].actionColor};
`;

const StatusSelector = styled(FormSelectField)`
  width: 130px;
  & > div {
    background-color: transparent;
  }

  & #status {
    font-weight: bold;
    color: ${({ theme, row }) =>
      customColors[theme.palette.mode][
        StatusColorNames[row?.status] ?? "greyText"
      ]};
    background-color: ${({ theme, row }) =>
      `${
        customColors[theme.palette.mode][
          StatusColorNames[row?.status] ?? "greyText"
        ]
      }29`};
  }
`;

const defaultValues = {
  status: "",
};

export const InvoiceDetails = ({
  id,
  client,
  refreshList = () => null,
  readOnly = false,
}) => {
  const dispatch = useAppDispatch();
  const { showWarning } = useContext(GlobalContext);
  const { callApiRequest } = useApiRequest();
  const [data, setData] = useState(null);

  const { control, setValue, getValues } = useForm({
    defaultValues,
  });

  const loadData = async (id) =>
    callApiRequest({
      apiRequest: getInvoiceById,
      params: id,
      onSuccess: (result) => {
        setData(result);
      },
      onError: (error) => {
        // @ts-ignore
        dispatch(showError(error));
        setData(null);
      },
    });

  useEffect(() => {
    if (id) {
      loadData(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleStatusChange = (e) => {
    const status = e?.target?.value;

    if (status !== getValues("status")) {
      callApiRequest({
        apiRequest: updateInvoiceStatusById,
        params: {
          data: {
            status,
          },
          id,
        },
        onSuccess: () => {
          refreshList();
          loadData(id);
          showWarning({
            title: "Invoice Status Changed",
            content: `Now the invoice has a ${status?.toLowerCase()} status.`,
            icon: <CompleteIcon />,
          });
        },
      });
    }
  };

  useEffect(() => {
    setValue("status", data?.status ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const totalAmount =
    data?.rides.reduce((acc, { actualCost }) => acc + actualCost, 0) ?? 0;

  return (
    <Container>
      {data?.id && (
        <>
          <Stack gap={1.5}>
            <Stack direction={"row"} alignItems={"center"} gap={2}>
              <Title>Invoice # {id}</Title>
              {/* <Status
                // @ts-ignore
                row={data}
              >
                <Box sx={{ padding: "6px 30px" }}>
                  {convertToReadable(data?.status ?? "")}
                </Box>
              </Status> */}
              <Box>
                <StatusSelector
                  id="status"
                  name="status"
                  control={control}
                  options={{
                    NEW: "New",
                    PAID: "Paid",
                    OVERDUE: "Overdue",
                  }}
                  withNone={false}
                  onChange={handleStatusChange}
                  row={data}
                  disabled={readOnly}
                />
              </Box>
            </Stack>
            <Stack direction={"row"} gap={2}>
              <Prop>
                <Label>Sent Date:</Label>
                <Value>{unixTimeToUSShortDate(data?.sentDateMs)}</Value>
              </Prop>
              <Prop>
                <Label>Due Date:</Label>
                <Value>{unixTimeToUSShortDate(data?.dueDateMs)}</Value>
              </Prop>
            </Stack>
            <Prop>
              <Label>Client:</Label>
              <Value>
                {data?.company?.companyName}, {data?.company?.phoneNumber}
              </Value>
            </Prop>
            <Stack
              mt={2}
              direction={"row"}
              justifyContent={"space-between"}
              gap={2}
              pr={1.5}
            >
              <Label>Rides</Label>
              <Label>Amount</Label>
            </Stack>
            <Divider />
          </Stack>
          <RidesHolder>
            {(data?.rides ?? []).map((ride, index) => (
              <React.Fragment key={`${ride?.id}-${index}`}>
                {!!index && <Divider />}
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  gap={4}
                  mt={1}
                  mb={2}
                >
                  <Stack gap={1.5}>
                    <RideInfo>
                      Ride ID: {ride?.id?.slice(0, 8)} - Date:{" "}
                      {unixTimeToUSDate(ride?.actualStartTime)}
                    </RideInfo>
                    <Stack gap={0.3}>
                      <RideProp>
                        <RideLabel>Passenger:</RideLabel>
                        <RideInfo>
                          {ride?.passengerName}, {ride?.phone}
                        </RideInfo>
                      </RideProp>
                      <RideProp>
                        <RideLabel>From:</RideLabel>
                        <RideInfo>{ride?.departure?.label}</RideInfo>
                      </RideProp>
                      <RideProp>
                        <RideLabel>To:</RideLabel>
                        <RideInfo>{ride?.destination?.label}</RideInfo>
                      </RideProp>
                      <Stack direction={"row"} rowGap={1} columnGap={4} flexWrap={"wrap"}>
                        <RideProp>
                          <RideLabel>Type:</RideLabel>
                          <RideInfo>
                            {convertToReadable(ride?.rideType)}
                          </RideInfo>
                        </RideProp>
                        <RideProp>
                          <RideLabel>Vehicle:</RideLabel>
                          <RideInfo>
                            {convertToReadable(ride?.vehicleType)}
                          </RideInfo>
                        </RideProp>
                        {!!ride?.waitingTime && (
                          <RideProp>
                            <RideLabel>Waiting time:</RideLabel>
                            <RideInfo>{ride?.waitingTime ?? 0} min</RideInfo>
                          </RideProp>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                  <Box pr={2}>{USDollar.format(ride?.actualCost)}</Box>
                </Stack>
              </React.Fragment>
            ))}
          </RidesHolder>
          <Stack gap={1.5}>
            <Divider />
            <Stack direction={"row"} justifyContent={"space-between"} gap={2}>
              <TotalLabel>Total Amount Due:</TotalLabel>
              <Total>{USDollar.format(totalAmount)}</Total>
            </Stack>
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => downloadPdf(data, client)}
              >
                Download PDF
              </Button>
            </Box>
          </Stack>
        </>
      )}
    </Container>
  );
};
