import React from "react";
import { Outlet } from "react-router-dom";
import { Box, Stack, styled } from "@mui/material";
import { ErrorDialog } from "components/ErrorDialog";
import { useAppSelector } from "store/hooks";
import { ModalSpinner } from "components/ModalSpinner";
import * as SC from "SC";
import { Sidebar } from "./Sidebar";
import { Header } from "./Header";
import { WarningDialog } from "./WarningDialog";
import { Confirmation } from "./Confirmation";
import { customColors } from "models/customColors";
import { FinishClientDetailsDialog } from "./FinishClientDetailsDialog";
import { useSpecialityOptions } from "hooks/useSpecialityOptions";

export const Container = styled(Stack)`
  width: 100%;
  height: 100%;

  min-width: 1024px;

  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].background};
`;

export const Content = styled(Stack)`
  flex: 1 1 auto;
  justify-content: space-between;

  min-height: 683px;
`;

export const CommonLayout = ({ menu }) => {
  const spinner = useAppSelector((state) => state.spinner);
  const companyData = useAppSelector((state) => state.user.companyData);
  const { specialityOptions, loadSpecialities } = useSpecialityOptions();

  const isDev = process.env.REACT_APP_BRANCH === "dev";

  return (
    <Container className="CommonContainer">
      {isDev && (
        <Box
          sx={{
            fontSize: "0.6rem",
            color: "red",
            position: "fixed",
            left: "5px",
            top: "2px",
          }}
        >
          dev environment
        </Box>
      )}
      <Sidebar menu={menu} />
      <Content>
        <Header menu={menu} />
        <SC.OutletContainer>
          <Outlet />
        </SC.OutletContainer>
      </Content>
      <Confirmation />
      <ErrorDialog />
      <WarningDialog />
      <ModalSpinner open={spinner} />
      <FinishClientDetailsDialog
        open={companyData?.status === "DETAILS_CHECK_NEEDED"}
        client={companyData}
        handleClose={() => null}
        refreshList={() => null}
        id={companyData?.id}
        loadSpecialities={loadSpecialities}
        specialityOptions={specialityOptions}
      />
    </Container>
  );
};
