import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Stack } from "@mui/material";
import { DialogWindow } from "UI/DialogWindow";
import { useApiRequest } from "api/useApiRequest";
import { useFormDataSync } from "hooks/useFormDataSync";
import DriverSelector from "./DriverSelector";
import { assignDriverToKericoVehicle } from "api/vehicleApi";

/** @type {{ userId: string; vehicleId: string; }} */
const defaultFormData = { userId: "", vehicleId: "" };

export const ChangeDriverOnKericoVehicleDialog = ({
  open,
  handleClose,
  refreshData,
  vehicleId,
  driver,
  vehiclePlate,
}) => {
  const { callApiRequest } = useApiRequest();

  const {
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: defaultFormData,
  });

  const { handleConfirmedClose, handleForcedClose } = useFormDataSync({
    isDirty,
    handleClose,
    open,
    reset,
  });

  const onSubmit = async () => {
    const params = getValues();

    callApiRequest({
      apiRequest: assignDriverToKericoVehicle,
      params: {
        userId: params?.userId ?? "",
        vehicleId: params?.vehicleId ?? "",
      },
      onSuccess: () => {
        refreshData();
        handleForcedClose();
      },
    });
  };

  useEffect(() => {
    if (open) {
      if (driver?.id) {
        setValue("userId", driver?.id, { shouldDirty: false });
        setValue("vehicleId", vehicleId, { shouldDirty: false });
      }
    } else {
      reset(defaultFormData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, driver]);

  const handleDriverSelect = (driver) => {
    if (!driver?.id) {
      setValue("userId", "", { shouldDirty: false });
    } else {
      setValue("userId", driver.id, {
        shouldDirty: true,
      });
    }
  };

  return (
    <DialogWindow
      open={open}
      withCloseButton
      handleClose={handleConfirmedClose}
      title={`Change Driver for Kerico  Vehicle ${vehiclePlate}`}
      content={
        <form>
          <Stack minWidth={"640px"}>
            <Stack mt={2} gap={3} width={"100%"}>
              <Stack gap={2}>
                <DriverSelector
                  id="location"
                  setValue={handleDriverSelect}
                  defaultValue={{
                    // @ts-ignore
                    id: driver?.id,
                    label: `${driver?.firstName} ${driver?.lastName}, ${driver?.phone}, ${driver?.email}`,
                  }}
                />
                <Stack
                  mt={3}
                  direction={"row"}
                  justifyContent={"center"}
                  gap={1}
                >
                  <Button
                    variant="outlined"
                    onClick={handleConfirmedClose}
                    size="large"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                    disabled={!isDirty}
                    size="large"
                  >
                    Save Changed Driver
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </form>
      }
    />
  );
};
