import { Box, Button, Divider, Stack, styled } from "@mui/material";
import { useApiRequest } from "api/useApiRequest";
import { getDriverDetails } from "api/userApi";
import { getVehicleDetails } from "api/vehicleApi";
import { customColors } from "models/customColors";
import { useCallback, useContext, useEffect, useState } from "react";
import { GlobalContext } from "store/globalContext";
import { useAppDispatch } from "store/hooks";
import { showError } from "store/reducers/errorReducer";
import { hideSpinner, showSpinner } from "store/reducers/spinnerReducer";
import { loadDriverPhoto, loadUserPhoto, unixTimeToUSShortDate } from "utils";
import { CarInfo } from "./CarInfo";
import { CarRejectDialog } from "./CarRejectDialog";
import { ChangeDriverOnKericoVehicleDialog } from "./ChangeDriverOnKericoVehicleDialog";
import { DefaultAvatar } from "./DefaultAvatar";
import {
  Detail,
  Label,
  // OffShiftStatus,
  // OnShiftStatus,
  Value,
} from "./DriverDetails.style";
import { ImageViewer } from "./ImageViewer";
import { useApproveCarRequest } from "hooks/useApproveCarRequest";

const Container = styled(Stack)`
  padding: 10px 40px;
  width: 45%;

  overflow-y: auto;
`;

export const Photo = styled(Box)`
  width: 57px;
  height: 57px;

  border-radius: 50%;

  background-repeat: "no-repeat";
  background-position: "center";

  cursor: pointer;
`;

const Title = styled(Box)`
  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
`;

/** @type {import("types/commonTypes").VehicleDetails | null} */
const defaultVehicle = null;

export const CarDetails = ({
  carId,
  closeDetails,
  refreshList,
  refreshKericoList,
}) => {
  const { openConfirmationWithCallback } = useContext(GlobalContext);
  const { callApiRequest } = useApiRequest();
  const dispatch = useAppDispatch();
  const [driverId, setDriverId] = useState("");
  const [driver, setDriver] = useState(null);
  const [vehicle, setVehicle] = useState(defaultVehicle);
  const [profileImage, setProfileImage] = useState(null);
  const [imageTitle, setImageTitle] = useState(null);
  const [image, setImage] = useState(null);
  const [isRejectionDialogOpen, setIsRejectionDialogOpen] = useState(false);
  const [isAssignDriverOpen, setIsAssignDriverOpen] = useState(false);

  const showDriverPhoto = () => {
    setImageTitle("Driver Photo");
    setImage(profileImage);
  };

  const loadDriverData = useCallback(
    async (id) => {
      callApiRequest({
        apiRequest: getDriverDetails,
        params: id,
        onSuccess: (result) => {
          setDriver(result);
        },
        onError: (error) => {
          // @ts-ignore
          dispatch(showError(error));
          setDriver(null);
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const loadLicenseImage = async () => {
    dispatch(showSpinner());
    setImageTitle("Driver's License");
    await loadDriverPhoto({
      driver,
      photoType: "License",
      setImage,
      setError: (error) => dispatch(showError(error)),
    });
    dispatch(hideSpinner());
  };

  const loadInsuranceImage = async (photoId) => {
    dispatch(showSpinner());
    setImageTitle("Vehicle Insurance");
    await loadUserPhoto({
      photoId,
      setImage,
      setError: (error) => dispatch(showError(error)),
    });

    dispatch(hideSpinner());
  };

  const loadData = useCallback(async () => {
    callApiRequest({
      apiRequest: getVehicleDetails,
      params: carId,
      onSuccess: (result) => {
        setVehicle(result);
        setDriverId(result?.userId ?? "");
      },
      onError: (error) => {
        // @ts-ignore
        dispatch(showError(error));
        setVehicle(defaultVehicle);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carId]);

  useEffect(() => {
    if (carId) {
      loadData();
      return;
    }
    setVehicle(defaultVehicle);
  }, [loadData, carId]);

  useEffect(() => {
    if (driverId) {
      loadDriverData(driverId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverId]);

  useEffect(() => {
    loadDriverPhoto({
      driver,
      photoType: "Profile",
      setImage: setProfileImage,
    });
  }, [driver]);

  const handleChangeDriverButtonClick = () => {
    openConfirmationWithCallback({
      title: "Attention",
      message: `All scheduled rides for this vehicle ${vehicle?.licensePlate?.licensePlate} will be canceled. Are you sure?`,
      callbackFn: () => {
        setIsAssignDriverOpen(true);
      },
    });
  };

  const { approveCarRequest } = useApproveCarRequest({
    refreshList: () => {
      refreshList();
      loadDriverData(driverId);
    },
    closeDetails,
  });

  return (
    <Container gap={2}>
      <Title mt={2}>Vehicle Details</Title>
      <CarInfo
        vehicle={vehicle}
        carId={carId}
        setIsRejectionDialogOpen={setIsRejectionDialogOpen}
        loadInsuranceImage={loadInsuranceImage}
        approveCarRequest={approveCarRequest}
      />
      <Title mt={2}>Driver Details</Title>
      <Stack direction={"row"} alignItems={"center"} gap={2}>
        {!profileImage && <DefaultAvatar user={driver} radius="57px" />}
        {profileImage && (
          <Photo
            sx={{
              backgroundSize: `${profileImage ? "cover" : "auto"}`,
              backgroundImage: `url("${profileImage}")`,
              flex: "0 0 auto",
            }}
            onClick={showDriverPhoto}
          />
        )}
        <Title>
          {driver?.firstName ?? "Firstname"} {driver?.lastName ?? "Lastname"}
        </Title>
        {/* {driver?.workStatus === "On_Shift" && (
          <OnShiftStatus>On Shift</OnShiftStatus>
        )}
        {driver?.workStatus === "Off_Shift" && (
          <OffShiftStatus>Off Shift</OffShiftStatus>
        )} */}
        {vehicle?.owner === "KERICO" && (
          <Button
            variant="contained"
            color="primary"
            disabled={!!vehicle?.activeRideId}
            onClick={handleChangeDriverButtonClick}
          >
            Change Driver
          </Button>
        )}
      </Stack>
      <Stack gap={1}>
        <Label>Details</Label>
        <Divider />
        <Stack gap={1.5}>
          <Detail>
            <Label>Region:</Label>
            <Value>{driver?.region?.label ?? "-"}</Value>
          </Detail>
          <Stack direction={"row"} gap={2}>
            <Detail>
              <Label>Phone Number:</Label>
              <Value>{driver?.phone ?? "-"}</Value>
            </Detail>
            <Detail>
              <Label>Email:</Label>
              <Value>{driver?.email ?? "-"}</Value>
            </Detail>
          </Stack>
          <Detail>
            <Label sx={{ flex: "0 0 auto" }}>Home Address:</Label>
            <Value>{driver?.homeAddress?.placeLabel ?? "-"}</Value>
          </Detail>
        </Stack>
        {!!driver?.licenseDto?.photoId && (
          <Stack direction={"row"} gap={1}>
            <Detail>
              <Label>Driver's License:</Label>
              <Value>
                {driver?.licenseDto?.expTimeMs
                  ? `Expires on ${unixTimeToUSShortDate(
                      driver?.licenseDto?.expTimeMs
                    )}`
                  : "-"}
              </Value>
            </Detail>
            <Button variant="outlined" onClick={loadLicenseImage}>
              View
            </Button>
          </Stack>
        )}
      </Stack>
      {image && (
        <ImageViewer
          image={image}
          setImage={setImage}
          imageTitle={imageTitle}
        />
      )}
      {!vehicle?.activeRideId && isAssignDriverOpen && (
        <ChangeDriverOnKericoVehicleDialog
          open={isAssignDriverOpen}
          handleClose={() => setIsAssignDriverOpen(false)}
          refreshData={() => {
            refreshList();
            refreshKericoList();
            loadData();
          }}
          driver={driver}
          vehicleId={carId}
          vehiclePlate={vehicle?.licensePlate?.licensePlate}
        />
      )}
      <CarRejectDialog
        open={isRejectionDialogOpen}
        carId={carId}
        handleClose={() => setIsRejectionDialogOpen(false)}
        closeDetails={closeDetails}
        refreshList={refreshList}
      />
    </Container>
  );
};
