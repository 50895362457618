import React, { useContext, useEffect } from "react";
import { GlobalContext } from "store/globalContext";
import { useForm } from "react-hook-form";
import { Box, Stack, styled } from "@mui/material";
import { DialogWindow } from "UI/DialogWindow";
import { FormTextField } from "UI/FormTextField";
import { required } from "models/validations";
import { useApiRequest } from "api/useApiRequest";
import { useFormDataSync } from "hooks/useFormDataSync";
import { ReactComponent as RejectedIcon } from "assets/img/rejected-icon.svg";
import * as CSC from "SC";
import { updateCarApproval } from "api/vehicleApi";

const Info = styled(Box)`
  font-family: Inter;
  font-size: 21px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
`;

const Title = styled(Box)`
  font-family: Inter;
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
`;

/** @type {import("types/commonTypes").ApprovalDto}*/
const defaultFormData = {
  approved: false,
  rejectReason: "",
};

export const CarRejectDialog = ({
  open,
  handleClose,
  carId,
  refreshList,
  closeDetails,
}) => {
  const { showWarning } = useContext(GlobalContext);
  const { callApiRequest } = useApiRequest();

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: defaultFormData,
  });

  const { handleConfirmedClose, handleForcedClose } = useFormDataSync({
    isDirty,
    handleClose,
    open,
    reset,
  });

  const onSubmit = async () => {
    const data = getValues();
    const params = { carId, data };

    callApiRequest({
      apiRequest: updateCarApproval,
      params,
      onSuccess: () => {
        showWarning({
          title: "Vehicle Rejected",
          content: "The Vehicle approval request was rejected",
          icon: <RejectedIcon />,
        });
        refreshList();
        closeDetails();
        handleForcedClose();
      },
    });
  };

  useEffect(() => {
    if (carId) {
      reset(defaultFormData);
    }
  }, [carId, open, reset]);

  return (
    <DialogWindow
      open={open}
      withCloseButton
      handleClose={handleConfirmedClose}
      title={<Title>Vehicle Rejection</Title>}
      content={
        <form>
          <Stack maxWidth={"512px"} gap={2}>
            <Info>
              To maintain transparency and assist the driver, please provide
              specific feedback or reasons for rejecting the vehicle
            </Info>
            <Stack mt={2} gap={3} justifyContent={"center"} width={"100%"}>
              <FormTextField
                id="rejectReason"
                name="rejectReason"
                placeholder="Reason for Rejection"
                control={control}
                rules={{ required }}
                InputProps={{
                  autoComplete: "off",
                }}
              />
            </Stack>
          </Stack>
        </form>
      }
      actions={
        <Stack gap={2}>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            gap={1}
            mt={2}
          >
            <CSC.DialogMainButton
              variant="outlined"
              size="large"
              onClick={handleConfirmedClose}
            >
              Cancel
            </CSC.DialogMainButton>
            <CSC.DialogMainButton
              variant="contained"
              size="large"
              onClick={handleSubmit(onSubmit)}
              disabled={!isDirty}
              color="error"
            >
              Reject
            </CSC.DialogMainButton>
          </Stack>
        </Stack>
      }
    />
  );
};
