import React from "react";
import { Stack, Tooltip, styled } from "@mui/material";
import { ReactComponent as SmallLogo } from "assets/img/Symbol_DarkBlue.svg";
import { ReactComponent as WhiteSmallLogo } from "assets/img/Symbol_White.svg";
import { ReactComponent as TextLogo } from "assets/img/KERICO_text.darkblue.svg";
import { ReactComponent as WhiteTextLogo } from "assets/img/KERICO_text.white.svg";

import { ReactComponent as LightModeIcon } from "assets/img/light-mode-icon.svg";
import { ReactComponent as DarkModeIcon } from "assets/img/dark-mode-icon.svg";

import { NavMenu } from "./NavMenu";
import { customColors } from "models/customColors";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ReactComponent as BurgerIcon } from "assets/img/burger-icon.svg";
import { ReactComponent as CollapseIcon } from "assets/img/collapse-menu-icon.svg";
import {
  collapseMenu,
  expandMenu,
  toggleThemeMode,
} from "store/reducers/layoutReducer";
import { ModeSwitcher } from "./ModeSwitcher";

/** @typedef {import("@mui/material").Theme} Theme */

const Container = styled(Stack)(
  ({ theme }) => `
    padding: 2rem 1rem;

    height: (100% - 2rem);

    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    color: ${customColors[theme.palette.mode].greyText};
    background-color: ${customColors[theme.palette.mode].background};

    border-right: 1px solid
      ${customColors[theme.palette.mode].borderColor};
`
);

const CollapseHolder = styled(Stack)(
  ({ theme }) => `
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    cursor: pointer;

    &:hover {
      color: ${customColors[theme.palette.mode].additionalAccent};
      path {
        fill: ${customColors[theme.palette.mode].additionalAccent};
      }
    }
`
);

const ThemeModeHolder = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;

  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;

  & path {
    fill: ${({ theme }) => customColors[theme.palette.mode].actionColor};
  }
  user-select: none;
`;

export const Sidebar = ({ menu }) => {
  const dispatch = useAppDispatch();
  const isMenuExpanded = useAppSelector(
    (state) => state?.layout?.isMenuExpanded
  );
  const mode = useAppSelector((state) => state?.layout?.themeMode);

  const handleClickThemeMode = () => {
    dispatch(toggleThemeMode());
  };

  return (
    <Container>
      <Stack alignItems={"center"} gap={3}>
        {isMenuExpanded ? (
          <>
            <Stack sx={{ height: "31px" }}>
              {mode === "dark" ? <WhiteTextLogo /> : <TextLogo />}
            </Stack>
            <CollapseHolder
              onClick={() => dispatch(collapseMenu())}
              sx={{ height: "31px" }}
            >
              <CollapseIcon /> Collapse menu
            </CollapseHolder>
          </>
        ) : (
          <>
            {mode === "dark" ? <WhiteSmallLogo /> : <SmallLogo />}
            <Tooltip title="Expand menu" placement="right">
              <CollapseHolder onClick={() => dispatch(expandMenu())}>
                <BurgerIcon />
              </CollapseHolder>
            </Tooltip>
          </>
        )}
        <NavMenu menu={menu} />
      </Stack>
      <ThemeModeHolder>
        {isMenuExpanded && mode === "light" && (
          <>
            <LightModeIcon />
            Light Mode
          </>
        )}
        {isMenuExpanded && mode === "dark" && (
          <>
            <DarkModeIcon />
            Dark Mode
          </>
        )}
        <ModeSwitcher onClick={handleClickThemeMode} />
      </ThemeModeHolder>
    </Container>
  );
};
