import React from "react";
import { InputAdornment, InputLabel, Stack } from "@mui/material";
import { FormMoneyField } from "UI/FormMoneyField";
import { notNullPriceField } from "models/validations";

export const ContractForm = ({ control }) => (
  <Stack>
    <Stack direction={"row"} justifyContent={"flex-start"} gap={2}>
      <Stack alignItems={"flex-start"} width={"30%"}>
        <InputLabel htmlFor="basePricePerMile">Price per mile</InputLabel>
        <FormMoneyField
          id="basePricePerMile"
          name="basePricePerMile"
          control={control}
          rules={notNullPriceField}
          InputProps={{
            autoComplete: "new-password",
            inputProps: {
              min: 0,
              step: 0.01,
            },
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Stack>
      <Stack alignItems={"flex-start"} width={"30%"}>
        <InputLabel htmlFor="baseDeliveryFee">Delivery Fee</InputLabel>
        <FormMoneyField
          id="baseDeliveryFee"
          name="baseDeliveryFee"
          control={control}
          rules={notNullPriceField}
          InputProps={{
            autoComplete: "new-password",
            inputProps: {
              min: 0,
              step: 0.01,
            },
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Stack>
      <Stack alignItems={"flex-start"} width={"30%"}>
        <InputLabel htmlFor="freeWaitingTimeMin">Waiting time margin</InputLabel>
        <FormMoneyField
          id="freeWaitingTimeMin"
          name="freeWaitingTimeMin"
          control={control}
          rules={notNullPriceField}
          InputProps={{
            autoComplete: "new-password",
            inputProps: {
              min: 0,
              step: 1,
              style: {
                textAlign: "right",
              },
            },
            endAdornment: <InputAdornment position="start">min</InputAdornment>,
          }}
        />
      </Stack>
    </Stack>
    <Stack direction={"row"} justifyContent={"flex-start"} gap={2}>
      <Stack alignItems={"flex-start"} width={"30%"}>
        <InputLabel htmlFor="wheelchairPricePerMile">
          Wheelchair Price per mile
        </InputLabel>
        <FormMoneyField
          id="wheelchairPricePerMile"
          name="wheelchairPricePerMile"
          control={control}
          rules={notNullPriceField}
          InputProps={{
            autoComplete: "new-password",
            inputProps: {
              min: 0,
              step: 0.01,
            },
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Stack>
      <Stack alignItems={"flex-start"} width={"30%"}>
        <InputLabel htmlFor="wheelchairDeliveryFee">
          Wheelchair Delivery Fee
        </InputLabel>
        <FormMoneyField
          id="wheelchairDeliveryFee"
          name="wheelchairDeliveryFee"
          control={control}
          rules={notNullPriceField}
          InputProps={{
            autoComplete: "new-password",
            inputProps: {
              min: 0,
              step: 0.01,
            },
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Stack>
      <Stack alignItems={"flex-start"} width={"30%"}>
        <InputLabel htmlFor="waitingRatePerMin">
          Waiting time rate per min
        </InputLabel>
        <FormMoneyField
          id="waitingRatePerMin"
          name="waitingRatePerMin"
          control={control}
          rules={notNullPriceField}
          InputProps={{
            autoComplete: "new-password",
            inputProps: {
              min: 0,
              step: 0.01,
            },
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Stack>
    </Stack>
    <Stack direction={"row"} justifyContent={"flex-start"} gap={2}>
      <Stack alignItems={"flex-start"} width={"30%"}>
        <InputLabel htmlFor="privateVehicleRate">
          Private Vehicle Rate
        </InputLabel>
        <FormMoneyField
          id="privateVehicleRate"
          name="privateVehicleRate"
          control={control}
          rules={notNullPriceField}
          InputProps={{
            autoComplete: "new-password",
            inputProps: {
              min: 0,
              step: 1,
              style: {
                textAlign: "right",
              },
            },
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Stack>
      <Stack alignItems={"flex-start"} width={"30%"}>
        <InputLabel htmlFor="companyVehicleRate">
          Company Vehicle Rate
        </InputLabel>
        <FormMoneyField
          id="companyVehicleRate"
          name="companyVehicleRate"
          control={control}
          rules={notNullPriceField}
          InputProps={{
            autoComplete: "new-password",
            inputProps: {
              min: 0,
              step: 1,
              style: {
                textAlign: "right",
              },
            },
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Stack>
      <Stack alignItems={"flex-start"} width={"30%"}>
        <InputLabel htmlFor="recalculationMargin">
          Cost estimate margin
        </InputLabel>
        <FormMoneyField
          id="recalculationMargin"
          name="recalculationMargin"
          control={control}
          rules={notNullPriceField}
          InputProps={{
            autoComplete: "new-password",
            inputProps: {
              min: 0,
              step: 1,
              style: {
                textAlign: "right",
              },
            },
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Stack>
    </Stack>
  </Stack>
);
