import React from "react";
import { IconButton } from "@mui/material";
import { customColors } from "models/customColors";
import { ReactComponent as MessagesIcon } from "assets/img/messages-icon.svg";
import { ReactComponent as MessagesSmallIcon } from "assets/img/messages-small-icon.svg";

export const MessageButton = ({ openChat,  size = "medium" }) => (
  <IconButton
    // @ts-ignore
    size={size}
    onClick={openChat}
    sx={{
      backgroundColor: (theme) => customColors[theme.palette.mode].textBody,
      "& path": {
        fill: (theme) => customColors[theme.palette.mode].background,
      },
      "&.Mui-disabled": {
        backgroundColor: (theme) =>
          customColors[theme.palette.mode].placeHolder,
      },
      "&:hover": {
        backgroundColor: (theme) =>
          customColors[theme.palette.mode].actionColor,
      },
      "&:focus": {
        backgroundColor: (theme) =>
          customColors[theme.palette.mode].actionColor,
      },
    }}
  >
    {size==="small" ? <MessagesSmallIcon /> : <MessagesIcon />}
  </IconButton>
);
