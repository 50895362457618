import React, { useEffect, useState } from "react";
import { Box, TextField, Autocomplete } from "@mui/material";
import { useApiRequest } from "api/useApiRequest";
import { AUTOCOMPLETE_TYPING_DELAY_IN_MS } from "models/constants";
import { getDriversBySearch } from "api/userApi";

export default function DriverSelector({
  id,
  setValue,
  defaultValue = { label: "" },
  sx = {},
  error = "",
}) {
  const { callApiRequest } = useApiRequest();
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");

  const handleChange = async (e) => {
    const value = e?.target?.value ?? "";
    setSearch(value);
  };

  const handleSelect = (e, option) => {
    if (setValue) {
      setValue(option);
    }
  };

  const handleClose = (option) => {
    setSearch("");
    setList([]);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (search?.length > 2) {
        callApiRequest({
          apiRequest: getDriversBySearch,
          params: {
            search,
          },
          onError: () => {
            setList([]);
          },
          onSuccess: (result) => {
            setList(result);
          },
          skipErrorHandling: true,
        });
        return;
      }

      if (!search || search === "" || search?.length < 3) {
        setList([]);
      }
    }, AUTOCOMPLETE_TYPING_DELAY_IN_MS);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Autocomplete
      id={id}
      sx={{ width: "100%", ...sx }}
      defaultValue={defaultValue}
      options={list}
      getOptionLabel={(option) => option.label}
      filterOptions={(options) => options}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Start typing driver name..."
          inputProps={{
            ...params.inputProps,
            autocomplete: "new-password", // disable autocomplete and autofill
            form: {
              autocomplete: "off",
            },
          }}
          onChange={handleChange}
          error={!!error}
          // @ts-ignore
          helperText={error}
          autoComplete="off"
        />
      )}
      autoHighlight
      onChange={handleSelect}
      onClose={handleClose}
      onInputChange={handleChange}
      clearOnEscape
      autoComplete
    />
  );
}
