import React, { useState } from "react";
import { Box, Button, Stack, styled, useTheme } from "@mui/material";
import * as SC from "SC";
import { useDataGrid } from "hooks/useDataGrid";
import { useAppSelector } from "store/hooks";
import { DataGrid } from "UI/DataGrid";
import { customColors } from "models/customColors";
import { convertToReadable, unixTimeToUSDate } from "utils";
import { useNavigate } from "react-router-dom";
import { getRides } from "api/rideApi";
import { RideDetails } from "components/RideDetails";
import { useClientList } from "hooks/useClientList";
import { TextFieldWithEllipsis } from "components/TextFiledWithEllipsis";
import * as FP from "utils/fp-js";
import { CloseWindowButton } from "UI/CloseWindowButton";

const Content = styled(Stack)`
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 40px;

  height: 100%;

  overflow-y: auto;
  overflow-x: hidden;
`;

const ActiveSelected = styled(Box)`
  width: 16px;
  height: 16px;
  border: 3px solid
    ${({ theme }) => customColors[theme.palette.mode].background};
  border-radius: 16px;
  background-color: ${({ theme }) => customColors[theme.palette.mode].success};
`;

const InactiveSelected = styled(ActiveSelected)`
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].strokeColor};
`;

const Selector = ({ selectedId, row }) => (
  <Stack justifyContent={"center"} alignItems={"center"} width={"100%"}>
    {selectedId === row?.id ? <ActiveSelected /> : <InactiveSelected />}
  </Stack>
);

const StatusColorNames = {
  NEW: "new",
  SCHEDULED: "scheduled",
  EN_ROUTE: "enRoute",
  ARRIVED: "arrived",
  ON_BOARD: "enRoute",
  COMPLETED: "success",
  CANCELLED: "error",
};

const Status = styled(Stack)`
  justify-content: center;
  align-items: center;

  padding: 4px 10px;
  border-radius: 8px;

  color: ${({
    // @ts-ignore
    row,
    theme,
  }) =>
    customColors[theme.palette.mode][
      StatusColorNames[row?.status] ?? "greyText"
    ]};
  background-color: ${({
    // @ts-ignore
    row,
    theme,
  }) =>
    `${
      customColors[theme.palette.mode][
        StatusColorNames[row?.status] ?? "greyText"
      ]
    }29`};
`;

export const ClientBookingsPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { getClientLabelById } = useClientList();

  const userId = useAppSelector((state) => state.user.id);

  const [selectedId, setSelectedId] = useState("");

  // const { isAddClientVisible, setIsAddClientVisible } = useAddClient();

  const columns = [
    {
      label: "Pick Up At",
      field: "plannedStartTime",
      xs: 2,
      sm: 2,
      md: 2,
      sortableName: "PICKUP_TIME",
      renderer: (row) =>
        FP.match(row?.plannedStartTime)
          .on([undefined, null, ""], "A.S.A.P")
          .otherwise(unixTimeToUSDate(row?.plannedStartTime)),
    },
    {
      label: "Patient",
      field: "passengerName",
      xs: 2.5,
      sm: 2.5,
      md: 2.5,
      sortableName: "PATIENT_NAME",
    },
    {
      label: "Pick Up Address",
      field: "departureLabel",
      xs: 4.5,
      sm: 4.5,
      md: 4.5,
      sortableName: "PICKUP_ADDRESS",
      renderer: ({ departureLabel }) => (
        <TextFieldWithEllipsis>{departureLabel}</TextFieldWithEllipsis>
      ),
    },
    {
      label: "Region",
      field: "region",
      xs: 1.5,
      sm: 1.5,
      md: 1.5,
      sortableName: "REGION",
      renderer: ({ region }) => (
        <TextFieldWithEllipsis>{region}</TextFieldWithEllipsis>
      ),
    },
    {
      label: "Status",
      field: "status",
      xs: 1.5,
      sm: 1.5,
      md: 1.5,
      sortableName: "STATUS",
      renderer: (row) => (
        <Status
          // @ts-ignore
          row={row}
        >
          {convertToReadable(row?.status ?? "")}
        </Status>
      ),
    },
  ];

  const shortColumns = [
    {
      label: "Pick Up At",
      field: "plannedStartTime",
      xs: 4,
      sm: 4,
      md: 4,
      sortableName: "PICKUP_TIME",
      renderer: (row) =>
        FP.match(row?.plannedStartTime)
          .on([undefined, null, ""], "A.S.A.P")
          .otherwise(unixTimeToUSDate(row?.plannedStartTime)),
    },
    {
      label: "Patient",
      field: "passengerName",
      xs: 5,
      sm: 5,
      md: 5,
      sortableName: "PATIENT_NAME",
    },
    // {
    //   label: "Pick Up Address",
    //   field: "departureLabel",
    //   xs: 3,
    //   sm: 3,
    //   md: 3,
    //   sortableName: "DEPARTURE_LABEL",
    // },
    {
      label: "Status",
      field: "status",
      xs: 2,
      sm: 2,
      md: 2,
      sortableName: "STATUS",
      renderer: (row) => (
        <Status
          // @ts-ignore
          row={row}
        >
          {convertToReadable(row?.status ?? "")}
        </Status>
      ),
    },
    {
      xs: 1,
      sm: 1,
      md: 1,
      renderer: (row) => <Selector selectedId={selectedId} row={row} />,
    },
  ];

  const dataGrid = useDataGrid({
    apiHandler: getRides,
    defaultSorter: {
      name: "STATUS",
      order: "ASC",
    },
    defaultLimit: 25,
    gridConfigIndex: `${userId}-bookings`,
  });

  const handleRowClick = (row) => {
    if (selectedId === row?.id) {
      setSelectedId("");
      return;
    }

    setSelectedId(row?.id ?? "");
  };

  return (
    <SC.Container>
      <Stack direction={"row"} height={"100%"} position={"relative"}>
        <Content>
          <DataGrid
            columns={selectedId ? shortColumns : columns}
            handleRowClick={handleRowClick}
            {...dataGrid}
            actionsAfter={
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/booking/schedule_a_ride")}
              >
                Schedule a Ride
              </Button>
            }
            keyName="id"
            selectedId={selectedId}
            selectedColor={customColors[theme.palette.mode].additionalStroke}
          />
          {selectedId && (
            <SC.HalfScreenPanel>
              <CloseWindowButton
                top={-35}
                right={-35}
                closeHandler={() => setSelectedId("")}
              />
              <RideDetails
                id={selectedId}
                getClientLabelById={getClientLabelById}
              />
            </SC.HalfScreenPanel>
          )}
        </Content>
      </Stack>
    </SC.Container>
  );
};
