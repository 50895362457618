import React from "react";
import { Box, Stack, styled } from "@mui/material";
import { customColors } from "models/customColors";

const Container = styled(Stack)`
  flex: 1 1 auto;
  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].background};
`;

export const UnderConstruction = ({ componentName }) => {

  return (
    <Container
      width={"100%"}
      height={"70vh"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      gap={3}
      pt={3}
    >
      <Box>
        The <strong>"{componentName}"</strong> component is under construction.
      </Box>
    </Container>
  );
};
