import { Box, Button, MenuItem, Stack } from "@mui/material";
import { unixTimeToUSShortDate } from "utils";
import { DefaultAvatar } from "./DefaultAvatar";
import { DriverRejectDialog } from "./DriverRejectDialog";
import { ImageViewer } from "./ImageViewer";

import { ContextMenu } from "UI/ContextMenu";
import { AssignKericoVehicleDialog } from "./AssignKericoVehicleDialog";
import { CarRejectDialog } from "./CarRejectDialog";
import { useDriverDetailsWindow } from "./DriverDetails.hook";
import {
  ActiveSelector,
  ApprovalIndicator,
  Container,
  Detail,
  InactiveSelector,
  Label,
  OffShiftStatus,
  OnShiftStatus,
  Photo,
  RejectReason,
  StatisticItem,
  StatisticLabel,
  StatisticValue,
  StyledMenuItem,
  Title,
  Value,
} from "./DriverDetails.style";
import { DriverDetailsCarData } from "./DriverDetailsCarData";
import { MessageButton } from "./MessageButton";
import { VehicleApprovalSelector } from "./VehicleApprovalSelector";
import { QuizViewer } from "./QuizViewer";

export const DriverDetails = ({
  userId,
  closeDetails,
  refreshList,
  withMessageIcon = false,
}) => {
  const {
    driver,
    statistics,
    vehicles,
    mode,
    setMode,
    handleStatusChange,
    handleDeleteDriver,
    approveDriverRequest,
    approveCarRequest,
    isNotApproved,
    isDriverRejectionDialogOpen,
    setIsDriverRejectionDialogOpen,
    isCarRejectionDialogOpen,
    setIsCarRejectionDialogOpen,
    carId,
    setCarId,
    loadLicenseImage,
    loadInsuranceImage,
    showDriverPhoto,
    image,
    imageTitle,
    isAddKericoVehicleDialogOpen,
    setIsAddKericoVehicleDialogOpen,
    openChat,
    control,
    profileImage,
    currentStatus,
    loadData,
    setImage,
    handleCreateCheck,
    handleViewChecks,
    isQuizViewerOpen,
    setIsQuizViewerOpen,
  } = useDriverDetailsWindow({
    userId,
    closeDetails,
    refreshList,
  });

  return (
    <Container gap={2}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
      >
        <Stack
          flex={1}
          direction={"row"}
          alignItems={"center"}
          gap={2}
          flexWrap={"wrap"}
        >
          {!profileImage && <DefaultAvatar user={driver} radius="57px" />}
          {profileImage && (
            <Photo
              sx={{
                backgroundSize: `${profileImage ? "cover" : "auto"}`,
                backgroundImage: `url("${profileImage}")`,
                flex: "0 0 auto",
              }}
              onClick={showDriverPhoto}
            />
          )}
          <Title>
            {driver?.firstName ?? "Firstname"} {driver?.lastName ?? "Lastname"}
          </Title>
          {withMessageIcon && <MessageButton openChat={openChat} />}
          <Stack direction={"row"} gap={1}>
            {isNotApproved && (
              <ApprovalIndicator>Awaiting Approval</ApprovalIndicator>
            )}
            {driver?.workStatus === "On_Shift" && !isNotApproved && (
              <OnShiftStatus>On Shift</OnShiftStatus>
            )}
            {driver?.workStatus === "Off_Shift" && !isNotApproved && (
              <OffShiftStatus>Off Shift</OffShiftStatus>
            )}
            {!isNotApproved && currentStatus === "ACTIVE" && (
              <Box>
                <ActiveSelector
                  id="status"
                  name="status"
                  control={control}
                  options={{
                    ACTIVE: "Active",
                    BLOCKED: "Blocked",
                  }}
                  withNone={false}
                  onChange={handleStatusChange}
                />
              </Box>
            )}
            {!isNotApproved && currentStatus === "BLOCKED" && (
              <Box>
                <InactiveSelector
                  id="status"
                  name="status"
                  control={control}
                  options={{
                    ACTIVE: "Active",
                    BLOCKED: "Blocked",
                  }}
                  withNone={false}
                  onChange={handleStatusChange}
                />
              </Box>
            )}
            {driver?.status === "REJECTED" && (
              <ApprovalIndicator>Rejected</ApprovalIndicator>
            )}
          </Stack>
        </Stack>
        <ContextMenu>
          <MenuItem onClick={handleCreateCheck}>
            Run New Background Check
          </MenuItem>
          <MenuItem onClick={handleViewChecks}>View Background Checks</MenuItem>
          <MenuItem onClick={() => setIsQuizViewerOpen(true)}>
            View Quiz Results
          </MenuItem>
          <StyledMenuItem onClick={handleDeleteDriver}>
            Remove the driver
          </StyledMenuItem>
        </ContextMenu>
      </Stack>
      {!isNotApproved && (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={2}
        >
          <StatisticItem>
            <StatisticLabel>All Rides</StatisticLabel>
            <StatisticValue>{statistics?.allRides ?? "-"}</StatisticValue>
          </StatisticItem>
          <StatisticItem>
            <StatisticLabel>Completed</StatisticLabel>
            <StatisticValue>{statistics?.completedRides ?? "-"}</StatisticValue>
          </StatisticItem>
          <StatisticItem>
            <StatisticLabel>Rejected</StatisticLabel>
            <StatisticValue>{statistics?.rejectedRides ?? "-"}</StatisticValue>
          </StatisticItem>
          <StatisticItem>
            <StatisticLabel>Average Ride Time</StatisticLabel>
            <StatisticValue>
              {statistics?.averageRideTimeMin ?? "-"}
            </StatisticValue>
          </StatisticItem>
        </Stack>
      )}
      <Stack gap={1}>
        <Stack gap={1.5}>
          <Detail>
            <Label>Region:</Label>
            <Value>{driver?.region?.label ?? "-"}</Value>
          </Detail>
          <Stack direction={"row"} flexWrap={"wrap"} gap={3}>
            <Detail>
              <Label>Phone Number:</Label>
              <Value>{driver?.phone ?? "-"}</Value>
            </Detail>
            <Detail>
              <Label>Email:</Label>
              <Value>{driver?.email ?? "-"}</Value>
            </Detail>
          </Stack>
          <Detail>
            <Label sx={{ flex: "0 0 auto" }}>Home Address:</Label>
            <Value>{driver?.homeAddress?.placeLabel ?? "-"}</Value>
          </Detail>
        </Stack>
        {driver?.status === "REJECTED" && (
          <RejectReason>
            Reject reason: <strong>{driver?.notification}</strong>
          </RejectReason>
        )}
        {!!driver?.licenseDto?.photoId && (
          <Stack direction={"row"} gap={1}>
            <Detail>
              <Label>Driver's License:</Label>
              <Value>
                {driver?.licenseDto?.expTimeMs
                  ? `Expires on ${unixTimeToUSShortDate(
                      driver?.licenseDto?.expTimeMs
                    )}`
                  : "-"}
              </Value>
            </Detail>
            <Button variant="outlined" onClick={loadLicenseImage}>
              View
            </Button>
          </Stack>
        )}
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={1}
      >
        {isNotApproved && (
          <Stack
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            gap={1}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={() => setIsDriverRejectionDialogOpen(true)}
            >
              Reject Driver
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={approveDriverRequest}
            >
              Approve Driver
            </Button>
          </Stack>
        )}
      </Stack>
      <Stack
        gap={2}
        direction={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        mt={2}
      >
        <Title>Vehicles</Title>
        <VehicleApprovalSelector
          mode={mode}
          setMode={setMode}
          refreshData={loadData}
        />
      </Stack>
      {vehicles.length === 0 && (
        <Stack mt={2} alignItems={"center"}>
          No data
        </Stack>
      )}
      {vehicles.map((vehicle) => (
        <DriverDetailsCarData
          key={vehicle.id}
          vehicle={vehicle}
          approveCarRequest={approveCarRequest}
          setCarId={setCarId}
          setIsCarRejectionDialogOpen={setIsCarRejectionDialogOpen}
          loadInsuranceImage={loadInsuranceImage}
        />
      ))}
      {!isNotApproved && (
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsAddKericoVehicleDialogOpen(true)}
          >
            Add Kerico Vehicle
          </Button>
        </Box>
      )}
      {image && (
        <ImageViewer
          image={image}
          setImage={setImage}
          imageTitle={imageTitle}
        />
      )}
      {isQuizViewerOpen && (
        <QuizViewer
          driver={driver}
          profileImage={profileImage}
          handleClose={() => setIsQuizViewerOpen(false)}
        />
      )}
      <DriverRejectDialog
        open={isDriverRejectionDialogOpen}
        driverId={userId}
        handleClose={() => setIsDriverRejectionDialogOpen(false)}
        closeDetails={closeDetails}
        refreshList={refreshList}
      />
      <CarRejectDialog
        open={isCarRejectionDialogOpen}
        carId={carId}
        handleClose={() => setIsCarRejectionDialogOpen(false)}
        closeDetails={closeDetails}
        refreshList={refreshList}
      />
      <AssignKericoVehicleDialog
        open={isAddKericoVehicleDialogOpen}
        handleClose={() => setIsAddKericoVehicleDialogOpen(false)}
        refreshData={() => {
          loadData();
          refreshList();
        }}
        driverId={userId}
        driverFullname={`${driver?.firstName} ${driver?.lastName}`}
      />
    </Container>
  );
};
