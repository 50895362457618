import { httpService } from "./httpService";

/**
 * @template T
 * @typedef {import('types/commonTypes').HttpResult<T>} HttpResult
 */

/**
 * @typedef {import('types/commonTypes').DriversResponse} DriversResponse
 * @param {import('types/commonTypes').TableRequest} data
 * @returns  {HttpResult<DriversResponse>}
 */
export const getDriversByWorkStatus = async (data) =>
  httpService({
    url: "/users/driver/by-work-status/search",
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @param {import('types/commonTypes').TableRequest} data
 * @returns  {HttpResult<DriversResponse>}
 */
export const getDrivers = async (data) =>
  httpService({
    url: "/users/driver/by-account-status/search",
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @typedef {import('types/commonTypes').DriverDetails} DriverDetails
 * @param {{ driverId: string; data: import('types/commonTypes').ApprovalDto }} props
 * @returns  {HttpResult<DriverDetails>}
 */
export const updateDriverApproval = async ({ driverId, data }) =>
  httpService({
    url: `/users/driver/${driverId}/approve`,
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @typedef {import('types/commonTypes').DriverStatistic} DriverStatistic
 * @param {string} driverId
 * @returns  {HttpResult<DriverStatistic>}
 */
export const getDriverStatistic = async (driverId) =>
  httpService({
    url: `/users/driver/${driverId}/statistic`,
    options: {
      method: "GET",
    },
  });

/**
 * @param {string} driverId
 * @returns  {HttpResult<any>}
 */
export const deleteDriver = async (driverId) =>
  httpService({
    url: `/users/driver/${driverId}`,
    options: {
      method: "DELETE",
    },
  });

/**
 * @typedef {import('types/commonTypes').DriverWorkStatus} DriverWorkStatus
 * @param {{ userId: string; workStatus: DriverWorkStatus }} data
 * @returns  {HttpResult<DriverDetails>}
 */
export const updateDriverStatus = async ({ userId, workStatus }) =>
  httpService({
    url: `/users/driver/${userId}/status`,
    options: {
      method: "PUT",
      data: { workStatus },
    },
  });

/**
 * @typedef {import('types/commonTypes').BackgroundCheckList} BackgroundCheckList
 * @param {string} driverId
 * @returns  {HttpResult<BackgroundCheckList>}
 */
export const getDriverBackgroundCheckListById = async (driverId) =>
  httpService({
    url: `/checkr/background-checks/user/${driverId}`,
    options: {
      method: "GET",
    },
  });

/**
 * @typedef {import('types/commonTypes').CreateBackgroundCheckRequest} CreateBackgroundCheckRequest
 * @param {import('types/commonTypes').TableRequest} data
 * @returns  {HttpResult<BackgroundCheckList>}
 */
export const createBackgroundCheck = async (data) =>
  httpService({
    url: "/checkr/background-checks/order",
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @typedef {import('types/commonTypes').QuizResult} QuizResult
 * @param {string} driverId
 * @returns  {HttpResult<QuizResult>}
 */
export const getDriverQuizResult = async (driverId) =>
  httpService({
    url: `/quizzes/driver/results/${driverId}`,
    options: {
      method: "GET",
    },
  });
