import React, { useState, useRef, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { useApiRequest } from "api/useApiRequest";
import { changePassword } from "api/authApi";
import { Stack, IconButton, InputLabel, Alert, Button } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ReactComponent as UserIcon } from "assets/img/user-small-icon.svg";

import {
  getPasswordConfirmValidation,
  passwordValidation,
} from "models/validations";
import * as UI from "UI";
import * as FP from "utils/fp-js";
import { GlobalContext } from "store/globalContext";

const defaultFormData = {
  oldPassword: "",
  newPassword: "",
  newPasswordRepeat: "",
};

export const ChangePasswordDialog = ({ open, handleClose }) => {
  const { showWarning } = useContext(GlobalContext);
  const { callApiRequest } = useApiRequest();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [error, setError] = useState("");
  const { control, handleSubmit, getValues, reset, watch } = useForm({
    defaultValues: defaultFormData,
  });

  const password = useRef("");
  password.current = watch("newPassword", "");

  const onSubmit = () => {
    const { oldPassword, newPassword } = getValues();
    setError("");

    FP.asyncEither({
      value: callApiRequest({
        apiRequest: changePassword,
        params: {
          oldPassword,
          newPassword,
        },
        skipErrorHandling: true,
      }),
      leftFn: setError,
      rightFn: () => {
        reset(defaultFormData);
        handleClose();
        showWarning({
          title: "User Password Updated",
          content: "User password has been successfully updated",
          icon: <UserIcon />,
        });
      },
    });
  };

  const closeAndReset = () => {
    setError("");
    reset(defaultFormData);
    handleClose();
  };

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open, reset]);

  return (
    <UI.DialogWindow
      open={open}
      withCloseButton
      handleClose={closeAndReset}
      title={"Change Password"}
      content={
        <Stack gap={4}>
          <Stack alignItems={"flex-start"}>
            <InputLabel htmlFor="oldPassword">Current password</InputLabel>
            <UI.FormTextField
              id="oldPassword"
              name="oldPassword"
              control={control}
              type={isPasswordVisible ? "text" : "password"}
              InputProps={{
                autoComplete: "off",
              }}
            />
          </Stack>
          <Stack gap={2}>
            <Stack alignItems={"flex-start"}>
              <InputLabel htmlFor="newPassword">New password</InputLabel>
              <UI.FormTextField
                id="newPassword"
                name="newPassword"
                control={control}
                rules={passwordValidation}
                type={isPasswordVisible ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      color="info"
                      onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    >
                      {isPasswordVisible ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  ),
                  autoComplete: "off",
                }}
              />
            </Stack>
            <Stack alignItems={"flex-start"}>
              <InputLabel htmlFor="newPasswordRepeat">
                Confirm new password
              </InputLabel>
              <UI.FormTextField
                id="newPasswordRepeat"
                name="newPasswordRepeat"
                control={control}
                rules={getPasswordConfirmValidation(password.current)}
                type={isPasswordVisible ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      color="info"
                      onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    >
                      {isPasswordVisible ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  ),
                  autoComplete: "off",
                }}
              />
            </Stack>
          </Stack>
          {error && <Alert severity="error">{error}</Alert>}
          <Stack
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            gap={1}
          >
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit(onSubmit)}>
              Update Password
            </Button>
          </Stack>
        </Stack>
      }
    />
  );
};
