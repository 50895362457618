import React from "react";
import { Grid } from "@mui/material";
import * as SC from "./DataGrid.style";
import { ReactComponent as AscIcon } from "assets/img/sort_asc.svg";
import { ReactComponent as DescIcon } from "assets/img/sort_desc.svg";
import * as FP from "utils/fp-js";
import { DataGridSelector } from "./DataGridSelector";

export const DataGridHeader = ({
  columns,
  rows,
  sorter,
  handleSort,
  visibility = [],
  withSelector = false,
  selectedRows = [],
  setSelectedRows = () => null,
  keyName,
}) => {
  const visibleSize = withSelector
    ? {
        xs: 12.5,
        sm: 12.5,
        md: 12.5,
      }
    : {
        xs: 12,
        sm: 12,
        md: 12,
      };

  const getMdByField = (field) =>
    columns.find((item) => item.field === field)?.md ?? 0;

  if (visibility.length > 0) {
    visibleSize.md = visibility.reduce(
      (acc, next) => acc + getMdByField(next),
      0
    );
  }

  const convertAlign = (align) =>
    FP.match(align)
      .on("left", "flex-start")
      .on("right", "flex-end")
      .otherwise("center");

  return (
    <SC.Header container wrap={"wrap"} columns={visibleSize}>
      {withSelector && (
        <DataGridSelector
          header
          keyName={keyName}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          rows={rows}
        />
      )}
      {columns.map(
        (
          {
            label,
            field,
            xs,
            sm,
            md,
            sortableName,
            align = "left",
            headerAlign = convertAlign(align),
          },
          index
        ) =>
          (visibility.length === 0 || visibility.includes(field)) && (
            <Grid
              key={`${label}-${field}-${index}`}
              item
              xs={xs}
              sm={sm}
              md={md}
            >
              {field && sortableName ? (
                <SC.HeaderItem
                  onClick={() => handleSort(sortableName)}
                  sx={{
                    justifyContent: headerAlign,
                  }}
                >
                  {label}
                  {sortableName === sorter.name && sorter.order === "ASC" && (
                    <AscIcon />
                  )}
                  {sortableName === sorter.name && sorter.order === "DESC" && (
                    <DescIcon />
                  )}
                </SC.HeaderItem>
              ) : (
                <SC.HeaderItemWithoutSorter
                  sx={{
                    justifyContent: headerAlign,
                  }}
                >
                  {label}
                </SC.HeaderItemWithoutSorter>
              )}
            </Grid>
          )
      )}
    </SC.Header>
  );
};
