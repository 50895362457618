import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { UnderConstruction } from "pages/UnderConstruction";
import { CommonLayout } from "components/CommonLayout";
import { ReactComponent as DashboardIcon } from "assets/img/dashboard-icon.svg";
import { ReactComponent as BookingIcon } from "assets/img/bookings-icon.svg";
import { ReactComponent as AnalitycsIcon } from "assets/img/analitycs-icon.svg";
import { ReactComponent as SettingsIcon } from "assets/img/settings-icon.svg";
import { ReactComponent as PaymentsIcon } from "assets/img/payments-icon.svg";
import { CreateBookingPage } from "pages/CreateBookingPage";
import { ClientBookingsPage } from "pages/ClientBookingsPage";
import { DashboardPage } from "pages/DashboardPage";
import { ClientCompanyInvoicesPage } from "pages/ClientCompanyInvoicesPage";
import { ClientInvoice } from "pages/ClientInvoice";
import { RideChatPage } from "pages/RideChatPage";
import { ClientManagerSettings } from "pages/ClientManagerSettings";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <CommonLayout
        menu={[
          {
            icon: <DashboardIcon />,
            label: "Dashboard",
            path: "/",
          },
          {
            icon: <BookingIcon />,
            label: "Booking",
            path: "/booking",
          },
          {
            icon: <PaymentsIcon />,
            label: "Invoices",
            path: "/invoices",
          },
          {
            icon: <AnalitycsIcon />,
            label: "Analytics",
            path: "/analytics",
          },
          {
            icon: <SettingsIcon />,
            label: "Settings",
            path: "/settings",
          },
        ]}
      />
    ),
    children: [
      {
        path: "/",
        element: <DashboardPage />,
      },
      {
        path: "/booking",
        element: <ClientBookingsPage />,
      },
      {
        path: "/booking/schedule_a_ride",
        element: <CreateBookingPage />,
      },
      {
        path: "/analytics",
        element: <UnderConstruction componentName={"SUPER_ADMIN analytics"} />,
      },
      {
        path: "/invoices",
        element: <ClientCompanyInvoicesPage />,
      },
      {
        path: "/invoice/:id",
        element: <ClientInvoice />,
      },
      {
        path: "/ride_chat/:id",
        element: <RideChatPage />,
      },
      {
        path: "/settings",
        element: <ClientManagerSettings />,
      },
      {
        path: "*",
        element: <Navigate to={"/"} replace={true} />,
      },
    ],
  },
]);

const ClientManager = () => <RouterProvider router={router} />;

export default ClientManager;
