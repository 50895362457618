import React, { useEffect } from "react";
import { Box, TextField, Autocomplete } from "@mui/material";
import { useApiRequest } from "api/useApiRequest";
import { getLocationsBySearch } from "api/geoApi";
import {
  AUTOCOMPLETE_TYPING_DELAY_IN_MS,
  AUTOCOMPLETE_MAX_NUMBER_OF_ITEMS,
} from "models/constants";
import { useAutocompleteOptions } from "hooks/useAutocompleteOptions";

const MIN_SEARCH_LENGTH = 2;

export default function LocationSelector({
  id,
  setValue,
  defaultValue = { id: "", suggestion: "" },
  sx = {},
  error = "",
}) {
  const { callApiRequest } = useApiRequest();

  const {
    noOptionsText,
    setIsLoading,
    setIsLoaded,
    list,
    setList,
    search,
    setSearch,
  } = useAutocompleteOptions({
    startTyping: "Start typing address",
    minSearchLength: MIN_SEARCH_LENGTH,
  });

  const handleChange = async (e) => {
    const value = e?.target?.value ?? "";
    setSearch(value);
  };

  const handleSelect = (e, option) => {
    if (setValue) {
      setValue(option);
    }
  };

  const handleClose = (e) => {
    setSearch("");
    setList([]);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoaded(false);
      if (search?.length > MIN_SEARCH_LENGTH) {
        setIsLoading(true);
        callApiRequest({
          apiRequest: getLocationsBySearch,
          params: {
            search,
            awsLimit: AUTOCOMPLETE_MAX_NUMBER_OF_ITEMS,
            dbLimit: AUTOCOMPLETE_MAX_NUMBER_OF_ITEMS,
          },
          onError: () => {
            setList([]);
            setIsLoading(false);
            setIsLoaded(true);
          },
          onSuccess: (result) => {
            setList(result);
            setIsLoading(false);
            setIsLoaded(true);
          },
          skipErrorHandling: true,
        });
        return;
      }

      if (!search || search === "" || search?.length <= MIN_SEARCH_LENGTH) {
        setIsLoaded(true);
        setList([]);
      }
    }, AUTOCOMPLETE_TYPING_DELAY_IN_MS);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Autocomplete
      id={id}
      sx={{ width: "300px", ...sx }}
      defaultValue={defaultValue}
      options={list}
      noOptionsText={noOptionsText}
      getOptionLabel={(option) => option.suggestion}
      filterOptions={(options) => options}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
          key={option?.id ?? option?.awsId}
        >
          {option.suggestion}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Choose a location"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
            onDragOver: (e) => {
              const target = e?.target;
              if (!target) return;
              // @ts-ignore
              target?.focus();
              // @ts-ignore
              target?.select();
              if (target.hasOwnProperty("value")) {
                // @ts-ignore
                target.value = "";
              }
            },
          }}
          // onChange={handleChange1}
          error={!!error}
          // @ts-ignore
          helperText={error}
        />
      )}
      autoHighlight
      onChange={handleSelect}
      onClose={handleClose}
      onInputChange={handleChange}
      autoComplete
      handleHomeEndKeys
    />
  );
}
