import { useCallback, useContext, useEffect } from "react";
import {
  setIsDataChanged,
  resetIsDataChanged,
} from "store/reducers/isDataChangedReducer";
import { GlobalContext } from "store/globalContext";
import { useAppDispatch } from "store/hooks";

export const useFormDataSync = ({
  isDirty,
  reset = () => null,
  open = true,
  handleClose = () => null,
}) => {
  const dispatch = useAppDispatch();
  const { openConfirmationWithCallback } = useContext(GlobalContext);

  const handleForcedClose = useCallback(() => {
    dispatch(resetIsDataChanged());
    handleClose();
  }, [dispatch, handleClose]);

  const handleConfirmedAction = useCallback(
    (callbackFn) => {
      if (isDirty) {
        openConfirmationWithCallback({
          title: "Are you sure?",
          message: "All entered data will be lost.",
          callbackFn,
        });
        return;
      }
      callbackFn();
    },
    [isDirty, openConfirmationWithCallback]
  );

  const handleConfirmedClose = useCallback(() => {
    handleConfirmedAction(handleClose);
  }, [handleClose, handleConfirmedAction]);

  useEffect(() => {
    reset();
  }, [open, reset]);

  useEffect(() => {
    dispatch(setIsDataChanged(isDirty));
  }, [dispatch, isDirty]);

  return {
    handleConfirmedClose,
    handleForcedClose,
    handleConfirmedAction,
  };
};
