import { Box, Button, Stack, styled, useTheme } from "@mui/material";
import { ReactComponent as SmallLogo } from "assets/img/Symbol_DarkBlue.svg";
import { ReactComponent as WhiteSmallLogo } from "assets/img/Symbol_White.svg";
import { customColors } from "models/customColors";
import { unixTimeToUSShortDate } from "utils";
import { Detail, Label, Value } from "./DriverDetails.style";

const CarData = styled(Stack)`
  padding: 20px;
  border-radius: 16px;
  border: 1px solid
    ${({ theme }) => customColors[theme.palette.mode].additionalStroke};
  background: ${({ theme }) => customColors[theme.palette.mode].background};
`;

export const RejectReason = styled(Box)`
  color: ${({ theme }) => customColors[theme.palette.mode].error};
`;

export const CarInfo = ({
  vehicle,
  carId,
  setIsRejectionDialogOpen,
  loadInsuranceImage,
  approveCarRequest,
}) => {
  const theme = useTheme();

  return (
    <CarData
      gap={2}
      sx={{
        backgroundColor:
          vehicle?.fleetStatus === "INACTIVE"
            ? customColors[theme.palette.mode].error + "19"
            : "transparent",
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={1}
      >
        <Detail flex={1}>
          <Stack direction={"row"}>
            {vehicle?.owner === "KERICO" && (
              <>
                {theme.palette.mode === "dark" ? (
                  <WhiteSmallLogo height="22px" />
                ) : (
                  <SmallLogo height="22px" />
                )}
              </>
            )}
            <Label>Vehicle:</Label>
          </Stack>
          <Value>
            {vehicle?.make} {vehicle?.model} {vehicle?.year}
            {", "}
            {vehicle?.licensePlate?.state
              ? `${vehicle?.licensePlate?.state}, `
              : ""}
            {vehicle?.licensePlate?.licensePlate}
          </Value>
        </Detail>
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={1}
          flexWrap={"wrap"}
        >
          <Detail>
            <Label>Expires:</Label>
            <Value>
              {vehicle?.insuranceDto?.expTimeMs &&
                unixTimeToUSShortDate(vehicle?.insuranceDto?.expTimeMs)}
            </Value>
          </Detail>
          <Button
            variant="outlined"
            onClick={() => loadInsuranceImage(vehicle?.insuranceDto?.photoId)}
            disabled={!vehicle?.insuranceDto?.photoId}
          >
            View
          </Button>
        </Stack>
      </Stack>
      {vehicle?.fleetStatus === "AWAITING" && (
        <Stack
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          gap={1}
        >
          <Button
            variant="outlined"
            color="error"
            onClick={() => setIsRejectionDialogOpen(true)}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => approveCarRequest(carId)}
          >
            Approve
          </Button>
        </Stack>
      )}
      {vehicle?.fleetStatus === "INACTIVE" && (
        <RejectReason>
          Reject reason: <strong>{vehicle?.notes}</strong>
        </RejectReason>
      )}
    </CarData>
  );
};
