import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, Button, Divider, Stack, styled } from "@mui/material";
import * as SC from "SC";
import { BackButton } from "components/BackButton";
import { useNavigate, useParams } from "react-router-dom";
import { useApiRequest } from "api/useApiRequest";
import { deleteClientById } from "api/clientApi";
import { customColors } from "models/customColors";
import { GlobalContext } from "store/globalContext";
import { useSpecialityOptions } from "hooks/useSpecialityOptions";
import { ReactComponent as UserIcon } from "assets/img/user-small-icon.svg";
import { AddClientLocationDialog } from "components/AddClientLocationDialog";
import { AddClientUserDialog } from "components/AddClientUserDialog";
import { convertToReadable } from "utils";
import { deleteLocationById } from "api/geoApi";
import { deleteClientUserById, getClientUserById } from "api/userApi";
import { MultiMapView } from "UI/MultiMapView";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { EditClientDialog } from "components/EditClientDialog";
import { MessageButton } from "components/MessageButton";
import { addUserToChat, createNewChat, searchChatByName } from "api/messageApi";
import { showError } from "store/reducers/errorReducer";
import { useClient } from "hooks/useClient";

export const SectionTitle = styled(Stack)`
  font-family: "Inter Medium";
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;

  color: ${({ theme }) => customColors[theme.palette.mode].greyText};
`;

export const DataRow = styled(Stack)`
  flex-direction: row;
  gap: 10px;

  font-family: "Inter";
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;

  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
`;

export const MapHolder = styled(Stack)`
  flex: 1 1 auto;
  justify-content: center;
  border-radius: 20px;

  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].additionalStroke};
  overflow: hidden;
`;

export const UserRow = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const ClientDetails = () => {
  const { callApiRequest } = useApiRequest();
  const { openConfirmationWithCallback, showWarning } =
    useContext(GlobalContext);
  const dispatch = useAppDispatch();

  const { id } = useParams();
  const navigate = useNavigate();
  const role = useAppSelector((state) => state?.user?.role);

  const { client, loadClientData } = useClient({ id });
  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
  const { specialityOptions, loadSpecialities } = useSpecialityOptions();
  const [isAddNewLocationOpen, setIsAddNewLocationOpen] = useState(false);
  const [isAddClientUserOpen, setIsAddClientUserOpen] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [reloadSignal, setReloadSignal] = useState(false);
  const mattermostUserId = useAppSelector(
    (state) => state.user.mattermostUserId
  );

  const sendRedrawMapSignal = () => setReloadSignal(!reloadSignal);

  const handleDelete = () =>
    openConfirmationWithCallback({
      title: "Are you sure?",
      message: "This action cannot be undone! Proceed?",
      callbackFn: () => {
        callApiRequest({
          apiRequest: deleteClientById,
          params: id,
          onSuccess: () => {
            navigate(-1);
          },
        });
      },
    });

  const deleteLocation = (id) =>
    callApiRequest({
      apiRequest: deleteLocationById,
      params: id,
      onSuccess: () => {
        showWarning({
          title: "Location Deleted",
          content: "The Location has been successfully deleted",
          icon: <UserIcon />,
        });
        loadClientData();
      },
    });

  const deleteUser = (id) =>
    callApiRequest({
      apiRequest: deleteClientUserById,
      params: id,
      onSuccess: () => {
        showWarning({
          title: "Client User Deleted",
          content: "The Client User has been successfully deleted",
          icon: <UserIcon />,
        });
        loadClientData();
      },
    });

  const handleDeleteLocation = (id) => {
    openConfirmationWithCallback({
      title: "Are you sure?",
      message: "This action cannot be undone. Proceed?",
      callbackFn: () => deleteLocation(id),
    });
  };

  const handleDeleteUser = (id) => {
    openConfirmationWithCallback({
      title: "Are you sure?",
      message: "This action cannot be undone. Proceed?",
      callbackFn: () => deleteUser(id),
    });
  };

  const openChat = async (userId) => {
    if (!userId) {
      return;
    }

    // 1) Check if the chat exists
    const existingChat = await callApiRequest({
      apiRequest: searchChatByName,
      params: `client_${userId}`,
    });

    if (existingChat?.error) {
      dispatch(showError(existingChat.error));
      return;
    }

    if (existingChat?.value?.length === 1) {
      // try to add my self to the chat
      const channel_id = existingChat.value[0].id;

      const data = {
        chatId: channel_id,
        userId: mattermostUserId,
      };

      const result = await callApiRequest({
        apiRequest: addUserToChat,
        params: data,
      });

      if (result?.error) {
        dispatch(showError(`Error adding member to chat: ${result.error}`));
        return;
      }

      navigate(`/client_chat/${userId}/${existingChat.value[0].id}`);
      return;
    }

    // get client user details
    const userResult = await callApiRequest({
      apiRequest: getClientUserById,
      params: userId,
    });

    if (userResult?.error) {
      dispatch(showError(`User details error: ${userResult.error}`));
      return;
    }

    const user = userResult?.value;

    // 2) Create new chat if it doesn't exist
    const params = {
      name: `client_${userId}`,
      team_id: process.env.REACT_APP_MATTERMOST_TEAM_ID,
      display_name: `Client_${userId}`,
      type: "O",
      purpose: `${user?.firstName} ${user?.lastName}, ${convertToReadable(
        user?.role
      )}, ${user?.phone}, ${client?.companyName}`,
      header: "",
    };
    const result = await callApiRequest({
      apiRequest: createNewChat,
      params,
    });

    if (result?.error) {
      dispatch(showError(`Chat creation error: ${result.error}`));
      return;
    }

    const channel_id = result?.value?.id;
    const userIds = [mattermostUserId, user?.mattermostUserId];

    // add members into the newly created chat
    for (const user_id of userIds) {
      const data = {
        chatId: channel_id,
        userId: user_id,
      };
      const result = await callApiRequest({
        apiRequest: addUserToChat,
        params: data,
      });

      if (result?.error) {
        dispatch(showError(`Error adding member to chat: ${result.error}`));
        return;
      }
    }

    navigate(`/client_chat/${userId}/${channel_id}`);
  };

  useEffect(() => {
    const markers = (client?.places ?? []).map(
      ({ id, placeLabel, coordinates }) => ({
        id,
        placeLabel,
        coordinates,
      })
    );
    setMarkers(markers);
    sendRedrawMapSignal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  const locations = useMemo(
    () =>
      (client?.places ?? []).filter(({ id }) => id !== client?.headOffice?.id),
    [client]
  );
  const users = useMemo(() => client?.employees ?? [], [client]);

  return (
    <SC.OverflowedContainer>
      <SC.Layout>
        <Stack direction={"row"} alignItems={"center"}>
          <BackButton />
          <SC.Title>{client?.companyName}</SC.Title>
        </Stack>
        {client?.companyName && (
          <Stack
            flex="1"
            direction={"column"}
            justifyContent={"space-between"}
            gap={1}
          >
            <Stack direction={"row"} justifyContent={"space-between"} gap={4}>
              <Stack flex={1}>
                <Stack gap={1}>
                  <SectionTitle>Details</SectionTitle>
                  <Divider />
                  <DataRow>
                    <SectionTitle>Email:</SectionTitle>
                    {client?.email}
                  </DataRow>
                  <DataRow>
                    <SectionTitle>Phone:</SectionTitle>
                    {client?.phoneNumber}
                  </DataRow>
                  <DataRow>
                    <SectionTitle>Address:</SectionTitle>
                    {client?.label}
                  </DataRow>
                  <DataRow>
                    <SectionTitle>Facility Type:</SectionTitle>
                    {client?.speciality}
                  </DataRow>
                </Stack>
                <Stack
                  mt={2}
                  direction={"row"}
                  justifyContent={"flex-start"}
                  gap={1}
                >
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleDelete}
                  >
                    Delete Client
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => setIsEditDialogVisible(true)}
                  >
                    Edit
                  </Button>
                </Stack>
              </Stack>
              <Stack flex={1}>
                <SectionTitle>Locations ({locations?.length})</SectionTitle>
                <Divider />
                <Stack>
                  {locations.map(({ id, placeLabel }) => (
                    <Stack
                      key={id}
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      {placeLabel}
                      <Button
                        size="small"
                        color="error"
                        onClick={() => handleDeleteLocation(id)}
                      >
                        Remove
                      </Button>
                    </Stack>
                  ))}
                </Stack>
                <Box>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={() => setIsAddNewLocationOpen(true)}
                  >
                    Add Location
                  </Button>
                </Box>
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              gap={4}
              flex={1}
            >
              <MapHolder>
                <MultiMapView
                  // @ts-ignore
                  markers={markers}
                  reloadSignal={reloadSignal}
                />
              </MapHolder>
              <Stack gap={1} flex={1}>
                <SectionTitle>Client Users ({users?.length})</SectionTitle>
                <Divider />
                <Stack gap={1}>
                  {users.map(
                    ({
                      id,
                      firstName,
                      lastName,
                      email,
                      role,
                      mattermostUserId,
                    }) => (
                      <UserRow key={id}>
                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                          {!!mattermostUserId &&
                            mattermostUserId !== "unknown" && (
                              <Stack
                                justifyContent={"center"}
                                alignItems={"center"}
                                flex={0}
                              >
                                <MessageButton
                                  size="small"
                                  openChat={() => openChat(id)}
                                />
                              </Stack>
                            )}
                          <Box>
                            {!!firstName &&
                              !!lastName &&
                              `${firstName} ${lastName}, `}
                            {convertToReadable(role)},{" "}
                            <a href={`mailto:${email}`}>{email}</a>,
                          </Box>
                        </Stack>
                        <Button
                          size="small"
                          color="error"
                          onClick={() => handleDeleteUser(id)}
                        >
                          Remove
                        </Button>
                      </UserRow>
                    )
                  )}
                </Stack>
                <Box>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={() => setIsAddClientUserOpen(true)}
                  >
                    Add Client User
                  </Button>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        )}
      </SC.Layout>
      {["SUPER_ADMIN", "KERICO_MANAGER"].includes(role) && (
        <EditClientDialog
          open={isEditDialogVisible}
          handleClose={() => setIsEditDialogVisible(false)}
          refreshList={loadClientData}
          specialityOptions={specialityOptions}
          loadSpecialities={loadSpecialities}
          client={client}
          id={id}
        />
      )}
      <AddClientLocationDialog
        open={isAddNewLocationOpen}
        handleClose={() => setIsAddNewLocationOpen(false)}
        refsreshData={loadClientData}
        clientId={id}
      />
      <AddClientUserDialog
        open={isAddClientUserOpen}
        refreshList={loadClientData}
        handleClose={() => setIsAddClientUserOpen(false)}
        clientId={id}
      />
    </SC.OverflowedContainer>
  );
};
