import React from "react";
import {
  IncomingHolder,
  IncomingMessage as MessageBody,
  Time,
  Avatar,
} from "./Messenger.style";
import { Stack } from "@mui/material";
import { unixTimeToUSDate } from "utils";

export const IncomingMessage = ({ message, create_at, userLabel, avatar }) => {
  return (
    <IncomingHolder>
      <Stack direction={"row"}>
        <Avatar
          sx={{
            backgroundSize: `${avatar ? "cover" : "auto"}`,
            backgroundImage: `url("${avatar}")`,
            flex: "0 0 auto",
          }}
        />
        <Stack gap={0.5}>
          <Stack
            direction={"row"}
            justifyContent={"flex-start"}
            gap={0.7}
            ml={0.6}
            flexWrap={"wrap"}
          >
            {userLabel}
            <Time>{unixTimeToUSDate(create_at)}</Time>
          </Stack>
          <MessageBody>
            {message?.split("\n").map((line, index) => (
              <React.Fragment key={`${line}-${index}`}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </MessageBody>
        </Stack>
      </Stack>
    </IncomingHolder>
  );
};
