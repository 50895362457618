import React, { useState } from "react";
import {
  Box,
  IconButton,
  Button,
  Stack,
  styled,
  useTheme,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { customColors } from "models/customColors";
import { convertToReadable, removeCountry, unixTimeToUSDate } from "utils";
import { ReactComponent as VanIcon } from "assets/img/van-icon.svg";
import { ReactComponent as SedanIcon } from "assets/img/sedan-icon.svg";
import { ReactComponent as WheelchairIcon } from "assets/img/wheelchair-icon.svg";
import { ReactComponent as ChangeDriverIcon } from "assets/img/change-driver-icon.svg";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useAppSelector } from "store/hooks";
import { AssignDriverDialog } from "./AssignDriverDialog";
import { useLocation, useNavigate } from "react-router-dom";
import { addUserToChat } from "api/messageApi";
import { useApiRequest } from "api/useApiRequest";
import { MessageButton } from "./MessageButton";
import { CancelBookingDialog } from "./CancelBookingDialog";
import { Status as StatusExternal } from "pages/BookingsPage.style";
import { CompleteBookingDialog } from "./CompleteBookingDialog";
import { HumanizedDuration } from "./HumanizedDuration";

export const ResultTitle = styled(Box)`
  font-family: "Inter Semi Bold";
  font-size: 24px;
  color: ${({ theme }) => customColors[theme.palette.mode].textBody};

  &.error {
    color: ${({ theme }) => customColors[theme.palette.mode].error};
  }
`;

const Status = styled(StatusExternal)`
  padding: 0 15px;
  height: 30px;
`;

export const Header = styled(Stack)`
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
`;

export const StatisticItem = styled(Stack)`
  flex: 1 1 auto;
  padding: 12px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 13px;
  border: 1px solid
    ${({ theme }) => customColors[theme.palette.mode].additionalStroke};
  background: ${({ theme }) => customColors[theme.palette.mode].background};
`;

export const StatisticLabel = styled(Box)`
  color: ${({ theme }) => customColors[theme.palette.mode].placeHolder};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`;

export const StatisticValue = styled(Box)`
  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.48px;
`;

export const StatisticUnits = styled("span")`
  color: ${({ theme }) => customColors[theme.palette.mode].placeHolder};
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
`;

export const Prop = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  width: 100%;
  max-width: 100%;
`;

export const Label = styled(Box)`
  font-family: Inter;
  font-size: 16px;
  color: ${({ theme }) => customColors[theme.palette.mode].placeHolder};
`;

export const Value = styled(Box)`
  flex: 1 1 auto;
  font-family: "Inter Medium";
  font-size: 16px;
  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
  text-align: left;
  overflow-wrap: anywhere;
  word-wrap: anywhere;
`;

export const CancellingReason = styled(Value)`
  color: ${({ theme }) => customColors[theme.palette.mode].error};
`;

export const Option = styled(Stack)`
  padding: 5px 15px;
  justify-content: center;
  font-size: 15px;
  align-items: center;
  border-radius: 8px;
  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].additionalStroke};
`;

export const Price = styled(Option)`
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  color: ${({ theme }) => customColors[theme.palette.mode].white};
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].actionColor};
`;

export const Canceled = styled(Price)`
  background-color: ${({ theme }) => customColors[theme.palette.mode].error};
`;

export const Unactive = styled(Price)`
  background-color: ${({ theme }) => customColors[theme.palette.mode].greyText};
`;

export const VehicleType = ({ vehicleType }) => (
  <Option>
    <Stack direction={"row"} alignItems={"center"} gap={1}>
      {vehicleType === "VAN" && <VanIcon />}
      {vehicleType === "SEDAN_OR_SALOON" && <SedanIcon />}
      {vehicleType === "WHEELCHAIR" && <WheelchairIcon />}
      {convertToReadable(vehicleType)}
    </Stack>
  </Option>
);

export const PickUpTime = styled(Box)`
  font-size: 1.2rem;
  font-weight: bold;
`;

const RefreshButton = styled(IconButton)`
  flex: 0 0 auto;

  height: 32px;
  width: 32px;

  border: thin solid
    ${({ theme }) => customColors[theme.palette.mode].borderColor};
  border-radius: 10px;
`;

export const RideData = (ride) => {
  const {
    departure,
    rideType,
    status,
    destination,
    estimatedDistance,
    distanceUnit,
    estimatedDurationMs,
    etaMs,
    passengerName,
    customerCompanyId,
    estimatedCost,
    passengersNumber,
    vehicleType,
    d2d,
    getClientLabelById,
    additionalNotes,
    departureTimeMs,
    driver,
    vehicle,
    refreshData,
    comment,
    newRideMode = false,
  } = ride;
  const theme = useTheme();
  const { callApiRequest } = useApiRequest();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [isCompleteOpen, setIsCompleteOpen] = useState(false);
  const [isAssignDriverOpen, setIsAssignDriverOpen] = useState(false);
  const userRole = useAppSelector((state) => state.user.role);
  const mattermostUserId = useAppSelector(
    (state) => state.user.mattermostUserId
  );
  const isCustomerUser =
    userRole === "CUSTOMER_ADMIN" || userRole === "CUSTOMER_MANAGER";

  const isChangeDriverEnabled =
    ["CONFIRMATION", "UNMATCHED", "SCHEDULED", "NEW"].includes(status) &&
    rideType === "SCHEDULED" &&
    !isCustomerUser;

  const isRefreshEnabled = [
    "NEW",
    "UNMATCHED",
    "CONFIRMATION",
    "SCHEDULED",
    "EN_ROUTE",
    "ARRIVED",
    "ON_BOARD",
  ].includes(status);

  const isMessagesEnabled =
    !!mattermostUserId && mattermostUserId !== "unknown";

  const openChat = () => {
    if (!ride?.channelId || !isMessagesEnabled) return;
    // try to add myself to the chat
    callApiRequest({
      apiRequest: addUserToChat,
      params: {
        chatId: ride?.channelId,
        userId: mattermostUserId,
      },
      onSuccess: () => navigate(`/ride_chat/${ride.id}`),
    });
  };

  const isCancelVisibleForKerico =
    !["COMPLETED", "CANCELLED"].includes(status) &&
    ["SUPER_ADMIN", "KERICO_MANAGER", "KERICO_DISPATCHER"].includes(userRole);

  const isCancelVisibleForClient =
    ["NEW", "UNMATCHED", "CONFIRMATION", "SCHEDULED"].includes(status) &&
    ["CUSTOMER_ADMIN", "CUSTOMER_MANAGER"].includes(userRole);

  const isCompleteVisible =
    ["EN_ROUTE", "ARRIVED", "ON_BOARD"].includes(status) &&
    ["SUPER_ADMIN", "KERICO_MANAGER", "KERICO_DISPATCHER"].includes(userRole);

  const isCanceled = status === "CANCELLED";

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        gap={2}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={1}
          flexWrap={"wrap"}
          mt={"2px"}
        >
          {isRefreshEnabled && !newRideMode && (
            <RefreshButton onClick={refreshData} size="small">
              <RefreshIcon />
            </RefreshButton>
          )}
          {isCanceled ? (
            <>
              <Canceled>Canceled</Canceled>
              <Unactive>
                {rideType === "SCHEDULED" ? "Scheduled" : "A.S.A.P."}
              </Unactive>
            </>
          ) : (
            <Price>{rideType === "SCHEDULED" ? "Scheduled" : "A.S.A.P."}</Price>
          )}

          {rideType === "SCHEDULED" && departureTimeMs && (
            <Option>
              {new Intl.DateTimeFormat("en-US", {
                dateStyle: "short",
                timeStyle: "short",
              }).format(departureTimeMs)}
            </Option>
          )}
          {!isCanceled && !newRideMode && (
            <Status
              // @ts-ignore
              status={status}
            >
              {convertToReadable(status)}
            </Status>
          )}
        </Stack>
        {!newRideMode && (
          <Stack
            direction={"row"}
            alignItems={"center"}
            height={"38px"}
            gap={1}
          >
            <Box>#{ride?.id?.slice(0, 8)}</Box>
            {isMessagesEnabled &&
              !!ride?.channelId &&
              !pathname?.startsWith("/ride_chat") && (
                <MessageButton openChat={openChat} />
              )}
          </Stack>
        )}
      </Stack>
      <Stack direction={"row"} gap={2}>
        <Stack>
          <ResultTitle>Pick Up:</ResultTitle>
          <Box>{removeCountry(departure?.label)}</Box>
        </Stack>
        <ArrowForwardIcon fontSize="large" />
        <Stack>
          <ResultTitle>Drop Off:</ResultTitle>
          <Box>{removeCountry(destination?.label)}</Box>
        </Stack>
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={2}
      >
        <StatisticItem>
          <StatisticLabel>Distance</StatisticLabel>
          <StatisticValue>
            {Math.ceil(estimatedDistance * 10) / 10}{" "}
            <StatisticUnits>{distanceUnit}</StatisticUnits>
          </StatisticValue>
        </StatisticItem>
        <StatisticItem>
          <StatisticLabel>Time</StatisticLabel>
          <HumanizedDuration
            durationInMs={estimatedDurationMs}
            StatisticUnits={StatisticUnits}
            StatisticValue={StatisticValue}
          />
        </StatisticItem>
        <StatisticItem>
          <StatisticLabel>ETA</StatisticLabel>
          <StatisticValue>
            {unixTimeToUSDate(etaMs)?.slice(0, -2)}
            <StatisticUnits>
              {unixTimeToUSDate(etaMs)?.slice(-2)}
            </StatisticUnits>
          </StatisticValue>
        </StatisticItem>
      </Stack>
      {/* <Stack>
      <StatisticUnits>Details</StatisticUnits>
      <Divider />
    </Stack> */}
      <Stack gap={1}>
        <Prop>
          <Label>Patient:</Label>
          <Value>{passengerName}</Value>
        </Prop>
        {!isCustomerUser && (
          <Prop>
            <Label>Client:</Label>
            <Value>{getClientLabelById(customerCompanyId)}</Value>
          </Prop>
        )}
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={1}
        >
          {isChangeDriverEnabled && (
            <IconButton
              onClick={() => setIsAssignDriverOpen(true)}
              size="small"
              title={driver?.driverName ? "Change driver" : "Assign driver"}
            >
              <ChangeDriverIcon
                fill={customColors[theme.palette.mode].textBody}
              />
            </IconButton>
          )}
          <Prop>
            <Label>Driver:</Label>
            <Value>{driver?.driverName ?? "-"}</Value>
            <Label>Vehicle:</Label>
            <Value>{vehicle?.label ?? "-"}</Value>
          </Prop>
        </Stack>
      </Stack>
      <Stack
        direction={"row"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        flexWrap={"wrap"}
        gap={1}
      >
        <Price>
          $ {(Math.round(estimatedCost * 100) / 100).toFixed(2) ?? 0}
        </Price>
        <Option>
          {passengersNumber} passenger{passengersNumber > 1 ? "s" : ""}
        </Option>
        <VehicleType vehicleType={vehicleType} />
        {d2d && <Option>Needs assistance - D2D</Option>}
      </Stack>
      <Box sx={{ fontSize: "13px" }}>{additionalNotes}</Box>
      {isCanceled && (
        <Prop>
          <Label>Cancelling Reason:</Label>
          <CancellingReason>{comment ?? " - "}</CancellingReason>
        </Prop>
      )}
      {status === "COMPLETED" && !!comment && (
        <Prop>
          <Label>Manual Completion Reason:</Label>
          <Value>{comment}</Value>
        </Prop>
      )}
      {!newRideMode && (
        <Stack direction={"row"} gap={1}>
          {isCompleteVisible && (
            <Button
              variant="contained"
              color="success"
              onClick={() => setIsCompleteOpen(true)}
            >
              Complete Ride
            </Button>
          )}
          {(isCancelVisibleForKerico || isCancelVisibleForClient) && (
            <Button
              variant="contained"
              color="error"
              onClick={() => setIsCancelOpen(true)}
            >
              Cancel Booking
            </Button>
          )}
        </Stack>
      )}
      <AssignDriverDialog
        open={isAssignDriverOpen}
        handleClose={() => setIsAssignDriverOpen(false)}
        refreshData={refreshData}
        ride={ride}
        action={driver?.driverName ? "Change" : "Assign"}
      />
      <CancelBookingDialog
        open={isCancelOpen}
        handleClose={() => setIsCancelOpen(false)}
        refreshData={refreshData}
        rideId={ride?.id}
      />
      <CompleteBookingDialog
        open={isCompleteOpen}
        handleClose={() => setIsCompleteOpen(false)}
        refreshData={refreshData}
        rideId={ride?.id}
      />
    </>
  );
};
