import React, { useEffect, useState } from "react";
import { Map, Marker, ColorScheme } from "mapkit-react";
import { getBounds, getCenter } from "geolib";
import { useTheme } from "@mui/material";
import CarImage from "assets/img/car-icon.svg";
import { customColors } from "models/customColors";

const GLYPH_CLICK_ANIMATION_DELAY = 300;

const defaultMarker = {
  // Houston
  coordinates: {
    lat: 29.749907,
    lon: -95.358421,
  },
};

const ZOOM_FACTOR_FOR_COORDS_DELTA = 2;
const DEFAULT_COORDS_DELTA_FOR_ONE_RIDE = 0.5;
const MIN_COORDS_DELTA = 0.05;
const POI_OF_DELAY_IN_MS = 500;
const RELOAD_DELAY_IN_MS = 500;

export const ActiveRidesMap = ({
  markers = [],
  reloadSignal,
  setSelectedId,
}) => {
  const theme = useTheme();
  const [initialRegion, setInitialRegion] = useState();
  const [showsPointsOfInterest, setShowsPointsOfInterest] = useState(true);
  const [isVisible, setIsVisible] = useState(false);

  const handleOnLoad = () => {
    setTimeout(() => {
      setShowsPointsOfInterest(false);
    }, POI_OF_DELAY_IN_MS);
  };

  useEffect(() => {
    const points = (markers?.length ? markers : [defaultMarker]).map(
      ({ coordinates: { lat, lon } }) => ({
        latitude: lat,
        longitude: lon,
      })
    );

    const { maxLat, minLat, maxLng, minLng } = getBounds(points);
    const center = getCenter(points);
    const latitudeDelta = maxLat - minLat;
    const longitudeDelta = maxLng - minLng;
    const maxDelta =
      latitudeDelta > longitudeDelta ? latitudeDelta : longitudeDelta;
    const visibleDelta =
      maxDelta * ZOOM_FACTOR_FOR_COORDS_DELTA < MIN_COORDS_DELTA
        ? MIN_COORDS_DELTA
        : maxDelta * ZOOM_FACTOR_FOR_COORDS_DELTA;

    setInitialRegion({
      // @ts-ignore
      centerLatitude: center?.latitude ?? 0,
      // @ts-ignore
      centerLongitude: center?.longitude ?? 0,
      latitudeDelta:
        markers?.length > 1 ? visibleDelta : DEFAULT_COORDS_DELTA_FOR_ONE_RIDE,
      longitudeDelta:
        markers?.length > 1 ? visibleDelta : DEFAULT_COORDS_DELTA_FOR_ONE_RIDE,
    });
  }, [markers]);

  useEffect(() => {
    setIsVisible(false);
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, RELOAD_DELAY_IN_MS);

    return () => clearTimeout(timeout);
  }, [reloadSignal]);

  return isVisible && !!initialRegion ? (
    <Map
      token={process.env.REACT_APP_MAP_TOKEN}
      showsScale={2}
      distances={0}
      initialRegion={initialRegion}
      cameraBoundary={initialRegion}
      showsCompass={1}
      showsPointsOfInterest={showsPointsOfInterest}
      onLoad={handleOnLoad}
      isScrollEnabled={true}
      showsUserLocationControl={false}
      allowWheelToZoom={true}
      isZoomEnabled={true}
      colorScheme={
        theme.palette.mode === "dark" ? ColorScheme.Dark : ColorScheme.Light
      }
    >
      {markers.map(({ id, title, subtitle, driverWorkStatus, coordinates: { lat, lon } }) => {
        const onSelect = (e) => {
          setTimeout(() => setSelectedId(id), GLYPH_CLICK_ANIMATION_DELAY);
        };

        return (
          <Marker
            key={id}
            title={title}
            subtitle={subtitle}
            subtitleVisibility={1}
            latitude={lat}
            longitude={lon}
            onSelect={onSelect}
            glyphImage={{
              1: CarImage,
            }}
            color={driverWorkStatus === "On_Shift" ? customColors[theme.palette.mode].new : customColors[theme.palette.mode].greyText}
            glyphColor={customColors[theme.palette.mode].white}
          />
        );
      })}
    </Map>
  ) : null;
};
