import React from "react";
import { Stack, styled } from "@mui/material";
import * as SC from "SC";
import { useParams } from "react-router-dom";
import { Messenger } from "components/Messenger";
import { customColors } from "models/customColors";
import { DriverDetails } from "components/DriverDetails";

const Container = styled(SC.Container)`
  padding: 20px;
`;

export const Content = styled(Stack)`
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-between;

  padding: 20px;

  overflow-y: hidden;
  overflow-x: hidden;

  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].additionalStroke};
  border-radius: 16px;
`;

const MessengerHolder = styled(Stack)`
  justify-content: space-between;
  padding: 20px 40px;
  width: calc(50% - 90px);
  overflow: hidden;

  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].background};
  border-radius: 16px;
`;

const HalfScreenPanel = styled(Stack)`
  flex: 0 0 auto;
  width: calc(50% - 40px);
  padding: 20px 5px 20px 25px;

  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].background};
  border-radius: 16px;
`;

export const DriverChatPage = () => {
  const { id, chatId } = useParams();

  return (
    <Container>
      <Stack direction={"row"} height={"100%"}>
        <Content>
          <MessengerHolder>
            {!!chatId && (
              <Messenger chatId={chatId} withHeader />
            )}
          </MessengerHolder>
          {id && (
            <HalfScreenPanel>
              <DriverDetails
                userId={id}
                refreshList={() => null}
                closeDetails={() => null}
              />
            </HalfScreenPanel>
          )}
        </Content>
      </Stack>
    </Container>
  );
};
