import { getFacilities } from "api/clientApi";
import { useApiRequest } from "api/useApiRequest";
import { useEffect, useState } from "react";

export const useSpecialityOptions = () => {
  const { callApiRequest } = useApiRequest();
  const [specialityOptions, setFacilityOptions] = useState([]);

  const loadSpecialities = () =>
    callApiRequest({
      apiRequest: getFacilities,
      onSuccess: (result) => {
        const data = (Array.isArray(result) ? result : [])
          .map(({ id, type }) => ({
            value: id,
            label: type,
          }))
          .sort((a, b) => (a.label < b.label ? -1 : 1));
        setFacilityOptions(data);
      },
    });

  useEffect(() => {
    loadSpecialities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    specialityOptions,
    setFacilityOptions,
    loadSpecialities,
  };
};
