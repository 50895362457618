import { useCallback, useContext } from "react";
import { resetIsDataChanged } from "store/reducers/isDataChangedReducer";
import { GlobalContext } from "store/globalContext";
import { useAppDispatch, useAppSelector } from "store/hooks";

export const useConfirmedClose = ({ handleClose }) => {
  const dispatch = useAppDispatch();
  const isDataChanged = useAppSelector((state) => state.isDataChanged);
  const { openConfirmationWithCallback } = useContext(GlobalContext);

  const handleForcedClose = useCallback(() => {
    dispatch(resetIsDataChanged());
    handleClose();
  }, [dispatch, handleClose]);

  const handleConfirmedAction = useCallback(
    (callbackFn) => {
      if (isDataChanged) {
        openConfirmationWithCallback({
          title: "Are you sure?",
          message: "All entered data will be lost.",
          callbackFn,
        });
        return;
      }
      callbackFn();
    },
    [isDataChanged, openConfirmationWithCallback]
  );

  const handleConfirmedClose = useCallback(() => {
    handleConfirmedAction(handleClose);
  }, [handleClose, handleConfirmedAction]);

  return {
    handleConfirmedClose,
    handleForcedClose,
    handleConfirmedAction,
  };
};
