import React from "react";
import { Box, Stack, styled } from "@mui/material";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { closeChatList, selectChat } from "store/reducers/chatsReducer";
import { DialogWindow } from "UI";
import { customColors } from "models/customColors";
import { ConditionalWrapper } from "UI/ConditionalWrapper";
import { useSearchInList } from "hooks/useSearchInList";
import { DataGridSearch } from "UI/DataGridSearch";
import {
  filterPassiveRideChat,
  getFilterPassiveRideChatForClientById,
  getReadableChatName,
} from "utils";

const Container = styled(Stack)`
  flex: 1 1 auto;
  justify-content: flex-start;
  gap: 1rem;
  min-width: 30vw;
  height: 40vh;
  overflow: hidden;
`;

const Chat = styled(Box)`
  padding: 5px;
  text-align: left;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    color: ${({ theme }) => customColors[theme.palette.mode].actionColor};
  }

  border-bottom: 1px solid
    ${({ theme }) => customColors[theme.palette.mode].greyText};
`;

export const ChatList = ({ newChats }) => {
  const dispatch = useAppDispatch();

  const prevChats = useAppSelector(
    (state) => state.chats.prev[state.chats.persistedUserId]
  );

  const userId = useAppSelector((state) => state.user.id);
  const userRole = useAppSelector((state) => state.user.role);

  const {
    search,
    getFilterSearchForPropArrayHandler,
    clearSearch,
    handleChangeSearch,
  } = useSearchInList();

  const handleOpenChat = (chat) => {
    dispatch(selectChat(chat));
    dispatch(closeChatList());
  };

  const newMessagesCountByChatId = (id, total_msg_count) => {
    const prevChat = prevChats?.find((chat) => chat.id === id);
    if (prevChat?.total_msg_count <= total_msg_count) {
      return total_msg_count - prevChat.total_msg_count;
    }
    return total_msg_count;
  };

  const mapperChats = newChats
    ?.filter((chat) => chat?.type !== "D")
    ?.filter(
      getFilterSearchForPropArrayHandler(search, ["display_name", "purpose"])
    )
    ?.map((chat) => ({
      ...chat,
      new_messages_count: newMessagesCountByChatId(
        chat.id,
        chat.total_msg_count
      ),
    }));

  mapperChats
    ?.sort((a, b) => a.display_name.localeCompare(b.display_name))
    .sort((a, b) => b.new_messages_count - a.new_messages_count);

  const isClientChat = ["CUSTOMER_ADMIN", "CUSTOMER_MANAGER"].includes(
    userRole
  );

  const filterPassiveRideChatForClient =
    getFilterPassiveRideChatForClientById(userId);

  const resultChatList = mapperChats?.filter(
    isClientChat ? filterPassiveRideChatForClient : filterPassiveRideChat
  );

  return (
    <DialogWindow
      open={true}
      title={"Chats"}
      content={
        <Container>
          <Stack>
            <DataGridSearch
              search={search}
              handleChangeSearch={handleChangeSearch}
              clearSearch={clearSearch}
            />
          </Stack>
          <Stack flex={1} overflow={"auto"}>
            {resultChatList?.map((chat) => (
              <React.Fragment key={chat.id}>
                <Chat onClick={() => handleOpenChat(chat)}>
                  <ConditionalWrapper
                    condition={chat?.new_messages_count > 0}
                    Wrapper={({ children }) => <strong>{children}</strong>}
                  >
                    {chat?.name?.startsWith(`client_${userId}`)
                      ? "Support Chat"
                      : getReadableChatName(chat)}{" "}
                    {chat?.new_messages_count > 0 &&
                      `(${chat.new_messages_count})`}
                  </ConditionalWrapper>
                </Chat>
              </React.Fragment>
            ))}
          </Stack>
        </Container>
      }
      handleClose={() => dispatch(closeChatList())}
      withCloseButton
    />
  );
};
