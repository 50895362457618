import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Stack } from "@mui/material";
import { DialogWindow } from "UI/DialogWindow";
import { required } from "models/validations";
import { useApiRequest } from "api/useApiRequest";
import { useFormDataSync } from "hooks/useFormDataSync";
import { getStatusAssingByAdminByRideId, getStatusAssingByCustomerByRideId } from "api/rideApi";
import { FormTextField } from "UI";
import { GlobalContext } from "store/globalContext";
import { ReactComponent as DoneIcon } from "assets/img/booking-scheduled-icon.svg";
import { useAppSelector } from "store/hooks";

const defaultValues = {
  status: "CANCELLED",
  comment: "",
};

export const CancelBookingDialog = ({
  open,
  handleClose,
  refreshData,
  rideId,
}) => {
  const { callApiRequest } = useApiRequest();
  const userRole = useAppSelector((state) => state.user.role);
  const { openConfirmationWithCallback, showWarning } = useContext(GlobalContext);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues,
  });

  const { handleConfirmedClose, handleForcedClose } = useFormDataSync({
    isDirty,
    handleClose,
    open,
    reset,
  });

  const isKericoUser = [
    "SUPER_ADMIN",
    "KERICO_MANAGER",
    "KERICO_DISPATCHER",
  ].includes(userRole);

  const onSubmit = async (params) =>
    openConfirmationWithCallback({
      title: "Cancel Booking",
      message: "Are you sure you want to cancel this booking?",
      callbackFn: () => {
        callApiRequest({
          apiRequest: isKericoUser
          ? getStatusAssingByAdminByRideId(rideId)
          : getStatusAssingByCustomerByRideId(rideId),
          params,
          onSuccess: () => {
            showWarning({
                title: "Ride Cancelled",
                content: "The ride has been successfully cancelled",
                icon: <DoneIcon />,
              });
            refreshData();
            handleForcedClose();
          },
        });
      },
    });

  useEffect(() => {
    reset(defaultValues);
  }, [open, reset]);

  return (
    <DialogWindow
      open={open}
      withCloseButton
      handleClose={handleConfirmedClose}
      title={`Cancel Booking`}
      content={
        <form>
          <Stack mt={2} gap={3} width={"100%"}>
            <Box>
              Please provide specific feedback or reasons for cancelling the
              booking
            </Box>
            <Stack gap={2}>
              <Stack alignItems={"flex-start"}>
                <FormTextField
                  id="comment"
                  name="comment"
                  control={control}
                  rules={{ required }}
                  InputProps={{
                    autoComplete: "off",
                  }}
                />
              </Stack>
              <Stack direction={"row"} justifyContent={"center"} gap={1}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleConfirmedClose}
                >
                  Continue
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleSubmit(onSubmit)}
                >
                  Cancel Booking
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      }
    />
  );
};
