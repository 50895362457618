import React, { useContext, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Box, Button, Divider, InputLabel, Stack, styled } from "@mui/material";
import { FormTextField } from "UI/FormTextField";
import {
  emailValidation,
  nameValidation,
  phoneNumberRequiredValidation,
} from "models/validations";
import { FormMaskedTextField } from "UI";
import { useApiRequest } from "api/useApiRequest";
import { GlobalContext } from "store/globalContext";
import { updateClientUser, updateKericoUser } from "api/userApi";
import { ReactComponent as UserIcon } from "assets/img/user-small-icon.svg";
import { ReactComponent as EditIcon } from "assets/img/edit-avatar-icon.svg";
import { updateProfile } from "store/reducers/userReducer";
import { customColors } from "models/customColors";
import { Title } from "SC";
import { httpUploadService } from "api/httpUploadService";
import { showError } from "store/reducers/errorReducer";
import { updateAvatar } from "store/reducers/avatarReducer";
import { Info } from "./SubMenuContent";
import { ChangePasswordDialog } from "./ChangePasswordDialog";

const EditButton = styled(Stack)`
  width: 27px;
  height: 27px;
  border-radius: 50%;

  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => customColors[theme.palette.mode].color};
`;

export const FileInput = styled("input")`
  height: 1px;
  width: 1px;

  overflow: hidden;
`;

export const InputHolder = styled("label")`
  position: relative;

  top: -27px;
  cursor: pointer;
`;

export const Preview = styled(Box)`
  width: 80px;
  height: 80px;

  border-radius: 50%;

  background-repeat: "no-repeat";
  background-position: "center";
`;

export const Avatar = styled(Stack)`
  width: 80px;
  height: 80px;

  justify-content: center;
  align-items: center;

  border-radius: 50%;
  color: ${({ theme }) => customColors[theme.palette.mode].background};
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].actionColor};
`;

export const ProfileSettings = () => {
  const dispatch = useAppDispatch();
  const { showWarning } = useContext(GlobalContext);
  const { callApiRequest } = useApiRequest();
  const user = useAppSelector((state) => state.user);
  const { firstName, lastName, email, phone, id, role } = user;
  const isClientUser = role === "CUSTOMER_ADMIN" || role === "CUSTOMER_MANAGER";
  const image = useAppSelector((state) => state.avatar);
  const setImage = (image) => dispatch(updateAvatar(image));
  const [isPasswordChangeOpen, setIsPasswordChangeOpen] = useState(false);

  const imageUploadInput = useRef();

  const initials = user?.firstName ? (
    <>
      {user?.firstName?.slice(0, 1)} {user?.lastName?.slice(0, 1)}
    </>
  ) : (
    <>
      {user?.role
        ?.split("_")
        .map((name) => name.slice(0, 1))
        .join("")}
    </>
  );

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { isDirty },
  } = useForm({
    values: {
      id,
      firstName,
      lastName,
      email,
      phone,
      role,
    },
  });

  const onSubmit = async () => {
    const params = getValues();
    callApiRequest({
      apiRequest: isClientUser ? updateClientUser : updateKericoUser,
      params: {
        id: params.id,
        data: params,
      },
      onSuccess: () => {
        dispatch(updateProfile(params));
        showWarning({
          title: "User Profile Updated",
          content: "User profile has been successfully updated",
          icon: <UserIcon />,
        });
      },
    });
  };

  const handleUpload = async () => {
    // @ts-ignore
    const newFile = imageUploadInput?.current?.files?.[0];
    if (newFile) {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          setImage(reader.result);
        },
        false
      );
      reader.readAsDataURL(newFile);
      const data = new FormData();
      data.append("userId", id);
      data.append("type", "Profile");
      if (newFile !== null) {
        data.append("photo", newFile);
      } else {
        return;
      }

      const result = await httpUploadService({
        url: `/user/details/photo/upload`,
        method: "POST",
        data,
      });

      if (result?.error) {
        dispatch(showError(result?.error));
      }
    }
  };

  return (
    <Stack ml={"74px"} gap={3}>
      <Title>Edit profile</Title>
      <Stack
        justifyContent={"flex-end"}
        alignItems={"flex-end"}
        width={"67px"}
        mb={-4}
      >
        {!image && <Avatar>{initials}</Avatar>}
        {image && (
          <Preview
            sx={{
              backgroundSize: `${image ? "cover" : "auto"}`,
              backgroundImage: `url("${image}")`,
            }}
          ></Preview>
        )}
        <InputHolder htmlFor="image-input">
          <EditButton>
            <EditIcon />
          </EditButton>
          <FileInput
            ref={imageUploadInput}
            id="image-input"
            name="preview"
            onChange={handleUpload}
            type="file"
            multiple={false}
            accept="image/png, image/jpeg"
          />
        </InputHolder>
      </Stack>
      <form>
        <Stack gap={2} maxWidth={"510px"}>
          <Stack direction={"row"} gap={3}>
            <Stack alignItems={"flex-start"}>
              <InputLabel htmlFor="firstName">First Name</InputLabel>
              <FormTextField
                id="firstName"
                name="firstName"
                control={control}
                rules={nameValidation}
                InputProps={{
                  autoComplete: "off",
                }}
              />
            </Stack>
            <Stack alignItems={"flex-start"}>
              <InputLabel htmlFor="lastName">Last Name</InputLabel>
              <FormTextField
                id="lastName"
                name="lastName"
                control={control}
                rules={nameValidation}
                InputProps={{
                  autoComplete: "off",
                }}
              />
            </Stack>
          </Stack>
          <Stack alignItems={"flex-start"}>
            <InputLabel htmlFor="email">Email</InputLabel>
            <FormTextField
              id="email"
              name="email"
              control={control}
              rules={emailValidation}
              InputProps={{
                autoComplete: "email",
              }}
            />
          </Stack>
          <Stack alignItems={"flex-start"}>
            <InputLabel htmlFor="phone">Phone Number</InputLabel>
            <FormMaskedTextField
              id="phone"
              name="phone"
              control={control}
              rules={phoneNumberRequiredValidation}
              mask="999-999-9999"
              InputProps={{
                autoComplete: "off",
              }}
            />
          </Stack>
          <Box>
            <Stack direction={"row"} gap={1}>
              {isDirty && (
                <Button variant="outlined" onClick={() => reset()}>
                  Cancel
                </Button>
              )}
              <Button
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                disabled={!isDirty}
              >
                Save Changes
              </Button>
            </Stack>
          </Box>
        </Stack>
      </form>
      <Divider />
      <Stack gap={1}>
        <Title>Change Password</Title>
        <Info>Change your password if you're unsure about its security</Info>
        <Box>
          <Button
            variant="outlined"
            onClick={() => setIsPasswordChangeOpen(true)}
          >
            Change Password
          </Button>
        </Box>
      </Stack>
      <ChangePasswordDialog
        open={isPasswordChangeOpen}
        handleClose={() => setIsPasswordChangeOpen(false)}
      />
    </Stack>
  );
};
