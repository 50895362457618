import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import { showError, hideError } from "./errorReducer";
import { localStorageNameEnum } from "models/constants";
import { showSpinner, hideSpinner } from "./spinnerReducer";
import {
  resetIsRefreshPending,
  setIsRefreshPending,
} from "./isRefreshPendingReducer";

/**
 * @template T
 * @typedef {import('types/stateTypes').PayloadPreparator<T>} PayloadPreparator
 */

/** @type {import("types/commonTypes").User} */
export const initialState = {
  id: "",
  login: "",
  firstName: "",
  lastName: "",
  usernameType: "UNCLASSIFIED",
  email: "",
  phone: "",
  role: undefined,
  status: undefined,
  sessionStatus: undefined,
  mfaEnabled: true,
  access: undefined,
  refresh: undefined,
  photos: [],
  companyId: undefined,
  companyData: undefined,
  mattermostUserId: undefined,
};

export const getAuth = createAsyncThunk(
  "user/getAuth",
  async (
    /** @type {{ username: string; password: string;}} */ credentials,
    thunkAPI
  ) => {
    thunkAPI.dispatch(showSpinner());
    thunkAPI.dispatch(hideError());

    try {
      const { username, password } = credentials;
      // @ts-ignore
      const result = await thunkAPI.extra.authApi.login({ username, password });

      if (result?.error) {
        window.sessionStorage.removeItem(localStorageNameEnum.AUTH_TOKEN);
        thunkAPI.dispatch(showError(result?.error));
        return Promise.reject(result?.error);
      }

      if (result?.value?.access?.token) {
        window.sessionStorage.setItem(
          localStorageNameEnum.AUTH_TOKEN,
          result?.value?.access?.token
        );
        window.localStorage.setItem(
          localStorageNameEnum.AUTH_REFRESH_TOKEN,
          result?.value?.refresh?.token
        );
      }

      return result?.value;
    } catch (e) {
      thunkAPI.dispatch(
        showError(e?.error ?? e?.message ?? "Something went wrong")
      );
      return Promise.reject();
    } finally {
      thunkAPI.dispatch(hideSpinner());
    }
  }
);

export const restoreAuthByRefreshToken = createAsyncThunk(
  "user/restoreAuthByRefreshToken",
  async (
    /** @type {string} */
    refreshToken,
    thunkAPI
  ) => {
    thunkAPI.dispatch(hideError());

    // @ts-ignore
    const isRefreshPending = thunkAPI.getState()?.isRefreshPending;

    if (isRefreshPending) {
      return Promise.reject();
    }

    thunkAPI.dispatch(setIsRefreshPending());

    try {
      // @ts-ignore
      const result = await thunkAPI.extra.authApi.refresh(refreshToken);

      if (result?.error) {
        window.sessionStorage.removeItem(localStorageNameEnum.AUTH_TOKEN);
        window.localStorage.removeItem(localStorageNameEnum.AUTH_REFRESH_TOKEN);
        return Promise.resolve({});
      }

      if (result?.value?.access?.token) {
        window.sessionStorage.setItem(
          localStorageNameEnum.AUTH_TOKEN,
          result?.value?.access?.token
        );
        window.localStorage.setItem(
          localStorageNameEnum.AUTH_REFRESH_TOKEN,
          result?.value?.refresh?.token
        );
      }

      return Promise.resolve(result?.value);
    } catch (e) {
      thunkAPI.dispatch(
        showError(e?.error ?? e?.message ?? "Something went wrong")
      );
      return Promise.reject();
    } finally {
      thunkAPI.dispatch(hideSpinner());
      thunkAPI.dispatch(resetIsRefreshPending());
    }
  }
);

export const restoreSessionByCode = createAsyncThunk(
  "user/restoreSessionByCode",
  async (
    /** @type {string} */
    code,
    thunkAPI
  ) => {
    thunkAPI.dispatch(hideError());

    // @ts-ignore
    const isRefreshPending = thunkAPI.getState()?.isRefreshPending;

    if (isRefreshPending) {
      return Promise.reject();
    }

    thunkAPI.dispatch(setIsRefreshPending());

    try {
      // @ts-ignore
      const result = await thunkAPI.extra.authApi.restoreSessionByCode(code);

      if (result?.error) {
        thunkAPI.dispatch(showError(result?.error ?? "Something went wrong"));
        return Promise.reject({});
      }

      if (result?.value?.access?.token) {
        window.sessionStorage.setItem(
          localStorageNameEnum.AUTH_TOKEN,
          result?.value?.access?.token
        );
        window.localStorage.setItem(
          localStorageNameEnum.AUTH_REFRESH_TOKEN,
          result?.value?.refresh?.token
        );
      }

      return Promise.resolve(result?.value);
    } catch (e) {
      thunkAPI.dispatch(
        showError(e?.error ?? e?.message ?? "Something went wrong")
      );
      return Promise.reject();
    } finally {
      thunkAPI.dispatch(hideSpinner());
      thunkAPI.dispatch(resetIsRefreshPending());
    }
  }
);

export const logoutAction = createAction("user/logout");

export const updateProfile = createAction(
  "user/updateProfile",
  /**
   * @type {PayloadPreparator<import("types/commonTypes").ProfileUpdateDto>}
   */
  (profileData) => ({ payload: profileData })
);

export const updateUserCompany = createAction(
  "user/updateUserCompany",
  /**
   * @type {PayloadPreparator<import("types/commonTypes").Company>}
   */
  (company) => ({ payload: company })
);

export const getUserCompanyData = createAsyncThunk(
  "user/getCompanyData",
  async (/** @type {string} */ id, thunkAPI) => {
    thunkAPI.dispatch(showSpinner());
    thunkAPI.dispatch(hideError());

    try {
      // @ts-ignore
      const result = await thunkAPI.extra.clientApi.getClientById(id);

      if (result?.error) {
        return Promise.reject(result?.error);
      }

      return result?.value;
    } catch (e) {
      thunkAPI.dispatch(
        showError(e?.error ?? e?.message ?? "Something went wrong")
      );
      return Promise.reject();
    } finally {
      thunkAPI.dispatch(hideSpinner());
    }
  }
);

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getAuth.fulfilled,
      (state, { payload: { access, refresh, userDto } }) => {
        state.id = userDto?.id;
        state.login = userDto?.login;
        state.firstName = userDto?.firstName;
        state.lastName = userDto?.lastName;
        state.email = userDto?.email;
        state.phone = userDto?.phone;
        state.role = userDto?.role;
        state.status = userDto?.status;
        state.sessionStatus = userDto?.sessionStatus;
        state.mfaEnabled = userDto?.mfaEnabled;
        state.access = access;
        state.refresh = refresh;
        state.photos = userDto?.photos ?? [];
        state.companyId = userDto?.companyId;
        state.mattermostUserId = userDto?.mattermostUserId;
      }
    )
    .addCase(logoutAction, () => {
      window.sessionStorage.removeItem(localStorageNameEnum.AUTH_TOKEN);
      window.localStorage.removeItem(localStorageNameEnum.AUTH_REFRESH_TOKEN);
      return initialState;
    })
    .addCase(
      restoreAuthByRefreshToken.fulfilled,
      (state, { payload: { access, refresh, userDto } }) => {
        state.id = userDto?.id;
        state.login = userDto?.login;
        state.firstName = userDto?.firstName;
        state.lastName = userDto?.lastName;
        state.email = userDto?.email;
        state.phone = userDto?.phone;
        state.role = userDto?.role;
        state.status = userDto?.status;
        state.sessionStatus = userDto?.sessionStatus;
        state.mfaEnabled = userDto?.mfaEnabled;
        state.access = access;
        state.refresh = refresh;
        state.photos = userDto?.photos ?? [];
        state.companyId = userDto?.companyId;
        state.mattermostUserId = userDto?.mattermostUserId;
      }
    )
    .addCase(
      restoreSessionByCode.fulfilled,
      (state, { payload: { access, refresh, userDto } }) => {
        state.id = userDto?.id;
        state.login = userDto?.login;
        state.firstName = userDto?.firstName;
        state.lastName = userDto?.lastName;
        state.email = userDto?.email;
        state.phone = userDto?.phone;
        state.role = userDto?.role;
        state.status = userDto?.status;
        state.sessionStatus = userDto?.sessionStatus;
        state.mfaEnabled = userDto?.mfaEnabled;
        state.access = access;
        state.refresh = refresh;
        state.photos = userDto?.photos ?? [];
        state.companyId = userDto?.companyId;
        state.mattermostUserId = userDto?.mattermostUserId;
      }
    )
    .addCase(getUserCompanyData.fulfilled, (state, { payload }) => {
      state.companyData = payload;
    })
    .addCase(updateUserCompany, (state, { payload }) => {
      state.companyData = payload;
    })
    .addCase(
      updateProfile,
      (state, { payload: { firstName, lastName, email, phone } }) => {
        state.firstName = firstName;
        state.lastName = lastName;
        state.email = email;
        state.phone = phone;
      }
    );
});

export default reducer;
