import { httpService } from "./httpService";

/**
 * @template T
 * @typedef {import('types/commonTypes').HttpResult<T>} HttpResult
 */

/**
 * @returns  {HttpResult<import("types/commonTypes").Contract>}
 */
export const getDefaultContractData = async () =>
  httpService({
    url: `/contracts/payment-details/general`,
    options: {
      method: "GET",
    },
  });

/**
 * @param {import("types/commonTypes").Contract} data
 * @returns  {HttpResult<import("types/commonTypes").Contract>}
 */
export const updateDefaultContractData = async (data) =>
  httpService({
    url: `/contracts/payment-details/general`,
    options: {
      method: "PUT",
      data,
    },
  });
