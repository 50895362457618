import React from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, Stack, Tooltip, styled, useTheme } from "@mui/material";
import { ActionButton } from "SC";
import { StatusColorNames } from "pages/BookingsPage.style";
import { customColors } from "models/customColors";
import { convertToReadable } from "utils";

const statusMap = {
  NEW: "ASAP ride awaiting driver assignment",
  UNMATCHED: "Dispatcher needs to assign a driver",
  CONFIRMATION: "Dispatcher awaits the driver's confirmation",
  SCHEDULED: "Driver has accepted the ride",
  EN_ROUTE: "Driver is on the way to the pick-up location",
  ARRIVED: "Driver is waiting for passenger boarding",
  ON_BOARD: "Driver is on the way to the drop-off address",
  COMPLETED: "Ride has been successfully finished",
  CANCELLED: "Ride has been canceled for a specific reason",
};

export const Status = styled("span")`
  font-weight: bold;
  color: ${({
    // @ts-ignore
    status,
    theme,
  }) =>
    customColors[theme.palette.mode][StatusColorNames[status] ?? "greyText"]};
`;

export const StatusLegend = () => {
  const theme = useTheme();
  
  return (
    <Tooltip
      title={
        <Stack gap={0.75}>
          {Object.entries(statusMap).map(([key, value]) => (
            <Box key={key}>
              <Status
                // @ts-ignore
                status={key}
              >
                {convertToReadable(key)}
              </Status>
              : {value}
            </Box>
          ))}
        </Stack>
      }
      placement="left-end"
      arrow
      slotProps={{
        arrow: {
          sx: {
            color: customColors[theme.palette.mode].background,
          },
        },
      }}
      componentsProps={{
        tooltip: {
          sx: {
            width: "fit-content",
            maxWidth: "100%",
            borderRadius: "14px",
            color: customColors[theme.palette.mode].textBody,
            backgroundColor: customColors[theme.palette.mode].background,
            border: `1px solid ${customColors[theme.palette.mode].borderColor}`,
            boxShadow: 6,
            padding: 2,
          },
        },
      }}
    >
      <ActionButton>
        <HelpOutlineIcon />
      </ActionButton>
    </Tooltip>
  );
};
