import { useEffect } from "react";

/**
 * @param {{ loadData: () => void; reloadIntervalInSeconds?: number; skipFirstLoad?: boolean;}} param0
 */
export const useDataReload = ({
  loadData,
  reloadIntervalInSeconds = 60,
  skipFirstLoad = false,
}) => {
  useEffect(() => {
    if (!skipFirstLoad) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      loadData();
    }, reloadIntervalInSeconds * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [loadData, reloadIntervalInSeconds]);
};
