export const HumanizedDuration = ({ durationInMs, StatisticValue, StatisticUnits}) => {
    const hours = Math.floor(durationInMs / 1000 / 60 / 60);
    const mins = Math.ceil((durationInMs / 1000 / 60) % 60);
  
    if (hours === 0) {
      return (
        <StatisticValue>
          {mins} <StatisticUnits>min</StatisticUnits>
        </StatisticValue>
      );
    }
  
    if (hours === 1 && mins === 0) {
      return (
        <StatisticValue>
          {hours} <StatisticUnits>hour</StatisticUnits>
        </StatisticValue>
      );
    } else if (hours > 1 && mins === 0) {
      return (
        <StatisticValue>
          {hours} <StatisticUnits>hours</StatisticUnits>
        </StatisticValue>
      );
    } else if (hours === 1 && mins > 0) {
      return (
        <StatisticValue>
          {hours} <StatisticUnits>hour</StatisticUnits> {mins}{" "}
          <StatisticUnits>min</StatisticUnits>
        </StatisticValue>
      );
    } else {
      return (
        <StatisticValue>
          {hours} <StatisticUnits>hours</StatisticUnits> {mins}{" "}
          <StatisticUnits>min</StatisticUnits>
        </StatisticValue>
      );
    }
  };