import { httpService } from "./httpService";

/**
 * @template T
 * @typedef {import('types/commonTypes').HttpResult<T>} HttpResult
 */
/**
 * @template T
 * @typedef {import('types/commonTypes').TableResponse<T>} TableResponse
 */

/**
 * @typedef {import('types/commonTypes').CsvFile} CsvFile
 * @param {import('types/commonTypes').TableRequest} data
 * @returns  {HttpResult<TableResponse<CsvFile>>}
 */
export const getCsvFilesSearch = async (data) =>
  httpService({
    url: "/file-requests/search",
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @param {import('types/commonTypes').NewFileRequestDto} data
 * @returns  {HttpResult<import("types/commonTypes").CsvFileRequest>}
 */
export const createCsvFileRequest = async (data) =>
  httpService({
    url: "/file-requests",
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @param {string} id
 * @returns  {HttpResult<import("types/commonTypes").CsvFileRequest>}
 */
export const getCsvFileRequestById = async (id) =>
  httpService({
    url: `/file-requests/${id}`,
    options: {
      method: "GET",
    },
  });

/**
 * @param {import('types/commonTypes').StatusUpdateDto} data
 * @returns  {HttpResult<import("types/commonTypes").CsvFileRequest>}
 */
export const updateFileRequestStatus = async (data) =>
  httpService({
    url: "/file-requests/change-status",
    options: {
      method: "PUT",
      data,
    },
  });

/**
 * @param {import('types/commonTypes').TableRequest & { rideRequestStatus: string; rideRequestId: string; }} data
 * @returns  {HttpResult<TableResponse<import("types/commonTypes").RideRequestItem>>}
 */
export const getFileRequestItemsSearch = async (data) =>
  httpService({
    url: `/csv-single-ride-requests/by-file-request/${data?.rideRequestId}/search`,
    options: {
      method: "POST",
      data: Object.entries(data)
        .filter(([key]) => "rideRequestId" !== key)
        .reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value,
          }),
          {}
        ),
    },
  });

/**
 * @param {string} id
 * @returns  {HttpResult<import("types/commonTypes").RideRequestItem>}
 */
export const getCsvFileRequestItemById = async (id) =>
  httpService({
    url: `/csv-single-ride-requests/${id}`,
    options: {
      method: "GET",
    },
  });

/**
 * @param {import('types/commonTypes').RequestItemUpdateDto} data
 * @returns  {HttpResult<import("types/commonTypes").RideRequestItem>}
 */
export const updateFileRequestItem = async (data) =>
  httpService({
    url: "/csv-single-ride-requests",
    options: {
      method: "PUT",
      data,
    },
  });

/**
 * @param {{ rideRequestIds: number[] }} data
 * @returns  {HttpResult<import("types/commonTypes").TransferResponse>}
 */
export const transferFileRequestItemsToRides = async (data) =>
  httpService({
    url: "/csv-single-ride-requests/transfer-to-rides",
    options: {
      method: "POST",
      data,
    },
  });
