export const requestItemStatuses = {
    VALIDATE: "VALIDATE",
    ERROR: "ERROR",
    ACCEPTED: "ACCEPTED",
    TRANSFERRED: "TRANSFERRED",
    UNACCEPTED: "UNACCEPTED",
    EXPORT: "EXPORT",
};

export const StatusColorNames = {
    VALIDATE: "new",
    ERROR: "unmatched",
    // CONFIRMATION: "confirmation",
    ACCEPTED: "scheduled",
    // EN_ROUTE: "enRoute",
    // ARRIVED: "arrived",
    // ON_BOARD: "onboard",
    TRANSFERRED: "completed",
    UNACCEPTED: "canceled",
    EXPORT: "enRoute",
  };
  