import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, InputLabel, Stack } from "@mui/material";
import { DialogWindow } from "UI/DialogWindow";
import { useApiRequest } from "api/useApiRequest";
import { useFormDataSync } from "hooks/useFormDataSync";
import { assignDriverToRide, getCandidatesByRideId } from "api/rideApi";
import { useAppDispatch } from "store/hooks";
import { showError } from "store/reducers/errorReducer";
import { FormSelectField } from "UI/FormSelectField";

/** @type {{ userId: string; vehicleId: string; }} */
const defaultFormData = { userId: "", vehicleId: "" };

export const AssignDriverDialog = ({
  open,
  handleClose,
  refreshData,
  ride,
  action,
}) => {
  const { callApiRequest } = useApiRequest();
  const dispatch = useAppDispatch();
  const [candidates, setCandidates] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: defaultFormData,
  });

  const { handleConfirmedClose, handleForcedClose } = useFormDataSync({
    isDirty,
    handleClose,
    open,
    reset,
  });

  const onSubmit = async () => {
    const params = getValues();

    callApiRequest({
      apiRequest: assignDriverToRide,
      params: {
        id: ride?.id,
        data: params,
      },
      onSuccess: () => {
        refreshData();
        handleForcedClose();
      },
    });
  };

  useEffect(() => {
    if (open) {
      if (ride?.driver?.id) {
        setValue("userId", ride?.driver?.id, { shouldDirty: false });
        setValue("vehicleId", ride?.vehicle?.id, { shouldDirty: false });
      }
      callApiRequest({
        apiRequest: getCandidatesByRideId,
        params: ride?.id,
        onSuccess: (result) => {
          const newCandidates = [];
          if (ride?.driver?.id) {
            newCandidates.push({
              id: ride.driver.id,
              driverName: ride.driver.driverName,
              vehicleId: ride?.vehicle?.id,
              vehicleLabel: ride.vehicle.label,
            });
          }
          const suggestedCandidates =
            result?.filter((candidate) => candidate.id !== ride?.driver?.id) ??
            [];
          setCandidates([...newCandidates, ...suggestedCandidates]);
          setIsLoaded(true);
        },
        onError: (error) => {
          // @ts-ignore
          dispatch(showError(error));
          setCandidates([]);
        },
      });
    } else {
      setCandidates([]);
      reset(defaultFormData);
      setIsLoaded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, ride]);

  const driverOptions = candidates?.reduce(
    (acc, { id, driverName, vehicleLabel }) => ({
      ...acc,
      [id]: `${driverName}, ${vehicleLabel}`,
    }),
    {}
  );

  const handleChangeDriver = (event) => {
    const driverId = event?.target?.value;
    const selectedDriver = candidates.find(
      (candidate) => candidate.id === driverId
    );
    if (selectedDriver) {
      setValue("userId", selectedDriver.id, { shouldDirty: true });
      setValue("vehicleId", selectedDriver.vehicleId, { shouldDirty: true });
    }
  };

  return (
    <DialogWindow
      open={open}
      withCloseButton
      handleClose={handleConfirmedClose}
      title={`${action} Driver`}
      content={
        <form>
          <Stack minWidth={"340px"}>
            <Stack mt={2} gap={3} width={"100%"}>
              <Stack gap={2}>
                {isLoaded && candidates?.length === 0 ? (
                  <p>No drivers available</p>
                ) : (
                  <Stack alignItems={"flex-start"}>
                    <InputLabel htmlFor="userId">Select Driver</InputLabel>
                    <FormSelectField
                      id="userId"
                      name="userId"
                      control={control}
                      options={driverOptions}
                      withNone={false}
                      // disabled={ride?.status !== "SCHEDULED" && ride?.status !== "NEW"}
                      onChange={handleChangeDriver}
                    />
                  </Stack>
                )}
                <Button
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                  disabled={!isDirty}
                >
                  {action} Driver
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      }
    />
  );
};
