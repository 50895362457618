import { useState } from "react";

export const useSearchInList = () => {
  const [search, setSearch] = useState("");

  const handleChangeSearch = (e) => {
    const value = e?.target?.value;

    setSearch(value);
  };

  const clearSearch = () => setSearch("");

  const getFilterSearchHandler = (search, propName) => (item) => {
    return item[propName].toLowerCase().includes(search.toLowerCase());
  };

  const getFilterSearchForPropArrayHandler = (search, propNames) => (item) => {
    const searchText = propNames.reduce((acc, propName) => {
      return `${acc} ${item[propName]}`;
    }, "");
    return (
      searchText?.trim().toLowerCase().includes(search.toLowerCase()) ||
      searchText?.replaceAll("_", " ").trim().toLowerCase().includes(search.toLowerCase())
    );
  };

  return {
    search,
    setSearch,
    getFilterSearchHandler,
    getFilterSearchForPropArrayHandler,
    handleChangeSearch,
    clearSearch,
  };
};
