import { Box, Stack, Typography, styled } from "@mui/material";
import { customColors } from "models/customColors";

export const Container = styled(Stack)`
  padding: 12px 28px;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid
    ${({ theme }) => customColors[theme.palette.mode].borderColor};

  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].background};
`;

export const PageHeader = styled(Stack)`
  flex: 1 0 auto;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  font-weight: 600;
  font-size: 20px;

  & path {
    fill: ${({ theme }) => customColors[theme.palette.mode].textBody};
  }
`;

export const UserInfo = styled(Stack)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  padding: 12px 16px 12px 16px;
  border-radius: 8px;
  border: 2px solid
    ${({ theme }) => customColors[theme.palette.mode].borderColor};
  gap: 10px;

  cursor: pointer;
`;

export const Name = styled(Box)`
  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  line-height: 18px;
  word-wrap: break-word;
`;

export const Role = styled(Box)`
  color: ${({ theme }) => customColors[theme.palette.mode].greyText};
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  line-height: 18px;
  word-wrap: break-word;
`;

export const RootPathSegment = styled(Typography)`
  font-size: 20px;

  color: ${({ theme }) => customColors[theme.palette.mode].greyText};

  &:hover {
    color: ${({ theme }) => customColors[theme.palette.mode].additionalAccent};
  }
`;

export const ProfilePhoto = styled(Box)`
  width: 37px;
  height: 37px;

  border-radius: 50%;

  background-repeat: "no-repeat";
  background-position: "center";
`;
