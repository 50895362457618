import { createAction, createReducer } from "@reduxjs/toolkit";

/**
 * @template T
 * @typedef {import('types/stateTypes').PayloadPreparator<T>} PayloadPreparator
 */

/** @type {{ selectedId?: string; isNewCheckWindowOpen: boolean; }} */
const initialState = {
  isNewCheckWindowOpen: false,
};

export const openChecksByDriverId = createAction(
  "checks/openByDriverId",
  /**
   * @type {PayloadPreparator<string>}
   */
  (drverId) => ({ payload: drverId })
);
export const openNewCheckByDriverId = createAction(
  "checks/openNewCheckWindow",
  /**
   * @type {PayloadPreparator<string>}
   */
  (drverId) => ({ payload: drverId })
);
export const closeChecksWindow = createAction("checks/closeCheckWindow");

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(openChecksByDriverId, (state, { payload }) => {
      state.selectedId = payload;
      state.isNewCheckWindowOpen = false;
    })
    .addCase(openNewCheckByDriverId, (state,  { payload }) => {
      state.selectedId = payload;
      state.isNewCheckWindowOpen = true;
    })
    .addCase(closeChecksWindow, () => initialState);
});

export default reducer;
