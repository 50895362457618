import React, { useEffect } from "react";
import { Stack, styled } from "@mui/material";
import { useAppDispatch } from "store/hooks";
import { customColors } from "models/customColors";
import { useNavigate, useParams } from "react-router-dom";
import { setNavigationData } from "store/reducers/navigationDataReducer";
import { ModalSpinner } from "components/ModalSpinner";

const Container = styled(Stack)`
  flex: 1 1 auto;
  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].background};
`;

export const ClientInvoice = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    dispatch(
      setNavigationData({
        search: id,
        selectedId: id,
      })
    );
    navigate(`/invoices`)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Container
      width={"100%"}
      height={"70vh"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      gap={3}
      pt={3}
    >
    <ModalSpinner open />
    </Container>
  );
};
