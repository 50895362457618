import React from "react";
import { Box, Stack, styled } from "@mui/material";
import { USDollar, convertToReadable, unixTimeToUSShortDate } from "utils";
import { TextFieldWithEllipsis } from "components/TextFiledWithEllipsis";
import { customColors } from "models/customColors";

export const Content = styled(Stack)`
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 10px;

  height: 100%;

  overflow-y: auto;
  overflow-x: hidden;
`;

const ActiveSelected = styled(Box)`
  width: 16px;
  height: 16px;
  border: 3px solid
    ${({ theme }) => customColors[theme.palette.mode].background};
  border-radius: 16px;
  background-color: ${({ theme }) => customColors[theme.palette.mode].success};
`;

const InactiveSelected = styled(ActiveSelected)`
  background-color: ${({ theme }) => customColors[theme.palette.mode].greyText};
`;

export const Selector = ({ selectedUserId, row }) => (
  <Stack justifyContent={"center"} alignItems={"center"} width={"100%"}>
    {selectedUserId === row?.id ? <ActiveSelected /> : <InactiveSelected />}
  </Stack>
);

export const companiesColumns = [
  {
    label: "Company",
    field: "companyName",
    xs: 3,
    sm: 3,
    md: 3,
    sortableName: "FULL_NAME",
    renderer: ({ companyName }) => (
      <TextFieldWithEllipsis>{companyName}</TextFieldWithEllipsis>
    ),
  },
  {
    label: "New",
    field: "newCount",
    xs: 1,
    sm: 1,
    md: 1,
    align: "center",
    sortableName: "NEW_COUNT",
  },
  {
    label: "New Total",
    field: "newAmount",
    xs: 1,
    sm: 1,
    md: 1,
    align: "right",
    sortableName: "NEW_AMOUNT",
    renderer: ({ newAmount }) => USDollar.format(newAmount),
  },
  {
    xs: 1,
    sm: 1,
    md: 1,
  },
  {
    label: "Paid",
    field: "paidCount",
    xs: 1,
    sm: 1,
    md: 1,
    align: "center",
    sortableName: "PAID_COUNT",
  },
  {
    label: "Paid Total",
    field: "paidAmount",
    xs: 1,
    sm: 1,
    md: 1,
    align: "right",
    sortableName: "PAID_AMOUNT",
    renderer: ({ paidAmount }) => USDollar.format(paidAmount),
  },
  {
    xs: 1.1,
    sm: 1.1,
    md: 1.1,
  },
  {
    label: "Overdue",
    field: "overdueCount",
    xs: 1,
    sm: 1,
    md: 1,
    align: "center",
    sortableName: "OVERDUE_COUNT",
  },
  {
    label: "Overdue Total",
    field: "overdueAmount",
    xs: 1.3,
    sm: 1.3,
    md: 1.3,
    align: "right",
    sortableName: "OVERDUE_AMOUNT",
    renderer: ({ overdueAmount }) => USDollar.format(overdueAmount),
  },
];

export const driversColumns = [
  {
    label: "Driver Name",
    field: "fullName",
    xs: 3,
    sm: 3,
    md: 3,
    sortableName: "FULL_NAME",
    renderer: ({ fullName }) => (
      <TextFieldWithEllipsis>{fullName}</TextFieldWithEllipsis>
    ),
  },
  {
    label: "Cash Out Type",
    field: "paymentPolicy",
    xs: 2,
    sm: 2,
    md: 2,
    align: "left",
    sortableName: "POLICY",
    renderer: ({ paymentPolicy }) => convertToReadable(paymentPolicy),
  },
  {
    label: "Last Cash Out",
    field: "lastCashOutMs",
    xs: 2,
    sm: 2,
    md: 2,
    align: "center",
    sortableName: "LAST_CASH_OUT",
    renderer: ({ lastCashOutMs }) =>
      lastCashOutMs ? unixTimeToUSShortDate(lastCashOutMs) : "",
  },
  {
    label: "Available Balance",
    field: "balance",
    xs: 2,
    sm: 2,
    md: 2,
    align: "right",
    sortableName: "BALANCE",
    renderer: ({ balance }) => USDollar.format(balance),
  },
  {
    xs: 0.5,
    sm: 0.5,
    md: 0.5,
  },
  {
    label: "Next Cash Out",
    field: "nextCashOutMs",
    xs: 2,
    sm: 2,
    md: 2,
    align: "center",
    sortableName: "NEXT_CASH_OUT",
    renderer: ({ nextCashOutMs }) =>
      nextCashOutMs ? unixTimeToUSShortDate(nextCashOutMs) : "",
  },
  {
    xs: 1.1,
    sm: 1.1,
    md: 1.1,
  },
];

export const getShortDriversColumns = (selectedUserId) => [
  {
    label: "Driver Name",
    field: "fullName",
    xs: 4,
    sm: 4,
    md: 4,
    sortableName: "FULL_NAME",
    renderer: ({ fullName }) => (
      <TextFieldWithEllipsis>{fullName}</TextFieldWithEllipsis>
    ),
  },
  {
    label: "Last Cash Out",
    field: "lastCashOutMs",
    xs: 3.5,
    sm: 3.5,
    md: 3.5,
    align: "center",
    sortableName: "LAST_CASH_OUT",
    renderer: ({ lastCashOutMs }) =>
      lastCashOutMs ? unixTimeToUSShortDate(lastCashOutMs) : "",
  },
  {
    label: "Available Balance",
    field: "balance",
    xs: 3.3,
    sm: 3.3,
    md: 3.3,
    align: "right",
    sortableName: "BALANCE",
    renderer: ({ balance }) => USDollar.format(balance),
  },
  {
    xs: 1.2,
    sm: 1.2,
    md: 1.2,
    renderer: (row) => <Selector selectedUserId={selectedUserId} row={row} />,
  },
];
