import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { resetNavigationData } from "store/reducers/navigationDataReducer";

const LOAD_SELECTED_ID_TIMEOUT_MS = 300;

export const useNavigationData = ({
  setMode = (param) => null,
  setSearch = (param) => null,
  setSelectedId = (param) => null,
}) => {
  const dispatch = useAppDispatch();
  const requestedMode = useAppSelector((state) => state.navigationData?.mode);
  const requestedSearch = useAppSelector(
    (state) => state.navigationData?.search
  );
  const requestedId = useAppSelector(
    (state) => state.navigationData?.selectedId
  );

  useEffect(() => {
    // @ts-ignore
    if (requestedMode) {
      setMode(requestedMode);
    }
    if (requestedSearch) {
      setSearch(requestedSearch);
    }
    if (requestedMode || requestedSearch) {
      dispatch(resetNavigationData());
    }
    if (requestedId) {
      setTimeout(() => {
        setSelectedId(requestedId);
      }, LOAD_SELECTED_ID_TIMEOUT_MS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSearch, requestedMode, requestedSearch, requestedId]);

  return { requestedMode, requestedSearch };
};
