import React, { useEffect, useMemo, useState } from "react";
import { GlobalContext } from "store/globalContext";
import { createThemeByMode } from "./theme";
import { useConfirmationsWithCallback } from "hooks/useConfirmationWithCallback";
import { useWarnings } from "hooks/useWarnings";
import { useAppDispatch, useAppSelector } from "store/hooks";
import * as FP from "utils/fp-js";
import "./App.css";
import { ModalSpinner } from "components/ModalSpinner";
import {
  APP_LOADING_DELAY_IN_MS,
  localStorageNameEnum,
} from "models/constants";
import {
  getUserCompanyData,
  restoreAuthByRefreshToken,
} from "store/reducers/userReducer";
import Auth from "routes/Auth";
import { ConfirmMfaCodePage } from "pages/ConfirmMfaCodePage";
import SuperAdmin from "routes/SuperAdmin";
import KericoManager from "routes/KericoManager";
import KericoDispatcher from "routes/KericoDispatcher";
import { ThemeProvider } from "@mui/material";
import { loadAvatar } from "utils";
import { updateAvatar } from "store/reducers/avatarReducer";
import ClientAdmin from "routes/ClientAdmin";
import ClientManager from "routes/ClientManager";

function App() {
  const dispatch = useAppDispatch();
  const confirmationWithCallbackProps = useConfirmationsWithCallback();
  const warningProps = useWarnings();
  const authExpiresAt = useAppSelector(
    (state) => state?.user?.access?.expirationDate
  );
  const user = useAppSelector((state) => state.user);
  const role = useAppSelector((state) => state.user.role);
  const status = useAppSelector((state) => state.user.status);
  const sessionStatus = useAppSelector((state) => state.user.sessionStatus);
  const companyId = useAppSelector((state) => state?.user?.companyId);
  const [isStarting, setIsStarting] = useState(true);

  const mode = useAppSelector((state) => state.layout.themeMode);
  const theme = useMemo(() => createThemeByMode(mode), [mode]);

  useEffect(() => {
    const refreshToken = window.localStorage.getItem(
      localStorageNameEnum.AUTH_REFRESH_TOKEN
    );

    if (refreshToken) {
      dispatch(restoreAuthByRefreshToken(refreshToken));
    }
    const timeout = setTimeout(
      () => setIsStarting(false),
      APP_LOADING_DELAY_IN_MS
    );

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timeout;

    if (authExpiresAt) {
      const now = new Date().getTime();
      const refreshIn = authExpiresAt - now - 1000 * 60; // interval of the auth refresh in 1 min before it is expired

      const refreshToken = window.localStorage.getItem(
        localStorageNameEnum.AUTH_REFRESH_TOKEN
      );

      timeout = setTimeout(
        () => dispatch(restoreAuthByRefreshToken(refreshToken)),
        refreshIn
      );
    }

    return () => (timeout ? clearTimeout(timeout) : null);
  }, [authExpiresAt, dispatch]);

  useEffect(() => {
    loadAvatar({ user, setImage: (image) => dispatch(updateAvatar(image)) });
  }, [dispatch, user]);

  useEffect(() => {
    if (companyId) {
      dispatch(getUserCompanyData(companyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  if (isStarting) {
    return <ModalSpinner open />;
  }

  return (
    <GlobalContext.Provider
      value={{
        ...confirmationWithCallbackProps,
        ...warningProps,
      }}
    >
      <ThemeProvider theme={theme}>
        {FP.match(true)
          .on(
            // true,
            status === "ACTIVE" && sessionStatus !== "ACTIVE",
            <ConfirmMfaCodePage />
          )
          .on(role === "SUPER_ADMIN", <SuperAdmin />)
          .on(role === "CUSTOMER_ADMIN", <ClientAdmin />)
          .on(role === "CUSTOMER_MANAGER", <ClientManager />)
          .on(role === "KERICO_MANAGER", <KericoManager />)
          .on(role === "KERICO_DISPATCHER", <KericoDispatcher />)
          .otherwise(<Auth />)}
      </ThemeProvider>
    </GlobalContext.Provider>
  );
}

export default App;
