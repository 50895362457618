import React from "react";
import { MenuItem, Pagination, Select } from "@mui/material";
import * as SC from "./DataGrid.style";
import { customColors } from "models/customColors";

export const DataGridPagination = ({
  totalPages,
  defaultLimit,
  limit,
  currentPage,
  handleChangePage,
  handleChangeRecordsPerPage,
  count,
  selectedRows = [],
}) => (
  <SC.PaginationContainer>
    <Pagination
      count={totalPages}
      page={currentPage}
      onChange={handleChangePage}
      size="small"
    />
    {selectedRows?.length > 0 && (
      <SC.SelectedRowsCounter>
        {selectedRows.length} selected
      </SC.SelectedRowsCounter>
    )}
    <SC.RecordsPerPageSelector>
      Show
      <Select
        value={limit}
        label="Age"
        onChange={handleChangeRecordsPerPage}
        size="small"
        sx={{ backgroundColor: customColors.white, fontSize: "14px" }}
      >
        <MenuItem value={defaultLimit}>{defaultLimit}</MenuItem>
        <MenuItem value={defaultLimit * 2}>{defaultLimit * 2}</MenuItem>
        <MenuItem value={defaultLimit * 4}>{defaultLimit * 4}</MenuItem>
      </Select>
      per page
    </SC.RecordsPerPageSelector>
  </SC.PaginationContainer>
);
