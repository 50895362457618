import React, { useEffect, useState } from "react";
import { Box, Stack, styled, useTheme } from "@mui/material";
import * as SC from "SC";
import { useForm } from "react-hook-form";
import { useIsDataChanged } from "hooks/useIsDataChanged";
import { useClientList } from "hooks/useClientList";
import { CreateBookingStepOne } from "components/CreateBookingStepOne";
import { CreateBookingStepTwo } from "components/CreateBookingStepTwo";
import { CreateBookingStepThree } from "components/CreateBookingStepThree";
import { RouteMapView } from "UI/RouteMapView";
import { customColors } from "models/customColors";
import { useAppSelector } from "store/hooks";
import { useLocationSelector } from "hooks/useLocationSelector";
import { useRoute } from "hooks/useRoute";

export const Container = styled(Stack)`
  max-height: 100%;
  overflow-y: auto;
`;

export const Title = styled(Box)`
  font-family: "Inter Semi Bold";
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;

/** @type {import("types/commonTypes").RideEstimateResponse} */
const defaultRideEstimaion = {
  distance: 0,
  distanceUnit: "",
  durationMs: 0,
  etaMs: 0,
  estimatedCost: 0,
};

export const CreateBookingPage = () => {
  const theme = useTheme();
  const customerCompanyId = useAppSelector((state) => state.user.companyId);

  const { clientListOptions, getClientLabelById } = useClientList();

  const { setIsDataChanged } = useIsDataChanged();
  const [step, setStep] = useState(0);

  const [rideEstimation, setRideEstimation] = useState(defaultRideEstimaion);

  /** @type {import("types/commonTypes").NewRide} */
  const defaultFormData = {
    customerCompanyId: customerCompanyId ?? "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    rideType: "ASAP",
    vehicleType: "SEDAN_OR_SALOON",
    departureTimeMs: null,
    departure: {
      id: "",
      scope: "INTERNAL",
    },
    destination: {
      id: "",
      scope: "INTERNAL",
    },
    passengersNumber: 1,
    additionalNotes: "",
    d2d: true,
  };

  const {
    control,
    getValues,
    setValue,
    trigger,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: defaultFormData,
  });

  const {
    departureError,
    departureLabel,
    destinationError,
    destinationLabel,
    setDepartureError,
    setDestinationError,
    handleLocationSelect,
  } = useLocationSelector({
    setValue,
  });

  const handlePrevStep = () => setStep(step - 1);

  useEffect(() => {
    setIsDataChanged(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  const { route } = useRoute({
    watch,
    getValues,
  });

  return (
    <SC.Container sx={{ padding: "30px" }}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        height={"100%"}
        gap={5}
      >
        <Stack flex={1}>
          {step === 0 && (
            <CreateBookingStepOne
              control={control}
              setStep={setStep}
              step={step}
              trigger={trigger}
              clientListOptions={clientListOptions}
            />
          )}
          {step === 1 && (
            <CreateBookingStepTwo
              control={control}
              departureError={departureError}
              departureLabel={departureLabel}
              destinationError={destinationError}
              destinationLabel={destinationLabel}
              getValues={getValues}
              handlePrevStep={handlePrevStep}
              setDepartureError={setDepartureError}
              setDestinationError={setDestinationError}
              setStep={setStep}
              setValue={setValue}
              step={step}
              trigger={trigger}
              watch={watch}
              handleLocationSelect={handleLocationSelect}
            />
          )}
          {step === 2 && (
            <CreateBookingStepThree
              defaultRideEstimaion={defaultRideEstimaion}
              departureLabel={departureLabel}
              destinationLabel={destinationLabel}
              getValues={getValues}
              getClientLabelById={getClientLabelById}
              handlePrevStep={handlePrevStep}
              rideEstimation={rideEstimation}
              setRideEstimation={setRideEstimation}
              step={step}
            />
          )}
        </Stack>
        <Stack
          flex={1}
          sx={{
            backgroundColor: customColors[theme.palette.mode].strokeColor,
            borderRadius: "20px",
            overflow: "hidden",
          }}
        >
          <RouteMapView
            route={route}
            departure={departureLabel}
            destination={destinationLabel}
          />
        </Stack>
      </Stack>
    </SC.Container>
  );
};
