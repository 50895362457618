import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, InputLabel, Stack, TextField, useTheme } from "@mui/material";
import { DialogWindow } from "UI/DialogWindow";
import { FormTextField } from "UI/FormTextField";
import { required } from "models/validations";
import { useFormDataSync } from "hooks/useFormDataSync";
import { FormSelectField } from "UI/FormSelectField";
import { states } from "models/constants";
import { customColors } from "models/customColors";
import { FormDateSelector } from "UI/FormDateSelector";
import { httpUploadService } from "api/httpUploadService";
import { useAppDispatch } from "store/hooks";
import { GlobalContext } from "store/globalContext";
import { showError } from "store/reducers/errorReducer";
import { showSpinner } from "store/reducers/spinnerReducer";
import { ReactComponent as CompleteIcon } from "assets/img/complete-icon.svg";

/** @type {import("types/commonTypes").NewVehicleDto} */
const defaultFormData = {
  owner: "KERICO",
  model: "",
  make: "",
  year: "",
  type: undefined,
  state: "",
  licensePlate: "",
  licensePlateExpTimeMs: null,
  insuranceExpTimeMs: null,
};

export const AssignKericoVehicleDialog = ({
  open,
  handleClose,
  refreshData,
  driverId,
  driverFullname,
}) => {
  const dispatch = useAppDispatch();
  const { showWarning } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);
  const [fileError, setFileError] = useState("");
  const fileRef = useRef(null);
  const theme = useTheme();
  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: defaultFormData,
    mode: "all",
  });

  const { handleConfirmedClose } = useFormDataSync({
    isDirty,
    handleClose,
    open,
    reset,
  });

  const onSubmit = async (newData) => {

    setFileError("");
    setIsLoading(true);

    const files = fileRef.current?.files;

    if (files?.length !== 1) {
      setFileError("is required");
      setIsLoading(false);
      return;
    }

    const file = files[0];

    const params = {
      ...newData,
      insuranceExpTimeMs: newData?.insuranceExpTimeMs
        ? parseInt(new Date(newData?.insuranceExpTimeMs).getTime()?.toFixed(0))
        : null,
      licensePlateExpTimeMs: newData?.licensePlateExpTimeMs
        ? parseInt(
            new Date(newData?.licensePlateExpTimeMs).getTime()?.toFixed(0)
          )
        : null,
      year: parseInt(newData?.year),
    };

    const data = new FormData();


    try {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      const dto = new Blob([JSON.stringify(params)], {
        type: "application/json",
      });

      data.append("dto", dto);
      data.append("insurance", file);
    } catch (error) {
      dispatch(showError(error));
      return;
    }

    dispatch(showSpinner());
    try {
      const result = await httpUploadService({
        url: `/vehicles/driver/${driverId}`,
        method: "POST",
        data,
      });

      if (result?.error) {
        dispatch(showError(result?.error));
        return;
      }
      showWarning({
        title: "Kerico Vehicle Added",
        content: "Kerico vehicle has been successfully added",
        icon: <CompleteIcon />,
      });
      refreshData();
      handleClose();
    } catch (error) {
      dispatch(showError(error));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open && driverId) {
      return;
    }
    reset(defaultFormData);
    setFileError("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverId, open]);

  return (
    <DialogWindow
      open={open}
      withCloseButton
      handleClose={handleConfirmedClose}
      title={`Add Kerico Vehicle for ${driverFullname}`}
      content={
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            <Stack mt={2} gap={3} width={"100%"}>
              <Stack gap={2}>
                <Stack direction={"row"} alignItems={"flex-start"} gap={2}>
                  <Stack alignItems={"flex-start"}>
                    <InputLabel htmlFor="make">Make</InputLabel>
                    <FormTextField
                      id="make"
                      name="make"
                      placeholde="Make"
                      control={control}
                      rules={{ required }}
                      InputProps={{
                        autoComplete: "off",
                      }}
                    />
                  </Stack>
                  <Stack alignItems={"flex-start"}>
                    <InputLabel htmlFor="model">Model</InputLabel>
                    <FormTextField
                      id="model"
                      name="model"
                      placeholde="Model"
                      control={control}
                      rules={{ required }}
                      InputProps={{
                        autoComplete: "off",
                      }}
                    />
                  </Stack>
                  <Stack alignItems={"flex-start"} maxWidth={"130px"}>
                    <InputLabel htmlFor="year">Year</InputLabel>
                    <FormTextField
                      id="year"
                      name="year"
                      placeholde="Year"
                      control={control}
                      rules={{ required }}
                      InputProps={{
                        autoComplete: "off",
                      }}
                      type="number"
                    />
                  </Stack>
                </Stack>
                <Stack direction={"row"} alignItems={"flex-start"} gap={2}>
                  <Stack alignItems={"flex-start"} flex={1}>
                    <InputLabel htmlFor="type">Vehicle Type</InputLabel>
                    <FormSelectField
                      id="type"
                      name="type"
                      control={control}
                      options={{
                        SEDAN_OR_SALOON: "Sedan or saloon",
                        VAN: "Van",
                        WHEELCHAIR: "Wheelchair",
                      }}
                      withNone={false}
                      rules={{ required }}
                    />
                  </Stack>
                  <Stack alignItems={"flex-start"} flex={1}>
                    <InputLabel htmlFor="state">State</InputLabel>
                    <FormSelectField
                      id="state"
                      name="state"
                      control={control}
                      options={states}
                      rules={{ required }}
                    />
                  </Stack>
                </Stack>
                <Stack direction={"row"} alignItems={"flex-start"} gap={2}>
                  <Stack alignItems={"flex-start"} flex={1}>
                    <InputLabel htmlFor="model">License Plate</InputLabel>
                    <FormTextField
                      id="licensePlate"
                      name="licensePlate"
                      placeholde="licensePlate"
                      control={control}
                      rules={{ required }}
                      InputProps={{
                        autoComplete: "off",
                      }}
                    />
                  </Stack>
                  <Stack alignItems={"flex-start"}>
                    <InputLabel htmlFor="licensePlateExpTimeMs">
                      License Plate Expiration Date
                    </InputLabel>
                    <FormDateSelector
                      id="licensePlateExpTimeMs"
                      name="licensePlateExpTimeMs"
                      control={control}
                      rules={{ required }}
                      size="small"
                      disablePast
                    />
                  </Stack>
                </Stack>
                <Stack direction={"row"} alignItems={"flex-start"} gap={2}>
                  <Stack alignItems={"flex-start"} flex={1}>
                    <InputLabel htmlFor="state">
                      Insurance Photo (JPEG, PNG)
                    </InputLabel>
                    <TextField
                      type="file"
                      size="small"
                      error={!!fileError}
                      // @ts-ignore
                      helperText={fileError}
                      inputRef={fileRef}
                      inputProps={{
                        accept: "image/png, image/jpeg",
                        multiple: false,
                        style: {
                          color: customColors[theme.palette.mode].actionColor,
                        },
                      }}
                      sx={{ width: "100%" }}
                      onChange={() => setFileError("")}
                    />
                  </Stack>
                  <Stack alignItems={"flex-start"}>
                    <InputLabel htmlFor="insuranceExpTimeMs">
                      Insurance Expiration Date
                    </InputLabel>
                    <FormDateSelector
                      id="insuranceExpTimeMs"
                      name="insuranceExpTimeMs"
                      control={control}
                      rules={{ required }}
                      size="small"
                      disablePast
                    />
                  </Stack>
                </Stack>
                <Stack mt={2} direction={"row"} alignItems={"center"} gap={1}>
                  <Button variant="outlined" onClick={handleConfirmedClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isLoading}
                  >
                    Add Kerico Vehicle
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </form>
      }
    />
  );
};
