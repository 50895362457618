import { useApiRequest } from "api/useApiRequest";
import { getClientUserById } from "api/userApi";
import { useCallback } from "react";
import { useEffect, useState } from "react";
import { useAppDispatch } from "store/hooks";
import { showError } from "store/reducers/errorReducer";

/** @type {import("types/commonTypes").UserDto | null} */
const defaultData = null;

export const useClientUser = ({ id }) => {
  const dispatch = useAppDispatch();
  const { callApiRequest } = useApiRequest();
  const [user, setUser] = useState(defaultData);

  const loadUserData = useCallback(async () => {
    if (!id) return;

    callApiRequest({
      apiRequest: getClientUserById,
      params: id,
      onSuccess: (result) => {
        setUser(result);
      },
      onError: (error) => {
        // @ts-ignore
        dispatch(showError(error));
        setUser(defaultData);
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    loadUserData();
  }, [loadUserData]);

  return {
    user,
    setUser,
    loadUserData,
  };
};
