import React, { useContext, useRef } from "react";
import { Button, InputLabel, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { DialogWindow } from "UI/DialogWindow";
import { FormTextField } from "UI/FormTextField";
import { FormSelectField } from "UI/FormSelectField";
import {
  getEmailConfirmValidation,
  emailValidation,
  nameValidation,
  required,
  phoneNumberRequiredValidation,
} from "models/validations";
import { FormMaskedTextField } from "UI";
import { useApiRequest } from "api/useApiRequest";
import { useFormDataSync } from "hooks/useFormDataSync";
import { GlobalContext } from "store/globalContext";
import { ReactComponent as UserIcon } from "assets/img/user-small-icon.svg";
import { addClientUser } from "api/userApi";

/** @type {import("types/commonTypes").NewClientUserDto & { emailRepeat: string; }} */
const defaultFormData = {
  firstName: "",
  lastName: "",
  email: "",
  emailRepeat: "",
  phone: "",
  role: "",
  companyId: "",
};

export const AddClientUserDialog = ({
  open,
  handleClose,
  refreshList,
  clientId,
}) => {
  const { showWarning } = useContext(GlobalContext);
  const { callApiRequest } = useApiRequest();

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: defaultFormData,
  });

  const { handleConfirmedClose, handleForcedClose } = useFormDataSync({
    isDirty,
    handleClose,
    open,
    reset,
  });

  const email = useRef("");
  email.current = watch("email", "");

  const onSubmit = async () => {
    const user = getValues();
    const params = Object.entries(user)
      .filter(([name]) => name !== "emailRepeat")
      .reduce((acc, [name, value]) => ({ ...acc, [name]: value }), {});

    // @ts-ignore
    params.companyId = clientId;

    callApiRequest({
      apiRequest: addClientUser,
      params,
      onSuccess: () => {
        showWarning({
          title: "Client User Added",
          content: "The Client User has been successfully added",
          icon: <UserIcon />,
        });
        refreshList();
        handleForcedClose();
      },
    });
  };

  return (
    <DialogWindow
      open={open}
      withCloseButton
      handleClose={handleConfirmedClose}
      title={"Add Client User"}
      content={
        <form>
          <Stack mt={2} gap={3} direction={"row"} justifyContent={"center"}>
            <Stack gap={2}>
              <Stack alignItems={"flex-start"}>
                <InputLabel htmlFor="firstName">First Name</InputLabel>
                <FormTextField
                  id="firstName"
                  name="firstName"
                  control={control}
                  rules={nameValidation}
                  InputProps={{
                    autoComplete: "off",
                  }}
                />
              </Stack>
              <Stack alignItems={"flex-start"}>
                <InputLabel htmlFor="lastName">Last Name</InputLabel>
                <FormTextField
                  id="lastName"
                  name="lastName"
                  control={control}
                  rules={nameValidation}
                  InputProps={{
                    autoComplete: "off",
                  }}
                />
              </Stack>
              <Stack alignItems={"flex-start"}>
                <InputLabel htmlFor="email">Email</InputLabel>
                <FormTextField
                  id="email"
                  name="email"
                  control={control}
                  rules={emailValidation}
                  InputProps={{
                    autoComplete: "email",
                  }}
                />
              </Stack>
              <Stack alignItems={"flex-start"}>
                <InputLabel htmlFor="emailRepeat">Confirm email</InputLabel>
                <FormTextField
                  id="emailRepeat"
                  name="emailRepeat"
                  control={control}
                  rules={getEmailConfirmValidation(email.current)}
                  InputProps={{
                    autoComplete: "newpassword",
                  }}
                />
              </Stack>
            </Stack>
            <Stack gap={2}>
              <Stack alignItems={"flex-start"} width={"100%"}>
                <InputLabel htmlFor="role">Role</InputLabel>
                <FormSelectField
                  id="role"
                  name="role"
                  control={control}
                  options={{
                    CUSTOMER_ADMIN: "Client Admin",
                    CUSTOMER_MANAGER: "Client Manager",
                  }}
                  withNone={false}
                  rules={{ required }}
                />
              </Stack>
              <Stack alignItems={"flex-start"}>
                <InputLabel htmlFor="phone">Phone Number</InputLabel>
                <FormMaskedTextField
                  id="phone"
                  name="phone"
                  control={control}
                  rules={phoneNumberRequiredValidation}
                  mask="999-999-9999"
                  InputProps={{
                    autoComplete: "off",
                  }}
                />
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                width={"100%"}
                gap={1}
                mt={2}
              >
                <Button variant="outlined" onClick={handleConfirmedClose}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                  Add Client User
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      }
    />
  );
};
