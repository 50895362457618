import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Controller } from "react-hook-form";

export const FormCheckbox = ({ name, control, label, ...props }) => (
  <Controller
    control={control}
    name={name}
    defaultValue={false}
    render={({ field: { onChange, value } }) => (
      <FormControlLabel
        label={label}
        control={<Checkbox checked={value} onChange={onChange} {...props} />}
      />
    )}
  />
);
