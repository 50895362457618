import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import { hiddenChatNames } from "models/constants";

/**
 * @template T
 * @typedef {import('types/stateTypes').PayloadPreparator<T>} PayloadPreparator
 */

/** @typedef {import("types/commonTypes").Chat} Chat*/

/** @type {import("types/stateTypes").ChatsData} */
const initialState = {
  new: [],
  prev: {},
  selected: undefined,
  isListVisible: false,
  persistedUserId: "",
};

export const loadChats = createAsyncThunk(
  "chats/load",
  async (
    /** @type {string} */
    userId,
    thunkAPI
  ) => {
    if (!userId || userId === "unknown") return Promise.reject();

    try {
      const result =
        // @ts-ignore
        await thunkAPI.extra.messageApi.getChatListByUserId(userId);

      if (result?.error) {
        // thunkAPI.dispatch(showError(result?.error));
        return Promise.reject();
      }
      // @ts-ignore
      const prevUserId = thunkAPI.getState().chats.persistedUserId;

      if (prevUserId !== userId) {
        thunkAPI.dispatch(resetChats());
        thunkAPI.dispatch(setPersistedUserId(userId));
      }

      return result?.value ?? [];
    } catch (e) {
      return Promise.reject();
    }
  }
);

export const setChats = createAction(
  "chats/set",
  /**
   * @type {PayloadPreparator<Chat[]>}
   */
  (chats) => ({ payload: chats })
);

export const setPersistedUserId = createAction(
  "chats/setPersistedUserId",
  /**
   * @type {PayloadPreparator<string>}
   */
  (userId) => ({
    payload: userId,
  })
);

export const updatePrevChat = createAction(
  "chats/updatePrev",
  /**
   * @type {PayloadPreparator<string>}
   */
  (id) => ({ payload: id })
);

export const inrceaseSentMessageCount = createAction(
  "chats/increaseSentMessageCount",
  /**
   * @type {PayloadPreparator<string>}
   */
  (chatId) => ({ payload: chatId })
);

export const selectChat = createAction(
  "chats/select",
  /**
   * @type {PayloadPreparator<Chat>}
   */
  (chat) => ({ payload: chat })
);

export const deletePrevChat = createAction(
  "chats/deletePrev",
  /**
   * @type {PayloadPreparator<string>}
   */
  (chatId) => ({ payload: chatId })
);

export const closeChatList = createAction("chats/closeList");

export const openChatList = createAction("chats/openList");

export const resetChats = createAction("chats/reset");

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loadChats.fulfilled, (state, { payload }) => {
      // state.new = payload ?? [];
      state.new =
        payload?.filter(
          (chat) =>
            chat?.type !== "D" &&
            !hiddenChatNames.includes(chat?.name)
        ) ?? [];
    })
    .addCase(setPersistedUserId, (state, { payload }) => {
      state.persistedUserId = payload;
    })
    .addCase(openChatList, (state) => {
      state.isListVisible = true;
    })
    .addCase(closeChatList, (state) => {
      state.isListVisible = false;
    })
    .addCase(setChats, (state, { payload }) => {
      state.new = payload;
    })
    .addCase(updatePrevChat, (state, { payload }) => {
      const newChat = state.new.find((chat) => chat.id === payload);
      if (newChat) {
        const prevChat = state.prev[state.persistedUserId]?.filter(
          (chat) => chat.id !== payload
        );
        if (prevChat?.length > 0) {
          state.prev[state.persistedUserId] = [...prevChat, newChat];
        } else {
          state.prev[state.persistedUserId] = [newChat];
        }
      }
    })
    .addCase(inrceaseSentMessageCount, (state, { payload }) => {
      const chat = state.new.find((chat) => chat.id === payload);
      if (chat) {
        chat.total_msg_count += 1;
      }

      const prevChat = state.prev[state.persistedUserId]?.find(
        (chat) => chat.id === payload
      );
      if (prevChat) {
        prevChat.total_msg_count += 1;
      }
    })
    .addCase(selectChat, (state, { payload }) => {
      if (!payload) {
        state.selected = undefined;
        return;
      }
      state.selected = payload;
      const prevChat = state.prev[state.persistedUserId]?.find(
        (chat) => chat.id === payload.id
      );
      if (prevChat) {
        state.prev[state.persistedUserId] = state.prev[
          state.persistedUserId
        ].map((chat) => (chat.id === payload.id ? payload : chat));
      } else {
        if (state.prev[state.persistedUserId]?.length >= 0) {
          state.prev[state.persistedUserId] = [
            ...state.prev[state.persistedUserId],
            payload,
          ];
        } else {
          state.prev[state.persistedUserId] = [payload];
        }
      }
    })
    .addCase(deletePrevChat, (state, { payload }) => {
      if (!state.prev[state.persistedUserId]) return;

      state.prev[state.persistedUserId] = state.prev[
        state.persistedUserId
      ].filter((chat) => chat.id !== payload);
    })
    .addCase(resetChats, (state) => {
      state.new = [];
      state.selected = undefined;
      state.isListVisible = false;
      state.persistedUserId = "";
    });
});

export default reducer;
