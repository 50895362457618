import { httpService } from "./httpService";

/**
 * @template T
 * @typedef {import('types/commonTypes').HttpResult<T>} HttpResult
 */

/**
 * @returns  {HttpResult<import('types/commonTypes').MapperRootField[]>}
 */
export const getRootFields = async () =>
  httpService({
    url: "/templates/butch-upload/root-fields",
    options: {
      method: "GET",
    },
  });

  
/**
 * @typedef {import('types/commonTypes').Template} Template
 * @param {Template} data
 * @returns  {HttpResult<Template>}
 */
export const createCsvTemplate = async (data) =>
  httpService({
    url: "/templates/csv",
    options: {
      method: "POST",
      data,
    },
  });

  /**
 * @param {string} id
 * @returns  {HttpResult<any>}
 */
export const deleteCsvTemplate = async (id) =>
  httpService({
    url: `/templates/csv/${id}`,
    options: {
      method: "DELETE",
    },
  });

/**
 * @param {Template} data
 * @returns  {HttpResult<Template>}
 */
export const updateCsvTemplate = async (data) =>
  httpService({
    url: "/templates/csv",
    options: {
      method: "PUT",
      data,
    },
  });

/**
 * @returns  {HttpResult<Template[]>}
 */
export const getAllCsvTemplates = async () =>
  httpService({
    url: "/templates/csv",
    options: {
      method: "GET",
    },
  });
