import { useForm } from "react-hook-form";
import { required } from "models/validations";
import { useAppDispatch } from "store/hooks";
import { getAuth } from "store/reducers/userReducer";
import { useEffect, useState } from "react";

const LOGIN_PAGE_VISIBILITY_DELAY_IN_MS = 500;

export const useLoginPage = () => {
  const { register, handleSubmit, formState, getValues } = useForm();
  const { errors } = formState;
  const [isLoading, setIsLoading] = useState(true);

  const usernameRegisterProps = register("username");
  const passwordRegisterProps = register("password", { required });

  const dispatch = useAppDispatch();

  const onSubmit = handleSubmit(() => {
    const { username, password } = getValues();

    dispatch(getAuth({ username, password }));
  });

  useEffect(() => {
    const timeout = setTimeout(
      () => setIsLoading(false),
      LOGIN_PAGE_VISIBILITY_DELAY_IN_MS
    );

    return () => clearTimeout(timeout);
  }, []);

  return {
    errors,
    onSubmit,
    usernameRegisterProps,
    passwordRegisterProps,
    isLoading,
  };
};
