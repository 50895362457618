import React, { useEffect, useState } from "react";
import { Button, Stack } from "@mui/material";
import * as SC from "SC";
import { useDataGrid } from "hooks/useDataGrid";
import { useAppSelector } from "store/hooks";
import { DataGrid } from "UI/DataGrid";
import { customColors } from "models/customColors";
import { USDollar, convertToReadable, unixTimeToUSShortDate } from "utils";
import { useParams } from "react-router-dom";
import { CloseWindowButton } from "UI/CloseWindowButton";
import { Content, Selector } from "pages/BookingsPage.style";
import { useNavigationData } from "hooks/useNavigationData";
import { getInvoicesInfoByCompanyIdHandler } from "api/invoiceApi";
import styled from "@emotion/styled";
import { InvoiceDetails } from "components/InvoiceDetails";
import { useSpecialityOptions } from "hooks/useSpecialityOptions";
import { useApiRequest } from "api/useApiRequest";
import { getClientById } from "api/clientApi";
import { CompanyInvoicesIndicators } from "components/CompanyInvoicesIndicators";
import { EditClientDialog } from "components/EditClientDialog";

export const HalfScreenPanel = styled(Stack)`
  flex: 0 0 auto;
  width: calc(55% - 20px);
`;

export const StatusColorNames = {
  NEW: "enRoute",
  PAID: "onboard",
  OVERDUE: "scheduled",
};

export const Status = styled(Stack)`
  justify-content: center;
  align-items: center;

  padding: 4px 10px;
  border-radius: 8px;

  color: ${({
    // @ts-ignore
    row,
    theme,
  }) =>
    // @ts-ignore
    customColors[theme.palette.mode][
      StatusColorNames[row?.status] ?? "greyText"
    ]};
  background-color: ${({
    // @ts-ignore
    row,
    theme,
  }) =>
    `${
      // @ts-ignore
      customColors[theme.palette.mode][
        StatusColorNames[row?.status] ?? "greyText"
      ]
    }29`};
`;
/** @type {import("types/commonTypes").Company} */
const defaultState = undefined;

export const CompanyInvoicesPage = () => {
  const { id } = useParams();
  const { callApiRequest } = useApiRequest();
  const [isEditDialogVisible, setIsEditDialogVisible] = useState(false);
  const userId = useAppSelector((state) => state.user.id);
  const { specialityOptions, loadSpecialities } = useSpecialityOptions();
  const [client, setClient] = useState(defaultState);
  const [selectedId, setSelectedId] = useState("");
  const [reloadSignal, setReloadSignal] = useState(0);
  const reloadIndicators = () => setReloadSignal((prev) => prev + 1);

  const columns = [
    {
      label: "Invoice #",
      field: "id",
      xs: 2,
      sm: 2,
      md: 2,
      align: "center",
      sortableName: "ID",
    },
    {
      xs: 0.5,
      sm: 0.5,
      md: 0.5,
    },
    {
      label: "Sent Date",
      field: "sentDate",
      xs: 2,
      sm: 2,
      md: 2,
      align: "center",
      sortableName: "SENT_DATE",
      renderer: (row) => unixTimeToUSShortDate(row?.sentDateMs),
    },
    {
      xs: 0.5,
      sm: 0.5,
      md: 0.5,
    },
    {
      label: "Due Amount",
      field: "amount",
      xs: 2,
      sm: 2,
      md: 2,
      align: "right",
      sortableName: "AMOUNT",
      renderer: ({ amount }) => USDollar.format(amount),
    },
    {
      xs: 0.5,
      sm: 0.5,
      md: 0.5,
    },
    {
      label: "Due Date",
      field: "dueDate",
      xs: 2,
      sm: 2,
      md: 2,
      sortableName: "DUE_DATE",
      align: "center",
      renderer: (row) => unixTimeToUSShortDate(row?.dueDateMs),
    },
    {
      xs: 1,
      sm: 1,
      md: 1,
    },
    {
      label: "Status",
      field: "status",
      xs: 1.5,
      sm: 1.5,
      md: 1.5,
      sortableName: "STATUS",
      renderer: (row) => (
        <Status
          // @ts-ignore
          row={row}
        >
          {convertToReadable(row?.status ?? "")}
        </Status>
      ),
    },
  ];

  const shortColumns = [
    {
      label: "Invoice #",
      field: "id",
      xs: 3,
      sm: 3,
      md: 3,
      align: "center",
      sortableName: "ID",
    },
    {
      label: "Sent Date",
      field: "sentDate",
      xs: 4,
      sm: 4,
      md: 4,
      align: "center",
      sortableName: "SENT_DATE",
      renderer: (row) => unixTimeToUSShortDate(row?.sentDateMs),
    },
    {
      label: "Status",
      field: "status",
      xs: 3,
      sm: 3,
      md: 3,
      sortableName: "STATUS",
      renderer: (row) => (
        <Status
          // @ts-ignore
          row={row}
        >
          {convertToReadable(row?.status ?? "")}
        </Status>
      ),
    },
    {
      xs: 2,
      sm: 2,
      md: 2,
      renderer: (row) => <Selector selectedId={selectedId} row={row} status={row.status} />,
    },
  ];

  const dataGrid = useDataGrid({
    apiHandler: getInvoicesInfoByCompanyIdHandler(id),
    defaultSorter: {
      name: "STATUS",
      order: "ASC",
    },
    defaultLimit: 25,
    gridConfigIndex: `${userId}-bookings`,
  });

  const handleRowClick = (row) => {
    if (selectedId === row?.id) {
      setSelectedId("");
      return;
    }

    setSelectedId(row?.id ?? "");
  };

  useNavigationData({
    setSearch: dataGrid.setSearch,
    setSelectedId: setSelectedId,
  });

  const loadClientData = (id) =>
    callApiRequest({
      apiRequest: getClientById,
      params: id,
      onSuccess: (result) => {
        if (result) {
          setClient(result);
        }
      },
    });

  useEffect(() => {
    if (id) {
      loadClientData(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <SC.Container>
      <Stack direction={"row"} height={"100%"} position={"relative"}>
        <Content>
          <Stack gap={"20px"} width={selectedId ? "calc(45% - 20px)" : "100%"}>
            <DataGrid
              columns={selectedId ? shortColumns : columns}
              handleRowClick={handleRowClick}
              {...dataGrid}
              actions={
                <CompanyInvoicesIndicators
                  companyId={id}
                  setSearch={dataGrid.setSearch}
                  reloadSignal={reloadSignal}
                />
              }
              actionsAfter={
                !selectedId && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setIsEditDialogVisible(true)}
                  >
                    Edit Client
                  </Button>
                )
              }
              keyName="id"
              selectedId={selectedId}
            />
          </Stack>
          {selectedId && (
            <HalfScreenPanel>
              <CloseWindowButton
                top={-35}
                right={-35}
                closeHandler={() => setSelectedId("")}
              />
              <InvoiceDetails
                id={selectedId}
                refreshList={() => {
                  dataGrid.loadData();
                  loadClientData(id);
                  reloadIndicators();
                }}
                client={client}
              />
            </HalfScreenPanel>
          )}
        </Content>
      </Stack>
      <EditClientDialog
        open={isEditDialogVisible}
        handleClose={() => setIsEditDialogVisible(false)}
        refreshList={() => {
          dataGrid.loadData();
          loadClientData(id);
        }}
        specialityOptions={specialityOptions}
        loadSpecialities={loadSpecialities}
        client={client}
        id={id}
      />
    </SC.Container>
  );
};
