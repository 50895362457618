import React from "react";
import {
  OutgoingHolder,
  OutgoingMessage as MessageBody,
  Time,
} from "./Messenger.style";
import { Stack } from "@mui/material";
import { unixTimeToUSDate } from "utils";

export const OutgoingMessage = ({ message, create_at }) => {
  return (
    <OutgoingHolder>
      <Stack direction={"row"} justifyContent={"flex-end"} gap={1}>
        <Time>{unixTimeToUSDate(create_at)}</Time>
        You
      </Stack>
      <MessageBody>
        {message?.split("\n").map((line, index) => (
          <React.Fragment key={`${line}-${index}`}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </MessageBody>
    </OutgoingHolder>
  );
};
