import React, { useContext, useEffect } from "react";
import { GlobalContext } from "store/globalContext";
import { Stack, styled, IconButton, Box } from "@mui/material";
import Close from "@mui/icons-material/Close";
import { customColors } from "models/customColors";

const WARNING_AUTOHIDE_DELAY_IN_MS = 3000;

const Container = styled(Stack)`
  position: fixed;
  top: 1rem;
  right: 1rem;
  margin-left: 1rem;
  min-width: 300px;
  max-width: 479px;

  display: flex;
  padding: 16px 38px 16px 16px;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 16px;
  color: ${({ theme }) => customColors[theme.palette.mode].background};
  background-color: ${({ theme }) => customColors[theme.palette.mode].color};
  user-select: none;
  z-index: 10000;
`;

const IconContainer = styled(Box)`
  & path {
    fill: ${({ theme }) => customColors[theme.palette.mode].background};
  }
`;

const Title = styled(Box)`
  font-family: "Inter Medium";
  font-size: 18px;
  line-height: 120%; /* 18px */
`;

const Content = styled(Box)`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
`;
const CloseButton = styled(IconButton)`
  margin: 0.3rem;
  &:hover {
    background-color: ${({ theme }) =>
      customColors[theme.palette.mode].greyText};
  }
`;
const CloseIcon = styled(Close)`
  fill: ${({ theme }) => customColors[theme.palette.mode].background};
`;

export const WarningDialog = () => {
  const { warning, hideWarning } = useContext(GlobalContext);
  const { title, content, icon } = warning;

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (warning?.title && warning.title !== "") {
        hideWarning();
      }
    }, WARNING_AUTOHIDE_DELAY_IN_MS);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warning]);

  if (!title || !content) return null;

  return (
    <Container>
      <Stack
        alignItems={"flex-end"}
        sx={{ margin: 0, position: "absolute", top: 0, right: 0 }}
      >
        <CloseButton
          size={"small"}
          aria-label="close"
          onClick={() => hideWarning()}
        >
          <CloseIcon fontSize="small" />
        </CloseButton>
      </Stack>
      {icon && <IconContainer>{icon}</IconContainer>}
      <Stack gap={"11px"}>
        <Title>{title}</Title>
        <Content>{content}</Content>
      </Stack>
    </Container>
  );
};
