import { createAction, createReducer } from "@reduxjs/toolkit";
import { viewModes } from "components/BookingsViewModeSelector";

/** @type {import("types/stateTypes").Layout} */
const initialState = {
  isMenuExpanded: true,
  themeMode: "light",
  bookingViewMode: viewModes.TABLE,
};

export const expandMenu = createAction("layout/expandMenu");
export const collapseMenu = createAction("layout/collapseMenu");

export const toggleThemeMode = createAction("layout/toggleThemeMode");
export const setBookingViewMode = createAction(
  "layout/setBookingViewMode",
  (mode) => ({
    payload: mode,
  })
);

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(expandMenu, (state) => {
    state.isMenuExpanded = true;
  });

  builder.addCase(collapseMenu, (state) => {
    state.isMenuExpanded = false;
  });

  builder.addCase(setBookingViewMode, (state, action) => {
    state.bookingViewMode = action.payload;
  });

  builder.addCase(toggleThemeMode, (state) => {
    if (state.themeMode === "dark") {
      state.themeMode = "light";
    } else {
      state.themeMode = "dark";
    }
  });
});

export default reducer;
