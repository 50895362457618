import React from "react";
import { InputLabel, Stack } from "@mui/material";
import { FormSelectField } from "UI/FormSelectField";
import { required } from "models/validations";
import { CsvDataPreview } from "components/CsvDataPreview";
import { FormCheckbox } from "UI/FormCheckbox";

export const ImportStepTwo = ({ control, watch, separatorOptions }) => (
  <>
    <Stack direction={"row"} gap={4} alignItems={"flex-end"}>
      <Stack alignItems={"flex-start"} width={"100%"} maxWidth={"320px"}>
        <InputLabel htmlFor="separator">
          Field separator for CSV data
        </InputLabel>
        <FormSelectField
          id="separator"
          name="separator"
          control={control}
          options={separatorOptions}
          withNone={false}
          rules={{ required }}
        />
      </Stack>
      <Stack alignItems={"flex-start"}>
        <FormCheckbox
          name="isNamesInFirstRow"
          control={control}
          label={"First row contains column names"}
        />
      </Stack>
      <Stack alignItems={"flex-start"}>
        <FormCheckbox
          name="stripQuotes"
          control={control}
          label={"Strip quotes from values"}
        />
      </Stack>
    </Stack>
    <CsvDataPreview {...watch()} />
  </>
);
