import { capitalize } from "@mui/material";
import { ReactComponent as BackIcon } from "assets/img/backIcon.svg";
import { ReactComponent as ChatIcon } from "assets/img/chat-icon.svg";
import { ReactComponent as PaymentsIcon } from "assets/img/payments-icon.svg";
import { ReactComponent as CsvIcon } from "assets/img/bookings-csv-icon.svg";

import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "store/hooks";

import * as SC from "SC";
import * as ASC from "./AuthCommonLayout.style";

import { getClientById } from "api/clientApi";
import { useApiRequest } from "api/useApiRequest";
import { useIsDataChanged } from "hooks/useIsDataChanged";
import { GlobalContext } from "store/globalContext";
import * as FP from "utils/fp-js";
import { Container, PageHeader, RootPathSegment } from "./Header.style";
import { Indicators } from "./Indicators";
import { MessagesIndicator } from "./MessagesIndicator";
import { UserInfoSection } from "./UserInfoSection";

export const Header = ({ menu }) => {
  const { callApiRequest } = useApiRequest();
  const [client, setClient] = useState(null);
  const { openConfirmationWithCallback } = useContext(GlobalContext);
  const { isDataChanged, setIsDataChanged } = useIsDataChanged();
  const navigate = useNavigate();
  const params = useParams();

  const { pathname } = useLocation();
  const breadCrumbs = pathname?.split("/");

  const firstSegment = `/${breadCrumbs[1]}`;
  const restSegments = breadCrumbs.slice(2).map((name) =>
    name
      ?.split("_")
      .map((name) => capitalize(name.toLocaleLowerCase()))
      .join(" ")
  );

  const user = useAppSelector((state) => state?.user);
  const currentMenu = menu?.find(({ path }) => path === firstSegment);

  const handleMenuClick = (e, path) => {
    if (isDataChanged) {
      e.stopPropagation();
      e.preventDefault();

      openConfirmationWithCallback({
        title: "Are you sure?",
        message: "All entered data will be lost.",
        callbackFn: () => {
          setIsDataChanged(false);
          navigate(path);
        },
      });
    }
  };

  const loadCompanyData = (id) => {
    if (id) {
      callApiRequest({
        apiRequest: getClientById,
        params: id,
        onSuccess: (result) => {
          if (result) {
            setClient(result);
          }
        },
      });
    }
  };

  useEffect(() => {
    if (
      !pathname.startsWith("/invoice/") &&
      !pathname.startsWith("/ride_chat/") &&
      !pathname.startsWith("/driver_chat/") &&
      !pathname.startsWith("/client_chat/") &&
      !pathname.startsWith("/request_files/")
    ) {
      loadCompanyData(params.id);
    }

    return () => {
      setClient(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, pathname]);

  return (
    <Container>
      {FP.match(true)
        .on(
          pathname.startsWith("/company_invoices/"),
          <PageHeader>
            <PaymentsIcon />
            <SC.Link
              to={"/payments"}
              onClick={(e) => handleMenuClick(e, "/payments")}
            >
              <RootPathSegment>Payments</RootPathSegment>
            </SC.Link>
            / {client?.companyName}
          </PageHeader>
        )
        .on(
          pathname.startsWith("/ride_chat/"),
          <PageHeader>
            <ASC.BackButton onClick={() => navigate(-1)}>
              <SC.BackButtonContent sx={SC.BackButtonContentSx}>
                <BackIcon />
              </SC.BackButtonContent>
            </ASC.BackButton>
            <ChatIcon />
            Chat
          </PageHeader>
        )
        .on(
          pathname.startsWith("/driver_chat/"),
          <PageHeader>
            <ASC.BackButton onClick={() => navigate(-1)}>
              <SC.BackButtonContent sx={SC.BackButtonContentSx}>
                <BackIcon />
              </SC.BackButtonContent>
            </ASC.BackButton>
            <ChatIcon />
            Driver Chat
          </PageHeader>
        )
        .on(
          pathname.startsWith("/client_chat/"),
          <PageHeader>
            <ASC.BackButton onClick={() => navigate(-1)}>
              <SC.BackButtonContent sx={SC.BackButtonContentSx}>
                <BackIcon />
              </SC.BackButtonContent>
            </ASC.BackButton>
            <ChatIcon />
            Client User Chat
          </PageHeader>
        )
        .on(
          pathname.startsWith("/invoice/"),
          <PageHeader>
            <PaymentsIcon />
            <SC.Link
              to={"/invoices"}
              onClick={(e) => handleMenuClick(e, "/invoices")}
            >
              <RootPathSegment>Invoices</RootPathSegment>
            </SC.Link>
            / {restSegments[0]}
          </PageHeader>
        )
        .on(
          pathname.startsWith("/request_files/") && !!restSegments[0],
          <PageHeader>
            <CsvIcon />
            <SC.Link
              to={"/request_files"}
              onClick={(e) => handleMenuClick(e, "/invoices")}
            >
              <RootPathSegment>Request Files</RootPathSegment>
            </SC.Link>
            / #{restSegments[0]}
          </PageHeader>
        )
        .otherwise(
          <PageHeader>
            {currentMenu?.icon}
            {restSegments.length > 0 ? (
              <>
                <SC.Link
                  to={currentMenu?.path}
                  onClick={(e) => handleMenuClick(e, currentMenu?.path)}
                >
                  <RootPathSegment>{currentMenu?.label}</RootPathSegment>
                </SC.Link>
                / {restSegments[0]}
              </>
            ) : (
              currentMenu?.label
            )}
          </PageHeader>
        )}
      {["SUPER_ADMIN", "KERICO_MANAGER", "KERICO_DISPATCHER"].includes(
        user?.role
      ) && <Indicators />}
      {!!user?.mattermostUserId && user.mattermostUserId !== "unknown" && (
        <MessagesIndicator />
      )}
      <UserInfoSection />
    </Container>
  );
};
