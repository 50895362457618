import { Button } from "@mui/material";
import {
    ButtonGroup
} from "pages/ImportCsvFilePage.style";

export const TemplateDriver = ({
    handleSaveTemplate,
    handleCreateTemplate,
    handleResetTemplate,
    handleLoadTemplate,
    isAllFieldsMapped,
}) => {
  return (
    <ButtonGroup>
      <Button
        variant="outlined"
        size="small"
        onClick={handleLoadTemplate}
        sx={{ minHeight: "20px" }}
      >
        Load Template
      </Button>
      <Button
        variant="outlined"
        size="small"
        onClick={handleSaveTemplate}
        sx={{ minHeight: "20px" }}
        disabled={!isAllFieldsMapped}
      >
        Save
      </Button>
      <Button
        variant="outlined"
        size="small"
        onClick={handleCreateTemplate}
        sx={{ minHeight: "20px" }}
        disabled={!isAllFieldsMapped}
      >
        Save As
      </Button>
      <Button
        variant="outlined"
        size="small"
        onClick={handleResetTemplate}
        sx={{ minHeight: "20px" }}
      >
        Reset
      </Button>
    </ButtonGroup>
  );
};
