import { getClientById } from "api/clientApi";
import { useApiRequest } from "api/useApiRequest";
import { useCallback, useEffect, useState } from "react";

/** @type {import("types/commonTypes").Company} */
const defaultState = undefined;

export const useClient = ({ id = null }) => {
  const { callApiRequest } = useApiRequest();
  const [client, setClient] = useState(defaultState);

  const loadClientData = useCallback(
    () => {
      if (!id) return;
      
      callApiRequest({
        apiRequest: getClientById,
        params: id,
        onSuccess: (result) => {
          if (result) {
            setClient(result);
          }
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  useEffect(() => {
    loadClientData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadClientData]);

  return {
    client,
    setClient,
    loadClientData,
  };
};
