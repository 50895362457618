import { localStorageNameEnum } from "models/constants";
import { useAppDispatch } from "store/hooks";
import { showError } from "store/reducers/errorReducer";
import { showSpinner, hideSpinner } from "store/reducers/spinnerReducer";
import {
  logoutAction,
  restoreAuthByRefreshToken,
} from "store/reducers/userReducer";
import { statusMessages } from "./httpService";

/**
 *
 * @template T
 * @typedef {import('types/commonTypes').HttpResult<T>} HttpResult
 */

export const useApiRequest = () => {
  const dispatch = useAppDispatch();
  /**
   * @template T
   * @param {{
   *   apiRequest: (params:any) => HttpResult<T>;
   *   params?: any;
   *   skipErrorHandling?: boolean;
   *   skipSpinner?: boolean;
   *   onError?: (error: string) => void;
   *   onSuccess?: (value: T) => void;
   *   disabled?: boolean;
   * }} props
   * @returns { HttpResult<T> }
   */
  const callApiRequest = async ({
    apiRequest,
    params,
    skipErrorHandling = false,
    skipSpinner = skipErrorHandling,
    onError,
    onSuccess,
  }) => {
    if (!skipSpinner) {
      dispatch(showSpinner());
    }

    const result = await apiRequest(params);

    const error = result?.error;

    if (error === statusMessages[401] || error === statusMessages[403]) {
      const refreshToken = window.localStorage.getItem(
        localStorageNameEnum.AUTH_REFRESH_TOKEN
      );
      if (refreshToken) {
        dispatch(restoreAuthByRefreshToken(refreshToken));
      } else {
        dispatch(logoutAction());
      }
    }

    if (!skipSpinner) {
      dispatch(hideSpinner());
    }

    if (!skipErrorHandling) {
      if (error) {
        dispatch(showError(error));
      }
    }

    if (typeof onError === "function" && error) {
      onError(error);
      return { error };
    }

    if (error) {
      return { error };
    }

    if (onSuccess && !error) {
      onSuccess(result.value);
    }

    return result;
  };

  return {
    callApiRequest,
  };
};
