import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  capitalize,
  styled,
} from "@mui/material";
import { ContentLoading, InputLabel } from "SC";
import { FormMaskedTextField, FormTextField } from "UI";
import { FormDateTimeSelector } from "UI/FormDateTimeSelector";
import { FormMoneyField } from "UI/FormMoneyField";
import { FormSelectField } from "UI/FormSelectField";
import { Canceled, Label, ResultTitle, Value } from "components/RideData";
import {
  nameValidation,
  notNullPriceField,
  phoneNumberRequiredValidation,
  required,
} from "models/validations";
import { Status } from "pages/RequestItemsPage.style";
import { convertToReadable, removeCountry } from "utils";
import { useRequestItemDetails } from "./RequestItemDetails.hook";
import { RideRequestRejectDialog } from "./RideRequestRejectDialog";
import { EditLocationDialog } from "./EditLocationDialog";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useAppSelector } from "store/hooks";
import RefreshIcon from "@mui/icons-material/Refresh";
import { RefreshButton } from "UI/DataGrid";
import { requestItemStatuses } from "models/bookingRequest";

const Container = styled(Stack)`
  padding: 0 20px 0 0;
  max-width: calc(100% - 20px);
  height: 100%;
  overflow-y: auto;
`;

export const Prop = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
`;

export const RequestItemDetails = ({
  request,
  requestItem,
  setRequestItem,
  refreshList = () => null,
  getClientLabelById,
  refreshRequestItem,
  setSelectedRideId,
  setWindowType,
}) => {
  const spinner = useAppSelector((state) => state.spinner);
  const [addressType, setAddressType] = useState(null);

  const {
    handleTransfer,
    handleViewRide,
    handleValidate,
    control,
    reset,
    adminNotes,
    departure,
    destination,
    externalId,
    willCall,
    status,
    validated,
    setValidated,
    isDirty,
    setValue,
    watch,
    handleSubmit,
    onSubmit,
    isRejectDialogOpen,
    setIsRejectDialogOpen,
    isUpdating,
    handleReject,
    handleRevokeRejection,
    departureError,
    destinationError,
    setLocation,
    departureLabel,
    destinationLabel,
    rejectReason,
    isUnaccepted,
    addressTypes,
  } = useRequestItemDetails({
    requestItem,
    refreshList,
    setRequestItem,
    refreshRequestItem,
    setSelectedRideId,
    setWindowType,
  });

  const isReadOnly = [
    requestItemStatuses.EXPORT,
    requestItemStatuses.UNACCEPTED,
    requestItemStatuses.TRANSFERRED,
    requestItemStatuses.ERROR,
  ].includes(status);

  const isRefreshVisible = [
    requestItemStatuses.EXPORT,
    requestItemStatuses.UNACCEPTED,
    requestItemStatuses.ERROR,
  ].includes(status);

  return (
    <Container gap={2}>
      {!requestItem?.id ? (
        <ContentLoading />
      ) : (
        <>
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <Prop>
              <Label>ID:</Label>
              <Value sx={{ minWidth: "130px" }}>{externalId}</Value>
            </Prop>
            <Prop alignItems={"center"}>
              <Label>Status:</Label>
              <Status
                // @ts-ignore
                status={status}
                sx={{
                  padding: "0.35rem 1rem",
                }}
              >
                {convertToReadable(status ?? "")}
              </Status>
            </Prop>
            {willCall && status === requestItemStatuses.VALIDATE && (
              <Canceled>Will Call</Canceled>
            )}
          </Stack>
          <Prop>
            <Label>Client:</Label>
            <Value>{getClientLabelById(request?.companyId)}</Value>
          </Prop>
          <Divider />
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={2}
          >
            <Stack alignItems={"flex-start"} maxWidth={"300px"}>
              <InputLabel htmlFor="plannedStartTimeMs">Pick Up Time</InputLabel>
              <FormDateTimeSelector
                id="plannedStartTimeMs"
                name="plannedStartTimeMs"
                control={control}
                rules={{ required }}
                size="small"
                disablePast
                closeOnSelect={false}
                disabled={isUnaccepted || isReadOnly}
              />
            </Stack>
            {status === requestItemStatuses.ACCEPTED && (
              <Button variant="contained" onClick={handleTransfer}>
                Transfer Ride
              </Button>
            )}
            {!!requestItem?.rideId && (
              <Stack direction={"row"} gap={2} alignItems={"center"}>
                #{requestItem?.rideId?.slice(0, 8)}
                <Button variant="outlined" onClick={handleViewRide}>
                  View Ride
                </Button>
              </Stack>
            )}
            {isRefreshVisible && (
              <RefreshButton onClick={() => refreshRequestItem(requestItem.id)}>
                <RefreshIcon />
              </RefreshButton>
            )}
          </Stack>
          <Stack gap={1}>
            {!!adminNotes && status !== requestItemStatuses.TRANSFERRED && (
              <Alert severity={"error"} sx={{ borderRadius: "12px" }}>
                {capitalize(adminNotes ?? "")}
              </Alert>
            )}
            {status === requestItemStatuses.ERROR && (
              <Stack alignItems={"flex-start"} direction={"row"} gap={1}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => setIsRejectDialogOpen(true)}
                >
                  Reject
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleRevokeRejection}
                >
                  Validate Again
                </Button>
              </Stack>
            )}
          </Stack>
          <Stack direction={"row"}>
            <Stack minWidth={"25%"} gap={0.5}>
              <Stack direction={"row"} alignItems={"center"}>
                <ResultTitle className={!departureLabel ? "error" : ""}>
                  Pick Up:
                </ResultTitle>
                {!isReadOnly && (
                  <IconButton
                    onClick={() => setAddressType(addressTypes.PICK_UP)}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </Stack>
              <Box>{removeCountry(departureLabel)}</Box>
              {departureError && (
                <Alert
                  severity="error"
                  sx={{
                    borderBottomLeftRadius: "12px",
                    borderBottomRightRadius: "12px",
                    borderTopRightRadius: "12px",
                  }}
                >
                  {departureError}
                </Alert>
              )}
            </Stack>
            <ArrowForwardIcon fontSize="large" sx={{ marginRight: 2 }} />
            <Stack gap={0.5}>
              <Stack direction={"row"} alignItems={"center"}>
                <ResultTitle className={!destinationLabel ? "error" : ""}>
                  Drop Off:
                </ResultTitle>
                {!isReadOnly && (
                  <IconButton
                    onClick={() => setAddressType(addressTypes.DROP_OFF)}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </Stack>
              <Box>{removeCountry(destinationLabel)}</Box>
              {destinationError && (
                <Alert
                  severity="error"
                  sx={{
                    borderBottomLeftRadius: "12px",
                    borderBottomRightRadius: "12px",
                    borderTopRightRadius: "12px",
                  }}
                >
                  {destinationError}
                </Alert>
              )}
            </Stack>
          </Stack>
          <Stack direction={"row"} gap={3} justifyContent={"space-between"}>
            <Stack alignItems={"flex-start"} flex={1}>
              <InputLabel htmlFor="passengerFirstName">
                Passenger First Name
              </InputLabel>
              <FormTextField
                id="passengerFirstName"
                name="passengerFirstName"
                control={control}
                rules={nameValidation}
                InputProps={{
                  autoComplete: "new-password",
                }}
                disabled={isReadOnly}
              />
            </Stack>
            <Stack alignItems={"flex-start"} flex={1}>
              <InputLabel htmlFor="passengerLastName">
                Passenger Last Name
              </InputLabel>
              <FormTextField
                id="passengerLastName"
                name="passengerLastName"
                control={control}
                rules={nameValidation}
                InputProps={{
                  autoComplete: "new-password",
                }}
                disabled={isReadOnly}
              />
            </Stack>
            <Stack alignItems={"flex-start"} flex={1}>
              <InputLabel htmlFor="phoneNumber">Phone Number</InputLabel>
              <FormMaskedTextField
                id="phoneNumber"
                name="phoneNumber"
                control={control}
                rules={phoneNumberRequiredValidation}
                mask="999-999-9999"
                InputProps={{
                  autoComplete: "new-password",
                }}
                disabled={isReadOnly}
              />
            </Stack>
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"} gap={2}>
            <Stack alignItems={"flex-start"} width={"50%"}>
              <InputLabel htmlFor="passengers">Passengers Number</InputLabel>
              <FormTextField
                id="passengers"
                name="passengers"
                control={control}
                inputProps={{
                  autoComplete: "new-password",
                  min: 1,
                  max: 10,
                }}
                rules={{ required }}
                type="number"
                disabled={isReadOnly}
              />
            </Stack>
            <Stack alignItems={"flex-start"} width={"50%"}>
              <InputLabel htmlFor="vehicleType">Vehicle Type</InputLabel>
              <FormSelectField
                id="vehicleType"
                name="vehicleType"
                control={control}
                options={{
                  SEDAN_OR_SALOON: "Sedan or saloon",
                  VAN: "Van",
                  WHEELCHAIR: "Wheelchair",
                }}
                withNone={false}
                rules={{ required }}
                disabled={isReadOnly}
              />
            </Stack>
            <Stack alignItems={"flex-start"}>
              <InputLabel htmlFor="clientCost">Client's Cost</InputLabel>
              <FormMoneyField
                id="clientCost"
                name="clientCost"
                control={control}
                rules={notNullPriceField}
                InputProps={{
                  autoComplete: "new-password",
                  inputProps: {
                    min: 0,
                    step: 0.01,
                  },
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                disabled={isReadOnly}
              />
            </Stack>
          </Stack>
          <Stack alignItems={"flex-start"}>
            <InputLabel htmlFor="driverNotes">Driver Notes</InputLabel>
            <FormTextField
              id="driverNotes"
              name="driverNotes"
              control={control}
              multiline
              rows={2}
              disabled={isReadOnly}
            />
          </Stack>
          {!isUpdating && isDirty && !isRejectDialogOpen && (
            <Stack direction={"row"} gap={1}>
              <Button variant="outlined" onClick={() => reset()}>
                Discard Changes
              </Button>
              <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                Save Changes
              </Button>
            </Stack>
          )}
          {!spinner &&
            !isDirty &&
            !isRejectDialogOpen &&
            status === requestItemStatuses.VALIDATE && (
              <Stack direction={"row"} gap={1}>
                <FormControlLabel
                  label={"Validated"}
                  control={
                    <Checkbox
                      checked={validated}
                      onChange={() => setValidated(!validated)}
                    />
                  }
                />
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => setIsRejectDialogOpen(true)}
                >
                  Reject
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleValidate}
                  disabled={!validated}
                >
                  Accept
                </Button>
              </Stack>
            )}
        </>
      )}
      {isUnaccepted && (
        <Stack alignItems={"flex-start"}>
          <InputLabel htmlFor="rejectReason">Reason of rejection: </InputLabel>
          <Stack
            direction={"row"}
            flexWrap={"wrap"}
            alignItems={"center"}
            gap={1}
          >
            <Alert severity="error" sx={{ borderRadius: "12px" }}>
              {rejectReason
                ? capitalize(rejectReason)
                : "No given reason for rejection."}
            </Alert>
            <Button
              variant="contained"
              color="success"
              onClick={handleRevokeRejection}
            >
              Revoke Rejection
            </Button>
          </Stack>
        </Stack>
      )}
      <RideRequestRejectDialog
        open={isRejectDialogOpen}
        handleClose={() => {
          reset();
          setIsRejectDialogOpen(false);
        }}
        control={control}
        onSubmit={handleReject}
        isDirty={isDirty}
        setValue={setValue}
        watch={watch}
        reset={reset}
      />
      {addressType && (
        <EditLocationDialog
          location={
            addressType === addressTypes.PICK_UP ? departure : destination
          }
          setLocation={setLocation}
          handleClose={() => setAddressType(null)}
          addressType={addressType}
        />
      )}
    </Container>
  );
};
