import jsPDF from "jspdf";
import {
  USDollar,
  unixTimeToUSDate,
  unixTimeToUSShortDate,
  convertToReadable,
} from "utils";
import logoImage from "assets/img/full_logo.png";
import unpaidStamp from "assets/img/unpaid_stamp.png";

const FONT_SIZE = 11;
const RIDE_FONT_SIZE = 10;
const FOOTER_FONT_SIZE = 9;

const LINE_HEIGHT = 10;
const RIDE_LINE_DELTA = 1.2;
const TOTAL_LINE_DELTA = 5;
const AMOUNT_COLUMN_WIDTH = 70;

const HEADER_START_POSIION = 30;
const MARGIN_LEFT = 40;
const LEFT_COLUMN_WIDTH = 270;

const MAX_RIGHT_POSITION = 430;

/**
 * Removes all characters in the string except numbers, Latin characters, ".", ",", and "-".
 *
 * @param {string} str - The string to be processed.
 * @returns {string} The processed string.
 */
function removeNonLatinCharacters(str) {
  return str?.replace(/[^0-9a-zA-Z.,-\s]/g, "") ?? "";
}

export const downloadPdf = (
  { id, company, status, rides, sentDateMs, dueDateMs },
  client
) => {
  if (!id) return;
  let offset = HEADER_START_POSIION;

  const totalAmount = rides.reduce(
    (acc, { actualCost }) => acc + actualCost,
    0
  );

  try {
    const pdf = new jsPDF("p", "px", "letter");
    pdf.setFontSize(FONT_SIZE);
    pdf.setFont("helvetica", "normal", "bold");
    pdf.text(
      ["Kerico Houston/ Rio Grande/ Laredo/ El Paso Fleet"],
      MARGIN_LEFT,
      offset
    );

    offset += LINE_HEIGHT;
    pdf.setFont("helvetica", "normal", "normal");
    pdf.text(
      ["5205 Broadway, Ste. 585, Pearland, 77581, US"],
      MARGIN_LEFT,
      offset
    );

    offset += LINE_HEIGHT * 2;
    pdf.setFont("helvetica", "normal", "bold");

    let text = company?.companyName ?? " ";
    pdf.text(text, MARGIN_LEFT, offset, {
      maxWidth: LEFT_COLUMN_WIDTH - MARGIN_LEFT,
    });
    offset +=
      pdf.getTextDimensions(text, {
        maxWidth: LEFT_COLUMN_WIDTH - MARGIN_LEFT,
      }).h + RIDE_LINE_DELTA;

    pdf.setFont("helvetica", "normal", "normal");
    text = client?.headOffice?.placeLabel ?? " ";
    pdf.text(text, MARGIN_LEFT, offset, {
      maxWidth: LEFT_COLUMN_WIDTH - MARGIN_LEFT,
    });
    offset +=
      pdf.getTextDimensions(text, {
        maxWidth: LEFT_COLUMN_WIDTH - MARGIN_LEFT,
      }).h + RIDE_LINE_DELTA;

    const image = new Image();
    image.src = logoImage;
    pdf.addImage(image, "PNG", 350, 15, 78, 47);

    if (status !== "PAID") {
      const image = new Image();
      image.src = unpaidStamp;
      pdf.addImage(image, "PNG", 368, 64, 42, 13);
    }

    offset += RIDE_LINE_DELTA;
    pdf.line(MARGIN_LEFT, offset, MAX_RIGHT_POSITION, offset);

    offset += LINE_HEIGHT / 3;
    pdf.setFillColor(255, 150, 28);
    pdf.rect(MARGIN_LEFT, offset, LEFT_COLUMN_WIDTH, LINE_HEIGHT * 2.2, "F");

    pdf.setTextColor(255, 255, 255);
    pdf.setFont("helvetica", "normal", "bold");
    pdf.text("Total Amount Due", MARGIN_LEFT + 6, offset + 13);

    pdf.text(
      USDollar.format(totalAmount),
      MARGIN_LEFT + LEFT_COLUMN_WIDTH - 6,
      offset + 13,
      {
        align: "right",
      }
    );

    pdf.line(
      MARGIN_LEFT * 1.1 + LEFT_COLUMN_WIDTH,
      offset,
      MARGIN_LEFT * 1.1 + LEFT_COLUMN_WIDTH,
      offset + LINE_HEIGHT * 7.5
    );

    pdf.setFillColor(255, 150, 28);
    pdf.rect(
      MARGIN_LEFT * 1.2 + LEFT_COLUMN_WIDTH,
      offset,
      MAX_RIGHT_POSITION - MARGIN_LEFT * 1.2 - LEFT_COLUMN_WIDTH,
      LINE_HEIGHT * 2.2,
      "F"
    );

    pdf.text(
      "Invoice Details",
      MARGIN_LEFT * 1.2 + LEFT_COLUMN_WIDTH + 6,
      offset + 13
    );

    offset += LINE_HEIGHT * 3.5;
    pdf.setTextColor(0, 0, 0);
    pdf.text("Balance Outstanding", MARGIN_LEFT + 6, offset);
    pdf.setFont("helvetica", "normal", "normal");
    pdf.text(
      USDollar.format(status !== "PAID" ? totalAmount : 0),
      MARGIN_LEFT + LEFT_COLUMN_WIDTH - 6,
      offset,
      {
        align: "right",
      }
    );

    pdf.setFont("helvetica", "normal", "normal");
    pdf.text(
      [`Invoice ID: #${id}`, `Sent Date: ${unixTimeToUSShortDate(sentDateMs)}`],
      MARGIN_LEFT * 1.2 + LEFT_COLUMN_WIDTH + 6,
      offset
    );

    offset += LINE_HEIGHT;
    pdf.setFont("helvetica", "normal", "bold");
    pdf.text(
      `Due Date: ${unixTimeToUSShortDate(dueDateMs)}`,
      MARGIN_LEFT * 1.2 + LEFT_COLUMN_WIDTH + 6,
      offset + LINE_HEIGHT * 1.5
    );

    pdf.line(MARGIN_LEFT, offset, MARGIN_LEFT + LEFT_COLUMN_WIDTH, offset);

    offset += LINE_HEIGHT * 1.8;
    pdf.setFontSize(FONT_SIZE + 3);
    pdf.setTextColor(255, 150, 28);
    pdf.setFont("helvetica", "normal", "bold");
    pdf.text("Current Charges:", MARGIN_LEFT * 1.1, offset);

    pdf.setFontSize(FONT_SIZE);
    pdf.setTextColor(0, 0, 0);

    offset += LINE_HEIGHT;

    rides?.forEach(
      ({
        actualCost,
        id,
        actualStartTime,
        passengerName,
        departure,
        destination,
        rideType,
        vehicleType,
        waitingTime,
      }) => {
        let startOffset = offset;
        offset += LINE_HEIGHT;

        if (offset > pdf.internal.pageSize.height - HEADER_START_POSIION) {
          pdf.addPage();
          offset = HEADER_START_POSIION;
          startOffset = offset - LINE_HEIGHT;
        }

        pdf.setFont("helvetica", "normal", "bold");
        pdf.text(
          `Ride ID: ${id?.slice(0, 8)} - Date: ${unixTimeToUSDate(
            actualStartTime
          )}`,
          MARGIN_LEFT * 1.1,
          offset,
          {
            maxWidth: LEFT_COLUMN_WIDTH - MARGIN_LEFT,
          }
        );

        pdf.line(
          MARGIN_LEFT * 1.1 + LEFT_COLUMN_WIDTH,
          startOffset,
          MARGIN_LEFT * 1.1 + LEFT_COLUMN_WIDTH,
          offset - LINE_HEIGHT
        );

        pdf.setFont("helvetica", "normal", "normal");
        pdf.setFontSize(RIDE_FONT_SIZE);

        pdf.text(
          USDollar.format(actualCost),
          MARGIN_LEFT + LEFT_COLUMN_WIDTH - 6,
          offset,
          {
            align: "right",
          }
        );

        offset += LINE_HEIGHT;
        pdf.line(
          MARGIN_LEFT * 1.1 + LEFT_COLUMN_WIDTH,
          startOffset,
          MARGIN_LEFT * 1.1 + LEFT_COLUMN_WIDTH,
          offset
        );

        startOffset = offset;
        text = `Passenger: ${passengerName}`;
        pdf.text(text, MARGIN_LEFT * 1.1, offset, {
          maxWidth: LEFT_COLUMN_WIDTH - MARGIN_LEFT,
        });
        offset +=
          pdf.getTextDimensions(text, {
            maxWidth: LEFT_COLUMN_WIDTH,
          }).h + RIDE_LINE_DELTA;

        pdf.line(
          MARGIN_LEFT * 1.1 + LEFT_COLUMN_WIDTH,
          startOffset,
          MARGIN_LEFT * 1.1 + LEFT_COLUMN_WIDTH,
          offset
        );

        if (offset > pdf.internal.pageSize.height - HEADER_START_POSIION) {
          pdf.addPage();
          offset = HEADER_START_POSIION;
          startOffset = offset - LINE_HEIGHT;
        }

        startOffset = offset;
        text = `from: ${removeNonLatinCharacters(departure?.label)}`;
        pdf.text(text, MARGIN_LEFT * 1.1, offset, {
          maxWidth: LEFT_COLUMN_WIDTH - MARGIN_LEFT,
        });
        offset +=
          pdf.getTextDimensions(text, {
            maxWidth: LEFT_COLUMN_WIDTH - MARGIN_LEFT,
          }).h + RIDE_LINE_DELTA;

        pdf.line(
          MARGIN_LEFT * 1.1 + LEFT_COLUMN_WIDTH,
          startOffset,
          MARGIN_LEFT * 1.1 + LEFT_COLUMN_WIDTH,
          offset
        );

        if (offset > pdf.internal.pageSize.height - HEADER_START_POSIION) {
          pdf.addPage();
          offset = HEADER_START_POSIION;
          startOffset = offset - LINE_HEIGHT;
        }

        startOffset = offset;
        text = `to: ${removeNonLatinCharacters(destination?.label)}`;
        pdf.text(text, MARGIN_LEFT * 1.1, offset, {
          maxWidth: LEFT_COLUMN_WIDTH - MARGIN_LEFT,
        });
        offset +=
          pdf.getTextDimensions(text, {
            maxWidth: LEFT_COLUMN_WIDTH - MARGIN_LEFT,
          }).h + RIDE_LINE_DELTA;

        pdf.line(
          MARGIN_LEFT * 1.1 + LEFT_COLUMN_WIDTH,
          startOffset,
          MARGIN_LEFT * 1.1 + LEFT_COLUMN_WIDTH,
          offset
        );

        startOffset = offset;
        text = [
          `Ride type: ${convertToReadable(rideType)}`,
          `Vehicle type: ${convertToReadable(vehicleType)}`,
          !!waitingTime ? `Waiting Time: ${waitingTime ?? 0} min` : false,
        ]
          .filter(Boolean)
          .join(", ");
        pdf.text(text, MARGIN_LEFT * 1.1, offset, {
          maxWidth: LEFT_COLUMN_WIDTH - MARGIN_LEFT,
        });
        offset +=
          pdf.getTextDimensions(text, {
            maxWidth: LEFT_COLUMN_WIDTH - MARGIN_LEFT,
          }).h + RIDE_LINE_DELTA;

        pdf.line(
          MARGIN_LEFT * 1.1 + LEFT_COLUMN_WIDTH,
          startOffset,
          MARGIN_LEFT * 1.1 + LEFT_COLUMN_WIDTH,
          offset
        );

        offset += LINE_HEIGHT;
        const endOffset = offset;

        pdf.line(
          MARGIN_LEFT * 1.1 + LEFT_COLUMN_WIDTH,
          startOffset,
          MARGIN_LEFT * 1.1 + LEFT_COLUMN_WIDTH,
          endOffset > pdf.internal.pageSize.height - HEADER_START_POSIION
            ? pdf.internal.pageSize.height - HEADER_START_POSIION
            : endOffset
        );
        pdf.setFontSize(FONT_SIZE);
      }
    );

    const verticalLineOffset = offset;

    pdf.line(MARGIN_LEFT, offset, MARGIN_LEFT + LEFT_COLUMN_WIDTH, offset);

    offset += LINE_HEIGHT;

    pdf.setFont("helvetica", "normal", "bold");

    const totalColumnMaxWidth =
      LEFT_COLUMN_WIDTH - MARGIN_LEFT - AMOUNT_COLUMN_WIDTH;
    const totalStartPosition =
      MARGIN_LEFT + LEFT_COLUMN_WIDTH - AMOUNT_COLUMN_WIDTH;
    const totalAmounrStartPosition = MARGIN_LEFT + LEFT_COLUMN_WIDTH - 6;

    text = "Net Total";
    pdf.text(text, totalStartPosition, offset, {
      maxWidth: totalColumnMaxWidth,
      align: "right",
    });
    pdf.text(USDollar.format(totalAmount), totalAmounrStartPosition, offset, {
      align: "right",
    });
    offset +=
      pdf.getTextDimensions(text, {
        maxWidth: totalColumnMaxWidth,
      }).h + TOTAL_LINE_DELTA;

    text = "Credits";
    pdf.text(text, totalStartPosition, offset, {
      maxWidth: totalColumnMaxWidth,
      align: "right",
    });
    pdf.text(`(${USDollar.format(0)})`, totalAmounrStartPosition, offset, {
      align: "right",
    });
    offset +=
      pdf.getTextDimensions(text, {
        maxWidth: totalColumnMaxWidth,
      }).h + TOTAL_LINE_DELTA;

    text = "Tax/VAT @ 0.00%";
    pdf.text(text, totalStartPosition, offset, {
      maxWidth: totalColumnMaxWidth,
      align: "right",
    });
    pdf.text(USDollar.format(0), totalAmounrStartPosition, offset, {
      align: "right",
    });
    offset +=
      pdf.getTextDimensions(text, {
        maxWidth: totalColumnMaxWidth,
      }).h + TOTAL_LINE_DELTA;

    text = "Total Amount Due";
    pdf.text(text, totalStartPosition, offset, {
      maxWidth: totalColumnMaxWidth,
      align: "right",
    });
    pdf.text(
      USDollar.format(status !== "PAID" ? totalAmount : 0),
      totalAmounrStartPosition,
      offset,
      {
        align: "right",
      }
    );
    offset +=
      pdf.getTextDimensions(text, {
        maxWidth: totalColumnMaxWidth,
      }).h + TOTAL_LINE_DELTA;

    pdf.line(
      MARGIN_LEFT * 1.1 + LEFT_COLUMN_WIDTH,
      verticalLineOffset,
      MARGIN_LEFT * 1.1 + LEFT_COLUMN_WIDTH,
      offset - LINE_HEIGHT * 0.2
    );

    pdf.line(MARGIN_LEFT, offset, MAX_RIGHT_POSITION, offset);

    offset += LINE_HEIGHT;

    pdf.setFontSize(FOOTER_FONT_SIZE);
    pdf.setFont("helvetica", "normal", "normal");
    pdf.text(
      `Company Number: N/A - VAT Number: N/A - This invoice was correct as of ${new Date(
        sentDateMs
      ).toDateString()}`,
      (MARGIN_LEFT + MAX_RIGHT_POSITION) / 2,
      offset,
      {
        maxWidth: MAX_RIGHT_POSITION - MARGIN_LEFT,
        align: "center",
      }
    );

    const blob = pdf.output("blob");
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `kerico_invoice_${id}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error while downloading the invoice PDF", error);
  }
};
