import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  InputLabel,
  Stack,
  styled,
  useTheme,
} from "@mui/material";
import { DialogWindow } from "UI/DialogWindow";
import { required } from "models/validations";
import { useApiRequest } from "api/useApiRequest";
import { useClientList } from "hooks/useClientList";
import { FormSelectField } from "UI/FormSelectField";
import LocationSelector from "./LocationSelector";
import { useLocationSelector } from "hooks/useLocationSelector";
import { FormRadioSwitcher } from "UI/FormRadioSwitcher";
import { customColors } from "models/customColors";
import { RouteMapView } from "UI/RouteMapView";
import { useRoute } from "hooks/useRoute";
import { estimateRide } from "api/rideApi";
import { FormDateTimeSelector } from "UI/FormDateTimeSelector";
import { unixTimeToUSDate } from "utils";
import { HumanizedDuration } from "components/HumanizedDuration";
import { useResetSignal } from "hooks/useResetSignal";

export const StatisticItem = styled(Stack)`
  flex: 1 1 auto;
  padding: 12px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 13px;
  border: 1px solid
    ${({ theme }) => customColors[theme.palette.mode].additionalStroke};
  background: ${({ theme }) => customColors[theme.palette.mode].background};
`;

export const StatisticLabel = styled(Box)`
  color: ${({ theme }) => customColors[theme.palette.mode].placeHolder};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`;

export const StatisticValue = styled(Box)`
  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.48px;
`;

export const StatisticUnits = styled("span")`
  color: ${({ theme }) => customColors[theme.palette.mode].placeHolder};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

/** @type {import("types/commonTypes").RideEstimateResponse} */
const defaultRideEstimaion = {
  distance: 0,
  distanceUnit: "",
  durationMs: 0,
  etaMs: 0,
  estimatedCost: 0,
};

/** @type {import("types/commonTypes").CalculatedRide} */
const defaultFormData = {
  customerCompanyId: "",
  rideType: "ASAP",
  vehicleType: "SEDAN_OR_SALOON",
  departureTimeMs: null,
  departure: {
    id: "",
    scope: "INTERNAL",
  },
  destination: {
    id: "",
    scope: "INTERNAL",
  },
  passengersNumber: 1,
  d2d: true,
};

export const CalculatorDialog = ({ open, handleClose }) => {
  const { callApiRequest } = useApiRequest();
  const { clientListOptions } = useClientList();
  const theme = useTheme();
  const { resetSignal, sendResetSignal } = useResetSignal();
  const [rideEstimation, setRideEstimation] = useState(defaultRideEstimaion);

  const { control, getValues, watch, handleSubmit, setValue, reset } = useForm({
    defaultValues: defaultFormData,
  });

  const {
    departureError,
    departureLabel,
    destinationError,
    destinationLabel,
    handleLocationSelect,
    setDepartureError,
    setDestinationError,
  } = useLocationSelector({
    setValue,
  });

  const handleReset = () => {
    reset(defaultFormData);
    setRideEstimation(defaultRideEstimaion);
    sendResetSignal();
  };

  const onSubmit = async () => {
    setRideEstimation(defaultRideEstimaion);

    const {
      customerCompanyId,
      departureTimeMs,
      rideType,
      departure,
      destination,
      vehicleType,
    } = getValues();

    if (departure?.id === "" || destination?.id === "") {
      if (departure?.id === "") {
        setDepartureError("Is required");
      }
      if (destination?.id === "") {
        setDestinationError("Is required");
      }
      return;
    }

    const params = {
      customerCompanyId,
      departureTimeMs:
        rideType === "SCHEDULED"
          ? parseInt(new Date(departureTimeMs).getTime().toFixed(0))
          : null,
      rideType,
      departure,
      destination,
      vehicleType,
    };

    callApiRequest({
      apiRequest: estimateRide,
      params,
      onSuccess: (result) => {
        setRideEstimation(result);
      },
      onError: () => setRideEstimation(defaultRideEstimaion),
    });
  };

  const { route } = useRoute({
    watch,
    getValues,
  });

  const rideType = watch("rideType");
  const customerCompanyId = watch("customerCompanyId");
  const departure = watch("departure");
  const destination = watch("destination");
  const distanceUnit = rideEstimation?.distanceUnit ?? "";
  const estimatedCost = rideEstimation?.estimatedCost ?? 0;
  const estimatedDistance = rideEstimation?.distance ?? 0;
  const estimatedDurationMs = rideEstimation.durationMs ?? 0;
  const etaMs = rideEstimation.etaMs;

  useEffect(() => {
    setRideEstimation(defaultRideEstimaion);
  }, [rideType, route, customerCompanyId, departure, destination]);

  return (
    <DialogWindow
      open={open}
      withCloseButton
      handleClose={handleClose}
      title={"Ride Calculator"}
      content={
        <form>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"stretch"}
            gap={3}
            width={"80vw"}
            maxWidth={"1024px"}
          >
            <Stack flex={1} mt={2} gap={1} justifyContent={"space-between"}>
              <Stack direction="row" alignItems={"flex-start"} width={"100%"}>
                <Stack alignItems={"flex-start"} width={"100%"}>
                  <InputLabel htmlFor="rideType">Ride type</InputLabel>
                  <FormRadioSwitcher
                    id="rideType"
                    name="rideType"
                    control={control}
                    setValue={setValue}
                    options={[
                      {
                        value: "ASAP",
                        label: "A.S.A.P",
                      },
                      {
                        value: "SCHEDULED",
                        label: "Scheduled",
                      },
                    ]}
                  />
                </Stack>
                {rideType === "SCHEDULED" && (
                  <Stack alignItems={"flex-start"} width={"100%"}>
                    <InputLabel htmlFor="departureTimeMs">
                      Pick Up Time
                    </InputLabel>
                    <FormDateTimeSelector
                      id="departureTimeMs"
                      name="departureTimeMs"
                      control={control}
                      rules={{ required }}
                      size="small"
                      disablePast
                      closeOnSelect={false}
                    />
                  </Stack>
                )}
              </Stack>
              <Stack alignItems={"flex-start"} width={"100%"}>
                <InputLabel htmlFor="customerCompanyId">
                  Client Company
                </InputLabel>
                <FormSelectField
                  id="customerCompanyId"
                  name="customerCompanyId"
                  control={control}
                  options={clientListOptions}
                  withNone={true}
                  noneLabel={"Use Default Contract"}
                />
              </Stack>

              <Stack alignItems={"flex-start"} flex={1}>
                <InputLabel htmlFor="pickUpLocation">
                  <Stack direction={"row"} gap={1}>
                    Pick Up Address
                  </Stack>
                </InputLabel>
                <LocationSelector
                  id="pickUpLocation"
                  setValue={(value) => handleLocationSelect(value, "departure")}
                  error={departureError}
                  sx={{ width: "100%" }}
                  key={`pickup-${resetSignal}`}
                  defaultValue={{ id: "", suggestion: "" }}
                />
              </Stack>
              <Stack alignItems={"flex-start"} flex={1}>
                <InputLabel htmlFor="dropOffLocation">
                  <Stack direction={"row"} gap={1}>
                    Drop Off Address
                  </Stack>
                </InputLabel>
                <LocationSelector
                  id="dropOffLocation"
                  setValue={(value) =>
                    handleLocationSelect(value, "destination")
                  }
                  error={destinationError}
                  sx={{ width: "100%" }}
                  key={`dropoff-${resetSignal}`}
                  defaultValue={{ id: "", suggestion: "" }}
                />
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                gap={2}
                width={"100%"}
              >
                <Stack alignItems={"flex-start"} flex={1}>
                  <InputLabel htmlFor="vehicleType">Vehicle Type</InputLabel>
                  <FormSelectField
                    id="vehicleType"
                    name="vehicleType"
                    control={control}
                    options={{
                      SEDAN_OR_SALOON: "Sedan or saloon",
                      VAN: "Van",
                      WHEELCHAIR: "Wheelchair",
                    }}
                    withNone={false}
                    rules={{ required }}
                  />
                </Stack>
              </Stack>
              <Stack mt={2} direction={"row"} gap={1}>
                <Button variant="outlined" onClick={handleReset}>
                  Clear Form
                </Button>
                <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                  Calculate Costs
                </Button>
              </Stack>
            </Stack>
            <Stack sx={{ mixHeight: "100%", minWidth: "50%" }} gap={3}>
              <Stack
                flex={1}
                sx={{
                  backgroundColor: customColors[theme.palette.mode].strokeColor,
                  borderRadius: "20px",
                  overflow: "hidden",
                }}
              >
                <RouteMapView
                  route={route}
                  departure={departureLabel}
                  destination={destinationLabel}
                />
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={1}
              >
                <StatisticItem>
                  <StatisticLabel>Price</StatisticLabel>
                  <StatisticValue>
                    $&nbsp;
                    {(Math.round(estimatedCost * 100) / 100).toFixed(2) ?? 0}
                  </StatisticValue>
                </StatisticItem>
                <StatisticItem>
                  <StatisticLabel>Distance</StatisticLabel>
                  <StatisticValue>
                    {Math.ceil(estimatedDistance * 10) / 10}{" "}
                    <StatisticUnits>{distanceUnit}</StatisticUnits>
                  </StatisticValue>
                </StatisticItem>
                <StatisticItem>
                  <StatisticLabel>Time</StatisticLabel>
                  <HumanizedDuration
                    durationInMs={estimatedDurationMs}
                    StatisticUnits={StatisticUnits}
                    StatisticValue={StatisticValue}
                  />
                </StatisticItem>
                {rideType === "SCHEDULED" && (
                  <StatisticItem>
                    <StatisticLabel>ETA</StatisticLabel>
                    <StatisticValue>
                      {unixTimeToUSDate(etaMs)?.slice(0, -2)}
                      <StatisticUnits>
                        {unixTimeToUSDate(etaMs)?.slice(-2)}
                      </StatisticUnits>
                    </StatisticValue>
                  </StatisticItem>
                )}
              </Stack>
            </Stack>
          </Stack>
        </form>
      }
    />
  );
};
