import { httpService } from "./httpService";

/**
 * @template T
 * @typedef {import('types/commonTypes').HttpResult<T>} HttpResult
 */

/**
 * @param {string} search
 * @returns  {HttpResult<any>}
 */
export const getGeofence = async (search) =>
  httpService({
    url: search ? `/geofence/?containing=${search}` : "/geofence",
    options: {
      method: "GET",
    },
  });

/**
 * @typedef {import('types/commonTypes').LocationSearchItem} LocationSearchItem
 * @param {import('types/commonTypes').LocationSearchRequest} data
 * @returns  {HttpResult<LocationSearchItem[]>}
 */
export const getLocationsBySearch = async (data) =>
  httpService({
    url: "/place/autocomplete",
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @param {{ companyId: string; awsId: string; name: string; }} data
 * @returns  {HttpResult<any>}
 */
export const addClientLocation = async (data) =>
  httpService({
    url: `/place`,
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @param {string} id
 * @returns  {HttpResult<any>}
 */
export const getLocationsByClientId = async (id) =>
  httpService({
    url: `/place/company/${id}`,
    options: {
      method: "GET",
    },
  });

/**
 * @param {string} id
 * @returns  {HttpResult<any>}
 */
export const deleteLocationById = async (id) =>
  httpService({
    url: `/place/${id}`,
    options: {
      method: "DELETE",
    },
  });
