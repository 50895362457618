/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unused-vars */
import React from "react";
import { Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export const FormDateSelector = ({
  label = "",
  name,
  control,
  rules = {},
  onSelect = (e) => null,
  disabled = false,
  size = "small",
  readOnly = false,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, onBlur, ref, value, ...inputProps },
        fieldState: { error },
      }) => (
        // <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={value ? new Date(value) : value}
          disabled={disabled}
          onChange={(newValue) => {
            onChange(newValue);
            try {
              const newDate =
                new Date(newValue)?.toISOString()?.replace("T", " ") ?? "";
              const adjustedValue = newDate.substring(0, 10);
              onSelect(adjustedValue);
            } catch (e) {
              onSelect("");
            }
          }}
          inputRef={ref}
          disableOpenPicker={readOnly}
          // @ts-ignore
          slotProps={{
            textField: {
              fullWidth: true,
              variant: "outlined",
              error: !!error,
              helperText: error?.message,
              // @ts-ignore
              size,
            },
          }}
          readOnly={readOnly}
          {...inputProps}
          {...props}
        />
        // </LocalizationProvider>
      )}
    />
  );
};
