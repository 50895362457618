import { toUnixTimestamp, unixTimeInMsToDate, unixTimeToUSDate } from "utils";
import * as FP from "./fp-js";

const fieldFormats = {
  long: "long",
  string: "string",
  withLabels: "withLabels",
  dateTime: "dateTime",
  double: "double",
  integer: "integer",
  boolean: "boolean",
};

const fixTime = (time) => {
  let fixedTime;
  if (time.length === 3) {
    fixedTime = `${time[0]}:${time.slice(1)}`;
  } else if (time.length === 4) {
    fixedTime = `${time.slice(0, 2)}:${time.slice(2)}`;
  }
  return fixedTime ?? time;
};

export const datetimeFieldHandler = (parts, forPreview) => () => {
  if (!Array.isArray(parts)) {
    return forPreview ? "Wrong source data" : null;
  }

  if (parts?.length === 1) {
    return parts[0];
  }

  if (parts?.length === 2) {
    const isDateFirst = parts[0]?.length > parts[1]?.length;
    const [date, time] = isDateFirst ? parts : parts.reverse();
    const fixedTime = fixTime(time);
    const value = toUnixTimestamp(new Date(`${date} ${fixedTime ?? time}`));
    return forPreview ? unixTimeToUSDate(value) : unixTimeInMsToDate(value);
  }

  return parts.filter(Boolean).join(" ");
};

export const withLabelsFieldHandler = (parts, delimiter, labels) => () => {
  const values = parts.map((part, index) =>
    part ? `${labels[index]}: ${part}` : undefined
  );
  return values.filter(Boolean).join(delimiter ?? "; ");
};

export const booleanFieldHandler = (parts, forPreview) => () => {
  if (parts.length !== 1) {
    return forPreview ? "Wrong source data" : null;
  }

  if (["yes", "y", "true"].includes(parts[0].toLowerCase())) {
    return forPreview ? "Yes" : true;
  }

  return forPreview ? "No" : false;
};

export const getFieldMapper =
  ({ forPreview = false, rideRequestFields, template, headerRow }) =>
  (row) => {
    const keys = rideRequestFields?.map(({ name }) => name);
    const result = forPreview ? [] : {};
    keys?.forEach((key) => {
      const indexes = template[key];
      const {
        delimiter,
        type,
        format = type,
      } = rideRequestFields.find((field) => field.name === key);
      const parts = indexes.map((index) => row[index]);
      const labels = indexes.map((index) => headerRow[index]);

      const value = FP.match(format)
        .on(fieldFormats.dateTime, datetimeFieldHandler(parts, forPreview))
        .on(fieldFormats.boolean, booleanFieldHandler(parts, forPreview))
        .on(
          fieldFormats.withLabels,
          withLabelsFieldHandler(parts, delimiter, labels)
        )
        .otherwise(parts.filter(Boolean).join(delimiter ?? " "));

      if (forPreview) {
        result.push(value);
      } else {
        result[key] = value;
      }
    });
    return result;
  };
