import { useEffect, useState } from "react";

export const useAutocompleteOptions = ({ startTyping = "Start typing", minSearchLength = 2 }) => {
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [noOptionsText, setNoOptionsText] = useState(startTyping);

  useEffect(() => {
    let timeout;

    if (isLoading) {
      setNoOptionsText("Loading...");
      return;
    }
    if (search.length <= minSearchLength) {
      setNoOptionsText("Start typing address");
      return;
    }
    if (isLoaded && list.length === 0) {
      setNoOptionsText("Loading...");
      timeout = setTimeout(() => {
        setNoOptionsText("No locations found");
      }, 1500);
      return;
    }
    setNoOptionsText("");

    return () => clearTimeout(timeout);
  }, [list, isLoaded, isLoading, search, minSearchLength]);

  return {
    isLoading,
    isLoaded,
    noOptionsText,
    setIsLoading,
    setIsLoaded,
    setNoOptionsText,
    list,
    setList,
    search,
    setSearch,
  };
};
