import React from "react";
import { Stack, styled } from "@mui/material";
import { useDraggable } from "@dnd-kit/core";
import { customColors } from "models/customColors";

const Container = styled(Stack)`
  justify-content: center;
  align-items: center;

  padding: 0 6px;
  justify-content: center;
  align-items: center;
  height: 22px;

  font-size: 12px;
  border: 1px solid ${({ theme }) => customColors[theme.palette.mode].textBody};
  background-color: ${({ theme }) => customColors[theme.palette.mode].tableBackground};
  border-radius: 22px;
  cursor: pointer;
  user-select: none;
`;

export const Draggable = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: String(id),
  });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <Container ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {children}
    </Container>
  );
};
