import { Button, Stack, useTheme } from "@mui/material";
import { ReactComponent as StarIcon } from "assets/img/star-icon.svg";
import { customColors } from "models/customColors";
import { unixTimeToUSShortDate } from "utils";

import { ReactComponent as SmallLogo } from "assets/img/Symbol_DarkBlue.svg";
import { ReactComponent as WhiteSmallLogo } from "assets/img/Symbol_White.svg";
import {
  CarData,
  Detail,
  Label,
  RejectReason,
  Value,
} from "./DriverDetails.style";

export const DriverDetailsCarData = ({
  vehicle,
  loadInsuranceImage,
  setCarId,
  setIsCarRejectionDialogOpen,
  approveCarRequest,
}) => {
  const theme = useTheme();
  return (
    <CarData
      gap={2}
      key={vehicle?.id}
      sx={{
        backgroundColor:
          vehicle?.fleetStatus === "INACTIVE"
            ? customColors[theme.palette.mode].error + "19"
            : "transparent",
      }}
    >
      <Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={1}
          flexWrap={"wrap"}
        >
          <Detail flex={1}>
            {vehicle?.assignment === "PRIMARY" && <StarIcon />}
            <Stack direction={"row"} alignItems={"center"}>
              {vehicle?.owner === "KERICO" && (
                <>
                  {theme.palette.mode === "dark" ? (
                    <WhiteSmallLogo height="22px" />
                  ) : (
                    <SmallLogo height="22px" />
                  )}
                </>
              )}
              <Label>Vehicle:</Label>
            </Stack>
            <Value>
              {vehicle?.make} {vehicle?.model} {vehicle?.year},{" "}
              {vehicle?.licensePlate?.state
                ? `${vehicle?.licensePlate?.state}, `
                : ""}
              {vehicle?.licensePlate?.licensePlate}
            </Value>
          </Detail>
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={1}
            flexWrap={"wrap"}
          >
            <Detail>
              <Label>Expires:</Label>
              <Value>
                {vehicle?.insuranceDto?.expTimeMs &&
                  unixTimeToUSShortDate(vehicle?.insuranceDto?.expTimeMs)}
              </Value>
            </Detail>
            <Button
              variant="outlined"
              onClick={() => loadInsuranceImage(vehicle)}
              disabled={!vehicle?.insurancePhoto?.id}
            >
              View
            </Button>
          </Stack>
        </Stack>
        {vehicle?.fleetStatus === "AWAITING" && (
          <Stack
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            gap={1}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                setCarId(vehicle?.id);
                setIsCarRejectionDialogOpen(true);
              }}
            >
              Reject
            </Button>
            <Button
              variant="outlined"
              color="success"
              onClick={() => approveCarRequest(vehicle?.id)}
            >
              Approve
            </Button>
          </Stack>
        )}
        {vehicle?.fleetStatus === "INACTIVE" && (
          <RejectReason>
            Reject reason: <strong>{vehicle?.notes}</strong>
          </RejectReason>
        )}
      </Stack>
    </CarData>
  );
};
