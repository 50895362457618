import { getChatUsers, getMattermostUserDetailssByIds } from "api/messageApi";
import { useApiRequest } from "api/useApiRequest";
import { useCallback, useState } from "react";
import { convertToReadable } from "utils";

export const useChatUsers = () => {
  const { callApiRequest } = useApiRequest();

  const [chatUsers, setChatUsers] = useState(null);
  const [chatUserDetails, setChatUserDetails] = useState(null);

  const getUserLabelById = useCallback(
    (id) => {
      const user = chatUserDetails?.find((user) => user.id === id);

      let result = [];
      if (user?.first_name) {
        result.push(`${user.first_name} ${user.last_name}`);
      }

      if (user?.props?.kerico_role) {
        result.push(convertToReadable(user.props.kerico_role));
      }

      if (result.length === 0 && user?.email) {
        result.push(user.email);
      }

      if (result.length === 0) {
        result.push("System");
      }

      return result.join(", ");
    },
    [chatUserDetails]
  );

  const loadUsersData = useCallback(
    async (id) => {
      callApiRequest({
        apiRequest: getChatUsers,
        params: id,
        skipSpinner: true,
        onSuccess: (result) => {
          if (result?.length > 0) {
            setChatUsers(result);
            callApiRequest({
              apiRequest: getMattermostUserDetailssByIds,
              params: result.map((user) => user.user_id),
              onSuccess: (details) => {
                setChatUserDetails(details);
              },
            });
            return;
          }
          setChatUsers([]);
          setChatUserDetails([]);
        },
      });
    },
    [callApiRequest]
  );

  return {
    chatUsers,
    setChatUsers,
    chatUserDetails,
    setChatUserDetails,
    loadUsersData,
    getUserLabelById,
  };
};
