import React, { useState } from "react";
import { Button, Stack, styled } from "@mui/material";
import * as SC from "SC";
import { BackButton } from "components/BackButton";
import { useDataGrid } from "hooks/useDataGrid";
import { getKericoUsers } from "api/userApi";
import { useAppSelector } from "store/hooks";
import { DataGrid } from "UI/DataGrid";
import { AddKericoUserDialog } from "components/AddKericoUserDialog";
import { EditKericoUserDialog } from "components/EditKericoUserDialog";
import { useAddKericoUser } from "hooks/useAddKericoUser";
import { useGeofenceOptions } from "hooks/useGeofenceOptions";
import { TextFieldWithEllipsis } from "components/TextFiledWithEllipsis";
import { convertToReadable } from "utils";

const Content = styled(Stack)`
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 10px;

  height: 100%;

  overflow-y: auto;
  overflow-x: hidden;
`;

export const ManageKericoUsers = () => {
  const userId = useAppSelector((state) => state.user.id);
  const [currentUser, setCurrentUser] = useState();

  const {
    isAddUserVisible,
    setIsAddUserVisible,
    selectedCounties,
    setSelectedCounties,
  } = useAddKericoUser();
  const { countiesOptions } = useGeofenceOptions();

  const columns = [
    {
      label: "Firstname",
      field: "firstName",
      xs: 2,
      sm: 2,
      md: 2,
      sortableName: "FIRST_NAME",
    },
    {
      label: "Lastname",
      field: "lastName",
      xs: 2,
      sm: 2,
      md: 2,
      sortableName: "LAST_NAME",
    },
    {
      label: "Role",
      field: "role",
      xs: 2.5,
      sm: 2.5,
      md: 2.5,
      sortableName: "ROLE",
      renderer: ({ role }) => convertToReadable(role),
    },
    {
      label: "Email",
      field: "email",
      xs: 3.5,
      sm: 3.5,
      md: 3.5,
      sortableName: "EMAIL",
      renderer: ({ email }) => (
        <TextFieldWithEllipsis>{email}</TextFieldWithEllipsis>
      ),
    },
    {
      label: "Phone Number",
      field: "phone",
      xs: 2,
      sm: 2,
      md: 2,
      sortableName: "PHONE",
    },
  ];

  const dataGrid = useDataGrid({
    apiHandler: getKericoUsers,
    defaultSorter: {
      name: "FIRST_NAME",
      order: "ASC",
    },
    defaultLimit: 25,
    gridConfigIndex: `${userId}-kerico-users`,
  });

  const handleRowClick = (row) => {
    const convertedData = {
      ...row,
      counties: (row.geofences ?? []).map(({ uuid }) => uuid),
    };
    if (row?.phone?.length !== 12) {
      convertedData.phone = "";
    }
    setCurrentUser(convertedData);
    const idsOfCounties = (row?.geofences ?? []).map(({ uuid }) => uuid);
    setSelectedCounties(idsOfCounties);
  };

  return (
    <SC.Container>
      <SC.Layout>
        <Stack direction={"row"} alignItems={"center"}>
          <BackButton />
          <SC.Title>Manage Kerico Users</SC.Title>
          <Button
            variant="contained"
            onClick={() => setIsAddUserVisible(true)}
            sx={{ marginLeft: "1rem" }}
          >
            Add Kerico User
          </Button>
        </Stack>
        <Content>
          <DataGrid
            columns={columns}
            handleRowClick={handleRowClick}
            {...dataGrid}
          />
        </Content>
      </SC.Layout>
      <AddKericoUserDialog
        open={isAddUserVisible}
        handleClose={() => setIsAddUserVisible(false)}
        refreshList={dataGrid.loadData}
        countiesOptions={countiesOptions}
        selectedCounties={selectedCounties}
        setSelectedCounties={setSelectedCounties}
      />
      <EditKericoUserDialog
        currentUser={currentUser}
        handleClose={() => setCurrentUser(undefined)}
        refreshList={dataGrid.loadData}
        countiesOptions={countiesOptions}
        selectedCounties={selectedCounties}
        setSelectedCounties={setSelectedCounties}
      />
    </SC.Container>
  );
};
