import React from "react";
import { IconButton, Stack, Tooltip, styled } from "@mui/material";
import { customColors } from "models/customColors";
import { RefreshButton } from "UI/DataGrid";
import RefreshIcon from "@mui/icons-material/Refresh";

const ActionButton = styled(IconButton)`
  padding: 0;
  flex: 0 0 auto;
  width: 42px;
  height: 42px;

  border: thin solid
    ${({ theme }) => customColors[theme.palette.mode].borderColor};
  border-radius: 6px;

  & path {
    fill: ${({ theme }) => customColors[theme.palette.mode].placeHolder};
  }

  &.active {
    cursor: default;
  }

  &.active path {
    fill: ${({ theme }) => customColors[theme.palette.mode].actionColor};
  }
`;

export const viewModes = {
  TABLE: 0,
  CALENDAR: 1,
};

/**
 * @typedef {Object} Option
 * @property {React.ReactNode} icon
 * @property {string} tooltip
 * @property {() => void} handleRefresh
 */

/**
 * @typedef {Object} BookingsViewModeSelectorProps
 * @property {number} mode
 * @property {React.Dispatch<React.SetStateAction<number>>} setMode
 * @property {() => void} closeRide
 * @property {Option[]} options
 */
/**
 * @param {BookingsViewModeSelectorProps} props
 */
export const BookingsViewModeSelector = ({
  mode,
  setMode,
  options,
  closeRide,
}) => {
  const handleClickUnactiveItem = (e, index) => {
    setMode(index);
    options[index]?.handleRefresh();
    closeRide();
  };

  return (
    <Stack direction={"row"} gap={1}>
      <Tooltip title={"Refresh data"}>
        <RefreshButton onClick={options[mode]?.handleRefresh}>
          <RefreshIcon />
        </RefreshButton>
      </Tooltip>
      {options?.map((option, index) => {
        const isActive = mode === index;

        return (
          <Tooltip key={`view-mode-${index}`} title={option.tooltip}>
            <ActionButton
              key={`view-mode-${index}`}
              className={isActive ? "active" : ""}
              onClick={
                isActive ? () => null : (e) => handleClickUnactiveItem(e, index)
              }
            >
              {option.icon}
            </ActionButton>
          </Tooltip>
        );
      })}
    </Stack>
  );
};
