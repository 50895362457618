import React, { useEffect, useMemo, useState } from "react";
import { Box, Divider, Stack } from "@mui/material";
import { DataGridHeader } from "UI/DataGridHeader";
import { DataGridRecords } from "UI/DataGridRecords";
import { DialogWindow } from "UI/DialogWindow";
import { getDriverBackgroundCheckListById } from "api/driverApi";
import { useApiRequest } from "api/useApiRequest";
import { useDriverDetails } from "hooks/useDriverDetails";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { closeChecksWindow } from "store/reducers/backgroundChecksReducer";
import { convertToReadable, getSorter, unixTimeToUSShortDate } from "utils";
import { SmallButton } from "./BackgroundCheckDriver";
import {
  CheckContainer,
  Container,
  Info,
  Label,
  Prop,
  ReportsHolder,
  Status,
  TableData,
  Value,
} from "./DriverChecksDialog.style";

const defaultSorter = { name: "createdAt", order: "desc" };
const sorter = getSorter(defaultSorter);

const getRows = (checks) =>
  checks
    ?.map(({ invitation, report }) => ({
      id: invitation.id,
      createdAt: invitation.createdAt,
      status: invitation.status,
      completedAt: invitation.completedAt,
      report,
    }))
    .sort(sorter);

const getColumns = (setSelectedCheckId) => [
  {
    label: "Created",
    field: "createdAt",
    xs: 3,
    sm: 3,
    md: 3,
    renderer: ({ createdAt }) => unixTimeToUSShortDate(createdAt),
  },
  {
    label: "Status",
    field: "status",
    align: "center",
    xs: 3,
    sm: 3,
    md: 3,
    renderer: ({ status, report }) => {
      if (status === "pending" || report?.status === "pending") {
        return <Status className="Pending">Pending</Status>;
      }

      if (report) {
        const result = convertToReadable(report?.result ?? "");
        const status = convertToReadable(report?.status ?? "");

        if (result === "Consider") {
          return <Status className={result}>{result}</Status>;
        }

        return <Status className={status}>{status}</Status>;
      }
      const readableStatus = convertToReadable(status);
      return <Status className={readableStatus}>{readableStatus}</Status>;
    },
  },
  {
    label: "Completed",
    field: "completedAt",
    align: "center",
    xs: 3,
    sm: 3,
    md: 3,
    renderer: ({ completedAt }) =>
      completedAt && unixTimeToUSShortDate(completedAt),
  },
  {
    label: "Action",
    align: "center",
    xs: 3,
    sm: 3,
    md: 3,
    renderer: ({ id, report }) => {
      return (
        ["complete", "consider"].includes(report?.status) && (
          <SmallButton
            variant="outlined"
            onClick={() => setSelectedCheckId(id)}
          >
            View Reports
          </SmallButton>
        )
      );
    },
  },
];

export const DriverChecksDialog = () => {
  const { callApiRequest } = useApiRequest();
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.backgroundChecks.selectedId);
  const isNewCheckWindowOpen = useAppSelector(
    (state) => state.backgroundChecks.isNewCheckWindowOpen
  );
  const { driver } = useDriverDetails(userId);

  const [checks, setChecks] = useState(null);
  const [selectedCheckId, setSelectedCheckId] = useState(null);

  const columns = useMemo(() => getColumns(setSelectedCheckId), []);
  const rows = useMemo(() => getRows(checks), [checks]);
  const selectedCheck = useMemo(
    () => checks?.find(({ invitation }) => invitation.id === selectedCheckId),
    [checks, selectedCheckId]
  );

  useEffect(() => {
    if (!userId) {
      setChecks(null);
      setSelectedCheckId(null);
      return;
    }

    callApiRequest({
      apiRequest: getDriverBackgroundCheckListById,
      params: userId,
      onSuccess: (data) => {
        setChecks(data);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return !selectedCheckId ? (
    <DialogWindow
      open={!isNewCheckWindowOpen && !!userId && !!driver?.id}
      withCloseButton
      handleClose={() => dispatch(closeChecksWindow())}
      title={
        <Stack gap={0}>
          <Box>Background Checks</Box>
          <Info>
            Driver Name:{" "}
            {[driver?.firstName, driver?.lastName].filter(Boolean).join(" ")}
          </Info>
        </Stack>
      }
      content={
        <Container>
          <TableData flex={1}>
            <DataGridHeader
              columns={columns}
              sorter={defaultSorter}
              handleSort={() => null}
            />
            <DataGridRecords columns={columns} rows={rows} />
          </TableData>
        </Container>
      }
    />
  ) : (
    <DialogWindow
      open={!isNewCheckWindowOpen && !!userId && !!driver?.id}
      withCloseButton
      handleClose={() => dispatch(closeChecksWindow())}
      withBackwardButton
      handleBackward={() => setSelectedCheckId(null)}
      title={
        <Stack gap={0} alignItems={"center"}>
          <Box>Background Check Results</Box>
          <Info>
            Driver Name:{" "}
            {[driver?.firstName, driver?.lastName].filter(Boolean).join(" ")}
          </Info>
        </Stack>
      }
      content={
        <CheckContainer>
          <Stack mt={1} direction={"row"} justifyContent={"flex-start"} gap={3}>
            <Prop>
              <Label>Created at:</Label>
              <Value>
                {selectedCheck?.invitation.createdAt &&
                  unixTimeToUSShortDate(selectedCheck?.invitation.createdAt)}
              </Value>
            </Prop>
            <Prop>
              <Label>Result:</Label>{" "}
              <Status
                className={convertToReadable(
                  selectedCheck?.report.result ?? ""
                )}
              >
                {convertToReadable(selectedCheck?.report.result)}
              </Status>
            </Prop>
            <Prop>
              <Label>Completed at:</Label>
              <Value>
                {selectedCheck?.invitation.completedAt &&
                  unixTimeToUSShortDate(selectedCheck?.invitation.completedAt)}
              </Value>
            </Prop>
          </Stack>
          <Stack mt={4} alignItems={"stretch"} gap={1}>
            <Stack alignItems={"flex-start"}>
              Document{selectedCheck?.report?.documents?.length > 1 && "s"}
            </Stack>
            <Divider />
          </Stack>
          <ReportsHolder>
            <Stack>
              {selectedCheck?.report?.documents?.map((doc, index) => (
                <React.Fragment key={`${index}-${doc.download_uri}`}>
                  <Stack
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    gap={2}
                    padding={"1rem 0"}
                  >
                    <Box>Checkr Report</Box>
                    <SmallButton
                      variant="contained"
                      onClick={() => window.open(doc.download_uri, "_blank")}
                      sx={{ padding: "0 1rem" }}
                    >
                      View Report {index + 1}
                    </SmallButton>
                  </Stack>
                  <Divider />
                </React.Fragment>
              ))}
            </Stack>
          </ReportsHolder>
        </CheckContainer>
      }
    />
  );
};
