import { Stack, styled, Button } from "@mui/material";
import { customColors } from "models/customColors";
export { Title, BackButtonContent, BackButtonContentSx } from "SC";

export const AuthContainer = styled(Stack)`
  width: 100dvw;
  height: 100dvh;

  flex-direction: row;
  justify-content: flex-start;

  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].background};
`;

export const OutletContainer = styled(Stack)`
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  overflow-y: auto;
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].tableBackground};
`;

export const BackButton = styled(Button)`
  align-self: flex-start;
`;

export const LogoHolder = styled(Stack)`
  flex: 0 0 auto;

  justify-content: center;
  align-items: center;
  padding: 0;
  padding-top: 3rem;
  padding-bottom: 1rem;
  text-align: center;
  width: 100%;
  max-width: 500px;
`;

export const TitleSx = {
  fontWeight: "bold",
  fontSize: {
    lg: 30,
    md: 25,
    sm: 24,
    xs: 24,
  },
};

export const DescriptionSx = {
  marginLeft: {
    lg: 2.4,
    md: 1.8,
    sm: 1.6,
    xs: 1.6,
  },
  fontWeight: "normal",
  fontSize: {
    lg: 20,
    md: 16,
    sm: 18,
    xs: 18,
  },
  lineHeight: {
    lg: 2,
    md: 1.8,
    sm: 1.6,
    xs: 1.6,
  },
};

export const ListItemSx = {
  padding: 0,
  listStyleType: "disc",
  display: "list-item",
};
