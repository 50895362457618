import React from "react";
import { styled, IconButton, InputAdornment, Stack } from "@mui/material";
import Clear from "@mui/icons-material/Clear";
import { customColors } from "models/customColors";

export const ChipSetsHolder = styled(Stack)`
  padding: 15px;
  border-radius: 15px;
  border: solid thin
    ${({ theme }) => customColors[theme.palette.mode].strokeColor};
  text-align: left;
  min-height: 100px;
  max-height: 180px;

  overflow-y: auto;

  width: 330px;

  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  gap: 0.2em;
`;

const Container = styled(Stack)`
  flex-direction: row;
  align-items: center;

  padding: 6px 0 6px 12px;

  font-family: Inter;
  font-size: 13px;

  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].additionalStroke};

  border-radius: 32px;
`;

const ClearIcon = styled(Clear)`
  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
`;

export const EditableChip = ({ value, onDelete }) => {
  return (
    <Container>
      {value}
      <InputAdornment position="end">
        <IconButton size="small" onClick={onDelete}>
          <ClearIcon fontSize="small" />
        </IconButton>
      </InputAdornment>
    </Container>
  );
};
