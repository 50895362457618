import { Stack } from "@mui/material";
import * as SC from "SC";
import { CloseWindowButton } from "UI/CloseWindowButton";
import { DataGrid } from "UI/DataGrid";
import { BookingsViewModeSelector } from "components/BookingsViewModeSelector";
import { RideDetails } from "components/RideDetails";
import { ActionPanel } from "pages/BookingsPage";
import { columns, getShortColumns } from "pages/BookingsPage.utils";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setBookingViewMode } from "store/reducers/layoutReducer";

export const TableView = ({
  setIsCalculatorOpen,
  selectedId,
  setSelectedId,
  viewModeOptions,
  dataGrid,
  getClientLabelById,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const mode = useAppSelector((state) => state.layout.bookingViewMode ?? 0);
  const setMode = (mode) => dispatch(setBookingViewMode(mode));

  const shortColumns = getShortColumns(selectedId);

  const handleRowClick = (row) => {
    if (selectedId === row?.id) {
      setSelectedId("");
      return;
    }

    setSelectedId(row?.id ?? "");
  };

  return (
    <>
      <Stack
        gap={"20px"}
        overflow={"hidden"}
        justifyContent={"space-between"}
        width={selectedId ? "calc(50% - 20px)" : "100%"}
      >
        <DataGrid
          columns={selectedId ? shortColumns : columns}
          handleRowClick={handleRowClick}
          {...dataGrid}
          actions={
            <BookingsViewModeSelector
              mode={mode}
              setMode={setMode}
              options={viewModeOptions}
              closeRide={() => setSelectedId("")}
            />
          }
          actionsAfter={
            <ActionPanel
              navigate={navigate}
              setIsCalculatorOpen={setIsCalculatorOpen}
            />
          }
          keyName="id"
          selectedId={selectedId}
          noRefresh
        />
      </Stack>
      {selectedId && (
        <SC.HalfScreenPanel>
          <CloseWindowButton
            top={-35}
            right={-35}
            closeHandler={() => setSelectedId("")}
          />
          <RideDetails
            id={selectedId}
            getClientLabelById={getClientLabelById}
            refreshList={dataGrid.loadData}
          />
        </SC.HalfScreenPanel>
      )}
    </>
  );
};
