import React from "react";
import { IconButton, Stack, Menu } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useContextMenu } from "hooks/useContextMenu";

export const ContextMenu = ({ children, icon = <MoreHorizIcon /> }) => {
  const { handleMenuClick, handleMenuClose, anchorEl, open } = useContextMenu();

  return (
    <Stack justifyContent={"center"} alignItems={"center"} mt={1}>
      <IconButton onClick={handleMenuClick}>{icon}</IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {children}
      </Menu>
    </Stack>
  );
};
