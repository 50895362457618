import { useAppDispatch, useAppSelector } from "store/hooks";
import { setIsDataChanged as setIsDataChangedGlobal } from "store/reducers/isDataChangedReducer";

export const useIsDataChanged = () => {
  const dispatch = useAppDispatch();
  const isDataChanged = useAppSelector((state) => state.isDataChanged);
  const setIsDataChanged = (value) => dispatch(setIsDataChangedGlobal(value));

  return {
    isDataChanged,
    setIsDataChanged,
  };
};
