import { useContext, useEffect, useState } from "react";
import { useClientList } from "hooks/useClientList";
import { useForm } from "react-hook-form";
import { useFormDataSync } from "hooks/useFormDataSync";
import { parseCSV } from "utils";
import { useRideRequestFields } from "hooks/useRideRequestFields";
import { getFieldMapper } from "utils/mapper.utils";
import { useApiRequest } from "api/useApiRequest";
import { createCsvFileRequest } from "api/bookingRequestsApi";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "store/globalContext";
import { useAppDispatch } from "store/hooks";
import { showError } from "store/reducers/errorReducer";

const defaultValues = {
  clientId: "",
  separator: ";",
  isNamesInFirstRow: true,
  stripQuotes: false,
  data: undefined,
  templateName: "",
  id: undefined,
};

const separatorOptions = {
  ",": `"," - comma`,
  ";": `";" - semicolon`,
  "|": `"|" - pipe`,
  "\t": "Tab",
};

const checkIfAllFieldsMapped = (template) => {
  if (!template) {
    return false;
  }
  return Object.values(template).every((field) => field.length > 0);
};

const checkIfSomeFieldsMapped = (template) => {
  if (!template) {
    return false;
  }
  return Object.values(template).some((field) => field.length > 0);
};

export const useImportCsvFilePage = () => {
  const { callApiRequest } = useApiRequest();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showWarning } = useContext(GlobalContext);
  const { clientListOptions } = useClientList();
  const { defaultTemplate, rideRequestFields } = useRideRequestFields();

  const [step, setStep] = useState(0);
  const [parsedData, setParsedData] = useState([]);
  const [headerRow, setHeaderRow] = useState([]);
  const [template, setTemplate] = useState(null);

  const {
    control,
    getValues,
    setValue,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues,
  });

  useFormDataSync({
    isDirty,
  });

  const handleFileSelect = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = function (event) {
      const csvData = event.target.result;
      setValue("data", csvData);
      setStep(1);
    };

    reader.readAsText(file);
  };

  const isReadyForUpload = !!watch("clientId");

  const isMapperDisabled =
    parsedData.length === 0 || (parsedData[0] && parsedData[0]?.length === 0);
  const data = watch("data");
  const separator = watch("separator");
  const isNamesInFirstRow = watch("isNamesInFirstRow");
  const stripQuotes = watch("stripQuotes");

  const startMapping = () => {
    setStep(2);
  };

  useEffect(() => {
    setTemplate(defaultTemplate);
  }, [defaultTemplate]);

  useEffect(() => {
    if (data?.length > 0) {
      const separators = Object.keys(separatorOptions);
      let maxCellCount = 0;
      let maxSeparator = ",";
      let maxQuotesCount = 0;
      separators.forEach((separator) => {
        const { cellCount, quotesCount } = parseCSV({
          data,
          separator,
        });
        if (cellCount > maxCellCount) {
          maxCellCount = cellCount;
          maxSeparator = separator;
          maxQuotesCount = quotesCount;
        }
      });
      setValue("stripQuotes", maxQuotesCount > maxCellCount / 10); // if quotes are present in more than 10% of cells, strip them
      setValue("separator", maxSeparator);
    }
  }, [data, setValue]);

  const handleImport = () => {
    const { clientId } = getValues();
    let params;

    try {
      params = {
        companyId: clientId,
        rideRequests:
          parsedData?.map(
            getFieldMapper({
              rideRequestFields,
              template,
              headerRow,
            })
          ) ?? [],
      };
    } catch (error) {
      dispatch(
        showError(
          "The entered data does not match the expected data format. Check the data in the source file."
        )
      );
      return;
    }

    callApiRequest({
      apiRequest: createCsvFileRequest,
      params,
      onSuccess: (data) => {
        showWarning({
          title: "File Request Importing",
          content: "The File Request is importing.",
        });
        navigate(`/request_files`);
      },
    });
  };

  useEffect(() => {
    const { rows, headerRow } = parseCSV(getValues());
    setHeaderRow(headerRow);
    setParsedData(rows);
  }, [getValues, data, separator, isNamesInFirstRow, stripQuotes]);

  useEffect(() => {
    // setTemplate(defaultTemplate);
  }, [data]);

  const isAllFieldsMapped = checkIfAllFieldsMapped(template);
  const previewHeaderRow = rideRequestFields?.map(({ label }) => label) ?? [];

  const previewRows =
    parsedData?.slice(0, 1)?.map(
      getFieldMapper({
        forPreview: true,
        rideRequestFields,
        template,
        headerRow,
      })
    ) ?? [];

  const isPreviewVisible = checkIfSomeFieldsMapped(template);

  return {
    step,
    setStep,
    clientListOptions,
    control,
    handleFileSelect,
    separatorOptions,
    isReadyForUpload,
    isMapperDisabled,
    startMapping,
    handleImport,
    parsedData,
    headerRow,
    template,
    setTemplate,
    isAllFieldsMapped,
    defaultTemplate,
    rideRequestFields,
    watch,
    previewHeaderRow,
    previewRows,
    isPreviewVisible,
    getValues,
    setValue,
  };
};
