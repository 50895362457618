import React from "react";
import { MaterialUISwitch } from "./ModeSwitch.style";
import { useAppSelector } from "store/hooks";
import { Box } from "@mui/material";

export const ModeSwitcher = ({ onClick }) => {
  const mode = useAppSelector((state) => state?.layout?.themeMode);

  return (
    <Box sx={{ cursor: "pointer" }} onClick={onClick}>
      <MaterialUISwitch sx={{ m: 1 }} checked={mode === "light"} />
    </Box>
  );
};
