import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { Button, InputLabel, Stack } from "@mui/material";
import { DialogWindow } from "UI/DialogWindow";
import { FormTextField } from "UI/FormTextField";
import { required } from "models/validations";
import { useApiRequest } from "api/useApiRequest";
import { useLocalFormDataSync } from "hooks/useLocalFormDataSync";
import { removeEmptyProps } from "utils";
import { createCsvTemplate } from "api/csvTemplateApi";
import { GlobalContext } from "store/globalContext";

const defaultFormData = {
  id: undefined,
  name: "",
  namesInFirstRaw: undefined,
  relations: undefined,
};

export const CreateCsvTemplateDialog = ({
  handleClose,
  data,
  handleSelectTemplate = (template) => null,
}) => {
  const { callApiRequest } = useApiRequest();
  const { showWarning } = useContext(GlobalContext);

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { isDirty },
  } = useForm({
    values: data || defaultFormData,
  });

  const { handleConfirmedClose, handleForcedClose } = useLocalFormDataSync({
    isDirty,
    handleClose,
    open: !!data,
    reset,
  });

  const onSubmit = async () => {
    const params = removeEmptyProps(getValues());

    callApiRequest({
      apiRequest: createCsvTemplate,
      params,
      onSuccess: (data) => {
        handleSelectTemplate(data);
        handleForcedClose();
        showWarning({
          title: "Template Created",
          content: "The template has been successfully created",
        });
      },
    });
  };

  return (
    <DialogWindow
      open={!!data}
      withCloseButton
      handleClose={handleConfirmedClose}
      title={"Create New Template"}
      content={
        <form>
          <Stack>
            <Stack mt={2} gap={3} width={"100%"}>
              <Stack gap={2}>
                <Stack alignItems={"flex-start"}>
                  <InputLabel htmlFor="name">Template Name</InputLabel>
                  <FormTextField
                    id="name"
                    name="name"
                    placeholder="Enter the template name"
                    control={control}
                    rules={{ required }}
                    InputProps={{
                      autoComplete: "off",
                    }}
                  />
                </Stack>
                <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                  Create New Template
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      }
    />
  );
};
