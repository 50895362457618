import React from "react";
import { RadioSwitcher } from "UI/RadioSwitcher";

export const VehicleApprovalSelector = ({ mode, setMode, refreshData }) => {
  const handleClickUnactiveItem = (e, value) => {
    setMode(value);
    refreshData();
  };

  return (
    <RadioSwitcher
      handleClickUnactiveItem={handleClickUnactiveItem}
      value={mode}
      options={[
        {
          value: "ACTIVE",
          label: "Active",
        },
        {
          value: "AWAITING",
          label: "Approval",
        },
        {
          value: "INACTIVE",
          label: "Rejected",
        },
      ]}
      size="small"
    />
  );
};
