import React from "react";
import { RadioSwitcher } from "UI/RadioSwitcher";

export const TableModeSelector = ({
  mode,
  setMode,
  resetCallback = () => null,
  options,
}) => {
  const handleClickUnactiveItem = (e, value) => {
    setMode(value);
    resetCallback();
    options?.find((option) => option.value === value)?.loadData();
  };

  return (
    <RadioSwitcher
      handleClickUnactiveItem={handleClickUnactiveItem}
      value={mode}
      options={options}
    />
  );
};
