import React from "react";
import { SelectedUserRow } from "./SelectedUserRow";
import { Grid, Stack, styled } from "@mui/material";
import { responsiveFontSize } from "UI/DataGrid.style";
import { customColors } from "models/customColors";
import { NoDataHolder } from "UI/DataGrid.style";
import { useSearchInList } from "hooks/useSearchInList";
import { DataGridSearch } from "UI/DataGridSearch";

const Container = styled(Stack)`
  height: 100%;

  overflow-y: auto;
  overflow-x: hidden;
`;

const Rows = styled(Stack)`
  height: 100%;

  font-size: 12px;

  overflow-y: auto;
  overflow-x: hidden;
`;

const Header = styled(Grid)`
  margin-top: 16px;
  padding: 20px 20px;
  ${responsiveFontSize}
  font-weight: bold;

  border: thin solid
    ${({ theme }) => customColors[theme.palette.mode].borderColor};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

export const SelectedUsers = ({ users, deleteUserFromChatByMattermostId }) => {
  const {
    search,
    getFilterSearchForPropArrayHandler,
    clearSearch,
    handleChangeSearch,
  } = useSearchInList();

  const sortedUserDetailsList = users
    ?.map((user) => ({
      ...user,
      phone: user?.props?.phone ?? "",
      role: user?.props?.kerico_role ?? "",
      noname: user?.first_name ? "" : user?.email ?? "",
    }))
    .filter(
      getFilterSearchForPropArrayHandler(search, [
        "first_name",
        "last_name",
        "phone",
        "role",
        "noname",
      ])
    )
    .sort((a, b) => a.email.localeCompare(b.email))
    .sort((a, b) => a.first_name.localeCompare(b.first_name));

  return (
    <Container mt={2}>
      <Stack>
        <DataGridSearch
          search={search}
          handleChangeSearch={handleChangeSearch}
          clearSearch={clearSearch}
        />
      </Stack>
      <Header container>
        <Grid item xs={6} textAlign={"left"}>
          User
        </Grid>
        <Grid item xs={4} textAlign={"left"}>
          Phone
        </Grid>
        <Grid item xs={2}></Grid>
      </Header>
      <Rows>
        {sortedUserDetailsList?.length === 0 && (
          <NoDataHolder>No users selected</NoDataHolder>
        )}
        {sortedUserDetailsList?.map((user) => (
          <SelectedUserRow
            key={user.id}
            user={user}
            deleteUserFromChatByMattermostId={deleteUserFromChatByMattermostId}
          />
        ))}
      </Rows>
    </Container>
  );
};
