import React from "react";
import { FormControl, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

/**
 * @param {{
 *  label?: string;
 *  name: string;
 *  control: any;
 *  rules?: any;
 *  defaultValue?: string;
 *  size?: "small" | "medium";
 *  [key: string]: any;
 * }} props
 */
export const FormMoneyField = ({
  label,
  name,
  control,
  rules = {},
  defaultValue = "",
  size = "small",
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: { ref, onChange, value, ...inputProps },
        fieldState: { error },
      }) => (
        <FormControl fullWidth error={!!error}>
          <TextField
            value={value}
            size={size}
            label={label}
            error={!!error}
            // @ts-ignore
            helperText={error?.message}
            inputRef={ref}
            {...inputProps}
            {...props}
            // variant={props.disabled ? "filled" : "outlined"}
            type={"number"}
            onChange={(e) => {
              if (e.target.value?.includes("e")) {
                e.target.value = e.target.value.replace("e", "");
              }
              if (e.target.value?.includes("E")) {
                e.target.value = e.target.value.replace("E", "");
              }

              const newValueParts = e.target.value.split(".");

              if (newValueParts.length > 1) {
                e.target.value = `${
                  newValueParts[0]
                }.${newValueParts[1].substring(0, 2)}`;
              }
              onChange(e);
            }}
          />
        </FormControl>
      )}
    />
  );
};
