import { requestItemStatuses } from "models/bookingRequest";
import { No, Yes } from "pages/RequestItemsPage.style";

export const WillCallField = ({ willCall, status }) => {
  if (!willCall) {
    return <No>No</No>;
  }
  if (
    willCall &&
    [
      requestItemStatuses.ACCEPTED,
      requestItemStatuses.TRANSFERRED,
      requestItemStatuses.EXPORT,
    ].includes(status)
  ) {
    return <No>Yes (done)</No>;
  }
  return <Yes>Yes</Yes>;
};
