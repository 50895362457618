import { httpLoadAvatarService } from "./httpLoadAvatarService";
import { httpService, convertToQueryString } from "./httpService";

/**
 * @template T
 * @typedef {import('types/commonTypes').HttpResult<T>} HttpResult
 */

/**
 * @template T
 * @typedef {import('types/commonTypes').TableResponse<T>} TableResponse
 */

/**
 * @typedef {import('types/commonTypes').Chat} Chat
 * @param {import('types/commonTypes').GetAllChatsQueryParams} queryParams
 * @returns  {HttpResult<Chat[]>}
 */
export const getChatList = async (queryParams) =>
  httpService({
    url: `/channels/${convertToQueryString(queryParams)}`,
    options: {
      method: "GET",
    },
    mattermost: true,
  });

/**
 * @param {string} userId
 * @returns  {HttpResult<Chat[]>}
 */
export const getChatListByUserId = async (userId) =>
  httpService({
    url: `/users/${userId}/teams/${process.env.REACT_APP_MATTERMOST_TEAM_ID}/channels`,
    options: {
      method: "GET",
    },
    mattermost: true,
  });

/**
 * @param {import('types/commonTypes').CreateNewChatRequest} data
 * @returns  {HttpResult<Chat>}
 */
export const createNewChat = async (data) =>
  httpService({
    url: `/channels`,
    options: {
      method: "POST",
      data,
    },
    mattermost: true,
  });

/**
 * @param {{ data: import('types/commonTypes').UpdateChatRequest, id: string; }} props
 * @returns  {HttpResult<Chat>}
 */
export const updateChat = async ({ data, id }) =>
  httpService({
    url: `/channels/${id}/patch`,
    options: {
      method: "PUT",
      data,
    },
    mattermost: true,
  });

/**
 * @param {string} id
 * @returns  {HttpResult<import("types/commonTypes").ChatDeleteResponse>}
 */
export const deleteChat = async (id) =>
  httpService({
    // x4k8br3catr6pnwcfysmu5fe3o
    url: `/channels/${id}/?permanent=true`,
    //url: `/channels/x4k8br3catr6pnwcfysmu5fe3o/?permanent=true`,

    options: {
      method: "DELETE",
    },
    mattermost: true,
  });

/**
 * @param {{ chatId: string; userId: string; }} props
 * @returns  {HttpResult<string>}
 */
export const deleteUserFromChat = async ({ chatId, userId }) =>
  httpService({
    url: `/channels/${chatId}/members/${userId}`,
    options: {
      method: "DELETE",
    },
    mattermost: true,
  });

/**
 * @param {{ chatId: string; userId: string; postUserId?: string; }} props
 * @returns  {HttpResult<string>}
 */
export const addUserToChat = async ({ chatId, userId, postUserId }) =>
  httpService({
    url: `/channels/${chatId}/members`,
    options: {
      method: "POST",
      data: {
        user_id: userId,
        post_user_id: postUserId,
      },
    },
    mattermost: true,
  });

/**
 * @param {string} chatId
 * @returns  {HttpResult<import("types/commonTypes").ChatUser[]>}
 */
export const getChatUsers = async (chatId) =>
  httpService({
    url: `/channels/${chatId}/members`,
    options: {
      method: "GET",
    },
    mattermost: true,
  });

/**
 * @param {{ chatId: string;  queryParams: import('types/commonTypes').GetPostsPageQueryParams }} props
 * @returns  {HttpResult<import("types/commonTypes").Page>}
 */
export const getChatPostPage = async ({ chatId, queryParams }) =>
  httpService({
    url: `/channels/${chatId}/posts/${convertToQueryString(queryParams)}`,
    options: {
      method: "GET",
    },
    mattermost: true,
  });

/**
 * @param {import('types/commonTypes').TableRequest} data
 * @returns  {HttpResult<TableResponse<import("types/commonTypes").MattermostUser>>}
 */
export const getMattermostUsers = async (data) =>
  httpService({
    url: "/users/search",
    options: {
      method: "POST",
      data,
    },
    mattermost: true,
  });

/**
 * @param {string[]} ids
 * @returns  {HttpResult<import("types/commonTypes").MattermostUserDetails[]>}
 */
export const getMattermostUserDetailssByIds = async (ids) =>
  httpService({
    url: `/users/ids`,
    options: {
      method: "POST",
      data: ids,
    },
    mattermost: true,
  });

/**
 * @param {{ channel_id: string; message: string; }} data
 * @returns  {HttpResult<import("types/commonTypes").Post>}
 */
export const createMessageInChat = async (data) =>
  httpService({
    url: `/posts/?set_online=true`,
    options: {
      method: "POST",
      data,
    },
    mattermost: true,
  });

/**
 * @param {string} id
 * @returns  {Promise<{ blob: Blob; contentType: string; } | { error: string; }>}
 */
export const getAvatarByMattermostId = async (id) => httpLoadAvatarService(id);

/**
 * @param {string[]} data
 * @returns  {HttpResult<Chat>}
 */
export const createDirectChannel = async (data) =>
  httpService({
    url: `/channels/direct`,
    options: {
      method: "POST",
      data,
    },
    mattermost: true,
  });

/**
 * @param {string} chatName
 * @returns  {HttpResult<Chat>}
 */
export const getChatByName = async (chatName) =>
  httpService({
    url: `/teams/${process.env.REACT_APP_MATTERMOST_TEAM_ID}/channels/name/${chatName}/?include_deleted=true`,
    options: {
      method: "GET",
    },
    mattermost: true,
  });

/**
 * @param {string} chatName
 * @returns  {HttpResult<Chat[]>}
 */
export const searchChatByName = async (chatName) =>
  httpService({
    url: `/teams/${process.env.REACT_APP_MATTERMOST_TEAM_ID}/channels/search`,
    options: {
      method: "POST",
      data: {
        term: chatName,
      },
    },
    mattermost: true,
  });
