import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { Button, InputLabel, Stack } from "@mui/material";
import { DialogWindow, FormTextField } from "UI";
import { getChatNameValidation } from "models/validations";
import { useFormDataSync } from "hooks/useFormDataSync";
import { GlobalContext } from "store/globalContext";
import { useApiRequest } from "api/useApiRequest";
import { updateChat } from "api/messageApi";
import { ReactComponent as DoneIcon } from "assets/img/booking-scheduled-icon.svg";
import { useAppSelector } from "store/hooks";
import { createNameFromLabel } from "utils";

export const EditChatDialog = ({ chat, handleClose, refreshList, chats }) => {
  const spinner = useAppSelector((state) => state.spinner);
  const { showWarning } = useContext(GlobalContext);
  const { callApiRequest } = useApiRequest();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    values: chat,
  });

  const { handleConfirmedClose, handleForcedClose } = useFormDataSync({
    isDirty,
    handleClose,
    open: !!chat,
    reset,
  });

  const onSubmit = (data) => {
    const { display_name } = data;

    const newData = {
      ...data,
      name: createNameFromLabel(display_name),
    };

    callApiRequest({
      apiRequest: updateChat,
      params: {
        data: {
          display_name: newData.display_name,
        },
        id: chat?.id,
      },
      onSuccess: (result) => {
        refreshList();
        handleForcedClose();
        showWarning({
          title: `Chat Updated`,
          content: `The "${result?.display_name}" chat has been successfully updated`,
          icon: <DoneIcon />,
        });
      },
    });
  };

  if (!chat) return null;

  return (
    <DialogWindow
      open={true}
      withCloseButton
      handleClose={handleConfirmedClose}
      title={`Edit Chat Name`}
      content={
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={5}>
            <Stack alignItems={"flex-start"}>
              <InputLabel htmlFor="display_name">Chat Name</InputLabel>
              <FormTextField
                id="display_name"
                name="display_name"
                control={control}
                rules={getChatNameValidation(chats)}
                InputProps={{
                  autoComplete: "off",
                }}
              />
            </Stack>
            <Button
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={!isDirty || spinner}
            >
              Save Changes
            </Button>
          </Stack>
        </form>
      }
    />
  );
};
