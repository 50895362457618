import React from "react";
import { IconButton } from "@mui/material";
import { DataGridHeader } from "./DataGridHeader";
import { DataGridPagination } from "./DataGridPagination";
import { DataGridRecords } from "./DataGridRecords";
import { DataGridSearch } from "./DataGridSearch";
import { Stack, styled } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { customColors } from "models/customColors";

const Container = styled(Stack)`
  flex: 1 1 auto;
  height: 100%;
  overflow: hidden;

  justify-content: space-between;
  align-items: stretch;
`;

export const RefreshButton = styled(IconButton)`
  flex: 0 0 auto;

  border: thin solid
    ${({ theme }) => customColors[theme.palette.mode].borderColor};
  border-radius: 6px;
`;

export const DataGrid = ({
  columns,
  handleRowClick,
  search,
  handleChangeSearch,
  clearSearch,
  limit,
  currentPage,
  totalPages,
  loadData,
  handleChangeRecordsPerPage,
  handleChangePage,
  sorter,
  handleSort,
  rows,
  totalCount,
  visibility,
  setVisibility,
  defaultLimit,
  selectedId = "",
  actions = null,
  actionsAfter = null,
  keyName = "id",
  selectedColor = null,
  tableLabel = null,
  noSearch = false,
  noRefresh = false,
  withSelector,
  selectedRows,
  setSelectedRows,
}) => {
  return (
    <Container>
      {!noSearch && (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={1}
          mb={"20px"}
          flexWrap={"wrap"}
        >
          {actions}
          <Stack flex={1} direction={"row"} gap={1}>
            {!noRefresh && (
              <RefreshButton onClick={loadData}>
                <RefreshIcon />
              </RefreshButton>
            )}
            <DataGridSearch
              search={search}
              handleChangeSearch={handleChangeSearch}
              clearSearch={clearSearch}
            />
          </Stack>
          {actionsAfter}
        </Stack>
      )}
      {tableLabel}
      <DataGridHeader
        columns={columns}
        rows={rows}
        sorter={sorter}
        handleSort={handleSort}
        visibility={visibility}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        withSelector={withSelector}
        keyName={keyName}
      />
      <DataGridRecords
        columns={columns}
        rows={rows}
        handleRowClick={handleRowClick}
        visibility={visibility}
        selectedId={selectedId}
        keyName={keyName}
        selectedColor={selectedColor}
        withSelector={withSelector}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
      <DataGridPagination
        currentPage={currentPage}
        handleChangePage={handleChangePage}
        defaultLimit={defaultLimit}
        limit={limit}
        handleChangeRecordsPerPage={handleChangeRecordsPerPage}
        totalPages={totalPages}
        count={totalCount}
        selectedRows={selectedRows}
      />
    </Container>
  );
};
