import React, { useEffect, useState } from "react";
import { Stack, styled, useTheme } from "@mui/material";
import { useApiRequest } from "api/useApiRequest";
import { customColors } from "models/customColors";
import { getInvoicesStatisticByCompanyId } from "api/invoiceApi";
import { INVOICE_INDICATORS_UPDATE_PERIOD } from "models/constants";

/** @type {import("types/commonTypes").InvoiceStatistics | null} */
const defaultData = {
  newCount: 0,
  paidCount: 0,
  overdueCount: 0,
};

const IndicatorHolder = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 3px;

  height: 36px;
  padding: 4px 8px;

  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;

  border-radius: 12px;
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].tableBackground};

  cursor: pointer;
  user-select: none;
`;

const Indicator = styled(Stack)`
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;

  border-radius: 50%;
  color: ${({ theme }) => customColors[theme.palette.mode].white};
`;

export const CompanyInvoicesIndicators = ({
  setSearch,
  companyId,
  reloadSignal,
}) => {
  const theme = useTheme();
  const { callApiRequest } = useApiRequest();

  const [data, setData] = useState(defaultData);

  const loadData = async () =>
    callApiRequest({
      apiRequest: getInvoicesStatisticByCompanyId,
      params: companyId,
      onSuccess: (result) => setData(result),
      skipErrorHandling: true,
    });

  useEffect(() => {
    const interval = setInterval(() => {
      loadData();
    }, INVOICE_INDICATORS_UPDATE_PERIOD);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadSignal]);

  return (
    <Stack direction={"row"} gap={1}>
      <IndicatorHolder onClick={() => setSearch("New")}>
        New:{" "}
        <Indicator
          sx={{ backgroundColor: customColors[theme.palette.mode].enRoute }}
        >
          {data?.newCount}
        </Indicator>
      </IndicatorHolder>
      <IndicatorHolder onClick={() => setSearch("Overdue")}>
        Overdue:{" "}
        <Indicator
          sx={{ backgroundColor: customColors[theme.palette.mode].scheduled }}
        >
          {data?.overdueCount}
        </Indicator>
      </IndicatorHolder>
      <IndicatorHolder onClick={() => setSearch("Paid")}>Paid</IndicatorHolder>
    </Stack>
  );
};
