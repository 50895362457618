import React from "react";
import { Stack, styled } from "@mui/material";
import { customColors } from "models/customColors";

export const Avatar = styled(Stack)`
  flex: 0 0 auto;

  justify-content: center;
  align-items: center;

  border-radius: 50%;
  color: ${({ theme }) => customColors[theme.palette.mode].background};
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].actionColor};
`;

export const DefaultAvatar = ({ user, radius = "47px" }) => {
  const initials = user?.firstName ? (
    <>
      {user?.firstName?.slice(0, 1)} {user?.lastName?.slice(0, 1)}
    </>
  ) : (
    <>
      {user?.role
        ?.split("_")
        .map((name) => name.slice(0, 1))
        .join("")}
    </>
  );
  return <Avatar sx={{ width: radius, height: radius }}>{initials}</Avatar>;
};
