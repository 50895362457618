import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Stack, Button, styled } from "@mui/material";
import * as SC from "SC";
import { customColors } from "models/customColors";
import { useApiRequest } from "api/useApiRequest";
import { sendResetPasswordLink } from "api/authApi";
import { ModalSpinner } from "components/ModalSpinner";
import { ErrorDialog } from "components/ErrorDialog";
import { restoreSessionByCode } from "store/reducers/userReducer";
import { showSpinner } from "store/reducers/spinnerReducer";

const codePlaces = [...Array(6)].map((_, i) => String(i));
const codeDigits = [...Array(10)].map((_, i) => String(i));

const Digit = styled(Stack)`
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  font-size: 1.2rem;
  color: ${customColors.light.text};
  background-color: ${customColors.light.menu.selected};
  border-radius: 8px;
  user-select: none;
`;

export const ConfirmMfaCodePage = () => {
  const { callApiRequest } = useApiRequest();
  const dispatch = useAppDispatch();
  const spinner = useAppSelector((state) => state.spinner);
  const user = useAppSelector((state) => state.user);
  const [code, setCode] = useState("");

  const resendCode = () => {
    callApiRequest({
      apiRequest: sendResetPasswordLink,
      params: {
        email: user.email ?? user.login,
      },
    });
    setCode("");
  };

  useEffect(() => {
    const onKeyDown = (e) => {
      const key = e?.key;
      if (key === "Backspace" || key === "ArrowLeft") {
        setCode(code.slice(0, -1));
        return;
      }
      if (key === "Escape") {
        setCode("");
        return;
      }
      if (codeDigits.includes(key)) {
        setCode(`${code}${key}`.slice(0, codePlaces.length));
      }
    };

    window.addEventListener("keydown", onKeyDown);

    if (code.length === codePlaces.length) {
      dispatch(showSpinner());
      dispatch(restoreSessionByCode(code));
    }

    return () => window.removeEventListener("keydown", onKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
      height={"80%"}
    >
      <SC.AuthFormContainer>
        <Stack gap={2}>
          <Stack justifyContent={"center"}>
            <SC.AuthTitle sx={SC.AuthTitleSx}>Confirmation Code</SC.AuthTitle>
            <SC.PlainText
              sx={{
                maxWidth: "381px",
                textAlign: "center",
                fontSize: "1.065rem",
              }}
            >
              We sent a verification code to your email
            </SC.PlainText>
          </Stack>
          <Stack gap={4}>
            <Stack alignItems={"flex-start"}>
              <SC.InputLabel htmlFor="code">Enter Code</SC.InputLabel>
              <Stack direction={"row"} gap={2}>
                {codePlaces.map((value, index) => (
                  <Digit key={`char${index}`}>{code.at(index) ?? "_"}</Digit>
                ))}
              </Stack>
            </Stack>
            <SC.PlainText
              sx={{
                maxWidth: "381px",
                textAlign: "center",
                fontSize: "0.875rem",
              }}
            >
              Didn't get the code?
              <br />
              <Button size={"small"} onClick={() => resendCode()}>
                Resend
              </Button>
            </SC.PlainText>
          </Stack>
        </Stack>
      </SC.AuthFormContainer>
      <ModalSpinner open={spinner} />
      <ErrorDialog />
    </Stack>
  );
};
