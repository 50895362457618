import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { Button, InputLabel, Stack } from "@mui/material";
import { DialogWindow } from "UI/DialogWindow";
import { FormTextField } from "UI/FormTextField";
import { required } from "models/validations";
import { useApiRequest } from "api/useApiRequest";
import { useFormDataSync } from "hooks/useFormDataSync";
import LocationSelector from "./LocationSelector";
import { addClientLocation } from "api/geoApi";
import { removeEmptyProps } from "utils";
import { GlobalContext } from "store/globalContext";
import { ReactComponent as UserIcon } from "assets/img/user-small-icon.svg";

const defaultFormData = {
  companyId: "",
  name: "",
  placeId: "",
  awsId: "",
};

export const AddClientLocationDialog = ({
  open,
  handleClose,
  refsreshData,
  clientId,
}) => {
  const { showWarning } = useContext(GlobalContext);
  const { callApiRequest } = useApiRequest();

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: defaultFormData,
  });

  const { handleConfirmedClose, handleForcedClose } = useFormDataSync({
    isDirty,
    handleClose,
    open,
    reset,
  });

  const onSubmit = async () => {
    const data = getValues();
    data.companyId = clientId;

    const params = removeEmptyProps(data);

    callApiRequest({
      apiRequest: addClientLocation,
      params,
      onSuccess: () => {
        showWarning({
          title: "Location Added",
          content: "The Location has been successfully added",
          icon: <UserIcon />,
        });
        refsreshData();
        handleForcedClose();
      },
    });
  };

  const handleLocationSelect = (location) => {
    if (location === null) {
      // reset location
      setValue("awsId", "", { shouldDirty: false });
      setValue("placeId", "", { shouldDirty: false });
    } else {
      // set new location
      setValue("awsId", location?.awsId ?? "", {
        shouldDirty: !!location?.awsId,
      });
      setValue("placeId", location?.id ?? "", {
        shouldDirty: !!location?.id,
      });
    }
  };

  return (
    <DialogWindow
      open={open}
      withCloseButton
      handleClose={handleConfirmedClose}
      title={"Add New Location"}
      content={
        <form>
          <Stack sx={{ minWidth: "460px" }}>
            <Stack mt={2} gap={3} width={"100%"}>
              <Stack gap={2}>
                <Stack alignItems={"flex-start"}>
                  <InputLabel htmlFor="location">
                    <Stack direction={"row"} gap={1}>
                      Location Address
                    </Stack>
                  </InputLabel>
                  <LocationSelector
                    id="location"
                    setValue={handleLocationSelect}
                    sx={{ width: "100%" }}
                  />
                </Stack>
                <Stack alignItems={"flex-start"}>
                  <InputLabel htmlFor="name">Location Name</InputLabel>
                  <FormTextField
                    id="name"
                    name="name"
                    control={control}
                    rules={{ required }}
                    InputProps={{
                      autoComplete: "off",
                    }}
                  />
                </Stack>
                <Stack direction={"row"} justifyContent={"flex-start"} gap={1}>
                  <Button variant="outlined" onClick={handleConfirmedClose}>
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                    Add New Location
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </form>
      }
    />
  );
};
