import { Stack, Typography, styled } from "@mui/material";
import { customColors } from "models/customColors";
import React from "react";

const Container = styled(Stack)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;

  margin: 1rem;

  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  text-align: left;

  color: ${({ theme }) => customColors[theme.palette.mode].greyText};
`;

export const ActionColoredText = styled(Typography)`
  color: ${({ theme }) => customColors[theme.palette.mode].actionColor};
`;

export const RequiredFieldsLegend = () => (
  <Container>
    <ActionColoredText>*</ActionColoredText> These fields are optional; the
    client can fill them in during registration
  </Container>
);
