import { getRidesFromDateRange } from "api/rideApi";
import { useApiRequest } from "api/useApiRequest";
import { viewModes } from "components/BookingsViewModeSelector";
import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";
import { getWeekRangeByDateFromSunday, returnShiftedDateByDays } from "utils";
import { useDataReload } from "./useDataReload";

const daysOfWeek = new Array(7).fill(0).map((_, index) => index);

export const useCalendarView = ({ dataGrid, viewModeOptions }) => {
  const { callApiRequest } = useApiRequest();
  const mode = useAppSelector((state) => state.layout.bookingViewMode ?? 0);

  const [viewOffset, setViewOffset] = useState(0);
  const [rides, setRides] = useState([]);

  const showPrevWeek = () => setViewOffset((prev) => prev - 1);
  const showNextWeek = () => setViewOffset((prev) => prev + 1);
  const showCurrentWeek = () => setViewOffset(0);

  const [startDate, endDate] = getWeekRangeByDateFromSunday(
    returnShiftedDateByDays(new Date(), viewOffset * 7)
  );

  const loadData = useCallback(
    async () => {
      const [startDate, endDate] = getWeekRangeByDateFromSunday(
        returnShiftedDateByDays(new Date(), viewOffset * 7)
      );

      const dayFrom = startDate?.getTime();
      const dayTo = endDate?.getTime();

      callApiRequest({
        apiRequest: getRidesFromDateRange,
        params: {
          search: dataGrid.search,
          dayFrom,
          dayTo,
        },
        onSuccess: (data) => {
          setRides(data); 
        },
        onError: () => {
          setRides([]);
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataGrid.search, viewOffset, mode]
  );

  const updatedViewModeOptions = viewModeOptions.map((option, index) =>
    index === viewModes.CALENDAR
      ? {
          ...option,
          handleRefresh: () => {
            loadData();
          },
        }
      : { ...option }
  );

  useDataReload({
    loadData,
    skipFirstLoad: true,
    reloadIntervalInSeconds: 60,
  });

  useEffect(() => {
    let timeout;

    if (mode === viewModes.CALENDAR) {
      setRides([]);

      if (dataGrid.search) {
        timeout = setTimeout(() => {
          loadData();
        }, 500);
      } else {
        loadData();
      }
    }

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  return {
    showPrevWeek,
    showNextWeek,
    showCurrentWeek,
    startDate,
    endDate,
    rides,
    daysOfWeek,
    updatedViewModeOptions,
  };
};
