import React from "react";
import { Box, Stack, styled } from "@mui/material";
import { customColors } from "models/customColors";
import * as FP from "utils/fp-js";
import { convertToReadable } from "utils";
import { TextFieldWithEllipsis } from "components/TextFiledWithEllipsis";
import { BackgroundCheckDriver } from "components/BackgroundCheckDriver";

export const Content = styled(Stack)`
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 10px;

  height: 100%;

  overflow-y: auto;
  overflow-x: hidden;
`;

const OffShiftLabel = styled(Box)`
  color: ${({ theme }) => customColors[theme.palette.mode].greyText};
`;

const OnShiftLabel = styled(Box)`
  font-family: "Inter Medium";
  color: ${({ theme }) => customColors[theme.palette.mode].success};
`;

const Approval = styled(Box)`
  font-family: "Inter Medium";
  color: ${({ theme }) => customColors[theme.palette.mode].error};
`;
const Active = styled(Box)`
  font-family: "Inter Medium";
  color: ${({ theme }) => customColors[theme.palette.mode].success};
`;
const Blocked = styled(Box)`
  font-family: "Inter Medium";
  color: ${({ theme }) => customColors[theme.palette.mode].error};
`;

const Rejected = styled(Box)`
  font-family: "Inter Medium";
  color: ${({ theme }) => customColors[theme.palette.mode].delayed};
`;

const Unactive = styled(Box)`
  width: 16px;
  height: 16px;
  border: 1px solid
    ${({ theme }) => customColors[theme.palette.mode].placeHolder};
  border-radius: 16px;
`;

const ActiveSelected = styled(Unactive)`
  background-color: ${({ theme }) => customColors[theme.palette.mode].actionColor};
  border-color: transparent;
`;

const ApprovalSelected = styled(ActiveSelected)`
  background-color: ${({ theme }) => customColors[theme.palette.mode].error};
`;

export const Selector = ({ selectedUserId, row }) => (
  <Stack justifyContent={"center"} alignItems={"center"} width={"100%"}>
    {selectedUserId === row?.userId
      ? FP.match(row?.driverWorkStatus)
          .on("ACTIVE", <ActiveSelected />)
          .on("APPROVAL", <ApprovalSelected />)
          .otherwise(<ActiveSelected />)
      : ""}
  </Stack>
);

export const workStatus = ({ driverWorkStatus }) =>
  FP.match(driverWorkStatus)
    .on(
      "On_Shift",
      <OnShiftLabel>{convertToReadable(driverWorkStatus)}</OnShiftLabel>
    )
    .otherwise(
      <OffShiftLabel>{convertToReadable(driverWorkStatus)}</OffShiftLabel>
    );

export const accountStatus = ({ userAccountStatus }) =>
  FP.match(userAccountStatus)
    .on("ACTIVE", <Active>{userAccountStatus}</Active>)
    .on("REJECTED", <Rejected>{userAccountStatus}</Rejected>)
    .on("BLOCKED", <Blocked>{userAccountStatus}</Blocked>)
    .on("APPROVAL", <Approval>{userAccountStatus}</Approval>)
    .otherwise(
      <OffShiftLabel>{convertToReadable(userAccountStatus)}</OffShiftLabel>
    );

export const workStatusColumns = [
  {
    label: "Driver",
    field: "userFullName",
    xs: 2,
    sm: 2,
    md: 2,
    sortableName: "FULL_NAME",
  },
  {
    label: "Region",
    field: "geofenceLabel",
    xs: 3,
    sm: 3,
    md: 3,
    sortableName: "REGION",
    renderer: ({ geofenceLabel }) => (
      <TextFieldWithEllipsis>{geofenceLabel}</TextFieldWithEllipsis>
    ),
  },
  {
    label: "Phone Number",
    field: "userPhone",
    xs: 2,
    sm: 2,
    md: 2,
    sortableName: "PHONE",
  },
  {
    label: "Car",
    field: "carLabel",
    xs: 3.5,
    sm: 3.5,
    md: 3.5,
    sortableName: "CAR",
    renderer: ({ carLabel }) => (
      <TextFieldWithEllipsis>{carLabel}</TextFieldWithEllipsis>
    ),
  },
  {
    label: "Work Status",
    field: "driverWorkStatus",
    xs: 1.5,
    sm: 1.5,
    md: 1.5,
    sortableName: "STATUS",
    align: "center",
    renderer: workStatus,
  },
];

export const accountStatusColumns = [
  {
    label: "Driver",
    field: "userFullName",
    xs: 2.5,
    sm: 2.5,
    md: 2.5,
    sortableName: "FULL_NAME",
  },
  {
    label: "Region",
    field: "geofenceLabel",
    xs: 1.5,
    sm: 1.5,
    md: 1.5,
    sortableName: "REGION",
    renderer: ({ geofenceLabel }) => (
      <TextFieldWithEllipsis>{geofenceLabel}</TextFieldWithEllipsis>
    ),
  },
  {
    label: "Phone Number",
    field: "userPhone",
    xs: 1.75,
    sm: 1.75,
    md: 1.75,
    sortableName: "PHONE",
  },
  {
    label: "Car",
    field: "carLabel",
    xs: 3,
    sm: 3,
    md: 3,
    sortableName: "CAR",
    renderer: ({ carLabel }) => (
      <TextFieldWithEllipsis>{carLabel}</TextFieldWithEllipsis>
    ),
  },
  {
    label: "Background Check",
    xs: 1.75,
    sm: 1.75,
    md: 1.75,
    align: "center",
    renderer: ({ userId }) => <BackgroundCheckDriver id={userId} />,
  },
  {
    label: "Account Status",
    field: "userAccountStatus",
    xs: 1.5,
    sm: 1.5,
    md: 1.5,
    align: "center",
    sortableName: "USER_ACCOUNT_STATUS",
    renderer: accountStatus,
  },
];

export const getShortWorkStatusColumns = (selectedUserId) => [
  {
    label: "Driver",
    field: "userFullName",
    xs: 6,
    sm: 6,
    md: 6,
    sortableName: "FULL_NAME",
  },
  {
    label: "Work Status",
    field: "driverWorkStatus",
    xs: 4,
    sm: 4,
    md: 4,
    sortableName: "STATUS",
  },
  {
    xs: 2,
    sm: 2,
    md: 2,
    renderer: (row) => <Selector selectedUserId={selectedUserId} row={row} />,
  },
];

export const getShortAccountStatusColumns = (selectedUserId) => [
  {
    label: "Driver",
    field: "userFullName",
    xs: 4,
    sm: 4,
    md: 4,
    sortableName: "FULL_NAME",
  },
  {
    label: "Account Status",
    field: "userAccountStatus",
    xs: 3,
    sm: 3,
    md: 3,
    sortableName: "USER_ACCOUNT_STATUS",
    align: "center",
    renderer: accountStatus,
  },
  {
    label: "Background Check",
    xs: 4,
    sm: 4,
    md: 4,
    align: "center",
    renderer: ({ userId }) => <BackgroundCheckDriver id={userId} />,
  },
  {
    xs: 1,
    sm: 1,
    md: 1,
    renderer: (row) => <Selector selectedUserId={selectedUserId} row={row} />,
  },
];
