import React, { useRef } from "react";
import { useAppSelector } from "store/hooks";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  getPasswordConfirmValidation,
  passwordValidation,
} from "models/validations";
import { Button, Stack, Divider, Box, IconButton } from "@mui/material";
import { usePasswordChangePage } from "./PasswordChangePage.hook";
import * as UI from "UI";
import * as SC from "SC";

export const PasswordChangePage = () => {
  const spinner = useAppSelector((state) => state.spinner);
  const {
    watch,
    control,
    isPasswordVisible,
    onSubmit,
    setIsPasswordVisible,
    newToken,
  } = usePasswordChangePage();

  const password = useRef("");
  password.current = watch("password", "");

  if (!newToken) return null;

  return (
    <Stack justifyContent={"flex-start"} alignItems={"center"} height={"100%"}>
      <Stack gap={2}>
        <SC.AuthTitle sx={SC.AuthTitleSx}>Create Password</SC.AuthTitle>
        <SC.PlainText sx={{ textAlign: "center" }}>
          Please create a password for your account
        </SC.PlainText>
        <Divider />
        <form onSubmit={onSubmit} className="mui-form">
          <Stack gap={2}>
            <Stack alignItems={"flex-start"}>
              <SC.InputLabel htmlFor="password">
                {"Create Password"}
              </SC.InputLabel>
              <UI.FormTextField
                id="password"
                name="password"
                control={control}
                rules={passwordValidation}
                type={isPasswordVisible ? "text" : "password"}
                inputProps={{
                  autoComplete: "new-password",
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      color="info"
                      onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    >
                      {isPasswordVisible ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  ),
                  autoComplete: "new-password",
                }}
              />
            </Stack>
            <Stack alignItems={"flex-start"}>
              <SC.InputLabel htmlFor="password_repeat">
                {"Confirm Password"}
              </SC.InputLabel>
              <UI.FormTextField
                id="password_repeat"
                name="password_repeat"
                control={control}
                rules={getPasswordConfirmValidation(password.current)}
                type={isPasswordVisible ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      color="info"
                      onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    >
                      {isPasswordVisible ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  ),
                  autoComplete: "new-password",
                }}
              />
            </Stack>
            <Button type="submit" variant="contained" disabled={!!spinner}>
              Continue
            </Button>
          </Stack>
        </form>
      </Stack>
      <Box sx={{ height: "25vh" }}></Box>
    </Stack>
  );
};
