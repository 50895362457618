import { getRides } from "api/rideApi";
import { viewModes } from "components/BookingsViewModeSelector";
import { useDataGrid } from "hooks/useDataGrid";
import { useNavigationData } from "hooks/useNavigationData";
import { useAppSelector } from "store/hooks";

export const useTableView = ({
  viewModeOptions,
}) => {
  const userId = useAppSelector((state) => state.user.id);

  const dataGrid = useDataGrid({
    apiHandler: getRides,
    defaultSorter: {
      name: "STATUS",
      order: "DESC",
    },
    defaultLimit: 25,
    gridConfigIndex: `${userId}-bookings`,
    reloadIntervalInSeconds: 30,
  });

  useNavigationData({
    setSearch: dataGrid.setSearch,
  });

  const updatedViewModeOptions = viewModeOptions.map((option, index) =>
    index === viewModes.TABLE
      ? {
          ...option,
          handleRefresh: () => {
            dataGrid.loadData();
          },
        }
      : { ...option }
  );

  return {
    dataGrid,
    updatedViewModeOptions,
  };
};
