import React, { useEffect, useState } from "react";
import { ReactComponent as UserIcon } from "assets/img/messages-icon.svg";
import { Box, Stack, styled } from "@mui/material";
import { customColors } from "models/customColors";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  closeChatList,
  loadChats,
  openChatList,
  resetChats,
  setPersistedUserId,
} from "store/reducers/chatsReducer";
import { useDataReload } from "hooks/useDataReload";
import { CHAT_INFO_RELOAD_INTERVAL_IN_SECONDS } from "models/constants";
import { ChatList } from "./ChatList";
import { ChatMessages } from "./ChatMessages";

const Container = styled(Box)`
  margin: 0 10px;
  position: relative;

  cursor: pointer;
`;

const Indicator = styled(Stack)`
  position: absolute;
  top: 15px;
  left: 10px;

  flex: 0 0 auto;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;

  border-radius: 50%;
  color: ${({ theme }) => customColors[theme.palette.mode].white};
  background-color: ${({ theme }) => customColors[theme.palette.mode].new};
  cursor: pointer;
`;

export const MessagesIndicator = () => {
  const isListVisible = useAppSelector((state) => state.chats.isListVisible);
  const persistedUserId = useAppSelector(
    (state) => state.chats.persistedUserId
  );
  const mattermostUserId = useAppSelector(
    (state) => state.user.mattermostUserId
  );
  const newChats = useAppSelector((state) => state.chats.new);
  const prevChats = useAppSelector(
    (state) => state.chats.prev[state.chats.persistedUserId]
  );
  const selectedChat = useAppSelector((state) => state.chats.selected);

  const dispatch = useAppDispatch();
  const [reloadIntervalInSeconds, setReloadIntervalInSeconds] = useState(0);

  const handleClick = () => {
    dispatch(loadChats(mattermostUserId));
    if (isListVisible) {
      dispatch(closeChatList());
    } else {
      dispatch(openChatList());
    }
  };

  useEffect(() => {
    if (!mattermostUserId) return;

    if (mattermostUserId && mattermostUserId !== persistedUserId) {
      dispatch(resetChats());
      dispatch(setPersistedUserId(mattermostUserId));
      return;
    }

    dispatch(loadChats(mattermostUserId));
    setReloadIntervalInSeconds(CHAT_INFO_RELOAD_INTERVAL_IN_SECONDS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mattermostUserId, persistedUserId]);

  const newMessagesCount =
    newChats?.reduce((acc, { id, total_msg_count }) => {
      const prevChat = prevChats?.find((chat) => chat.id === id);

      if (prevChat?.total_msg_count <= total_msg_count) {
        return acc + (total_msg_count - prevChat.total_msg_count);
      }

      return acc + total_msg_count;
    }, 0) ?? 0;

  useDataReload({
    loadData: () => dispatch(loadChats(mattermostUserId)),
    skipFirstLoad: true,
    reloadIntervalInSeconds,
  });

  return (
    <>
      <Container onClick={handleClick}>
        <UserIcon />
        {!!newMessagesCount && <Indicator>{newMessagesCount}</Indicator>}
      </Container>
      {isListVisible && <ChatList newChats={newChats} />}
      {!isListVisible && !!selectedChat?.id && (
        <ChatMessages id={selectedChat.id} />
      )}
    </>
  );
};
