import React from "react";
import { FormControl, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { getOptionsForSelect } from "utils/getOptionsForSelect";

/**
 * @param {{
 *  label?: string;
 *  name: string;
 *  control: any;
 *  rules?: any;
 *  size?: "small" | "medium";
 *  withNone?: boolean;
 *  [key: string]: any;
 * }} props
 */
export const FormSelectField = ({
  label,
  name,
  control,
  rules = {},
  size = "small",
  options,
  withNone = false,
  doNotSortOptions = false,
  noneLabel = undefined,
  ...props
}) => {
  const optionSet = Array.isArray(options)
    ? options.reduce(
        (acc, { value, label }) => ({ ...acc, [value]: label }),
        {}
      )
    : options;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { ref, value, ...inputProps },
        fieldState: { error },
      }) => (
        <FormControl fullWidth error={!!error}>
          <TextField
            select
            value={value ?? ""}
            size={size}
            label={label}
            error={!!error}
            helperText={error?.message}
            inputRef={ref}
            {...inputProps}
            {...props}
            InputProps={{
              sx: {
                textAlign: "left",
              },
            }}
          >
            {getOptionsForSelect(optionSet, withNone, doNotSortOptions, noneLabel)}
          </TextField>
        </FormControl>
      )}
    />
  );
};
