import { httpService } from "./httpService";
import * as FP from "utils/fp-js";

/**
 * @template T
 * @typedef {import('types/commonTypes').HttpResult<T>} HttpResult
 */
/**
 * @template T
 * @typedef {import('types/commonTypes').TableResponse<T>} TableResponse
 */

/**
 * @typedef {import('types/commonTypes').UsersResponse} UsersResponse
 * @param {import('types/commonTypes').TableRequest} data
 * @returns  {HttpResult<UsersResponse>}
 */
export const getKericoUsers = async (data) =>
  httpService({
    url: "/users/kerico/search",
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @param {import("types/commonTypes").NewUserDto} data
 * @returns  {HttpResult<import("types/commonTypes").UserDto>}
 */
export const addKericoUser = async (data) =>
  httpService({
    url: `/users/kerico`,
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @param {{ id: string; data: import("types/commonTypes").UserDto}} props
 * @returns  {HttpResult<import("types/commonTypes").UserDto>}
 */
export const updateKericoUser = async ({ id, data }) =>
  httpService({
    url: `/users/kerico/${id}`,
    options: {
      method: "PUT",
      data,
    },
  });

/**
 * @param {import("types/commonTypes").NewClientUserDto} data
 * @returns  {HttpResult<import("types/commonTypes").UserDto>}
 */
export const addClientUser = async (data) =>
  httpService({
    url: `/customers/users`,
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @param {string} id
 * @returns  {HttpResult<any>}
 */
export const deleteClientUserById = async (id) =>
  httpService({
    url: `/customers/users/${id}`,
    options: {
      method: "DELETE",
    },
  });

/**
 * @typedef {import('types/commonTypes').DriverDetails} DriverDetails
 * @param {string} id
 * @returns  {HttpResult<DriverDetails>}
 */
export const getDriverDetails = async (id) =>
  httpService({
    url: `/users/driver/${id}`,
    options: {
      method: "GET",
    },
  });

/**
 * @typedef {import('types/commonTypes').UserUpdateAccountStatus} UserUpdateAccountStatus
 * @param {{ userId: string; data: UserUpdateAccountStatus }} data
 * @returns  {HttpResult<import("types/commonTypes").UserDto>}
 */
export const updateUserAccountStatus = async ({ userId, data }) =>
  httpService({
    url: `/users/status/${userId}`,
    options: {
      method: "PUT",
      data,
    },
  });

/**
 * @param {string} id
 * @returns  {HttpResult<DriverDetails>}
 */
export const getLastDriverPosition = async (id) =>
  httpService({
    url: `/api/v1/users/driver/${id}/position`,
    options: {
      method: "GET",
    },
  });

/**
 * @param {import("types/commonTypes").UserRole} role
 * @returns  {HttpResult<import("types/commonTypes").IndicatorsData>}
 */
export const getPollingDataByRole = async (role) => {
  const urlPart = FP.match(role)
    .on("SUPER_ADMIN", "super-admin")
    .on("KERICO_MANAGER", "manager")
    .on("KERICO_DISPATCHER", "dispatcher")
    .otherwise("nobody");

  return httpService({
    url: `/updates/kerico/${urlPart}`,
    options: {
      method: "GET",
    },
  });
};

/**
 * @typedef {import('types/commonTypes').DriverSearchItem} DriverSearchItem
 * @param {import('types/commonTypes').DriverSearchRequest} data
 * @returns  {HttpResult<DriverSearchItem[]>}
 */
export const getDriversBySearch = async (data) =>
  httpService({
    url: "/users/driver/by-name/short-details",
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @typedef {import('types/commonTypes').UserDto} UserDto
 * @param {string} id
 * @returns  {HttpResult<UserDto>}
 */
export const getClientUserById = async (id) =>
  httpService({
    url: `/customers/users/${id}`,
    options: {
      method: "GET",
    },
  });

/**
 * @param {{ id: string; data: import("types/commonTypes").ClientUserUpdateDto}} props
 * @returns  {HttpResult<import("types/commonTypes").UserDto>}
 */
export const updateClientUser = async ({ id, data }) =>
  httpService({
    url: `/customers/users/${id}`,
    options: {
      method: "PUT",
      data,
    },
  });

/**
 * @param {import('types/commonTypes').TableRequest} data
 * @returns  {HttpResult<UsersResponse>}
 */
export const getClientUsers = async (data) =>
httpService({
  url: "/customers/users/search",
  options: {
    method: "POST",
    data,
  },
});