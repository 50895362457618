import { createAction, createReducer } from "@reduxjs/toolkit";

/**
 * @template T
 * @typedef {import('types/stateTypes').PayloadPreparator<T>} PayloadPreparator
 */

/** @type {string} */
const initialState = "";

export const updateAvatar = createAction(
  "avatar/update",
  /**
   * @type {PayloadPreparator<string>}
   */
  (image) => ({ payload: image })
);

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(updateAvatar, (state, { payload }) => payload);
});

export default reducer;
