import React from "react";
import { Tooltip, styled, tooltipClasses } from "@mui/material";
import { customColors } from "models/customColors";

export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    slotProps={{
      popper: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -8],
            },
          },
        ],
      },
    }}
    arrow
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: customColors[theme.palette.mode].additionalAccent,
  },
}));
