import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "store/globalContext";
import { useForm } from "react-hook-form";
import { Button, InputLabel, Stack } from "@mui/material";
import { DialogWindow } from "UI/DialogWindow";
import { FormTextField } from "UI/FormTextField";
import { FormSelectField } from "UI/FormSelectField";
import {
  getEmailConfirmValidation,
  emailValidation,
  clientNameValidation,
  phoneNumberValidation,
  notNullPriceField,
} from "models/validations";
import { FormMaskedTextField } from "UI";
import { useApiRequest } from "api/useApiRequest";
import { useFormDataSync } from "hooks/useFormDataSync";
import {
  RequiredFieldsLegend,
  ActionColoredText,
} from "UI/RequiredFieldsLegend";
import { addClient } from "api/clientApi";
import { ReactComponent as UserIcon } from "assets/img/user-small-icon.svg";
import { AddSpecialityDialog } from "./AddSpecialityDialog";
import LocationSelector from "./LocationSelector";
import { mapNewClientDataForSave, removeEmptyProps } from "utils";
import { DEFAULT_DUE_DAYS_PERIOD } from "models/constants";
import { FormMoneyField } from "UI/FormMoneyField";
import { FormCheckbox } from "UI/FormCheckbox";
import { ClientContractForm } from "./ClientContractForm";
import { useDefaultContractData } from "hooks/useDefaultContractData";
import { useDefaultContractValues } from "hooks/useDefaultContractValues";

/** @type {import("types/commonTypes").CompanyFormState & { emailRepeat: string; }}*/
const defaultFormData = {
  companyName: "",
  phoneNumber: "",
  email: "",
  emailRepeat: "",
  headOfficePlaceId: "",
  headOfficeAwsId: "",
  specialityId: "",
  dueDate: DEFAULT_DUE_DAYS_PERIOD,
  invoicePolicy: "MONTHLY",
  // contract related data
  hasOwnContract: false,
  contractName: "",
  baseDeliveryFee: 0,
  basePricePerMile: 0,
  wheelchairPricePerMile: 0,
  companyVehicleRate: 0,
  privateVehicleRate: 0,
  wheelchairDeliveryFee: 0,
  waitingRatePerMin: 0,
  freeWaitingTimeMin: 0,
  recalculationMargin: 0,
};

export const AddClientDialog = ({
  open,
  handleClose,
  refreshList,
  specialityOptions,
  loadSpecialities,
}) => {
  const { showWarning } = useContext(GlobalContext);
  const { callApiRequest } = useApiRequest();

  const [isAddFacilityOpen, setIsAddFacilityOpen] = useState(false);
  const { defaultContract } = useDefaultContractData();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: defaultFormData,
  });

  const { handleConfirmedClose, handleForcedClose } = useFormDataSync({
    isDirty,
    handleClose,
    open,
    reset,
  });

  const email = useRef("");
  email.current = watch("email", "");

  const onSubmit = async (data) => {
    /** @type {{ [key: string]: boolean | number | string }} */
    const values = removeEmptyProps(data);
    values.headOfficePlaceName = values.companyName;

    const params = mapNewClientDataForSave(values);

    callApiRequest({
      apiRequest: addClient,
      params,
      onSuccess: () => {
        refreshList();
        handleForcedClose();
        showWarning({
          title: "Client Added",
          content: "The Client has been successfully added",
          icon: <UserIcon />,
        });
      },
    });
  };

  const handleLocationSelect = (location) => {
    if (location === null) {
      // reset location
      setValue("headOfficeAwsId", "", { shouldDirty: false });
      setValue("headOfficePlaceId", "", { shouldDirty: false });
    } else {
      // set new location
      setValue("headOfficeAwsId", location?.awsId ?? "", {
        shouldDirty: !!location?.awsId,
      });
      setValue("headOfficePlaceId", location?.id ?? "", {
        shouldDirty: !!location?.id,
      });
    }
  };

  const hasOwnContract = watch("hasOwnContract");

  const { setDefaultContractValues } = useDefaultContractValues({
    setValue,
    defaultContract,
    hasOwnContract,
  });

  useEffect(() => {
    if (hasOwnContract) {
      setDefaultContractValues();
    }
  }, [hasOwnContract, setDefaultContractValues]);

  return (
    <DialogWindow
      open={open && !!defaultContract}
      withCloseButton
      handleClose={handleConfirmedClose}
      title={"Add Client"}
      content={
        <form>
          <Stack>
            <Stack
              mt={2}
              gap={3}
              direction={"row"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Stack gap={1}>
                <Stack alignItems={"flex-start"}>
                  <InputLabel htmlFor="companyName">Client Name</InputLabel>
                  <FormTextField
                    id="companyName"
                    name="companyName"
                    control={control}
                    rules={clientNameValidation}
                    InputProps={{
                      autoComplete: "off",
                    }}
                  />
                </Stack>
                <Stack alignItems={"flex-start"}>
                  <InputLabel htmlFor="email">Email</InputLabel>
                  <FormTextField
                    id="email"
                    name="email"
                    control={control}
                    rules={emailValidation}
                    InputProps={{
                      autoComplete: "email",
                    }}
                  />
                </Stack>
                <Stack alignItems={"flex-start"}>
                  <InputLabel htmlFor="emailRepeat">Confirm email</InputLabel>
                  <FormTextField
                    id="emailRepeat"
                    name="emailRepeat"
                    control={control}
                    rules={getEmailConfirmValidation(email.current)}
                    InputProps={{
                      autoComplete: "newpassword",
                    }}
                  />
                </Stack>
                <Stack alignItems={"flex-start"}>
                  <InputLabel htmlFor="phoneNumber">
                    <Stack direction={"row"} gap={1}>
                      Phone Number <ActionColoredText>*</ActionColoredText>
                    </Stack>
                  </InputLabel>
                  <FormMaskedTextField
                    id="phoneNumber"
                    name="phoneNumber"
                    control={control}
                    rules={phoneNumberValidation}
                    mask="999-999-9999"
                    InputProps={{
                      autoComplete: "off",
                    }}
                  />
                </Stack>
                <Stack alignItems={"flex-start"}>
                  <Stack alignItems={"flex-start"} width={"100%"}>
                    <InputLabel htmlFor="specialityId">
                      <Stack direction={"row"} gap={1}>
                        Speciality <ActionColoredText>*</ActionColoredText>
                      </Stack>
                    </InputLabel>
                    <FormSelectField
                      id="specialityId"
                      name="specialityId"
                      control={control}
                      options={specialityOptions}
                      withNone={false}
                    />
                  </Stack>
                  <Button onClick={() => setIsAddFacilityOpen(true)}>
                    Add New Speciality
                  </Button>
                </Stack>
              </Stack>
              <Stack gap={1}>
                <Stack alignItems={"flex-start"} width={"100%"}>
                  <InputLabel htmlFor="location">
                    <Stack direction={"row"} gap={1}>
                      Main Office Address{" "}
                      <ActionColoredText>*</ActionColoredText>
                    </Stack>
                  </InputLabel>
                  <LocationSelector
                    id="location"
                    setValue={handleLocationSelect}
                  />
                </Stack>
                <Stack alignItems={"flex-start"} width={"100%"}>
                  <InputLabel htmlFor="invoicePolicy">
                    Generate Invoices
                  </InputLabel>
                  <FormSelectField
                    id="invoicePolicy"
                    name="invoicePolicy"
                    control={control}
                    options={[
                      {
                        value: "MONTHLY",
                        label: "Every Month (Last Day of Month)",
                      },
                      {
                        value: "WEEKLY",
                        label: "Every Week (Next Monday)",
                      },
                      { value: "SINGLE_RIDE", label: "Per Single Ride" },
                    ]}
                    withNone={false}
                  />
                </Stack>
                <Stack alignItems={"flex-start"}>
                  <InputLabel htmlFor="dueDate">Due Payment Days</InputLabel>
                  <FormMoneyField
                    id="dueDate"
                    name="dueDate"
                    control={control}
                    rules={notNullPriceField}
                    InputProps={{
                      autoComplete: "new-password",
                      inputProps: {
                        min: 0,
                        max: 100,
                        step: 1,
                      },
                    }}
                  />
                </Stack>
                <Stack mt={1} alignItems={"flex-start"}>
                  <FormCheckbox
                    name="hasOwnContract"
                    control={control}
                    label={"Client has own contract"}
                  />
                </Stack>
                {hasOwnContract && (
                  <Stack alignItems={"flex-start"}>
                    <InputLabel htmlFor="contractName">
                      Contract Description
                    </InputLabel>
                    <FormTextField
                      id="contractName"
                      name="contractName"
                      control={control}
                      InputProps={{
                        autoComplete: "off",
                      }}
                    />
                  </Stack>
                )}
                {!hasOwnContract && (
                  <Stack
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    width={"100%"}
                    gap={1}
                  >
                    <Button variant="outlined" onClick={handleConfirmedClose}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Add Client
                    </Button>
                  </Stack>
                )}
              </Stack>
              {hasOwnContract && <ClientContractForm control={control} />}
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <RequiredFieldsLegend />
              {hasOwnContract && (
                <Stack
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  gap={1}
                >
                  <Button variant="outlined" onClick={handleConfirmedClose}>
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                    Add Client
                  </Button>
                </Stack>
              )}
            </Stack>
          </Stack>
          <AddSpecialityDialog
            open={isAddFacilityOpen}
            handleClose={() => setIsAddFacilityOpen(false)}
            loadSpecialities={loadSpecialities}
          />
        </form>
      }
    />
  );
};
