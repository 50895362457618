import { httpService } from "./httpService";

/**
 * @template T
 * @typedef {import('types/commonTypes').HttpResult<T>} HttpResult
 */

/**
 * @param {import('types/commonTypes').RideEstimateRequest} data
 * @returns  {HttpResult<import('types/commonTypes').RideEstimateResponse>}
 */
export const estimateRide = async (data) =>
  httpService({
    url: "/rides/estimate",
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @param {import('types/commonTypes').NewRide} data
 * @returns  {HttpResult<import('types/commonTypes').Ride>}
 */
export const createRide = async (data) =>
  httpService({
    url: "/rides/asap",
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @param {import('types/commonTypes').NewRide} data
 * @returns  {HttpResult<import('types/commonTypes').Ride>}
 */
export const createScheduledRide = async (data) =>
  httpService({
    url: "/rides/scheduled",
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @param {import('types/commonTypes').TableRequest} data
 * @returns  {HttpResult<import('types/commonTypes').RideDescription>}
 */
export const getRides = async (data) =>
  httpService({
    url: "/rides/search",
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @param {string} id
 * @returns  {HttpResult<import('types/commonTypes').Ride>}
 */
export const getRideDetails = async (id) =>
  httpService({
    url: `/rides/${id}`,
    options: {
      method: "GET",
    },
  });

/**
 * @param {string} id
 * @returns  {HttpResult<import('types/commonTypes').Ride>}
 */
export const getLastRidePosition = async (id) =>
  httpService({
    url: `/rides/${id}/position`,
    options: {
      method: "GET",
    },
  });

export const getActiveRides = async (data) =>
  httpService({
    url: `/rides/active/search`,
    options: {
      method: "POST",
      data,
    },
  });

// rides/{rideId}/candidates

/**
 * @param {string} id
 * @returns  {HttpResult<import('types/commonTypes').Candidate[]>}
 */
export const getCandidatesByRideId = async (id) =>
  httpService({
    url: `/rides/${id}/candidates`,
    options: {
      method: "GET",
    },
  });

/**
 * @param {{ data: { userId: string; vehicleId: string; }; id: string; }} id
 * @returns  {HttpResult<import('types/commonTypes').Ride>}
 */
export const assignDriverToRide = async ({ data, id }) =>
  httpService({
    url: `/rides/${id}/assign`,
    options: {
      method: "PUT",
      data,
    },
  });

/**
 * @param {string} rideId
 * @returns  {(data: { status: string; comment: string; }) => HttpResult<import("types/commonTypes").Transaction>}
 */
export const getStatusAssingByCustomerByRideId = (rideId) => async (data) =>
  httpService({
    url: `/rides/${rideId}/status/assign/by-customer`,
    options: {
      method: "PUT",
      data,
    },
  });

/**
 * @param {string} rideId
 * @returns  {(data: { status: string; comment: string; }) => HttpResult<import("types/commonTypes").Transaction>}
 */
export const getStatusAssingByAdminByRideId = (rideId) => async (data) =>
  httpService({
    url: `/rides/${rideId}/status/assign/by-admin`,
    options: {
      method: "PUT",
      data,
    },
  });


/**
 * @param {import('types/commonTypes').CalenderRequest} data
 * @returns  {HttpResult<import('types/commonTypes').CalenderRide[]>}
 */
export const getRidesFromDateRange = async (data) =>
  httpService({
    url: "/rides/search/calendar",
    options: {
      method: "POST",
      data,
    },
  });
