import React from "react";
import { Button, InputLabel, Stack } from "@mui/material";
import { FormTextField } from "UI";
import { required } from "models/validations";
import { FormSelectField } from "UI/FormSelectField";
import LocationSelector from "components/LocationSelector";
import { FormCheckbox } from "UI/FormCheckbox";
import { Title, Container } from "pages/CreateBookingPage";
import { ProgressBar } from "./ProgressIndicator";
import { FormRadioSwitcher } from "UI/FormRadioSwitcher";
import { FormDateTimeSelector } from "UI/FormDateTimeSelector";

export const CreateBookingStepTwo = ({
  control,
  step,
  trigger,
  setStep,
  setDepartureError,
  setDestinationError,
  setValue,
  getValues,
  departureLabel,
  departureError,
  destinationLabel,
  destinationError,
  handlePrevStep,
  watch,
  handleLocationSelect,
}) => {
  const handleStepThree = async () => {
    const { departure, destination } = getValues();
    const result = await trigger();

    if (departure?.id === "" || destination?.id === "") {
      if (departure?.id === "") {
        setDepartureError("Is required");
      }
      if (destination?.id === "") {
        setDestinationError("Is required");
      }
      return;
    }

    if (result) {
      setStep(step + 1);
    }
  };

  const rideType = watch("rideType");

  return (
    <Container gap={2}>
      <Title>Step 2 - Schedule a Ride</Title>
      <ProgressBar step={step} steps={3} />
      <Stack direction={"row"} gap={1}>
        <Stack alignItems={"flex-start"} width={"100%"}>
          <InputLabel htmlFor="rideType">Ride type</InputLabel>
          <FormRadioSwitcher
            id="rideType"
            name="rideType"
            control={control}
            setValue={setValue}
            options={[
              {
                value: "ASAP",
                label: "A.S.A.P",
              },
              {
                value: "SCHEDULED",
                label: "Scheduled",
              },
            ]}
          />
        </Stack>
        {rideType === "SCHEDULED" && (
          <Stack alignItems={"flex-start"} width={"100%"}>
            <InputLabel htmlFor="departureTimeMs">Pick Up Time</InputLabel>
            <FormDateTimeSelector
              id="departureTimeMs"
              name="departureTimeMs"
              control={control}
              rules={{ required }}
              size="small"
              disablePast
              closeOnSelect={false}
            />
          </Stack>
        )}
      </Stack>
      <Stack direction={"column"} gap={2} justifyContent={"space-between"}>
        <Stack alignItems={"flex-start"} flex={1}>
          <InputLabel htmlFor="pickUpLocation">
            <Stack direction={"row"} gap={1}>
              Pick Up Address
            </Stack>
          </InputLabel>
          <LocationSelector
            id="pickUpLocation"
            setValue={(value) => handleLocationSelect(value, "departure")}
            defaultValue={{ id: "", suggestion: departureLabel }}
            error={departureError}
            sx={{ width: "100%" }}
          />
        </Stack>
        <Stack alignItems={"flex-start"} flex={1}>
          <InputLabel htmlFor="dropOffLocation">
            <Stack direction={"row"} gap={1}>
              Drop Off Address
            </Stack>
          </InputLabel>
          <LocationSelector
            id={"dropOffLocation"}
            setValue={(value) => handleLocationSelect(value, "destination")}
            defaultValue={{ id: "", suggestion: destinationLabel }}
            error={destinationError}
            sx={{ width: "100%" }}
          />
        </Stack>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"} gap={2}>
        <Stack alignItems={"flex-start"} width={"50%"}>
          <InputLabel htmlFor="passengersNumber">Passengers Number</InputLabel>
          <FormTextField
            id="passengersNumber"
            name="passengersNumber"
            control={control}
            inputProps={{
              autocomplete: "new-password",
              min: 1,
              max: 10,
            }}
            rules={{ required }}
            type="number"
          />
        </Stack>
        <Stack alignItems={"flex-start"} width={"50%"}>
          <InputLabel htmlFor="vehicleType">Vehicle Type</InputLabel>
          <FormSelectField
            id="vehicleType"
            name="vehicleType"
            control={control}
            options={{
              SEDAN_OR_SALOON: "Sedan or saloon",
              VAN: "Van",
              WHEELCHAIR: "Wheelchair",
            }}
            withNone={false}
            rules={{ required }}
          />
        </Stack>
      </Stack>
      <Stack alignItems={"flex-start"} flex={1}>
        <FormCheckbox
          name="d2d"
          control={control}
          label={"Needs assistance - D2D"}
        />
      </Stack>
      <Stack alignItems={"flex-start"} flex={1}>
        <InputLabel htmlFor="additionalNotes">Additional Notes</InputLabel>
        <FormTextField
          id="additionalNotes"
          name="additionalNotes"
          control={control}
          multiline
          rows={2}
        />
      </Stack>
      <Stack direction={"row"} gap={1}>
        <Button variant="outlined" color="primary" onClick={handlePrevStep}>
          Previous step
        </Button>
        <Button variant="contained" color="primary" onClick={handleStepThree}>
          Next step
        </Button>
      </Stack>
    </Container>
  );
};
