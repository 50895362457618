import { TextFieldWithEllipsis } from "components/TextFiledWithEllipsis";
import { Selector, Status } from "pages/BookingsPage.style";
import { convertToReadable, unixTimeToUSDate } from "utils";
import * as FP from "utils/fp-js";

export const columns = [
  {
    label: "ID",
    field: "id",
    xs: 1.3,
    sm: 1.3,
    md: 1.3,
    sortableName: "ID",
    renderer: ({ id }) => (
      <TextFieldWithEllipsis>{id?.slice(0, 8) ?? ""}...</TextFieldWithEllipsis>
    ),
  },
  {
    label: "Client",
    field: "companyName",
    xs: 2,
    sm: 2,
    md: 2,
    sortableName: "COMPANY_NAME",
  },
  {
    label: "Patient",
    field: "passengerName",
    xs: 2,
    sm: 2,
    md: 2,
    sortableName: "PATIENT_NAME",
  },
  {
    label: "Region",
    field: "region",
    xs: 1.2,
    sm: 1.2,
    md: 1.2,
    sortableName: "REGION",
    renderer: ({ region }) => (
      <TextFieldWithEllipsis>{region}</TextFieldWithEllipsis>
    ),
  },
  {
    label: "Pick Up At",
    field: "plannedStartTime",
    xs: 1.5,
    sm: 1.5,
    md: 1.5,
    sortableName: "PICKUP_TIME",
    renderer: (row) =>
      FP.match(row?.plannedStartTime)
        .on([undefined, null, ""], "A.S.A.P")
        .otherwise(unixTimeToUSDate(row?.plannedStartTime)),
  },
  {
    label: "Pick Up Address",
    field: "departureLabel",
    xs: 2.5,
    sm: 2.5,
    md: 2.5,
    sortableName: "PICKUP_ADDRESS",
    renderer: ({ departureLabel }) => (
      <TextFieldWithEllipsis>{departureLabel}</TextFieldWithEllipsis>
    ),
  },
  {
    label: "Status",
    field: "status",
    xs: 1.5,
    sm: 1.5,
    md: 1.5,
    sortableName: "STATUS",
    renderer: (row) => (
      <Status
        // @ts-ignore
        status={row?.status}
      >
        {convertToReadable(row?.status ?? "")}
      </Status>
    ),
  },
];

export const getShortColumns = (selectedId) => [
  {
    label: "Client",
    field: "companyName",
    xs: 4,
    sm: 4,
    md: 4,
    sortableName: "COMPANY_NAME",
  },
  {
    label: "Patient",
    field: "passengerName",
    xs: 4,
    sm: 4,
    md: 4,
    sortableName: "PATIENT_NAME",
  },
  {
    label: "Status",
    field: "status",
    xs: 2,
    sm: 2,
    md: 2,
    sortableName: "STATUS",
    renderer: (row) => (
      <Status
        // @ts-ignore
        status={row?.status}
      >
        {convertToReadable(row?.status ?? "")}
      </Status>
    ),
  },
  {
    xs: 2,
    sm: 2,
    md: 2,
    renderer: (row) => (
      <Selector status={row?.status} selectedId={selectedId} row={row} />
    ),
  },
];
