import React, { useState } from "react";
import { Divider, Stack, styled } from "@mui/material";
import * as SC from "SC";
import { ProfileSettings } from "components/ProfileSettings";
import { SubMenuContent } from "components/SubMenuContent";
import { AddClientUserDialog } from "components/AddClientUserDialog";
import { useAppSelector } from "store/hooks";
import { ClientContractDetails } from "components/ClientContractDetails";
import { useProfileTabOpener } from "hooks/useProfileTabOpener";

const Layout = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  height: 100%;

  gap: 28px;
`;

const Menu = styled(Stack)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const Content = styled(Stack)`
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;

  height: 100%;

  overflow-y: auto;
  overflow-x: hidden;
`;

const tabsLabels = ["User Management", "Contract Details", "Personal Settings"];

export const ClientSettings = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const companyId = useAppSelector((state) => state.user.companyId);
  const [isAddUserVisible, setIsAddUserVisible] = useState(false);

  const clientUserManagementContent = [
    {
      title: "Manage Users",
      info: "You can view and edit Manager and Admin data",
      buttonLabel: "Manage Users",
      path: "/settings/manage_users",
    },
    {
      title: "Add User",
      info: "Add a Manager or Admin to your company",
      buttonLabel: "Add User",
      onClick: () => setIsAddUserVisible(true),
    },
  ];

  useProfileTabOpener({ handleTabOpen: () => setCurrentTab(2) });

  return (
    <SC.OverflowedContainer>
      <Layout>
        <Menu>
          {tabsLabels.map((label, index) =>
            index === currentTab ? (
              <SC.ActiveSubmenu
                key={label}
                onClick={() => setCurrentTab(index)}
              >
                {label}
              </SC.ActiveSubmenu>
            ) : (
              <SC.Submenu key={label} onClick={() => setCurrentTab(index)}>
                {label}
              </SC.Submenu>
            )
          )}
        </Menu>
        <Divider orientation="vertical" />
        <Content>
          {currentTab === 0 && (
            <Stack gap={3}>
              {clientUserManagementContent.map(
                ({ title, info, buttonLabel, path, onClick }, index) => (
                  <React.Fragment key={`settings-${title}`}>
                    {index > 0 && <Divider />}
                    <SubMenuContent
                      title={title}
                      info={info}
                      buttonLabel={buttonLabel}
                      path={path}
                      onClick={onClick}
                    />
                  </React.Fragment>
                )
              )}
              <AddClientUserDialog
                open={isAddUserVisible}
                handleClose={() => setIsAddUserVisible(false)}
                refreshList={() => null}
                clientId={companyId}
              />
            </Stack>
          )}
          {currentTab === 1 && <ClientContractDetails />}
          {currentTab === 2 && <ProfileSettings />}
        </Content>
      </Layout>
    </SC.OverflowedContainer>
  );
};
