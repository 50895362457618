import React from "react";
import { RadioSwitcher } from "UI/RadioSwitcher";

export const ChatsSelector = ({ mode, setMode }) => {
  const handleClickUnactiveItem = (e, value) => {
    setMode(value);
  };

  return (
    <RadioSwitcher
      handleClickUnactiveItem={handleClickUnactiveItem}
      value={mode}
      options={[
        {
          value: "General",
          label: "General",
        },
        {
          value: "Ride",
          label: "Ride",
        },
        {
          value: "Driver",
          label: "Driver",
        },
        {
          value: "Client",
          label: "Client",
        },
      ]}
    />
  );
};
