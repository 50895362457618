import {
  getCsvFileRequestById,
  getCsvFileRequestItemById,
  getFileRequestItemsSearch,
  transferFileRequestItemsToRides,
  updateFileRequestStatus,
} from "api/bookingRequestsApi";
import { useApiRequest } from "api/useApiRequest";
import { TextFieldWithEllipsis } from "components/TextFiledWithEllipsis";
import { useClientList } from "hooks/useClientList";
import { useDataGrid } from "hooks/useDataGrid";
import { useNavigationData } from "hooks/useNavigationData";
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GlobalContext } from "store/globalContext";
import { useAppSelector } from "store/hooks";
import { convertToReadable, formatPhoneNumber, unixTimeToUSDate } from "utils";
import { Status } from "./RequestItemsPage.style";
import { WillCallField } from "components/WillCallField";
import { useIsDataChanged } from "hooks/useIsDataChanged";
import { requestItemStatuses } from "models/bookingRequest";

const columns = [
  {
    label: "External ID",
    field: "externalId",
    xs: 1.3,
    sm: 1.3,
    md: 1.3,
    sortableName: "EXTERNAL_ID",
    renderer: ({ externalId }) => (
      <TextFieldWithEllipsis>{externalId}</TextFieldWithEllipsis>
    ),
  },
  {
    label: "Pick Up Time",
    field: "plannedStartTime",
    xs: 1.5,
    sm: 1.5,
    md: 1.5,
    sortableName: "DATE",
    renderer: ({ plannedStartTime }) => unixTimeToUSDate(plannedStartTime),
  },
  {
    label: "Pick Up Address",
    field: "departure.label",
    xs: 2.5,
    sm: 2.5,
    md: 2.5,
    sortableName: "PICK_UP_ADDRESS",
    renderer: ({ departure }) => (
      <TextFieldWithEllipsis>
        {departure?.place?.label ?? "-"}
      </TextFieldWithEllipsis>
    ),
  },
  {
    label: "Drop Off Address",
    field: "destination.label",
    xs: 2.5,
    sm: 2.5,
    md: 2.5,
    sortableName: "DROP_OFF_ADDRESS",
    renderer: ({ destination }) => (
      <TextFieldWithEllipsis>
        {destination?.place?.label ?? "-"}
      </TextFieldWithEllipsis>
    ),
  },
  {
    label: "Patient Name",
    field: "passengerFirstName",
    xs: 1.95,
    sm: 1.95,
    md: 1.95,
    sortableName: "PATIENT_NAME",
    renderer: ({ passengerFirstName, passengerLastName }) => (
      <TextFieldWithEllipsis>
        {passengerFirstName} {passengerLastName}
      </TextFieldWithEllipsis>
    ),
  },
  {
    label: "Will Call",
    field: "willCall",
    xs: 1,
    sm: 1,
    md: 1,
    sortableName: "WILL_CALL",
    align: "center",
    renderer: (row) => <WillCallField {...row} />,
  },
  {
    label: "Status",
    field: "status",
    xs: 1.25,
    sm: 1.25,
    md: 1.25,
    sortableName: "STATUS",
    renderer: (row) => (
      <Status
        // @ts-ignore
        status={row?.status}
      >
        {convertToReadable(row?.status ?? "")}
      </Status>
    ),
  },
];

const shortColumns = [
  {
    xs: 0.5,
    sm: 0.5,
    md: 0.5,
  },
  {
    label: "ID",
    field: "externalId",
    xs: 2.5,
    sm: 2.5,
    md: 2.5,
    sortableName: "EXTERNAL_ID",
    renderer: ({ externalId }) => (
      <TextFieldWithEllipsis>{externalId}</TextFieldWithEllipsis>
    ),
  },
  {
    label: "Pick Up",
    field: "plannedStartTime",
    xs: 3.5,
    sm: 3.5,
    md: 3.5,
    sortableName: "DATE",
    renderer: ({ plannedStartTime }) => unixTimeToUSDate(plannedStartTime),
  },
  {
    label: "Will Call",
    field: "willCall",
    xs: 2.5,
    sm: 2.5,
    md: 2.5,
    sortableName: "WILL_CALL",
    align: "center",
    renderer: (row) => <WillCallField {...row} />,
  },
  {
    label: "Status",
    field: "status",
    xs: 3,
    sm: 3,
    md: 3,
    sortableName: "STATUS",
    align: "center",
    renderer: (row) => (
      <Status
        // @ts-ignore
        status={row?.status}
      >
        {convertToReadable(row?.status ?? "")}
      </Status>
    ),
  },
];

/** @type {"VALIDATE_REQUESTS" | "ALL_REQUESTS"} */
const defaultMode = "VALIDATE_REQUESTS";

export const windowTypes = {
  RIDE: "RIDE",
  REQUEST: "REQUEST",
};

export const useRequestItemsPage = () => {
  const { id } = useParams();
  const { showWarning, openConfirmationWithCallback } =
    useContext(GlobalContext);
  const { callApiRequest } = useApiRequest();
  const { getClientLabelById } = useClientList();
  const { isDataChanged, setIsDataChanged } = useIsDataChanged();

  const userId = useAppSelector((state) => state.user.id);

  const [mode, setMode] = useState(defaultMode);

  const [selectedId, setSelectedId] = useState("");
  const [selectedRideIt, setSelectedRideId] = useState("");

  const [request, setRequest] = useState(null);
  const [item, setItem] = useState(null);

  const [windowType, setWindowType] = useState(null);

  const dataGrid = useDataGrid({
    apiHandler: getFileRequestItemsSearch,
    additionalParams: {
      // rideRequestStatus: mode === "VALIDATE_REQUESTS" ? "VALIDATE" : undefined,
      rideRequestId: id,
    },
    defaultSorter: {
      name: "DATE",
      order: "ASC",
    },
    defaultLimit: 50,
    gridConfigIndex: `${userId}-request-items`,
    reloadIntervalInSeconds: 30,
    // @ts-ignore
    withSelector: true,
  });

  const validateDataGrid = useDataGrid({
    apiHandler: getFileRequestItemsSearch,
    additionalParams: {
      rideRequestStatus: requestItemStatuses.VALIDATE,
      rideRequestId: id,
    },
    defaultSorter: {
      name: "DATE",
      order: "ASC",
    },
    defaultLimit: 50,
    gridConfigIndex: `${userId}-request-validate-items`,
    reloadIntervalInSeconds: 30,
    // @ts-ignore
  });

  const handleTransferSelected = () => {
    if (!dataGrid?.selectedRows?.length) return;

    callApiRequest({
      apiRequest: transferFileRequestItemsToRides,
      params: { rideRequestIds: dataGrid.selectedRows },
      onSuccess: (data) => {
        dataGrid?.loadData();

        const errors = Object.entries(data?.responseDtoMap ?? {}).filter(
          ([_, { success }]) => success === false
        );

        const reasons = [...new Set(errors.map(([_, { message }]) => message))];

        if (errors.length) {
          openConfirmationWithCallback({
            title: "Attention",
            message:
              errors.length > 1 ? (
                <>
                  {errors.length} items could not be transferred.
                  <p>
                    <b>Reason{reasons?.length > 1 ? "s" : ""}:</b>{" "}
                    {reasons.join("; ")}.
                  </p>
                </>
              ) : (
                <>
                  {errors.length} item could not be transferred.
                  <p>
                    <b>Reason:</b> {errors[0][1].message ?? "-"}.
                  </p>
                </>
              ),
            withOneButton: true,
          });
          return;
        }

        showWarning({
          title: "Request Items Transferred",
          content: "Request Items have been transferred successfully",
        });
      },
    });
  };

  const handleLoadSelectedItem = (id) => {
    callApiRequest({
      apiRequest: getCsvFileRequestItemById,
      params: id,
      onSuccess: (data) => {
        const mappedData = {
          ...data,
          phoneNumber: formatPhoneNumber(data?.phoneNumber ?? "") ?? "",
          plannedStartTimeMs: data?.plannedStartTime ?? "",
        };

        if (
          data?.rideId &&
          data?.status === requestItemStatuses.TRANSFERRED &&
          windowType === windowTypes.RIDE
        ) {
          setSelectedId(id);
          setSelectedRideId(data?.rideId);
          setWindowType(windowTypes.RIDE);
          return;
        }

        setItem(mappedData);
        setSelectedId(id);
        setWindowType(windowTypes.REQUEST);
      },
    });
  };

  const closeWindow = () => {
    setSelectedId("");
    setSelectedRideId("");
  };

  const handleRowClick = (row) => {
    if (selectedId === row?.id) {
      // setSelectedId("");
      // setSelectedRideId("");
      return;
    }

    if (isDataChanged) {
      openConfirmationWithCallback({
        title: "Are you sure?",
        message: "All entered data will be lost.",
        callbackFn: () => {
          setIsDataChanged(false);
          handleLoadSelectedItem(row?.id);
        },
      });
      return;
    }

    handleLoadSelectedItem(row?.id);
  };

  useNavigationData({
    setSearch: dataGrid.setSearch,
  });

  const loadData = useCallback(() => {
    callApiRequest({
      apiRequest: getCsvFileRequestById,
      params: id,
      onSuccess: (data) => {
        setRequest(data);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleArchiveRequest = () => {
    const params = {
      id,
      status: "ARCHIVED",
    };

    callApiRequest({
      apiRequest: updateFileRequestStatus,
      params,
      onSuccess: (data) => {
        showWarning({
          title: "Request archived",
          content: "Request has been archived successfully",
        });
        setRequest(data);
        dataGrid.setSelectedRows([]);
      },
    });
  };

  const handleRestoreRequest = () => {
    const params = {
      id,
      status: "PROCESSING",
    };

    callApiRequest({
      apiRequest: updateFileRequestStatus,
      params,
      onSuccess: (data) => {
        showWarning({
          title: "Request restored",
          content: "Request has been restored successfully",
        });
        setRequest(data);
      },
    });
  };

  useEffect(() => loadData(), [loadData]);

  return {
    id,
    dataGrid,
    validateDataGrid,
    request,
    item,
    setItem,
    windowType,
    mode,
    setMode,
    selectedId,
    setSelectedId,
    handleTransferSelected,
    handleRowClick,
    handleArchiveRequest,
    handleRestoreRequest,
    handleLoadSelectedItem,
    columns,
    shortColumns,
    getClientLabelById,
    windowTypes,
    closeWindow,
    selectedRideIt,
    setSelectedRideId,
    setWindowType,
  };
};
