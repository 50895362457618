import { capitalize } from "@mui/material";
import {
  transferFileRequestItemsToRides,
  updateFileRequestItem,
} from "api/bookingRequestsApi";
import { useApiRequest } from "api/useApiRequest";
import { useIsDataChanged } from "hooks/useIsDataChanged";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { GlobalContext } from "store/globalContext";
import { useAppDispatch } from "store/hooks";
import { showError } from "store/reducers/errorReducer";
import { windowTypes } from "pages/RequestItemsPage.hook";
import { requestItemStatuses } from "models/bookingRequest";

const addressTypes = {
  PICK_UP: "Pick Up",
  DROP_OFF: "Drop Off",
};

export const useRequestItemDetails = ({
  requestItem,
  refreshList = () => null,
  setRequestItem,
  refreshRequestItem,
  setSelectedRideId,
  setWindowType,
}) => {
  const dispatch = useAppDispatch();
  const { showWarning } = useContext(GlobalContext);
  const { callApiRequest } = useApiRequest();
  const { setIsDataChanged } = useIsDataChanged();
  const [validated, setValidated] = useState(false);
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [departureError, setDepartureError] = useState(null);
  const [destinationError, setDestinationError] = useState(null);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    trigger,
    formState: { isDirty },
  } = useForm({
    values: requestItem,
  });

  const { adminNotes, departure, destination, externalId, willCall, status } =
    getValues();

  const timePreparation = (time) => {
    if (typeof time === "number") {
      return time;
    }

    return new Date(time).getTime();
  };

  const mapOutput = (input) => {
    /** @type {import("types/commonTypes").RequestItemUpdateDto} */
    const result = {
      id: input.id,
      externalId: input.externalId,
      adminNotes: input.adminNotes,
      status: input.status,
      vehicleType: input.vehicleType,
      plannedStartTimeMs: timePreparation(input.plannedStartTimeMs),
      clientCost: input.clientCost,
      passengerFirstName: input.passengerFirstName,
      passengerLastName: input.passengerLastName,
      phoneNumber: input?.phoneNumber ?? null,
      passengers: input.passengers,
      driverNotes: input.driverNotes,
      departureId: input?.departure?.id,
      departureScope: input?.departure?.scope,
      destinationId: input?.destination?.id,
      destinationScope: input.destination?.scope ?? "AWS",
      willCall: input.willCall,
      rejectReason: input.rejectReason ?? "",
    };

    return result;
  };

  const onSubmit = (data) => {
    const params = mapOutput(data);

    setIsUpdating(true);
    callApiRequest({
      apiRequest: updateFileRequestItem,
      params,
      onSuccess: (result) => {
        showWarning({
          title: "Request Item Updated",
          content: "Request Item has been updated successfully",
        });
        setRequestItem(result);
        reset(data);
        refreshList();
      },
      onError: () => {
        setIsUpdating(false);
      },
    });
  };

  const handleViewRide = () => {
    const rideId = requestItem?.rideId;
    setSelectedRideId(rideId);
    setWindowType(windowTypes.RIDE);
  };

  const handleTransfer = () => {
    if (!requestItem?.id) return;

    callApiRequest({
      apiRequest: transferFileRequestItemsToRides,
      params: { rideRequestIds: [requestItem.id] },
      onSuccess: (data) => {
        const error = Object.entries(data?.responseDtoMap ?? {}).find(
          ([_, { success }]) => success === false
        );
        if (error) {
          dispatch(
            showError(
              error[1]?.message
                ? capitalize(error[1].message)
                : "Something went wrong."
            )
          );
          refreshList();
          refreshRequestItem(requestItem.id);
          return;
        }
        showWarning({
          title: "Request Item Transferred",
          content: "Request Item has been transferred successfully",
        });
        refreshList();
        refreshRequestItem(requestItem.id);
      },
    });
  };

  const handleValidate = async () => {
    const isValid = await trigger();
    if (!isValid) return;

    const data = getValues();
    if (!data?.departure?.id) {
      setDepartureError("Pick Up address is required");
      return;
    }
    if (!data?.destination?.id) {
      setDestinationError("Drop Off address is required");
      return;
    }

    const params = {
      ...mapOutput(data),
      status: requestItemStatuses.ACCEPTED,
      rejectReason: "",
    };

    setIsUpdating(true);
    callApiRequest({
      apiRequest: updateFileRequestItem,
      params,
      onSuccess: (result) => {
        showWarning({
          title: "Request Item Accepted",
          content: "Request Item has been accepted successfully",
        });
        setRequestItem(result);
        reset(data);
        refreshList();
      },
      onError: () => {
        setIsUpdating(false);
      },
    });
  };

  const handleReject = () => {
    const data = getValues();
    const params = mapOutput(data);

    setIsUpdating(true);
    callApiRequest({
      apiRequest: updateFileRequestItem,
      params,
      onSuccess: (result) => {
        showWarning({
          title: "Request Item Rejected",
          content: "Request Item has been rejected successfully",
        });
        setIsRejectDialogOpen(false);
        setRequestItem(result);
        reset(data);
        refreshList();
      },
      onError: () => {
        setIsUpdating(false);
      },
    });
  };

  const handleRevokeRejection = () => {
    const data = getValues();
    const params = {
      ...mapOutput(data),
      status: requestItemStatuses.VALIDATE,
      rejectReason: "",
    };

    setIsUpdating(true);
    callApiRequest({
      apiRequest: updateFileRequestItem,
      params,
      onSuccess: (result) => {
        showWarning({
          title: "Request Item Restored",
          content: "Request Item has been restored successfully",
        });
        setIsRejectDialogOpen(false);
        setRequestItem(result);
        reset(data);
        refreshList();
      },
      onError: () => {
        setIsUpdating(false);
      },
    });
  };

  useEffect(() => {
    setIsDataChanged(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    setIsUpdating(false);
    setDepartureError(null);
    setDestinationError(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestItem]);

  const setLocation = (location) => {
    const { departure, destination } = getValues();
    const isPickUp = location.addressType === addressTypes.PICK_UP;
    const name = isPickUp ? "departure" : "destination";
    const prevLocation = JSON.parse(
      JSON.stringify(isPickUp ? departure : destination)
    );

    const updatedLocation = {
      ...prevLocation,
      place: {
        ...prevLocation.place,
        label: location.label,
      },
      id: location?.placeId ? location.placeId : location.awsId,
      scope: location?.awsId ? "AWS" : "INTERNAL",
    };

    setValue(name, updatedLocation, { shouldDirty: true });
    if (isPickUp) {
      setDepartureError(null);
    } else {
      setDestinationError(null);
    }
  };

  useEffect(() => {
    const EXPORT_STATUS_REFRESH_INTERVAL_MS = 3000;
    let timeout;

    if ([requestItemStatuses.EXPORT].includes(status)) {
      timeout = setTimeout(() => {
        refreshRequestItem(requestItem.id);
        refreshList();
      }, EXPORT_STATUS_REFRESH_INTERVAL_MS);
    }

    return () => clearTimeout(timeout);
  }, [refreshList, refreshRequestItem, requestItem.id, status]);

  const departureLabel = watch("departure.place.label");
  const destinationLabel = watch("destination.place.label");
  const rejectReason = watch("rejectReason");
  const isUnaccepted = status === requestItemStatuses.UNACCEPTED;

  return {
    handleTransfer,
    handleValidate,
    handleReject,
    handleRevokeRejection,
    control,
    reset,
    adminNotes,
    departure,
    destination,
    externalId,
    willCall,
    status,
    validated,
    setValidated,
    setValue,
    getValues,
    watch,
    isDirty,
    handleSubmit,
    onSubmit,
    isRejectDialogOpen,
    setIsRejectDialogOpen,
    isUpdating,
    departureError,
    destinationError,
    setLocation,
    departureLabel,
    destinationLabel,
    rejectReason,
    isUnaccepted,
    addressTypes,
    handleViewRide,
  };
};
