import { httpService } from "./httpService";

/**
 * @template T
 * @typedef {import('types/commonTypes').HttpResult<T>} HttpResult
 */
/**
 * @template T
 * @typedef {import('types/commonTypes').TableResponse<T>} TableResponse
 */

/**
 * @param {import('types/commonTypes').TableRequest} data
 * @returns  {HttpResult<TableResponse<import("types/commonTypes").CompanyInvoiceDetails>>}
 */
export const getInvoicesInfoByCompanies = async (data) =>
  httpService({
    url: "/invoices/aggregated-by-company/search",
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @param {string} companyId
 * @returns  {(data: import('types/commonTypes').TableRequest) => HttpResult<TableResponse<import("types/commonTypes").InvoiceDetails>>}
 */
export const getInvoicesInfoByCompanyIdHandler = (companyId) => async (data) =>
  httpService({
    url: `/invoices/company/${companyId}/search`,
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @param {number} id
 * @returns  {HttpResult<import("types/commonTypes").Invoice>}
 */
export const getInvoiceById = async (id) =>
  httpService({
    url: `/invoices/${id}`,
    options: {
      method: "GET",
    },
  });

/**
 * @param {{ id: number; data: { status: string; } }} props
 * @returns  {HttpResult<import("types/commonTypes").Invoice>}
 */
export const updateInvoiceStatusById = async ({ id, data }) =>
  httpService({
    url: `/invoices/${id}/update-status`,
    options: {
      method: "PUT",
      data,
    },
  });

/**
 * @param {string} companyId
 * @returns  {HttpResult<import("types/commonTypes").InvoiceStatistics>}
 */
export const getInvoicesStatisticByCompanyId = async (companyId) =>
  httpService({
    url: `/invoices/company/${companyId}/short-count-details`,
    options: {
      method: "GET",
    },
  });

/**
 * @param {import('types/commonTypes').TableRequest} data
 * @returns  {HttpResult<TableResponse<import("types/commonTypes").FinanceInfo>>}
 */
export const getFinanceInfoByDrivers = async (data) =>
  httpService({
    url: "/payments/drivers/financial-data/search",
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @param {string} driverId
 * @returns  {HttpResult<import("types/commonTypes").EarningsInfo>}
 */
export const getDriverWalletInfo = async (driverId) =>
  httpService({
    url: `/payments/drivers/${driverId}/wallet/summary`,
    options: {
      method: "GET",
    },
  });

/**
 * @param {string} driverId
 * @returns  {(data: import('types/commonTypes').TableRequest) => HttpResult<TableResponse<import("types/commonTypes").Transaction>>}
 */
export const getDriverTransactionHandler = (driverId) => async (data) =>
  httpService({
    url: `/payments/drivers/${driverId}/transactions/search`,
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @param {string} driverId
 * @returns  {(data: { amount: number }) => HttpResult<import("types/commonTypes").Transaction>}
 */
export const getPayToDriverHandler = (driverId) => async (data) =>
  httpService({
    url: `/payments/drivers/${driverId}/pay-salary`,
    options: {
      method: "POST",
      data,
    },
  });
