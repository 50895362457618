import { capitalize } from "@mui/material";
import { httpDownloadService } from "api/httpDownloadService";
import * as FP from "utils/fp-js";
import { v4 as uuidv4 } from "uuid";

/** @type {(text: string) => string} */
export const convertToReadable = (text) =>
  (text ?? "")
    .replaceAll("_", " ")
    .split(" ")
    .map((text) => capitalize(text.toLowerCase()))
    .join(" ");

/**
 * @param {{ [key: string]: boolean | number | string | undefined | null }} data
 * @returns {{ [key: string]: boolean | number | string | undefined | null }}
 */
export const removeEmptyProps = (data) =>
  Object.entries(data)
    .filter(([_, value]) =>
      FP.match(true)
        .on(typeof value === "boolean", true)
        .on(typeof value === "number", true)
        .on(!!value && value !== "", true)
        .otherwise(false)
    )
    .reduce((acc, [name, value]) => ({ ...acc, [name]: value }), {});

/**
 * @typedef {import("types/commonTypes").User} User
 * @type {(props: {
 *  user: User; setImage: (url: string) => void; setError?: (text: string) => void
 * }) => Promise<void> }}
 */
export const loadAvatar = async ({ user, setImage, setError = () => null }) => {
  if (!user?.id) {
    setImage("");
    return;
  }

  const photoId = (user?.photos ?? []).find(
    ({ type }) => type === "Profile"
  )?.id;

  if (photoId) {
    // @ts-ignore
    const result = await httpDownloadService({ userId: user?.id, photoId });
    // @ts-ignore
    if (result?.error) {
      // @ts-ignore
      setError(result?.error);
      setImage("");
      return;
    }

    // @ts-ignore
    setImage(URL.createObjectURL(result.blob));
  }
};

/**
 * @typedef {import("types/commonTypes").DriverDetails} DriverDetails
 * @typedef {import("types/commonTypes").Photo} Photo
 * @type {(props: {
 *  driver: DriverDetails;
 *  photoType: Photo;
 *  setImage: (url: string) => void;
 *  setError?: (text: string) => void;
 *  afterLoadingCallback?: () => void;
 * }) => Promise<void> }}
 */
export const loadDriverPhoto = async ({
  driver,
  photoType,
  setImage,
  setError = () => null,
  afterLoadingCallback = () => null,
}) => {
  if (!driver?.id) {
    setImage("");
    return;
  }

  const photoId = (driver?.photos ?? []).find(
    ({ type }) => type === photoType
  )?.id;

  if (photoId) {
    // @ts-ignore
    const result = await httpDownloadService({ photoId });
    // @ts-ignore
    if (result?.error) {
      // @ts-ignore
      setError(result?.error);
      setImage("");
      afterLoadingCallback();
      return;
    }

    // @ts-ignore
    setImage(URL.createObjectURL(result.blob));
    afterLoadingCallback();
    return;
  }

  setImage("");
  afterLoadingCallback();
};

/**
 * @type {(props: {
 *  photoId: string; setImage: (url: string) => void; setError?: (text: string) => void
 * }) => Promise<void> }}
 */
export const loadUserPhoto = async ({
  photoId,
  setImage,
  setError = () => null,
}) => {
  if (!photoId) {
    setImage("");
    return;
  }
  // @ts-ignore
  const result = await httpDownloadService({ photoId });
  // @ts-ignore
  if (result?.error) {
    // @ts-ignore
    setError(result?.error);
    setImage("");
    return;
  }
  // @ts-ignore
  setImage(URL.createObjectURL(result.blob));
};

export const unixTimeToUSDate = (myUnixTimestamp) => {
  try {
    const date = new Date(myUnixTimestamp);

    return new Intl.DateTimeFormat("en-US", {
      dateStyle: "short",
      timeStyle: "short",
    }).format(date);
  } catch (e) {
    return "";
  }
};

export const unixTimeToUSShortDate = (myUnixTimestamp) => {
  const date = new Date(myUnixTimestamp);

  return new Intl.DateTimeFormat("en-US", {
    dateStyle: "short",
  }).format(date);
};

export const unixTimeToUSLongDate = (myUnixTimestamp) => {
  const date = new Date(myUnixTimestamp);

  return new Intl.DateTimeFormat("en-US", {
    dateStyle: "long",
  }).format(date);
};

export const unixTimeToUSTime = (myUnixTimestamp) => {
  const date = new Date(myUnixTimestamp);

  return new Intl.DateTimeFormat("en-US", {
    timeStyle: "short",
  }).format(date);
};

/**
 * Converts a duration in milliseconds to a human-readable format.
 *
 * @param {number} duration - The duration in milliseconds.
 * @returns {string} The duration in a human-readable format, e.g., "2 hours 30 min".
 */
export const humanizeDurationInMs = (duration) => {
  const hours = Math.floor(duration / 1000 / 60 / 60);
  const mins = Math.ceil((duration / 1000 / 60) % 60);

  if (hours === 0) {
    return `${mins} min`;
  }

  if (hours === 1 && mins === 0) {
    return `${hours} hour`;
  } else if (hours > 1 && mins === 0) {
    return `${hours} hours`;
  } else if (hours === 1 && mins > 0) {
    return `${hours} hour ${mins} min`;
  } else {
    return `${hours} hours ${mins} min`;
  }
};

/**
 * @param {string} str
 * @returns {string}
 */
export const removeCountry = (str) =>
  (str ?? "").replace(", United States", "").replace(" United States", "");

export const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

/**
 * Replaces all backspaces in a string with underscores.
 *
 * @param {string} str - The source string.
 * @returns {string} The modified string.
 */
export const createNameFromLabel = (str) => {
  return `${(str ?? "")
    .replace(/\s/g, "_")
    .toLocaleLowerCase()}_${uuidv4()}_general`;
};

/**
 * Checks if a string is in the UUID format.
 *
 * @param {string} str - The string to check.
 * @returns {boolean} True if the string is in the UUID format, false otherwise.
 */
export const isUUID = (str) => {
  const uuidRegex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  return uuidRegex.test(str);
};

export const isAutomaticChatName = (name) => {
  const parts = name?.split("_");
  if (parts?.length !== 2) return false;

  return (
    (name?.startsWith("ride_") ||
      name?.startsWith("driver_") ||
      name?.startsWith("client_")) &&
    isUUID(parts[1])
  );
};

export const getChatTypeByName = (name) => {
  if (name?.startsWith("ride_")) return "ride";
  if (name?.startsWith("driver_")) return "driver";
  if (name?.startsWith("client_")) return "client";

  return undefined;
};

export const getChatIdByName = (name) => {
  const parts = name?.split("_");
  if (parts.length === 2) return parts[1];

  return undefined;
};

export const filterPassiveRideChat = (chat) => {
  if (chat?.new_messages_count > 0) return true;

  return !isAutomaticChatName(chat?.name);
};

export const getFilterPassiveRideChatForClientById = (userId) => (chat) => {
  if (chat?.new_messages_count > 0) return true;

  if (chat?.name?.startsWith(`client_${userId}`)) return true;

  return !isAutomaticChatName(chat?.name);
};

export const reduceAutomaticDisplayName = (name) => {
  const parts = name?.replace(" ", "_").split("_");
  const idParts = parts[1]?.split("-");

  return `${parts[0]} #${idParts[0]}`;
};

export const getReadableChatName = (chat) => {
  if (!isAutomaticChatName(chat?.name)) {
    return chat?.display_name ?? "Deleted Chat";
  }
  return (
    reduceAutomaticDisplayName(chat?.display_name) ?? chat?.name ?? "No name"
  );
};

export const mapNewClientDataForSave = (data) => {
  const commonData = removeEmptyProps({
    id: data?.id,
    headOfficePlaceName: data?.headOfficePlaceName,
    companyName: data?.companyName,
    phoneNumber: data?.phoneNumber,
    email: data?.email,
    emailRepeat: data?.emailRepeat,
    headOfficePlaceId: data?.headOfficePlaceId,
    headOfficeAwsId: data?.headOfficeAwsId,
    specialityId: data?.specialityId,
    dueDate: data?.dueDate,
    invoicePolicy: data?.invoicePolicy,
    hasOwnContract: data?.hasOwnContract,
  });

  if (data?.hasOwnContract) {
    return {
      ...commonData,
      contract: {
        basePricePerMile: data?.basePricePerMile,
        baseDeliveryFee: data?.baseDeliveryFee,
        wheelchairPricePerMile: data?.wheelchairPricePerMile,
        wheelchairDeliveryFee: data?.wheelchairDeliveryFee,
        companyVehicleRate: data?.companyVehicleRate,
        privateVehicleRate: data?.privateVehicleRate,
        name: data?.contractName ?? "",
        freeWaitingTimeMin: data?.freeWaitingTimeMin,
        waitingRatePerMin: data?.waitingRatePerMin,
        recalculationMargin: data?.recalculationMargin,
      },
    };
  }

  return commonData;
};

export const mapClientDataForForm = (data) => {
  return {
    id: data?.id,
    companyName: data?.companyName,
    phoneNumber: data?.phoneNumber,
    email: data?.email,
    emailRepeat: data?.emailRepeat,
    headOfficePlaceId: data?.headOffice?.id,
    headOfficeAwsId: data?.headOffice?.awsId,
    specialityId: data?.specialityId,
    dueDate: data?.dueDate,
    invoicePolicy: data?.invoicePolicy,
    hasOwnContract: data?.hasOwnContract,
    basePricePerMile: data?.contract?.basePricePerMile,
    baseDeliveryFee: data?.contract?.baseDeliveryFee,
    wheelchairPricePerMile: data?.contract?.wheelchairPricePerMile,
    wheelchairDeliveryFee: data?.contract?.wheelchairDeliveryFee,
    companyVehicleRate: data?.contract?.companyVehicleRate,
    privateVehicleRate: data?.contract?.privateVehicleRate,
    contractName: data?.contract?.name ?? "",
    contractId: data?.contract?.id,
    freeWaitingTimeMin: data?.contract?.freeWaitingTimeMin,
    waitingRatePerMin: data?.contract?.waitingRatePerMin,
    recalculationMargin: data?.contract?.recalculationMargin,
  };
};

export const splitLine = ({ data = "", separator }) => {
  const regex = new RegExp(`${separator}(?=(?:[^"]*"[^"]*")*[^"]*$)`, "g");
  return separator === "|" ? data.split("|") : data.split(regex);
};

export const countQuotes = (str) => (str.match(/["]/g) || []).length;

export const trimQuotes = (str) => str.replace(/^["]|["]$/g, "");

export const parseCSV = ({
  data = "",
  isNamesInFirstRow = true,
  separator = ";",
  stripQuotes = false,
}) => {
  const lines = data.split(/\r?\n/).filter(Boolean);

  const rows = [];
  let cellCount = 0;
  let quotesCount = 0;

  const firstRow = splitLine({
    data: lines[0],
    separator,
  });

  const headerRow = isNamesInFirstRow
    ? firstRow
    : Array.from(
        { length: firstRow?.length },
        (_, index) => `Column ${index + 1}`
      );

  for (let i = isNamesInFirstRow ? 1 : 0; i < lines.length; i++) {
    const obj = [];
    const currentLine = splitLine({
      data: lines[i],
      separator,
    });

    for (let j = 0; j < currentLine.length; j++) {
      obj.push(
        (stripQuotes ? trimQuotes(currentLine[j]) : currentLine[j]).trim()
      );
      cellCount++;
      quotesCount += countQuotes(currentLine[j]);
    }
    rows.push(obj);
  }
  return {
    headerRow,
    rows,
    cellCount,
    quotesCount,
  };
};

export const getSorter = (sorter) => (a, b) => {
  const { name, order } = sorter;
  const sign = order === "asc" ? 1 : -1;
  if (a[name] < b[name]) {
    return -1 * sign;
  }
  if (a[name] > b[name]) {
    return 1 * sign;
  }
  return 0;
};
export const toUnixTimestamp = (date) => Math.floor(date.getTime());
export const toUnixTimestampInSec = (date) => Math.floor(date.getTime() / 1000);
export const fromUnixTimestamp = (timestamp) => new Date(timestamp * 1000);
export const getUnixTimestamp = () => Math.floor(Date.now() / 1000);
export const getUnixTimestampMs = () => Date.now();
export const getUnixTimestampFromMs = (ms) => Math.floor(ms / 1000);

export const unixTimeInMsToDate = (unixTimestampInMs) => {
  const date = new Date(unixTimestampInMs);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export const getWeekRangeByDateFromMonday = (date = new Date()) => {
  const dayOfWeek = (date.getDay() + 6) % 7; // 0 (Monday) to 6 (Sunday)
  const startOfWeek = new Date(date);
  const endOfWeek = new Date(date);

  startOfWeek.setDate(date.getDate() - dayOfWeek);
  endOfWeek.setDate(date.getDate() + (6 - dayOfWeek));

  // Reset time to the start of the day for both dates
  startOfWeek.setHours(0, 0, 0, 0);
  endOfWeek.setHours(23, 59, 59, 999);

  return [startOfWeek, endOfWeek];
};

export const getWeekRangeByDateFromSunday = (date = new Date()) => {
  const dayOfWeek = date.getDay(); // 0 (Sunday) to 6 (Saturday)
  const startOfWeek = new Date(date);
  const endOfWeek = new Date(date);

  startOfWeek.setDate(date.getDate() - dayOfWeek);
  endOfWeek.setDate(date.getDate() + (6 - dayOfWeek));

  // Reset time to the start of the day for both dates
  startOfWeek.setHours(0, 0, 0, 0);
  endOfWeek.setHours(23, 59, 59, 999);

  return [startOfWeek, endOfWeek];
};

export const returnShiftedDateByDays = (date, days) => {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + days);
  return newDate;
};

export const getShortDayName = (dayIndex) => {
  const date = new Date();
  date.setDate(date.getDate() - date.getDay() + dayIndex + 1);
  return date.toLocaleString("en-US", { weekday: "short" });
};

export const getShortDayNameFromSunday = (dayIndex) => {
  const date = new Date();
  date.setDate(date.getDate() - date.getDay() + dayIndex);
  return date.toLocaleString("en-US", { weekday: "short" });
};

export const getDateInWeekByIndexFromStartDay = (startDay, dayIndex) => {
  const date = new Date(startDay);
  date.setDate(date.getDate() + dayIndex);
  return `${date.getMonth() + 1}/${date.getDate()}`;
};

export const getRidesByIndexOfTheDayInWeekFromStartDate = ({
  rides,
  startDate,
  dayIndex,
}) => {
  const date = new Date(startDate);
  date.setDate(date.getDate() + dayIndex);

  return rides.filter(
    (ride) =>
      new Date(ride?.plannedStartTime).getDate() === date.getDate() &&
      new Date(ride?.plannedStartTime).getMonth() === date.getMonth()
  );
};

export const formatPhoneNumber = (phoneNumber) => {
  const cleaned = (String(phoneNumber)).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  return null;
};
