import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Stack, styled } from "@mui/material";
import * as SC from "SC";
import { BackButton } from "components/BackButton";
import { useDataGrid } from "hooks/useDataGrid";
import { useAppSelector } from "store/hooks";
import { DataGrid } from "UI/DataGrid";
import { useAddClient } from "hooks/useAddClient";
import { useSpecialityOptions } from "hooks/useSpecialityOptions";
import { getClients } from "api/clientApi";
import { TextFieldWithEllipsis } from "components/TextFiledWithEllipsis";
import { AddClientDialog } from "components/AddClientDialog";

const Content = styled(Stack)`
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 10px;

  height: 100%;

  overflow-y: auto;
  overflow-x: hidden;
`;

const columns = [
    {
      label: "Client",
      field: "companyName",
      xs: 2.5,
      sm: 2.5,
      md: 2.5,
      sortableName: "COMPANY_NAME",
    },
    {
      label: "Email",
      field: "email",
      xs: 2.5,
      sm: 2.5,
      md: 2.5,
      sortableName: "EMAIL",
      renderer: ({ email }) => (
        <TextFieldWithEllipsis>{email}</TextFieldWithEllipsis>
      ),
    },
    {
      label: "Phone Number",
      field: "phoneNumber",
      xs: 1.5,
      sm: 1.5,
      md: 1.5,
      sortableName: "PHONE",
    },
    {
      label: "Main Office Address",
      field: "address",
      xs: 4,
      sm: 4,
      md: 4,
      sortableName: "MAIN_ADDRESS",
      // renderer: (row) => row?.headOffice?.placeLabel,
      renderer: (row) => (
        <TextFieldWithEllipsis>
          {row?.headOffice?.placeLabel}
        </TextFieldWithEllipsis>
      ),
    },
    {
      label: "Speciality",
      field: "speciality",
      xs: 1.5,
      sm: 1.5,
      md: 1.5,
      sortableName: "SPECIALITY",
    },
  ];

export const ManageClients = () => {
  const navigate = useNavigate();
  const userId = useAppSelector((state) => state.user.id);
  // const [currentClient, setCurrentClient] = useState();
  const { isAddClientVisible, setIsAddClientVisible } = useAddClient();
  const { specialityOptions, loadSpecialities } = useSpecialityOptions();
  const role = useAppSelector((state) => state.user.role);

  const dataGrid = useDataGrid({
    apiHandler: getClients,
    defaultSorter: {
      name: "COMPANY_NAME",
      order: "ASC",
    },
    defaultLimit: 25,
    gridConfigIndex: `${userId}-kerico-clients`,
  });

  const handleRowClick = (row) => {
    navigate(`/settings/manage_clients/${row?.id}`);
  };

  return (
    <SC.Container>
      <SC.Layout>
        <Stack direction={"row"} alignItems={"center"}>
          <BackButton />
          <SC.Title>Manage Clients</SC.Title>
          <Button
            variant="contained"
            onClick={() => setIsAddClientVisible(true)}
            sx={{ marginLeft: "1rem" }}
          >
            Add Client
          </Button>
        </Stack>
        <Content>
          <DataGrid
            columns={columns}
            handleRowClick={handleRowClick}
            {...dataGrid}
          />
        </Content>
      </SC.Layout>
      {["SUPER_ADMIN", "KERICO_MANAGER"].includes(role) && (
        <AddClientDialog
          open={isAddClientVisible}
          handleClose={() => setIsAddClientVisible(false)}
          refreshList={dataGrid.loadData}
          specialityOptions={specialityOptions}
          loadSpecialities={loadSpecialities}
        />
      )}
    </SC.Container>
  );
};
