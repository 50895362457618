import { Box, Button, Divider, Stack, styled } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Title } from "./DriverDetails.style";
import { customColors } from "models/customColors";
import { useApiRequest } from "api/useApiRequest";
import { deleteChat, getChatList } from "api/messageApi";
import CreateNewChatDialog from "./CreateNewChatDialog";
import { EditChatDialog } from "./EditChatDialog";
import { GlobalContext } from "store/globalContext";
import { ReactComponent as DoneIcon } from "assets/img/booking-scheduled-icon.svg";
import { EditChatUsersDialog } from "./EditChatUsersDialog";
import { useSearchInList } from "hooks/useSearchInList";
import { DataGridSearch } from "UI/DataGridSearch";
import { getReadableChatName, isAutomaticChatName } from "utils";
import { ChatsSelector } from "./ChatsSelector";
import { hiddenChatNames } from "models/constants";

const Container = styled(Stack)`
  flex: 1 1 auto;
  padding: 0 40px;
  gap: 15px;
`;

const Description = styled(Box)`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

  color: ${({ theme }) => customColors[theme.palette.mode].greyText};
`;

const ChatName = styled(Box)`
  font-family: Inter;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: ${({ theme }) => customColors[theme.palette.mode].blackText};
`;

const Purpose = styled(Box)`
  font-family: Inter;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: ${({ theme }) => customColors[theme.palette.mode].greyText};
`;

/** @type {import("types/commonTypes").GetAllChatsQueryParams} */
const defaultChatRequestParams = {
  include_deleted: false,
  not_associated_to_group: "not_associated_to_group",
};

export const ChatManagement = () => {
  const { openConfirmationWithCallback, showWarning } =
    useContext(GlobalContext);
  const { callApiRequest } = useApiRequest();
  const {
    search,
    clearSearch,
    handleChangeSearch,
    getFilterSearchForPropArrayHandler,
  } = useSearchInList();
  const [chats, setChats] = useState(null);
  const [currentChat, setCurrentChat] = useState(null);
  const [currentChatWithUsers, setCurrentChatWithUsers] = useState(null);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [mode, setMode] = useState("General");

  const sortChats = (chats) =>
    chats
      .filter((chat) => !hiddenChatNames.includes(chat?.name))
      .sort((a, b) => a.name.localeCompare(b.name));

  const loadData = async () => {
    callApiRequest({
      apiRequest: getChatList,
      params: defaultChatRequestParams,
      onSuccess: (result) => {
        setChats(sortChats(result));
      },
    });
  };

  const handleDeleteChat = (chat) =>
    openConfirmationWithCallback({
      title: "Delete Chat",
      message: (
        <>
          The "{getReadableChatName(chat)}" chat will be deleted. <br /> Are you
          sure?
        </>
      ),
      buttonLabels: ["Cancel", "Continue"],
      callbackFn: () => {
        callApiRequest({
          apiRequest: deleteChat,
          params: chat.id,
          onSuccess: () => {
            showWarning({
              title: `Chat Deleted`,
              content: `The Chat "${chat?.display_name}" has been successfully deleted`,
              icon: <DoneIcon />,
            });
            loadData();
          },
        });
      },
    });

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentChat) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChat]);

  const filterChatByType = (chat) => {
    if (mode === "General") {
      return !isAutomaticChatName(chat?.name);
    }

    if (!isAutomaticChatName(chat?.name)) {
      return false;
    }

    switch (mode) {
      case "Ride":
        return chat?.name?.startsWith("ride_");
      case "Driver":
        return chat?.name?.startsWith("driver");
      case "Client":
        return chat?.name?.startsWith("client");
      default:
        return false;
    }
  };

  const resultChats = chats
    ?.filter(
      getFilterSearchForPropArrayHandler(search, ["display_name", "purpose"])
    )
    ?.filter(filterChatByType);

  return (
    <Container>
      <Title>Chat Management</Title>
      <Description>You can create or delete group chat here</Description>
      <Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            loadData();
            setIsAddDialogOpen(true);
          }}
        >
          Create Group Chat
        </Button>
      </Box>
      <Stack mt={2} maxWidth={"640px"} gap={1}>
        <Description>Chat list</Description>
        <Stack direction={"row"} gap={1}>
          <ChatsSelector mode={mode} setMode={setMode} />
          <DataGridSearch
            search={search}
            handleChangeSearch={handleChangeSearch}
            clearSearch={clearSearch}
          />
        </Stack>
        {resultChats?.map((chat) => {
          const isAutomaticChat = isAutomaticChatName(chat?.name);

          return (
            <React.Fragment key={chat.id}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={2}
              >
                <Stack>
                  <ChatName>{getReadableChatName(chat)}</ChatName>
                  <Purpose>{chat?.purpose}</Purpose>
                </Stack>
                <Stack direction={"row"} gap={1}>
                  {!isAutomaticChat && (
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => setCurrentChat(chat)}
                      disabled={isAutomaticChat}
                    >
                      Edit
                    </Button>
                  )}
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => setCurrentChatWithUsers(chat)}
                  >
                    Users
                  </Button>
                  {!isAutomaticChat && (
                    <Button
                      variant="text"
                      color="error"
                      onClick={() => handleDeleteChat(chat)}
                    >
                      Delete
                    </Button>
                  )}
                </Stack>
              </Stack>
              <Divider />
            </React.Fragment>
          );
        })}
      </Stack>
      <CreateNewChatDialog
        open={isAddDialogOpen}
        handleClose={() => setIsAddDialogOpen(false)}
        refreshList={loadData}
        setCurrentChat={setCurrentChatWithUsers}
        chats={chats}
      />
      <EditChatDialog
        chat={currentChat}
        handleClose={() => setCurrentChat(null)}
        refreshList={loadData}
        chats={chats}
      />
      <EditChatUsersDialog
        chat={currentChatWithUsers}
        handleClose={() => setCurrentChatWithUsers(null)}
        refreshList={loadData}
      />
    </Container>
  );
};
