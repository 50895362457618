import { getGeofence } from "api/geoApi";
import { useApiRequest } from "api/useApiRequest";
import { useEffect, useState } from "react";

export const useGeofenceOptions = () => {
  const { callApiRequest } = useApiRequest();
  const [countiesOptions, setCountiesOptions] = useState([]);

  const getCountyLabelByCode = (code) => {
    const result = countiesOptions[code]?.label;
    return result;
  };

  useEffect(() => {
    const loadGeofence = () =>
      callApiRequest({
        apiRequest: getGeofence,
        onSuccess: (result) => {
          const resultCountiesOptions = (Array.isArray(result) ? result : [])
            .map(({ uuid, label }) => ({
              value: uuid,
              label,
            }))
            .sort((a, b) => (a.label < b.label ? -1 : 1));
          setCountiesOptions(resultCountiesOptions);
        },
      });

    loadGeofence();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    countiesOptions,
    setCountiesOptions,
    getCountyLabelByCode,
  };
};
