import { useApiRequest } from "api/useApiRequest";
import { getDriverDetails } from "api/userApi";
import { useCallback } from "react";
import { useEffect, useState } from "react";
import { useAppDispatch } from "store/hooks";
import { showError } from "store/reducers/errorReducer";

/** @type {import("types/commonTypes").DriverDetails | null} */
const defaultDriver = null;

export const useDriverDetails = (id) => {
  const dispatch = useAppDispatch();
  const { callApiRequest } = useApiRequest();
  const [driver, setDriver] = useState(defaultDriver);

  const loadDriverDetails = useCallback(async () => {
    callApiRequest({
      apiRequest: getDriverDetails,
      params: id,
      onSuccess: (result) => {
        setDriver(result);
      },
      onError: (error) => {
        dispatch(showError(error));
        setDriver(defaultDriver);
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id) {
      loadDriverDetails();
    }
  }, [id, loadDriverDetails]);

  return {
    driver,
    setDriver,
    loadDriverDetails,
  };
};
