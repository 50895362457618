import React, { useContext, useState } from "react";
import { Box, TextField, Autocomplete, styled } from "@mui/material";
import { ReactComponent as DoneIcon } from "assets/img/booking-scheduled-icon.svg";
import { GlobalContext } from "store/globalContext";
import { customColors } from "models/customColors";

const Container = styled(Autocomplete)`
  width: 100%;

  & label {
    color: ${({ theme }) => customColors[theme.palette.mode].actionColor};
  }
`;

export default function CountrySelect({
  counties,
  getValues,
  setValue,
  setSelectedCounties,
}) {
  const { showWarning } = useContext(GlobalContext);
  const [list, setList] = useState([]);

  const onSelect = (option) => {
    const counties = getValues("counties");
    const isAlreadySelected =
      counties.filter((value) => value === option?.value)?.length > 0;

    if (isAlreadySelected) {
      showWarning({
        title: "Already selected",
        content: "This county is already selected.",
        icon: <DoneIcon />,
      });
      return;
    }
    const newValue = [...counties, option?.value];
    setSelectedCounties(newValue);
    setValue("counties", newValue, { shouldDirty: true });
  };

  const handleChange = async (e) => {
    const search = e?.target?.value;

    if (search?.length > 1) {
      const result = counties.filter(({ label }) =>
        label?.toLowerCase().includes(search.toLowerCase())
      );
      setList(result);
      return;
    }

    if (!search || search === "" || search?.length < 2) {
      setList([]);
    }
  };

  const handleSelect = (e, option) => {
    if (!option?.value) return;
    onSelect(option);
    setList([]);
  };

  const handleClose = () => {
    setList([]);
  };

  return (
    <Container
      id="county"
      value={null}
      options={list}
      autoHighlight
      // @ts-ignore
      noOptionsText={"Start typing county name"}
      // @ts-ignore
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {
            // @ts-ignore
            option.label
          }
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Enter county or state name"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password",
          }}
          size="small"
        />
      )}
      onChange={handleSelect}
      onClose={handleClose}
      onInputChange={handleChange}
      blurOnSelect
      clearOnBlur
      clearOnEscape
      autoComplete
    />
  );
}
