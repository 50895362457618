import React from "react";
import { Stack, TextField, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { customColors } from "models/customColors";

export const DataGridSearch = ({ search, handleChangeSearch, clearSearch }) => {
  const theme = useTheme();

  return (
    <Stack
      flex={1}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      gap={2}
      minWidth={"120px"}
    >
      <TextField
        value={search}
        onChange={handleChangeSearch}
        placeholder="Search"
        InputProps={{
          inputProps: {
            autoComplete: "off",
          },
          startAdornment: (
            <SearchIcon
              sx={{ color: customColors.grayText, margin: "0 10px" }}
            />
          ),
          endAdornment: !search.length ? null : (
            <ClearIcon
              onClick={clearSearch}
              sx={{ cursor: "pointer", margin: "0 10px" }}
            />
          ),
          sx: {
            height: "40px",
            borderRadius: "5px",
            color:
              search === ""
                ? customColors[theme.palette.mode].greyText
                : customColors[theme.palette.mode].actionColor,
            backgroundColor:
              search === ""
                ? customColors[theme.palette.mode].inputBackk
                : customColors[theme.palette.mode].additionalStroke,
          },
        }}
        sx={{
          flex: 1,
          height: "40px",
          borderRadius: "5px",
        }}
        variant="outlined"
        size="medium"
      />
    </Stack>
  );
};
