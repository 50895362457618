import { localStorageNameEnum } from "../models/constants";
import { statusMessages } from "./httpService";

const isDev = process.env.REACT_APP_USER_BRANCH === "dev";

/**
 * Returns only two variants of the result:
 *  1) In case of successful result:  { value: <returned value> }
 *  2) When something goes wrong: { error: <error message> }
 * @param {{ url: string; method: string; data: FormData }} props
 * @template T
 * @returns {Promise<import("types/commonTypes").HttpResult<T>>}
 */
export const httpUploadService = async ({ url, method, data }) => {
  const storedToken = window.sessionStorage.getItem(
    localStorageNameEnum.AUTH_TOKEN
  );

  const headers = {
    Accept: "application/json",
    type: "formData",
  };
  headers.Authorization = `Bearer ${storedToken}`;

  try {
    const response = await fetch(
      `${
        isDev
          ? process.env.REACT_APP_API_DEV_URL
          : process.env.REACT_APP_API_URL
      }${url}`,
      {
        headers,
        method,
        body: data,
      }
    );

    if (response?.status === 401 || response?.status === 403) {
      throw new Error(statusMessages[response?.status]);
    }

    const contentType = response?.headers?.get("Content-Type");
    let json;

    if (contentType === "application/json") {
      try {
        json = await response.json();
      } catch (e) {
        throw new Error(`JSON Error in API response: ${e.message}`);
      }
    }

    if (response?.status !== 200) {
      throw new Error(
        response?.status
          ? json?.message && json.message !== ""
            ? json.message
            : statusMessages[response?.status]
          : "Network Error"
      );
    }

    return {
      value: json,
    };
  } catch (error) {
    console.error(`httpService`, error);
    return {
      error: String(error.message).replace(/Error:/g, ""),
      // error: 'Network error'
    };
  }
};
