import React, { useEffect, useState } from "react";
import { Map, Marker, ColorScheme } from "mapkit-react";
import { getBounds, getCenter } from "geolib";
import { useTheme } from "@mui/material";

const ZOOM_FACTOR_FOR_COORDS_DELTA = 2;
const MIN_COORDS_DELTA = 0.002;
const POI_OF_DELAY_IN_MS = 500;
const RELOAD_DELAY_IN_MS = 500;

export const MultiMapView = ({ markers, reloadSignal }) => {
  const theme = useTheme();
  const [initialRegion, setInitialRegion] = useState();
  const [showsPointsOfInterest, setShowsPointsOfInterest] = useState(true);
  const [isVisible, setIsVisible] = useState(true);

  const handleOnLoad = () => {
    setTimeout(() => {
      setShowsPointsOfInterest(false);
    }, POI_OF_DELAY_IN_MS);
  };

  useEffect(() => {
    if (markers?.length) {
      const points = markers.map(({ coordinates: { lat, lon } }) => ({
        latitude: lat,
        longitude: lon,
      }));

      const { maxLat, minLat, maxLng, minLng } = getBounds(points);
      const center = getCenter(points);
      const latitudeDelta = maxLat - minLat;
      const longitudeDelta = maxLng - minLng;
      const maxDelta =
        latitudeDelta > longitudeDelta ? latitudeDelta : longitudeDelta;
      const visibleDelta =
        maxDelta * ZOOM_FACTOR_FOR_COORDS_DELTA < MIN_COORDS_DELTA
          ? MIN_COORDS_DELTA
          : maxDelta * ZOOM_FACTOR_FOR_COORDS_DELTA;

      setInitialRegion({
        // @ts-ignore
        centerLatitude: center?.latitude ?? 0,
        // @ts-ignore
        centerLongitude: center?.longitude ?? 0,
        latitudeDelta: visibleDelta,
        longitudeDelta: visibleDelta,
      });
    }
  }, [markers]);

  useEffect(() => {
    setIsVisible(false);

    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, RELOAD_DELAY_IN_MS);

    return () => clearTimeout(timeout);
  }, [reloadSignal]);

  return isVisible && !!initialRegion ? (
    <Map
      token={process.env.REACT_APP_MAP_TOKEN}
      showsScale={2}
      distances={0}
      initialRegion={initialRegion}
      cameraBoundary={initialRegion}
      showsCompass={1}
      showsPointsOfInterest={showsPointsOfInterest}
      onLoad={handleOnLoad}
      isScrollEnabled={true}
      showsUserLocationControl={false}
      allowWheelToZoom={true}
      isZoomEnabled={true}
      colorScheme={
        theme.palette.mode === "dark" ? ColorScheme.Dark : ColorScheme.Light
      }
    >
      {markers.map(({ id, placeLabel, coordinates: { lat, lon } }) => (
        <Marker
          key={id}
          subtitle={placeLabel}
          subtitleVisibility={1}
          latitude={lat}
          longitude={lon}
        />
      ))}
    </Map>
  ) : null;
};
