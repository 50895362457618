import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setNavigationData } from "store/reducers/navigationDataReducer";

export const useProfileTabOpener = ({ handleTabOpen}) => {
    const dispatch = useAppDispatch();
    const targetSettingsTab = useAppSelector(
      (state) => state.navigationData?.targetSettingsTab
    );

    useEffect(() => {
        if (targetSettingsTab) {
          handleTabOpen();
          dispatch(setNavigationData({}));
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [targetSettingsTab]);

    return {
       
    };
};