import { Box, Stack, styled } from "@mui/material";
import { customColors } from "models/customColors";

export const Title = styled(Box)`
  font-family: "Inter Semi Bold";
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;
export const Label = styled(Box)`
  font-size: 12px;
  user-select: none;
`;

export const Info = styled(Box)`
  font-size: 14px;
  color: ${({ theme }) => customColors[theme.palette.mode].actionColor};
  user-select: none;
`;

export const Mapper = styled(Stack)`
  align-items: stretch;
  overflow-y: auto;
  gap: 16px;
`;

export const ButtonGroup = styled(Stack)`
  flex-direction: row;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 20px 0 0;
  gap: 10px;
`;

export const UnactiveItem = styled(Stack)`
  flex: 0 1 auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 6px;

  padding: 0 3px 0 6px;
  justify-content: center;
  align-items: center;
  height: 20px;

  font-size: 12px;
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].additionalStroke};
  border: 1px solid ${({ theme }) => customColors[theme.palette.mode].textBody};
  border-radius: 22px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;
`;
