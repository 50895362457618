import { useApiRequest } from "api/useApiRequest";
import { updateCarApproval } from "api/vehicleApi";
import { ReactComponent as CompleteIcon } from "assets/img/complete-icon.svg";
import { useContext } from "react";
import { GlobalContext } from "store/globalContext";

export const useApproveCarRequest = ({ refreshList, closeDetails }) => {
  const { callApiRequest } = useApiRequest();
  const { showWarning } = useContext(GlobalContext);

  const approveCarRequest = (carId) => {
    /** @type {import("types/commonTypes").ApprovalDto} */
    const data = {
      approved: true,
    };

    callApiRequest({
      apiRequest: updateCarApproval,
      params: {
        data,
        carId,
      },
      onSuccess: () => {
        refreshList();
        closeDetails();
        showWarning({
          title: "Vehicle Approved",
          content: "Vehicle successfully approved!",
          icon: <CompleteIcon />,
        });
      },
    });
  };

  return {
    approveCarRequest,
  };
};
