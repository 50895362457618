import {
  CHAT_NAME_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  TEXT_FIELD_MAX_LENGTH,
  USERNAME_MAX_LENGTH,
} from "models/constants";

export const required = "is required";

export const notNullPriceField = {
  required,
  validate: (value) => {
    const price = Number(value);
    if (price > 0) return true;
    return "is required and should be more than 0";
  },
};

export const getChatNameValidation = (chats) => ({
  required,
  maxLength: {
    value: CHAT_NAME_MAX_LENGTH,
    message: `Max name length is ${CHAT_NAME_MAX_LENGTH}`,
  },

  validate: (value) => {
    const regex = /^[a-zA-Z0-9 _]*$/;
    if (!regex.test(value)) return "Use letters, numbers, and spaces only";
    if (!chats.find((chat) => chat?.display_name === value)) return true;
    return "Already exists";
  },
});

export const priceField = {
  required,
  validate: (value) => {
    const price = Number(value);
    if (price >= 0 && price <= 100000) return true;
    return "should be from 0 to 100000";
  },
};

export const nonEmptyTextField = {
  required,
  validate: (value) => {
    const str = value.toString();
    if (str.replace(/\s/g, "").length === 0) return required;
    return true;
  },
  maxLength: {
    value: TEXT_FIELD_MAX_LENGTH,
    message: `Max length is ${TEXT_FIELD_MAX_LENGTH}`,
  },
};

export const getSerialNumberValidation = (translatedMessage) => ({
  validate: {
    length: (value) => (value?.length && value.length > 0) || translatedMessage,
    notEnoughDigits: (value) => !value.match(/_/i) || "All digits are required",
  },
});

export const rangeUnitNameValidation = {
  required,
  validate: (value) => {
    if (value < 0) {
      return "Use positive number";
    }
    return true;
  },
};

export const phoneNumberValidation = {
  validate: (value) => {
    const str = value?.toString() ?? "_";

    if (str.includes("_")) {
      return "All digits are required";
    }
    return true;
  },
};

export const phoneNumberRequiredValidation = {
  ...phoneNumberValidation,
  required,
};

export const emailPattern = {
  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  //value: /^\S+@\S+$/i,
  message: "Invalid email address",
};

export const emailValidation = {
  required,
  pattern: emailPattern,
};

export const passwordValidation = {
  required,
  validate: {
    minLength: (value) =>
      value.length >= PASSWORD_MIN_LENGTH ||
      `Min length is ${PASSWORD_MIN_LENGTH}`,
    // noWhiteSpaces: value =>
    //   !!value.match(RegExp('^[^\\s]*$')) || 'need no whitespaces',
    oneLowercase: (value) =>
      !!value.match(RegExp("(.*[a-z].*)")) ||
      "need one lowercase character at least",
    oneUppercase: (value) =>
      !!value.match(RegExp("(.*[A-Z].*)")) ||
      "need one uppercase character at least",
    oneNumber: (value) =>
      !!value.match(RegExp("(.*\\d.*)")) ||
      "need one number character at least",
    oneSpecialChar: (value) =>
      !!value.match(RegExp('[!@#$%^&*(),.?":{}|<>]')) ||
      "need one special character at least (e.g “!@#$%^&*)",
  },
};

export const getPasswordConfirmValidation = (password) => ({
  validate: (value) => value === password || "Passwords must match",
});

export const nameValidation = {
  required,
  pattern: {
    value: /^[a-z\s]+$/i,
    message: "Use letters only",
  },
  maxLength: {
    value: USERNAME_MAX_LENGTH,
    message: `Max length is ${USERNAME_MAX_LENGTH}`,
  },
};

export const clientNameValidation = {
  required,
  maxLength: {
    value: USERNAME_MAX_LENGTH,
    message: `Max length is ${USERNAME_MAX_LENGTH}`,
  },
};

export const getEmailConfirmValidation = (email) => ({
  validate: (value) => value === email || "Email must match",
});
