import { Box, Stack, styled } from "@mui/material";
import { customColors } from "models/customColors";

export const Info = styled(Stack)`
  font-size: 0.9rem;
  font-weight: normal;
  color: ${({ theme }) => customColors[theme.palette.mode].greyText};
`;

export const Prop = styled(Stack)`
  flex-direction: row;
  justify-content: center;
  gap: 6px;

  font-size: 0.9rem;
`;

export const Label = styled(Box)`
  color: ${({ theme }) => customColors[theme.palette.mode].greyText};
`;
export const Value = styled(Box)`
  color: ${({ theme }) => customColors[theme.palette.mode].text};
`;

export const Container = styled(Stack)`
  width: 70vw;
  max-width: 640px;
  height: 30vh;
  min-height: 250px;
  justify-content: space-between;
  font-size: 0.9rem;
`;

export const CheckContainer = styled(Container)`
  width: 50vw;
  max-width: 480px;
  min-height: 220px;
`;

export const TableData = styled(Stack)`
  flex: 1 1 auto;
  height: 100%;
  overflow: hidden;

  justify-content: space-between;
  align-items: stretch;
`;

export const ReportsHolder = styled(Stack)`
  flex: 1 1 auto;
  height: 100%;
  overflow-y: auto;
`;

export const Status = styled(Box)`
  &.Pending {
    color: ${({ theme }) => customColors[theme.palette.mode].delayed};
  }
  &.Completed {
    color: ${({ theme }) => customColors[theme.palette.mode].success};
  }
  &.Consider {
    color: ${({ theme }) => customColors[theme.palette.mode].error};
  }
`;
