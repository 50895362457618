import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { Button, InputLabel, Stack } from "@mui/material";
import { DialogWindow, FormTextField } from "UI";
import { getChatNameValidation } from "models/validations";
import { useFormDataSync } from "hooks/useFormDataSync";
import { GlobalContext } from "store/globalContext";
import { useApiRequest } from "api/useApiRequest";
import { createNewChat } from "api/messageApi";
import { ReactComponent as DoneIcon } from "assets/img/booking-scheduled-icon.svg";
import { useAppSelector } from "store/hooks";
import { createNameFromLabel } from "utils";

const CreateNewChatDialog = ({
  open,
  handleClose,
  refreshList,
  setCurrentChat,
  chats,
}) => {
  const spinner = useAppSelector((state) => state.spinner);
  const { showWarning } = useContext(GlobalContext);
  const { callApiRequest } = useApiRequest();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      display_name: "",
    },
  });

  const { handleConfirmedClose, handleForcedClose } = useFormDataSync({
    isDirty,
    handleClose,
    open,
    reset,
  });

  const onSubmit = (data) => {
    const { display_name } = data;

    const params = {
      name: createNameFromLabel(display_name),
      team_id: process.env.REACT_APP_MATTERMOST_TEAM_ID,
      display_name,
      type: "O",
      purpose: "",
      header: "",
    };

    callApiRequest({
      apiRequest: createNewChat,
      params,
      onSuccess: (result) => {
        setCurrentChat(result);
        refreshList();
        handleForcedClose();
        showWarning({
          title: `Chat Added`,
          content: `The Chat "${result?.display_name}" has been successfully added`,
          icon: <DoneIcon />,
        });
      },
    });
  };

  return (
    <DialogWindow
      open={open}
      withCloseButton
      handleClose={handleConfirmedClose}
      title={"Create New Chat"}
      content={
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={5}>
            <Stack alignItems={"flex-start"}>
              <InputLabel htmlFor="display_name">Enter Chat Name</InputLabel>
              <FormTextField
                id="display_name"
                name="display_name"
                control={control}
                rules={getChatNameValidation(chats)}
                InputProps={{
                  autoComplete: "off",
                }}
              />
            </Stack>
            <Button
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={spinner}
            >
              Create Group Chat
            </Button>
          </Stack>
        </form>
      }
    />
  );
};

export default CreateNewChatDialog;
