import { Button, Stack, Tooltip } from "@mui/material";
import * as SC from "SC";
import { ReactComponent as CalculatorIcon } from "assets/img/calculator-icon.svg";
import { ReactComponent as CalendarViewIcon } from "assets/img/calender-view-icon.svg";
import { ReactComponent as TableViewIcon } from "assets/img/table-view-icon.svg";
import { viewModes } from "components/BookingsViewModeSelector";
import { CalculatorDialog } from "components/CalculatorDialog";
import { Content } from "pages/BookingsPage.style";
import { useState } from "react";
import { useAppSelector } from "store/hooks";
import { CalendarView } from "../components/CalendarView";
import { useTableView } from "hooks/useTableView";
import { TableView } from "components/TableView";
import { useClientList } from "hooks/useClientList";

export const ActionPanel = ({ navigate, setIsCalculatorOpen }) => (
  <Stack direction={"row"} gap={1} maxHeight={"40px"}>
    <Button
      variant="contained"
      color="primary"
      onClick={() => navigate("/booking/schedule_a_ride")}
    >
      Schedule a Ride
    </Button>
    <Button
      variant="outlined"
      color="primary"
      onClick={() => navigate("/requests_files")}
    >
      Request-Files
    </Button>
    <Tooltip title="Calculate Ride Costs">
      <Button
        variant="outlined"
        sx={{ padding: "0 6px", minWidth: "unset" }}
        onClick={() => setIsCalculatorOpen(true)}
      >
        <CalculatorIcon />
      </Button>
    </Tooltip>
  </Stack>
);

const viewModeOptions = [
  {
    icon: <TableViewIcon />,
    handleRefresh: () => null,
    tooltip: "List View",
  },
  {
    icon: <CalendarViewIcon />,
    handleRefresh: () => null,
    tooltip: "Weekly View",
  },
];

export const BookingsPage = () => {
  const mode = useAppSelector((state) => state.layout.bookingViewMode ?? 0);
  const [selectedId, setSelectedId] = useState("");
  const [isCalculatorOpen, setIsCalculatorOpen] = useState(false);
  const { getClientLabelById } = useClientList();

  const { dataGrid, updatedViewModeOptions } = useTableView({
    viewModeOptions,
  });

  return (
    <SC.Container>
      <Stack direction={"row"} height={"100%"} position={"relative"}>
        <Content>
          {mode === viewModes.CALENDAR && (
            <CalendarView
              selectedId={selectedId}
              viewModeOptions={viewModeOptions}
              dataGrid={dataGrid}
              setIsCalculatorOpen={setIsCalculatorOpen}
              setSelectedId={setSelectedId}
              getClientLabelById={getClientLabelById}
            />
          )}
          {mode === viewModes.TABLE && (
            <TableView
              dataGrid={dataGrid}
              selectedId={selectedId}
              setIsCalculatorOpen={setIsCalculatorOpen}
              setSelectedId={setSelectedId}
              viewModeOptions={updatedViewModeOptions}
              getClientLabelById={getClientLabelById}
            />
          )}
          {isCalculatorOpen && (
            <CalculatorDialog
              open={isCalculatorOpen}
              handleClose={() => setIsCalculatorOpen(false)}
            />
          )}
        </Content>
      </Stack>
    </SC.Container>
  );
};
