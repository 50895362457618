import React, { useContext, useEffect } from "react";
import { GlobalContext } from "store/globalContext";
import { Box, Button, Stack, styled } from "@mui/material";
import { customColors } from "models/customColors";
import { useApiRequest } from "api/useApiRequest";
import { createRide, createScheduledRide, estimateRide } from "api/rideApi";
import { convertToReadable } from "utils";
import { Title } from "pages/CreateBookingPage";
import { ProgressBar } from "./ProgressIndicator";
import { ReactComponent as VanIcon } from "assets/img/van-icon.svg";
import { useNavigate } from "react-router-dom";
import { useIsDataChanged } from "hooks/useIsDataChanged";
import { ReactComponent as DoneIcon } from "assets/img/booking-scheduled-icon.svg";
import { RideData } from "./RideData";

const Container = styled(Stack)`
  max-height: 100%;
  overflow-y: auto;
`;

export const ResultTitle = styled(Box)`
  font-family: "Inter Semi Bold";
  font-size: 24px;
  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
`;

export const Header = styled(Stack)`
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
`;

export const StatisticItem = styled(Stack)`
  flex: 1 1 auto;
  padding: 12px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 13px;
  border: 1px solid
    ${({ theme }) => customColors[theme.palette.mode].additionalStroke};
  background: ${({ theme }) => customColors[theme.palette.mode].background};
`;

export const StatisticLabel = styled(Box)`
  color: ${({ theme }) => customColors[theme.palette.mode].placeHolder};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`;

export const StatisticValue = styled(Box)`
  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.48px;
`;

export const StatisticUnits = styled("span")`
  color: ${({ theme }) => customColors[theme.palette.mode].placeHolder};
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
`;

export const Prop = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  width: 100%;
  max-width: 100%;
`;

export const Label = styled(Box)`
  font-family: Inter;
  font-size: 16px;
  color: ${({ theme }) => customColors[theme.palette.mode].placeHolder};
`;

export const Value = styled(Box)`
  flex: 1 1 auto;
  font-family: "Inter Medium";
  font-size: 16px;
  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
  text-align: left;
  overflow-wrap: anywhere;
  word-wrap: anywhere;
`;

export const Option = styled(Stack)`
  padding: 5px 15px;
  justify-content: center;
  font-size: 15px;
  align-items: center;
  border-radius: 8px;
  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].additionalStroke};
`;

export const Price = styled(Option)`
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  color: ${({ theme }) => customColors[theme.palette.mode].white};
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].actionColor};
`;

export const VehicleType = ({ vehicleType }) => (
  <Option>
    <Stack direction={"row"} alignItems={"center"} gap={1}>
      {vehicleType === "VAN" && <VanIcon />}
      {convertToReadable(vehicleType)}
    </Stack>
  </Option>
);

export const CreateBookingStepThree = ({
  step,
  departureLabel,
  destinationLabel,
  rideEstimation,
  handlePrevStep,
  setRideEstimation,
  getValues,
  defaultRideEstimaion,
  getClientLabelById,
}) => {
  const navigate = useNavigate();
  const { showWarning } = useContext(GlobalContext);
  const { setIsDataChanged } = useIsDataChanged();
  const { callApiRequest } = useApiRequest();

  const Submit = () => {
    const newData = getValues();
    const isScheduled = newData?.rideType === "SCHEDULED";

    const params = {
      ...newData,
      departureTimeMs: isScheduled
        ? parseInt(new Date(newData?.departureTimeMs).getTime().toFixed(0))
        : null,
    };

    callApiRequest({
      apiRequest: isScheduled ? createScheduledRide : createRide,
      params,
      onSuccess: () => {
        showWarning({
          title: "Ride Added",
          content: "The new ride has been successfully added",
          icon: <DoneIcon />,
        });
        setIsDataChanged(false);
        navigate("/booking");
      },
    });
  };

  useEffect(() => {
    if (step === 2) {
      setRideEstimation(defaultRideEstimaion);
      const {
        customerCompanyId,
        departureTimeMs,
        rideType,
        departure,
        destination,
        vehicleType,
      } = getValues();

      const params = {
        customerCompanyId,
        departureTimeMs:
          rideType === "SCHEDULED"
            ? parseInt(new Date(departureTimeMs).getTime().toFixed(0))
            : null,
        rideType,
        departure,
        destination,
        vehicleType,
      };

      callApiRequest({
        apiRequest: estimateRide,
        params,
        onSuccess: (result) => {
          setRideEstimation(result);
        },
        onError: () => setRideEstimation(defaultRideEstimaion),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const {
    additionalNotes,
    customerCompanyId,
    d2d,
    // departure,
    // destination,
    departureTimeMs,
    emailAddress,
    firstName,
    lastName,
    passengersNumber,
    phoneNumber,
    rideType,
    vehicleType,
  } = getValues();

  return (
    <Container gap={2}>
      <Title>Step 3 - Check Before Schedule</Title>
      <ProgressBar step={step} steps={3} />
      <RideData
        customerCompanyId={customerCompanyId}
        d2d={d2d}
        departure={{ label: departureLabel }}
        destination={{ label: destinationLabel }}
        distanceUnit={rideEstimation?.distanceUnit ?? ""}
        estimatedCost={rideEstimation?.estimatedCost ?? 0}
        estimatedDistance={rideEstimation?.distance ?? 0}
        estimatedDurationMs={rideEstimation.durationMs ?? 0}
        etaMs={rideEstimation.etaMs}
        getClientLabelById={getClientLabelById}
        passengerName={`${firstName} ${lastName}, ${phoneNumber} ${
          emailAddress ? `, ${emailAddress}` : ""
        }`}
        passengersNumber={passengersNumber}
        vehicleType={vehicleType}
        additionalNotes={additionalNotes}
        rideType={rideType}
        departureTimeMs={departureTimeMs}
        newRideMode
      />
      <Stack direction={"row"} gap={1} mt={2}>
        <Button variant="outlined" color="primary" onClick={handlePrevStep}>
          Previous step
        </Button>
        <Button variant="contained" color="primary" onClick={Submit}>
          Schedule a Ride
        </Button>
      </Stack>
    </Container>
  );
};
