import { Button, Stack, useTheme, styled } from "@mui/material";
import * as SC from "SC";
import { DataGrid } from "UI/DataGrid";
import { getCsvFilesSearch } from "api/bookingRequestsApi";
import { TextFieldWithEllipsis } from "components/TextFiledWithEllipsis";
import { useDataGrid } from "hooks/useDataGrid";
import { useNavigationData } from "hooks/useNavigationData";
import { customColors } from "models/customColors";
import { Content } from "pages/BookingsPage.style";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { convertToReadable, unixTimeToUSDate } from "utils";

export const StatusColorNames = {
  IMPORTING: "new",
  ERROR: "unmatched",
  PROCESSING: "enRoute",
  ARCHIVED: "completed",
  // =============================
  CONFIRMATION: "confirmation",
  EN_ROUTE: "enRoute",
  ARRIVED: "arrived",
  ON_BOARD: "onboard",
  CANCELLED: "canceled",
};

export const Status = styled(Stack)`
  justify-content: center;
  align-items: center;

  padding: 5px;
  border-radius: 8px;

  color: ${({
    // @ts-ignore
    status,
    theme,
  }) =>
    customColors[theme.palette.mode][StatusColorNames[status] ?? "greyText"]};
  background-color: ${({
    // @ts-ignore
    status,
    theme,
  }) =>
    `${
      customColors[theme.palette.mode][StatusColorNames[status] ?? "greyText"]
    }29`};
`;

export const RequestFilesPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const userId = useAppSelector((state) => state.user.id);

  const columns = [
    {
      label: "ID",
      field: "id",
      xs: 1.3,
      sm: 1.3,
      md: 1.3,
      sortableName: "ID",
      renderer: ({ id }) => <TextFieldWithEllipsis>{id}</TextFieldWithEllipsis>,
    },
    {
      label: "Client",
      field: "companyName",
      xs: 2,
      sm: 2,
      md: 2,
      sortableName: "COMPANY_NAME",
    },
    {
      label: "Created At",
      field: "createdAtMs",
      xs: 2,
      sm: 2,
      md: 2,
      sortableName: "DATE",
      renderer: (row) => unixTimeToUSDate(row.createdAtMs),
    },
    {
      label: "Accepted",
      field: "accepted",
      xs: 1.5,
      sm: 1.5,
      md: 1.5,
      align: "center",
      renderer: ({ status, accepted }) =>
        status === "IMPORTING" ? "-" : accepted,
    },
    {
      label: "Validate",
      field: "validate",
      xs: 1.5,
      sm: 1.5,
      md: 1.5,
      align: "center",
      renderer: ({ status, validate }) =>
        status === "IMPORTING" ? "-" : validate,
    },
    {
      label: "Unaccepted",
      field: "unaccepted",
      xs: 1.5,
      sm: 1.5,
      md: 1.5,
      align: "center",
      renderer: ({ status, unaccepted }) =>
        status === "IMPORTING" ? "-" : unaccepted,
    },
    {
      label: "Status",
      field: "status",
      xs: 2,
      sm: 2,
      md: 2,
      sortableName: "STATUS",
      renderer: (row) => (
        <Status
          // @ts-ignore
          status={row?.status}
        >
          {convertToReadable(row?.status ?? "")}
        </Status>
      ),
    },
  ];

  const dataGrid = useDataGrid({
    apiHandler: getCsvFilesSearch,
    defaultSorter: {
      name: "ID",
      order: "DESC",
    },
    defaultLimit: 25,
    gridConfigIndex: `${userId}-request-files`,
    reloadIntervalInSeconds: 30,
  });

  const handleRowClick = (row) => {
    if (row?.status === "IMPORTING") return;
    navigate(`/request_files/${row.id}`);
  };

  useNavigationData({
    setSearch: dataGrid.setSearch,
  });

  return (
    <SC.Container>
      <Stack direction={"row"} height={"100%"} position={"relative"}>
        <Content>
          <Stack gap={"20px"} width={"100%"}>
            <DataGrid
              columns={columns}
              handleRowClick={handleRowClick}
              {...dataGrid}
              actionsAfter={
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/import_csv_file")}
                >
                  Import CSV File
                </Button>
              }
              keyName="id"
              selectedColor={customColors[theme.palette.mode].additionalStroke}
            />
          </Stack>
        </Content>
      </Stack>
    </SC.Container>
  );
};
