import React from "react";
import { Box, Button, Stack, styled } from "@mui/material";
import { useDefaultContractData } from "hooks/useDefaultContractData";
import { useForm } from "react-hook-form";
import { Title } from "./AuthCommonLayout.style";
import { useFormDataSync } from "hooks/useFormDataSync";
import { customColors } from "models/customColors";
import { ContractForm } from "./ContractForm";

const Description = styled(Box)`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

  color: ${({ theme }) => customColors[theme.palette.mode].greyText};
`;

export const DefaultContractData = () => {
  const { defaultContract, submitDefaultContractData } =
    useDefaultContractData();

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
  } = useForm({
    values: defaultContract,
  });

  useFormDataSync({
    reset,
    isDirty,
  });

  const name = watch("name");

  if (!defaultContract) {
    return null;
  }

  return (
    <Box padding="0 40px">
      <form>
        <Stack>
          <Stack gap={3} width={"100%"}>
            <Stack gap={"15px"}>
              <Title>{name}</Title>
              <Description>You can edit default contract properties here</Description>
            </Stack>
            <Stack gap={2}>
              <ContractForm control={control} />
              <Stack mt={3} direction={"row"} justifyContent={"flex-start"} gap={1}>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => reset()}
                  disabled={!isDirty}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit(submitDefaultContractData)}
                  disabled={!isDirty}
                >
                  Save Changes
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </Box>
  );
};
