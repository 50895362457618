import { createContext } from "react";
import { defaultConfirmationsWidthCallbackContextState } from "hooks/useConfirmationWithCallback";

const noop = () => null;

/**
 * @typedef {import("types/confirmationTypes").ConfirmationsWithCallbackProps} ConfirmationsWithCallbackProps
 */

/**
 * @type {{
 *  confirmationWithCallback: ConfirmationsWithCallbackProps;
 *  openConfirmationWithCallback: React.Dispatch<React.SetStateAction<ConfirmationsWithCallbackProps>>;
 *  closeConfirmationWithCallback: () => void;
 *  warning: any;
 *  showWarning: (props: { title: any; content: any; icon?:React.ReactElement }) => void;
 *  hideWarning: () => void;
 * }}
 */
export const initialContextState = {
  confirmationWithCallback: defaultConfirmationsWidthCallbackContextState,
  openConfirmationWithCallback: noop,
  closeConfirmationWithCallback: noop,
  warning: {},
  showWarning: noop,
  hideWarning: noop,
};

export const GlobalContext = createContext(initialContextState);
