import React from "react";
import { Button, styled } from "@mui/material";
import { TextFieldWithEllipsis } from "./TextFiledWithEllipsis";
import { convertToReadable } from "utils";
import { RecordsGrid, Record, RecordItem } from "UI/DataGrid.style";
import { customColors } from "models/customColors";

const Role = styled("span")`
  font-size: 12px;
  color: ${({ theme }) => customColors[theme.palette.mode].placeHolder};
`;

export const SelectedUserRow = ({ user, deleteUserFromChatByMattermostId }) => {
  const { id, first_name, last_name, email, props, username } = user;

  return (
    <RecordsGrid key={id}>
      <Record container>
        <RecordItem item xs={6} textAlign={"left"}>
          <TextFieldWithEllipsis>
            {first_name ? (
              <>
                {first_name} {last_name}
              </>
            ) : (
              email
            )}
            <br />
            <Role>{convertToReadable(props?.kerico_role)}</Role>
          </TextFieldWithEllipsis>
        </RecordItem>
        <RecordItem item xs={4}>
          <TextFieldWithEllipsis>{props?.phone}</TextFieldWithEllipsis>
        </RecordItem>
        <RecordItem item xs={2}>
          {username !== "kericosystembot" && (
            <Button onClick={() => deleteUserFromChatByMattermostId(id)}>
              Remove
            </Button>
          )}
        </RecordItem>
      </Record>
    </RecordsGrid>
  );
};
