import React, { useCallback, useContext } from "react";
import { Button, InputLabel, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "store/globalContext";
import { FormMaskedTextField, FormTextField } from "UI";
import {
  emailValidation,
  nameValidation,
  phoneNumberRequiredValidation,
  required,
} from "models/validations";
import { useIsDataChanged } from "hooks/useIsDataChanged";
import { FormSelectField } from "UI/FormSelectField";
import { Title, Container } from "pages/CreateBookingPage";
import { ProgressBar } from "./ProgressIndicator";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { resetIsDataChanged } from "store/reducers/isDataChangedReducer";

export const CreateBookingStepOne = ({
  control,
  step,
  trigger,
  setStep,
  clientListOptions,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { openConfirmationWithCallback } = useContext(GlobalContext);
  const userRole = useAppSelector((state) => state.user.role);
  const isCustomerUser =
    userRole === "CUSTOMER_ADMIN" || userRole === "CUSTOMER_MANAGER";
  const { isDataChanged } = useIsDataChanged();

  const handleConfirmedClose = useCallback(() => {
    if (isDataChanged) {
      openConfirmationWithCallback({
        title: "Are you sure?",
        message: "All entered data will be lost.",
        callbackFn: () => {
          dispatch(resetIsDataChanged());
          navigate("/booking");
        },
      });
      return;
    }

    navigate("/booking");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataChanged, navigate, openConfirmationWithCallback]);

  const handleStepTwo = async () => {
    const result = await trigger();
    if (result) {
      setStep(step + 1);
    }
  };

  return (
    <Container gap={3}>
      <Title>Step 1 - Passenger info</Title>
      <ProgressBar step={step} steps={3} />
      <Stack gap={2}>
        {!isCustomerUser && (
          <Stack alignItems={"flex-start"} width={"100%"}>
            <InputLabel htmlFor="customerCompanyId">Client Company</InputLabel>
            <FormSelectField
              id="customerCompanyId"
              name="customerCompanyId"
              control={control}
              options={clientListOptions}
              withNone={false}
              rules={{ required }}
            />
          </Stack>
        )}
        <Stack direction={"row"} gap={3} justifyContent={"space-between"}>
          <Stack alignItems={"flex-start"} flex={1}>
            <InputLabel htmlFor="firstName">Passenger First Name</InputLabel>
            <FormTextField
              id="firstName"
              name="firstName"
              control={control}
              rules={nameValidation}
              inputProps={{
                autocomplete: "new-password",
              }}
            />
          </Stack>
          <Stack alignItems={"flex-start"} flex={1}>
            <InputLabel htmlFor="lastName">Passenger Last Name</InputLabel>
            <FormTextField
              id="lastName"
              name="lastName"
              control={control}
              rules={nameValidation}
              inputProps={{
                autocomplete: "new-password",
              }}
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} gap={3} justifyContent={"space-between"}>
          <Stack alignItems={"flex-start"} flex={1}>
            <InputLabel htmlFor="phoneNumber">Phone Number</InputLabel>
            <FormMaskedTextField
              id="phoneNumber"
              name="phoneNumber"
              control={control}
              rules={phoneNumberRequiredValidation}
              mask="999-999-9999"
              inputProps={{
                autocomplete: "new-password",
              }}
            />
          </Stack>
          <Stack alignItems={"flex-start"} flex={1}>
            <InputLabel htmlFor="emailAddress">Email</InputLabel>
            <FormTextField
              id="emailAddress"
              name="emailAddress"
              control={control}
              rules={emailValidation}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack direction={"row"} gap={1}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleConfirmedClose}
        >
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleStepTwo}>
          Next step
        </Button>
      </Stack>
    </Container>
  );
};
