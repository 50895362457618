import React from "react";
import { styled, Container as ContainerMUI } from "@mui/material";
import { trimQuotes } from "utils";
import { useAppSelector } from "store/hooks";
import { customColors } from "models/customColors";

const LINES_LIMIT_FOR_PREVIEW = 500;

const Container = styled(ContainerMUI)`
  overflow: auto;
  margin: 0;
  min-height: 100px;
  width: calc(100vw - 256px);

  &.expanded {
    width: calc(100vw - 397px);
  }
`;

const Table = styled("table")`
  border: none;
  border-collapse: collapse;
`;
const TableHead = styled("thead")`
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].strokeColor};

  & td {
    text-align: center;
    color: ${({ theme }) => customColors[theme.palette.mode].text};
  }
`;
const TableBody = styled("tbody")``;
const TableRow = styled("tr")``;
const TableCell = styled("td")`
  font-size: 11px;
  padding: 2px 4px;
  border: 1px solid ${({ theme }) => customColors[theme.palette.mode].greyText};
`;

export const TablePreview = ({
  headerRow = [],
  rows = [],
  stripQuotes = true,
  limit = LINES_LIMIT_FOR_PREVIEW,
}) => {
  const isMenuExpanded = useAppSelector(
    (state) => state?.layout?.isMenuExpanded
  );

  return (
    <Container
      maxWidth={false}
      disableGutters
      // @ts-ignore
      className={isMenuExpanded ? "expanded" : ""}
    >
      <Table>
        <TableHead>
          <TableRow>
            {headerRow.map((cell, index) => (
              <TableCell key={index}>
                {stripQuotes ? trimQuotes(cell) : cell}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(0, limit).map((fields, index) => (
            <TableRow key={index}>
              {fields.map((cell, index) => (
                <TableCell key={index}>
                  {stripQuotes ? trimQuotes(cell) : cell}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};
