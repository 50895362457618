import { MenuItem, Stack, capitalize } from "@mui/material";
import { ReactComponent as UserDetailsIcon } from "assets/img/user-details.svg";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";


import { ContextMenu } from "UI/ContextMenu";
import { GlobalContext } from "store/globalContext";
import { setNavigationData } from "store/reducers/navigationDataReducer";
import { logoutAction } from "store/reducers/userReducer";
import { DefaultAvatar } from "./DefaultAvatar";
import {
  Name,
  ProfilePhoto,
  Role,
  UserInfo
} from "./Header.style";

export const UserInfoSection = () => {
  const dispatch = useAppDispatch();
  const avatar = useAppSelector((state) => state.avatar);
  const { openConfirmationWithCallback } = useContext(GlobalContext);
  const navigate = useNavigate();


  const user = useAppSelector((state) => state?.user);

  const role = user?.role
    ?.split("_")
    .map((name) => capitalize(name.toLocaleLowerCase()))
    .join(" ");

  const handleLogout = () =>
    openConfirmationWithCallback({
      title: "Confirmation needed",
      message: "Do you really want to log out?",
      callbackFn: () => {
        dispatch(logoutAction());
      },
      closeIcon: false,
    });

  const handleProfileSettings = () => {
    dispatch(setNavigationData({ targetSettingsTab: "profile" }));
    navigate(`/settings`);
  };

  return (
    <UserInfo>
      {avatar ? (
        <ProfilePhoto
          sx={{
            backgroundSize: `${avatar ? "cover" : "auto"}`,
            backgroundImage: `url("${avatar}")`,
          }}
        ></ProfilePhoto>
      ) : (
        <DefaultAvatar user={user} radius="37px" />
      )}
      <Stack sx={{ userSelect: "none" }}>
        <Name>
          {user?.firstName} {user?.lastName}
        </Name>
        <Role>{role}</Role>
      </Stack>
      <ContextMenu icon={<UserDetailsIcon />}>
        <MenuItem onClick={handleProfileSettings}>Profile Settings</MenuItem>
        <MenuItem onClick={handleLogout}>Log out</MenuItem>
      </ContextMenu>
    </UserInfo>
  );
};
