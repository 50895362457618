import { Box, IconButton, Stack, styled } from "@mui/material";
import { DialogWindow } from "UI/DialogWindow";
import { deleteCsvTemplate, getAllCsvTemplates } from "api/csvTemplateApi";
import { useApiRequest } from "api/useApiRequest";
import { customColors } from "models/customColors";
import { useCallback, useContext, useEffect, useState } from "react";
import { GlobalContext } from "store/globalContext";
import { useAppSelector } from "store/hooks";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const Item = styled(Box)`
  padding: 5px 10px;

  &:hover {
    color: ${({ theme }) => customColors[theme.palette.mode].actionColor};
  }
  cursor: pointer;
`;

const InfoMessage = styled(Stack)`
  flex: 1 1 auto;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => customColors[theme.palette.mode].greyText};
`;

export const TemplateListDialog = ({
  open,
  handleClose,
  handleSelectTemplate = (template) => null,
}) => {
  const spinner = useAppSelector((state) => state.spinner);
  const { callApiRequest } = useApiRequest();
  const [templates, setTemplates] = useState(null);
  const { openConfirmationWithCallback, showWarning } =
    useContext(GlobalContext);

  const loadData = useCallback(async () => {
    callApiRequest({
      apiRequest: getAllCsvTemplates,
      onSuccess: (data) => {
        const sortedByName = data.sort((a, b) => a.name.localeCompare(b.name));
        setTemplates(sortedByName);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteTemplate = (e, id) => {
    e.stopPropagation();
    openConfirmationWithCallback({
      title: "Delete Template",
      message: "Are you sure you want to delete this template?",
      callbackFn: () => {
        callApiRequest({
          apiRequest: deleteCsvTemplate,
          params: id,
          onSuccess: () => {
            loadData();
            showWarning({
              title: "Template Deleted",
              content: "Csv Template deleted successfully",
            });
          },
        });
      },
      buttonLabels: ["Cancel", "Delete"],
    });
  };

  useEffect(() => {
    if (open) {
      loadData();
      return;
    }
    setTemplates(null);
  }, [loadData, open]);

  const data = Array.isArray(templates) ? [...templates] : [];

  return (
    <DialogWindow
      open={open}
      withCloseButton
      handleClose={handleClose}
      title={"Select Template"}
      content={
        <Stack
          mt={2}
          gap={3}
          minHeight={"100px"}
          maxHeight={"40vh"}
          minWidth={"300px"}
          overflow={"auto"}
        >
          <Stack gap={0}>
            {spinner && !templates && <InfoMessage>Loading...</InfoMessage>}
            {!spinner && templates && !templates?.length && (
              <InfoMessage>No templates found</InfoMessage>
            )}
            {data?.map((template) => (
              <Stack
                direction={"row"}
                width={"100%"}
                justifyContent={"space-between"}
                alignItems={"center"}
                key={template.id}
                sx={{
                  "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() => handleSelectTemplate(template)}
              >
                <Item>{template.name}</Item>
                <IconButton
                  onClick={(e) => handleDeleteTemplate(e, template.id)}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </Stack>
            ))}
          </Stack>
        </Stack>
      }
    />
  );
};
