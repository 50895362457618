import { getClientList } from "api/clientApi";
import { useApiRequest } from "api/useApiRequest";
import { useEffect, useState } from "react";

export const useClientList = () => {
  const { callApiRequest } = useApiRequest();
  const [clientList, setClientList] = useState([]);

  const loadData = async () => {
    callApiRequest({
      apiRequest: getClientList,
      onSuccess: (result) => {
        setClientList(result);
      },
      onError: (error) => {
        console.error(error);
        setClientList([]);
      },
    });
  };

  const clientListOptions = clientList?.reduce(
    (acc, { id, companyName, speciality, phoneNumber }) => ({
      ...acc,
      [id]: `${companyName}, ${speciality}, ${phoneNumber}`,
    }),
    {}
  );

  const getClientLabelById = (id) => {
    if (!id) return "";

    const { companyName, speciality, phoneNumber, email } = clientList?.find(
      (client) => client?.id === id
    ) ?? {};
    return `${companyName}, ${speciality}, ${phoneNumber}, ${email}`;
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    clientList,
    clientListOptions,
    getClientLabelById,
  };
};
