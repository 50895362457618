import { DndContext } from "@dnd-kit/core";
import { Divider, Grid, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { BootstrapTooltip } from "UI/BootstrapTooltip";
import { updateCsvTemplate } from "api/csvTemplateApi";
import { useApiRequest } from "api/useApiRequest";
import { Draggable } from "components/Draggable";
import { Droppable } from "components/Droppable";
import {
  Info,
  Label,
  Mapper,
  UnactiveItem,
} from "pages/ImportCsvFilePage.style";
import { useContext, useState } from "react";
import { GlobalContext } from "store/globalContext";
import { trimQuotes } from "utils";
import { CreateCsvTemplateDialog } from "./CreateCsvTemplateDialog";
import { TemplateDriver } from "./TemplateDriver";
import { TemplateListDialog } from "./TemplateListDialog";

const checkIfFieldMappedByHeaderIndex = (template, index) => {
  if (!template) {
    return false;
  }
  return Object.values(template)?.some((field) =>
    field?.some((value) => value === index)
  );
};

const cutText = (text) => {
  const length = 15;
  if (text.length <= length) {
    return text;
  }
  return text.slice(0, length - 3) + "...";
};

export const ImportStepThree = ({
  headerRow,
  template,
  setTemplate,
  defaultTemplate,
  rideRequestFields,
  getValues,
  setValue,
  isAllFieldsMapped,
  watch,
}) => {
  const { callApiRequest } = useApiRequest();
  const { showWarning } = useContext(GlobalContext);
  const [newTemplateData, setNewTemplateData] = useState(null);
  const [isTemplateListOpened, setIsTemplateListOpened] = useState(false);

  const handleSelectTemplate = (template) => {
    const { id, name, namesInFirstRaw, relations } = template;
    setValue("id", id);
    setValue("name", name);
    setValue("isNamesInFirstRow", namesInFirstRaw);

    const newTemplate = relations.reduce(
      (acc, { rootCsvFieldId, columnIndexes }) => {
        const fieldName = rideRequestFields.find(
          (field) => field.id === rootCsvFieldId
        ).name;
        acc[fieldName] = columnIndexes;
        return acc;
      },
      {}
    );

    setTemplate(newTemplate);
    setIsTemplateListOpened(false);
  };
  const handleCreateTemplate = () => {
    const { name, isNamesInFirstRow } = getValues();

    const relations = Object.entries(template).map(([key, value]) => {
      return {
        rootCsvFieldId: rideRequestFields.find((field) => field.name === key)
          .id,
        columnIndexes: value,
      };
    });

    const newTemplate = {
      name: name ? "Copy of " + name : "",
      namesInFirstRaw: isNamesInFirstRow,
      relations,
    };

    setNewTemplateData(newTemplate);
  };

  const handleSaveTemplate = () => {
    const { id, name, isNamesInFirstRow } = getValues();

    const relations = Object.entries(template).map(([key, value]) => {
      return {
        rootCsvFieldId: rideRequestFields.find((field) => field.name === key)
          .id,
        columnIndexes: value,
      };
    });

    const newTemplate = {
      id,
      name,
      namesInFirstRaw: isNamesInFirstRow,
      relations,
    };

    if (!id || !name) {
      setNewTemplateData(newTemplate);
      return;
    }

    callApiRequest({
      apiRequest: updateCsvTemplate,
      params: newTemplate,
      onSuccess: () => {
        showWarning({
          title: "Template Saved",
          content: "The template has been successfully saved",
        });
      },
    });
  };

  const handleResetTemplate = () => {
    setTemplate(defaultTemplate);
    setValue("id", undefined);
    setValue("name", "");
  };

  const handleClearField = (field) => {
    setTemplate((prev) => ({
      ...prev,
      [field]: [],
    }));
  };

  const handleResetItem = (name) => {
    const index = headerRow?.indexOf(name);
    if (!(index >= 0)) return;

    setTemplate((prev) => {
      const keys = Object.keys(prev);
      const name = keys.find((key) => prev[key].includes(index));
      const newValue = prev[name].filter((item) => item !== index);

      return {
        ...prev,
        [name]: newValue,
      };
    });
  };

  const getFieldKeyById = (id) => rideRequestFields[id]?.name;

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over || !active) return;

    const activeId = Number(active.id);
    const overId = Number(over.id);

    setTemplate((prev) => {
      if (prev[getFieldKeyById(overId)].includes(activeId)) {
        return prev;
      }
      return {
        ...prev,
        [getFieldKeyById(overId)]: [...prev[getFieldKeyById(overId)], activeId],
      };
    });
  };

  const name = watch("name");

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <Mapper>
        {!isAllFieldsMapped && (
          <Info>
            Load the template or drag-and-drop CSV columns into the gray fields
            below to map the data:
          </Info>
        )}
        <Stack direction={"row"} flexWrap={"wrap"} gap={1}>
          {headerRow.map((cell, index) => {
            const isFieldMapped = checkIfFieldMappedByHeaderIndex(
              template,
              index
            );
            if (isFieldMapped) {
              return null;
            }
            return (
              <Draggable key={`${index}-${cell}`} id={index}>
                {trimQuotes(cell)}
              </Draggable>
            );
          })}
        </Stack>
        {name && `Applied template: ${name}`}
        <Divider />
        <Stack
          width={"100%"}
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          gap={3}
        >
          <Grid
            flex={1}
            container
            columnSpacing={2}
            rowSpacing={0.5}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {rideRequestFields?.map(
              ({ label, name, xs, sm = xs, md = xs }, index) => (
                <Grid
                  item
                  xs={xs}
                  sm={sm}
                  md={md}
                  key={`${index}-${label}-${name}`}
                >
                  <Stack direction="row" alignItems={"flex-start"} gap={0.3}>
                    <Label mt={1}>{label}:</Label>
                    <Droppable
                      key={index}
                      id={index}
                      sourceData={template[name]}
                      handleClearField={() => handleClearField(name)}
                    >
                      {template[name]
                        .map((cellId) => headerRow[cellId])
                        .map((cell, index) =>
                          cutText(cell) === cell ? (
                            <UnactiveItem key={`${cell}-${index}`}>
                              {cutText(trimQuotes(cell))}
                              <IconButton
                                onClick={() => handleResetItem(cell)}
                                sx={{ padding: 0 }}
                              >
                                <CloseIcon sx={{ width: "16px" }} />{" "}
                              </IconButton>
                            </UnactiveItem>
                          ) : (
                            <BootstrapTooltip
                              key={`${cell}-${index}`}
                              title={cell}
                              placement="top"
                            >
                              <UnactiveItem>
                                {cutText(trimQuotes(cell))}
                                <IconButton
                                  onClick={() => handleResetItem(cell)}
                                  sx={{ padding: 0 }}
                                >
                                  <CloseIcon sx={{ width: "16px" }} />
                                </IconButton>
                              </UnactiveItem>
                            </BootstrapTooltip>
                          )
                        )}
                    </Droppable>
                  </Stack>
                </Grid>
              )
            )}
          </Grid>
          <TemplateDriver
            handleLoadTemplate={() => setIsTemplateListOpened(true)}
            handleResetTemplate={handleResetTemplate}
            handleSaveTemplate={handleSaveTemplate}
            handleCreateTemplate={handleCreateTemplate}
            isAllFieldsMapped={isAllFieldsMapped}
          />
        </Stack>
      </Mapper>
      <CreateCsvTemplateDialog
        data={newTemplateData}
        handleClose={() => setNewTemplateData(null)}
        handleSelectTemplate={handleSelectTemplate}
      />
      <TemplateListDialog
        open={isTemplateListOpened}
        handleClose={() => setIsTemplateListOpened(false)}
        handleSelectTemplate={handleSelectTemplate}
      />
    </DndContext>
  );
};
