import React from "react";
import { Controller } from "react-hook-form";
import { RadioSwitcher } from "./RadioSwitcher";

export const FormRadioSwitcher = ({
  control,
  name,
  setValue,
  options,
  ...props
}) => {
  const handleClickUnactiveItem = (e, value) => setValue(name, value);

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <RadioSwitcher
          handleClickUnactiveItem={handleClickUnactiveItem}
          options={options}
          value={value}
          {...props}
        />
      )}
    />
  );
};
