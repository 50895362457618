import React, { useState } from "react";
import { Stack } from "@mui/material";
import * as SC from "SC";
import { useDataGrid } from "hooks/useDataGrid";
import { useAppSelector } from "store/hooks";
import { DataGrid } from "UI/DataGrid";
import { CloseWindowButton } from "UI/CloseWindowButton";
import {
  Content,
  companiesColumns,
  driversColumns,
  getShortDriversColumns,
} from "./PaymentsPage.data";
import { TableModeSelector } from "components/TableModeSelector";
import { useMemo } from "react";
import {
  getFinanceInfoByDrivers,
  getInvoicesInfoByCompanies,
} from "api/invoiceApi";
import { useNavigate } from "react-router-dom";
import { DriverFinancialDetails } from "components/DriverFinancialDetails";

/** @type {"INVOICES" | "DRIVER_PAYMENTS"} */
const defaultMode = "INVOICES";

export const PaymentsPage = () => {
  const navigate = useNavigate();
  const userId = useAppSelector((state) => state.user.id);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [isWaitingForReload, setIsWaitingForReload] = useState(false);
  const [mode, setMode] = useState(defaultMode);

  const companiesDataGrid = useDataGrid({
    apiHandler: getInvoicesInfoByCompanies,
    defaultSorter: {
      name: "FULL_NAME",
      order: "ASC",
    },
    defaultLimit: 25,
    gridConfigIndex: `${userId}-companies-payments`,
  });

  const driversDataGrid = useDataGrid({
    apiHandler: getFinanceInfoByDrivers,
    defaultSorter: {
      name: "FULL_NAME",
      order: "ASC",
    },
    defaultLimit: 25,
    gridConfigIndex: `${userId}-companies-payments`,
  });

  const handleRowClickDriver = (row) => {
    if (selectedUserId === row?.id) {
      setSelectedUserId("");
    } else if (selectedUserId && selectedUserId !== row?.id) {
      setIsWaitingForReload(true);
      setSelectedUserId("");
      setTimeout(() => setSelectedUserId(row?.id));
    } else if (row?.id) {
      setSelectedUserId(row?.id);
    }
  };

  const handleRowClickCompany = (row) => {
    navigate(`/company_invoices/${row.companyId}`);
  };

  const isInvoicesMode = mode === "INVOICES";

  const modeSelectorOptions = useMemo(
    () => [
      {
        label: "Invoices",
        value: "INVOICES",
        loadData: companiesDataGrid.loadData,
      },
      {
        label: "Driver Payments",
        value: "DRIVER_PAYMENTS",
        loadData: driversDataGrid.loadData,
      },
    ],
    [driversDataGrid.loadData, companiesDataGrid.loadData]
  );

  return (
    <SC.Container>
      <Stack direction={"row"} height={"100%"} position={"relative"}>
        <SC.Layout sx={{ flex: 1 }}>
          <Content>
            {isInvoicesMode ? (
              <DataGrid
                columns={companiesColumns}
                handleRowClick={handleRowClickCompany}
                {...companiesDataGrid}
                selectedId={selectedUserId}
                actions={
                  <TableModeSelector
                    mode={mode}
                    setMode={setMode}
                    resetCallback={() => setSelectedUserId("")}
                    options={modeSelectorOptions}
                  />
                }
                keyName="companyId"
              />
            ) : (
              <DataGrid
                columns={
                  selectedUserId || isWaitingForReload
                    ? getShortDriversColumns(selectedUserId)
                    : driversColumns
                }
                handleRowClick={handleRowClickDriver}
                {...driversDataGrid}
                selectedId={selectedUserId}
                actions={
                  <TableModeSelector
                    mode={mode}
                    setMode={setMode}
                    resetCallback={() => setSelectedUserId("")}
                    options={modeSelectorOptions}
                  />
                }
                keyName="id"
              />
            )}
          </Content>
        </SC.Layout>
        {selectedUserId && (
          <>
            <CloseWindowButton
              top={-35}
              right={-35}
              closeHandler={() => setSelectedUserId("")}
            />
            <DriverFinancialDetails
              userId={selectedUserId}
              isWaitingForReload={isWaitingForReload}
              setIsWaitingForReload={setIsWaitingForReload}
              refreshList={driversDataGrid.loadData}
            />
          </>
        )}
      </Stack>
    </SC.Container>
  );
};
