import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { useApiRequest } from "api/useApiRequest";
import { changePasswordByToken, reissuePasswordChangeToken } from "api/authApi";
import { useUnrestrictedEffect } from "hooks/useUnrestrictedEffect";
import { restoreAuthByRefreshToken } from "store/reducers/userReducer";
import * as FP from "utils/fp-js";
import { showError } from "store/reducers/errorReducer";

const defaultValues = {
  password: "",
  password_repeat: "",
};

export const usePasswordChangePage = () => {
  const { callApiRequest } = useApiRequest();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  const [newToken, setNewToken] = useState("");

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const {
    register,
    control,
    reset,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isDirty },
    trigger,
    watch,
  } = useForm({
    defaultValues,
  });

  const onSubmit = handleSubmit(() => {
    const { password } = getValues();
    
    FP.asyncEither({
      value: callApiRequest({
        apiRequest: changePasswordByToken,
        params: {
          token: newToken,
          password,
        },
      }),
      rightFn: (value) => {
        dispatch(restoreAuthByRefreshToken(value?.refresh?.token));
        navigate("/");
      },
    });
  });

  useUnrestrictedEffect({
    loadFn: () => {
      FP.asyncEither({
        value: callApiRequest({
          apiRequest: reissuePasswordChangeToken,
          params: {
            token,
          },
          skipErrorHandling: true,
        }),
        leftFn: () => {
          dispatch(showError("This link is expired."));
          navigate("/");
        },
        rightFn: (value) => {
          setNewToken(value?.passwordGrant?.token);
        },
      });
    },
  });

  return {
    onSubmit,
    isPasswordVisible,
    setIsPasswordVisible,
    errors,
    isDirty,
    trigger,
    getValues,
    setValue,
    register,
    reset,
    control,
    watch,
    newToken,
    setNewToken,
  };
};
