import { useState } from "react";

export const useAddKericoUser = () => {
  const [isAddUserVisible, setIsAddUserVisible] = useState(false);
  const [selectedCounties, setSelectedCounties] = useState([]);

  return {
    isAddUserVisible,
    setIsAddUserVisible,
    selectedCounties,
    setSelectedCounties,
  };
};
