import React from "react";
import { Box, Stack, styled } from "@mui/material";
import { customColors } from "models/customColors";

const Container = styled(Stack)`
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-radius: 12px;
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].additionalStroke};
`;

const UnactiveItem = styled(Box)`
  padding: 10px 20px;
  font-family: Inter;
  font-size: 17px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;

  border-radius: 12px;
  color: ${({ theme }) => customColors[theme.palette.mode].greyText};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => customColors[theme.palette.mode].actionColor};
  }
`;

const ActiveItem = styled(UnactiveItem)`
  color: ${({ theme }) => customColors[theme.palette.mode].background};
  background-color: ${({ theme }) => customColors[theme.palette.mode].textBody};
  cursor: default;

  &:hover {
    color: ${({ theme }) => customColors[theme.palette.mode].background};
  }
`;

export const RadioSwitcher = ({
  options,
  value,
  handleClickUnactiveItem,
  ...props
}) => (
  <Container {...props}>
    {options.map((option) =>
      option?.value === value ? (
        <ActiveItem key={option?.value}>{option?.label}</ActiveItem>
      ) : (
        <UnactiveItem
          onClick={(e) => handleClickUnactiveItem(e, option?.value)}
          key={option?.value}
        >
          {option?.label}
        </UnactiveItem>
      )
    )}
  </Container>
);
