import { Box, IconButton, Stack, styled } from "@mui/material";
import { useState } from "react";
import { customColors } from "models/customColors";
import { StatusColorNames } from "pages/BookingsPage.style";
import { convertToReadable, unixTimeToUSTime } from "utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ErrorIcon from "@mui/icons-material/Error";

const errorsStatus = ["unmatched", "cancelled"];

export const Container = styled(Stack)`
  padding: 4px;

  justify-content: center;
  align-items: stretch;

  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  text-align: left;

  border: 3px solid
    ${({ theme }) => customColors[theme.palette.mode].background};
  border-radius: 10px;
  color: ${({
    // @ts-ignore
    status,
    theme,
  }) =>
    customColors[theme.palette.mode][StatusColorNames[status] ?? "greyText"]};
  background-color: ${({
    // @ts-ignore
    status,
    theme,
  }) =>
    `${
      customColors[theme.palette.mode][StatusColorNames[status] ?? "greyText"]
    }29`};
  user-select: none;
`;

const Label = styled(Box)`
  font-weight: 500;
  color: ${({ theme }) => customColors[theme.palette.mode].greyText};
`;

const Value = styled(Box)`
  font-weight: 500;
  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
`;

const Status = styled(Stack)`
  font-size: 14px;
  font-weight: bold;
`;

const CardClickArea = styled(Stack)`
  border-radius: 10px;
  cursor: pointer;

  &:hover, &.selected {
    background-color: ${({
      // @ts-ignore
      status,
      theme,
    }) =>
      `${
        customColors[theme.palette.mode][StatusColorNames[status] ?? "greyText"]
      }20`};
  }
`;

const CardHeader = styled(Stack)`
  cursor: pointer;

  user-select: none;

  border-radius: 8px;

  &:hover, &.selected {
    background-color: ${({
      // @ts-ignore
      status,
      theme,
    }) =>
      `${
        customColors[theme.palette.mode][StatusColorNames[status] ?? "greyText"]
      }20`};
  }
`;

export const RideCard = ({ ride, selectedId, setSelectedId }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Container
      // @ts-ignore
      status={ride?.status}
    >
      <CardHeader
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"top"}
        columnGap={1}
        pl={0.5}
        onClick={() => setIsExpanded(!isExpanded)}
        // @ts-ignore
        status={ride?.status}
        className={ride?.id === selectedId ? "selected" : ""}
      >
        {errorsStatus.includes(ride?.status?.toLowerCase()) && (
          <ErrorIcon fontSize="small" />
        )}
        <Stack
          flex={1}
          direction={"row"}
          alignSelf={"center"}
          flexWrap={"wrap"}
          columnGap={1}
          height={"100%"}
        >
          <Box>{unixTimeToUSTime(ride?.plannedStartTime)}</Box>
          <Box>{ride?.passengerName}</Box>
          {isExpanded && <Box>{ride?.passengerPhone}</Box>}
        </Stack>
        <IconButton sx={{ alignSelf: "flex-start", width: 18, height: 18 }}>
          {isExpanded ? (
            <ExpandLessIcon fontSize="small" color="disabled" />
          ) : (
            <ExpandMoreIcon fontSize="small" color="disabled" />
          )}
        </IconButton>
      </CardHeader>

      {isExpanded && (
        <CardClickArea
          // @ts-ignore
          status={ride?.status}
          onClick={() => setSelectedId(ride?.id)}
          gap={0.5}
          padding={0.75}
          className={ride?.id === selectedId ? "selected" : ""}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            flexWrap={"wrap"}
            mb={0.5}
          >
            <Status>{convertToReadable(ride?.status)}</Status>
          </Stack>
          {!!ride?.driverName && (
            <Stack>
              <Label>Driver:</Label>
              <Stack direction={"row"} columnGap={1} flexWrap={"wrap"}>
                <Value>{ride?.driverName}</Value>
                <Value>{ride?.driverPhone}</Value>
              </Stack>
            </Stack>
          )}
          <Stack>
            <Label>Client:</Label>
            <Stack direction={"row"} columnGap={1} flexWrap={"wrap"}>
              <Value>{ride?.companyName}</Value>
              <Value>{ride?.companyPhone}</Value>
            </Stack>
          </Stack>
        </CardClickArea>
      )}
    </Container>
  );
};
