import React from "react";
import { RadioSwitcher } from "UI/RadioSwitcher";

export const FleetTableModeSelector = ({
  mode,
  setMode,
  privateCarsDataGrid,
  kericoCarsDataGrid,
  inactiveCarsDataGrid,
  setSelectedCarId,
}) => {
  const handleClickUnactiveItem = (e, value) => {
    setMode(value);
    setSelectedCarId("");
    if (value === "PRIVATE_CARS") {
      privateCarsDataGrid.loadData();
    } else if (value === "KERICO_CARS") {
      kericoCarsDataGrid.loadData();
    } else {
      // @ts-ignore
      inactiveCarsDataGrid.loadData();
    }
  };

  return (
    <RadioSwitcher
      handleClickUnactiveItem={handleClickUnactiveItem}
      value={mode}
      options={[
        {
          value: "PRIVATE_CARS",
          label: "Private Vehicles",
        },
        {
          value: "KERICO_CARS",
          label: "Kerico Vehicles",
        },
        {
          value: "INACTIVE_CARS",
          label: "Approval",
        },
      ]}
    />
  );
};
