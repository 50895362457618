import { useState } from "react";
import { useForm } from "react-hook-form";
import { emailValidation } from "models/validations";
import { sendResetPasswordLink } from "api/authApi";
import { useApiRequest } from "api/useApiRequest";
import * as FP from "utils/fp-js";

export const usePasswordRecoveryPage = () => {
  const { callApiRequest } = useApiRequest();
  const { getValues, register, handleSubmit, formState } = useForm({
    defaultValues: {
      email: "",
    },
  });
  const [isEmailSent, setIsEmailSent] = useState(false);
  const { errors } = formState;

  const usernameRegisterProps = register("email", emailValidation);

  const onSubmit = handleSubmit(() => {
    const { email } = getValues();
    FP.asyncEither({
      value: callApiRequest({
        apiRequest: sendResetPasswordLink,
        params: {
          email,
        },
      }),
      rightFn: () => setIsEmailSent(true),
    });
  });

  return {
    errors,
    onSubmit,
    usernameRegisterProps,
    isEmailSent,
  };
};
