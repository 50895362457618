import { httpService } from "./httpService";

/**
 * @template T
 * @typedef {import('types/commonTypes').HttpResult<T>} HttpResult
 */

/**
 * @param {import('types/commonTypes').RoutePreview} data
 * @returns  {HttpResult<import('types/commonTypes').RouteData>}
 */
export const getRoutePreview = async (data) =>
  httpService({
    url: "/routes/calculate/by-place/preview",
    options: {
      method: "POST",
      data,
    },
  });
