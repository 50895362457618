import {
  getDefaultContractData,
  updateDefaultContractData,
} from "api/contractApi";
import { useApiRequest } from "api/useApiRequest";
import { useCallback, useContext } from "react";
import { useEffect, useState } from "react";
import { GlobalContext } from "store/globalContext";
import { ReactComponent as CompleteIcon } from "assets/img/complete-icon.svg";

/** @type {import("types/commonTypes").ContractData | null} */
const defaultData = null;

export const useDefaultContractData = () => {
  const { callApiRequest } = useApiRequest();
  const { showWarning } = useContext(GlobalContext);

  const [defaultContract, setDefaultContract] = useState(defaultData);

  const loadDefaultContractData = useCallback(async () => {
    callApiRequest({
      apiRequest: getDefaultContractData,
      onSuccess: (result) => {
        setDefaultContract(result);
      },
      onError: (error) => {
        setDefaultContract(defaultData);
      },
    });
  }, [callApiRequest]);

  const submitDefaultContractData = useCallback(
    async (data) => {
      const params = {
        ...data,
        basePricePerMile: Number(data.basePricePerMile),
        wheelchairPricePerMile: Number(data.wheelchairPricePerMile),
        baseDeliveryFee: Number(data.baseDeliveryFee),
        wheelchairDeliveryFee: Number(data.wheelchairDeliveryFee),
        privateVehicleRate: Number(data.privateVehicleRate),
        companyVehicleRate: Number(data.companyVehicleRate),
        freeWaitingTimeMin: Number(data.freeWaitingTimeMin ?? 0),
        waitingRatePerMin: Number(data.waitingRatePerMin ?? 0),
        recalculationMargin: Number(data.recalculationMargin ?? 0),
      };

      callApiRequest({
        apiRequest: updateDefaultContractData,
        params,
        onSuccess: () => {
          showWarning({
            title: "Default Contract Updated",
            content: "Default contract data has been successfully added",
            icon: <CompleteIcon />,
          });
          loadDefaultContractData();
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadDefaultContractData]
  );

  useEffect(() => {
    loadDefaultContractData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    defaultContract,
    setDefaultContract,
    loadDefaultContractData,
    submitDefaultContractData,
  };
};
