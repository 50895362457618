import { useState } from "react";

export const useLocationSelector = ({ setValue }) => {
  const [departureLabel, setDepartureLabel] = useState("");
  const [departureError, setDepartureError] = useState("");
  const [destinationLabel, setDestinationLabel] = useState("");
  const [destinationError, setDestinationError] = useState("");

  const handleLocationSelect = (location, type) => {
    if (type === "departure") {
      setDepartureError("");
    } else {
      setDestinationError("");
    }

    if (location === null) {
      // reset
      setValue(
        type,
        {
          id: "",
          scope: "INTERNAL",
        },
        { shouldDirty: false }
      );
      if (type === "departure") {
        setDepartureLabel("");
      } else {
        setDestinationLabel("");
      }
    } else {
      // set new location
      const newValue = location?.fromAws
        ? {
            id: location.awsId,
            scope: "AWS",
          }
        : {
            id: location.id,
            scope: "INTERNAL",
          };
      setValue(type, newValue, {
        shouldDirty: true,
      });
    }
    if (type === "departure") {
      setDepartureLabel(location?.suggestion ?? "");
    } else {
      setDestinationLabel(location?.suggestion ?? "");
    }
  };

  return {
    handleLocationSelect,
    departureLabel,
    departureError,
    destinationLabel,
    destinationError,
    setDepartureLabel,
    setDepartureError,
    setDestinationLabel,
    setDestinationError,
  };
};
