import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Stack, styled } from "@mui/material";
import { useApiRequest } from "api/useApiRequest";
import { getDriverDetails } from "api/userApi";
import {
  convertToReadable,
  loadDriverPhoto,
  unixTimeToUSShortDate,
  USDollar,
} from "utils";
import { DefaultAvatar } from "./DefaultAvatar";
import { useAppDispatch } from "store/hooks";
import { showError } from "store/reducers/errorReducer";
import {
  Detail,
  Label,
  OffShiftStatus,
  Photo,
  StatisticItem,
  StatisticLabel,
  StatisticValue,
  Title,
  Value,
} from "./DriverDetails.style";
import {
  getDriverTransactionHandler,
  getDriverWalletInfo,
} from "api/invoiceApi";
import { ImageViewer } from "./ImageViewer";
import { useDataGrid } from "hooks/useDataGrid";
import { DataGrid } from "UI/DataGrid";
import { customColors } from "models/customColors";
import { AddCashoutDialog } from "./AddCashoutDialog";

export const Container = styled(Stack)`
  padding: 10px 30px 10px 60px;
  width: 50%;
  max-height: 100%;
  justify-content: space-between;

  overflow-y: hidden;
`;

const Loading = styled(Stack)`
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;

  font-size: 1.5rem;
  color: ${({ theme }) => customColors[theme.palette.mode].greyText};
`;

const RowWrapper = styled(Box)`
  color: ${({
    theme,
    // @ts-ignore
    transactionType,
  }) =>
    transactionType === "INCOME"
      ? customColors[theme.palette.mode].textBody
      : customColors[theme.palette.mode].actionColor};
`;

/** @type {import("types/commonTypes").DriverDetails | null} */
const defaultDriver = null;

export const DriverFinancialDetails = ({
  userId,
  isWaitingForReload,
  setIsWaitingForReload,
  refreshList,
}) => {
  const { callApiRequest } = useApiRequest();
  const dispatch = useAppDispatch();

  const [isPaymentOpen, setIsPaymentOpen] = useState(false);

  const [driver, setDriver] = useState(defaultDriver);
  const [profileImage, setProfileImage] = useState(null);
  const [imageTitle, setImageTitle] = useState(null);
  const [image, setImage] = useState(null);
  const [statistics, setStatistics] = useState(null);

  const columns = useMemo(
    () => [
      {
        label: "Date",
        field: "transactionTimeMs",
        xs: 4,
        sm: 4,
        md: 4,
        sortableName: "DATE",
        renderer: ({ transactionTimeMs, transactionType }) => (
          <RowWrapper
            // @ts-ignore
            transactionType={transactionType}
          >
            {unixTimeToUSShortDate(transactionTimeMs)}
          </RowWrapper>
        ),
      },
      {
        label: "Transaction type",
        field: "transactionType",
        xs: 4,
        sm: 4,
        md: 4,
        sortableName: "PAYMENT_TYPE",
        renderer: ({ transactionType }) => (
          <RowWrapper
            // @ts-ignore
            transactionType={transactionType}
          >
            {convertToReadable(transactionType)}
          </RowWrapper>
        ),
      },
      {
        label: "Amount",
        field: "amount",
        xs: 3,
        sm: 3,
        md: 3,
        sortableName: "AMOUNT",
        align: "right",
        renderer: ({ amount, transactionType }) => (
          <RowWrapper
            // @ts-ignore
            transactionType={transactionType}
          >
            {USDollar.format(amount)}
          </RowWrapper>
        ),
      },
      {
        xs: 1,
        sm: 1,
        md: 1,
      },
    ],
    []
  );

  const dataGrid = useDataGrid({
    apiHandler: getDriverTransactionHandler(userId),
    defaultSorter: {
      name: "DATE",
      order: "DESC",
    },
    defaultLimit: 25,
    gridConfigIndex: `${userId}-driver-payments`,
  });

  const showDriverPhoto = () => {
    setImageTitle("Driver Photo");
    setImage(profileImage);
  };

  const loadData = useCallback(async () => {
    callApiRequest({
      apiRequest: getDriverDetails,
      params: userId,
      onSuccess: (result) => {
        setDriver(result);
      },
      onError: (error) => {
        // @ts-ignore
        dispatch(showError(error));
        setDriver(defaultDriver);
      },
    });

    callApiRequest({
      apiRequest: getDriverWalletInfo,
      params: userId,
      onSuccess: (result) => {
        setStatistics(result);
      },
      onError: (error) => {
        // @ts-ignore
        dispatch(showError(error));
        setStatistics(null);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (userId) {
      loadData();
      return;
    }
    setDriver(defaultDriver);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData, userId]);

  useEffect(() => {
    loadDriverPhoto({
      driver,
      photoType: "Profile",
      setImage: setProfileImage,
      afterLoadingCallback: () => setIsWaitingForReload(false),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driver]);

  // @ts-ignore
  const isNotApproved = driver?.status === "APPROVAL";

  return (
    <Container gap={2}>
      {isWaitingForReload ? (
        <Loading>Loading...</Loading>
      ) : (
        <>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack flex={1} direction={"row"} alignItems={"center"} gap={2}>
              {!profileImage && <DefaultAvatar user={driver} radius="57px" />}
              {profileImage && (
                <Photo
                  sx={{
                    backgroundSize: `${profileImage ? "cover" : "auto"}`,
                    backgroundImage: `url("${profileImage}")`,
                    flex: "0 0 auto",
                  }}
                  onClick={showDriverPhoto}
                />
              )}
              <Title>
                {driver?.firstName ?? "Firstname"}{" "}
                {driver?.lastName ?? "Lastname"}
              </Title>
              {!!driver?.paymentPolicy && (
                <OffShiftStatus>
                  {convertToReadable(driver.paymentPolicy)}
                </OffShiftStatus>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsPaymentOpen(true)}
              >
                Pay the wage
              </Button>
            </Stack>
          </Stack>
          {!isNotApproved && (
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={2}
            >
              <StatisticItem>
                <StatisticLabel>Next Cash Out</StatisticLabel>
                <StatisticValue>
                  {statistics?.nextPaymentTimeMs
                    ? unixTimeToUSShortDate(statistics?.nextPaymentTimeMs)
                    : "-"}
                </StatisticValue>
              </StatisticItem>
              <StatisticItem>
                <StatisticLabel>Balance</StatisticLabel>
                <StatisticValue>
                  {USDollar.format(statistics?.remainingBalance ?? 0)}
                </StatisticValue>
              </StatisticItem>
              <StatisticItem>
                <StatisticLabel>Distance</StatisticLabel>
                <StatisticValue>
                  {statistics?.miles
                    ? Math.round(statistics?.miles * 10) / 10
                    : 0}{" "}
                  miles
                </StatisticValue>
              </StatisticItem>
              <StatisticItem>
                <StatisticLabel>Trips</StatisticLabel>
                <StatisticValue>{statistics?.trips ?? "-"}</StatisticValue>
              </StatisticItem>
            </Stack>
          )}
          <Stack
            gap={1}
            flex={1}
            justifyContent={"space-between"}
            overflow={"hidden"}
          >
            <Stack gap={1.5}>
              <Stack direction={"row"} flexWrap={"wrap"} gap={3}>
                <Detail>
                  <Label>Region:</Label>
                  <Value>{driver?.region?.label ?? "-"}</Value>
                </Detail>
                <Detail>
                  <Label>Phone Number:</Label>
                  <Value>{driver?.phone ?? "-"}</Value>
                </Detail>
                <Detail>
                  <Label>Email:</Label>
                  <Value>{driver?.email ?? "-"}</Value>
                </Detail>
              </Stack>
            </Stack>
            <DataGrid
              columns={columns}
              handleRowClick={() => null}
              {...dataGrid}
              tableLabel={<h3>Transactions</h3>}
              keyName="id"
              noSearch
            />
          </Stack>
        </>
      )}
      {image && (
        <ImageViewer
          image={image}
          setImage={setImage}
          imageTitle={imageTitle}
        />
      )}
      <AddCashoutDialog
        open={isPaymentOpen}
        handleClose={() => setIsPaymentOpen(false)}
        refreshData={() => {
          refreshList();
          loadData();
          dataGrid.loadData();
        }}
        driverId={userId}
        driverName={`${driver?.firstName} ${driver?.lastName}`}
        defaultValue={statistics?.remainingBalance ?? 0}
      />
    </Container>
  );
};
