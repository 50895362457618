import React, { useEffect, useState } from "react";
import { Map, Marker, Polyline, ColorScheme } from "mapkit-react";
import { getBounds, getCenter } from "geolib";
import { customColors } from "models/customColors";
import { useTheme } from "@mui/material";
import PickUpImage from "assets/img/pick-up-image.png";
import DropOffImage from "assets/img/drop-off-image.png";
import CarImage from "assets/img/car-icon.svg";
import { unixTimeToUSTime } from "utils";

const ZOOM_FACTOR_FOR_COORDS_DELTA = 1.6;
const INITIAL_COORDS_DELTA = 0.5;
const MIN_COORDS_DELTA = 0.002;
const POI_OF_DELAY_IN_MS = 500;
const RELOAD_DELAY_IN_MS = 500;

const defaultMarker = {
  // Houston
  lat: 29.749907,
  lon: -95.358421,
};

export const RouteMapView = ({
  route = [],
  preRoute = [],
  departure,
  destination,
  driver = null,
}) => {
  const theme = useTheme();
  const [initialRegion, setInitialRegion] = useState({
    centerLatitude: defaultMarker.lat,
    centerLongitude: defaultMarker.lon,
    latitudeDelta: INITIAL_COORDS_DELTA,
    longitudeDelta: INITIAL_COORDS_DELTA,
  });
  const [showsPointsOfInterest, setShowsPointsOfInterest] = useState(true);
  const [isVisible, setIsVisible] = useState(true);

  const points = route.map(({ lat, lon }) => ({
    latitude: lat,
    longitude: lon,
  }));

  const preRoutePoints = preRoute.map(({ lat, lon }) => ({
    latitude: lat,
    longitude: lon,
  }));

  const handleOnLoad = () => {
    setTimeout(() => {
      setShowsPointsOfInterest(false);
    }, POI_OF_DELAY_IN_MS);
  };

  useEffect(() => {
    if (route?.length) {
      const points = [...preRoute,...route].map(({ lat, lon }) => ({
        latitude: lat,
        longitude: lon,
      }));

      const { maxLat, minLat, maxLng, minLng } = getBounds(points);
      const center = getCenter(points);
      const latitudeDelta = maxLat - minLat;
      const longitudeDelta = maxLng - minLng;
      const maxDelta =
        latitudeDelta > longitudeDelta ? latitudeDelta : longitudeDelta;
      const visibleDelta =
        maxDelta * ZOOM_FACTOR_FOR_COORDS_DELTA < MIN_COORDS_DELTA
          ? MIN_COORDS_DELTA
          : maxDelta * ZOOM_FACTOR_FOR_COORDS_DELTA;

      setInitialRegion({
        // @ts-ignore
        centerLatitude: center?.latitude ?? 0,
        // @ts-ignore
        centerLongitude: center?.longitude ?? 0,
        latitudeDelta: visibleDelta,
        longitudeDelta: visibleDelta,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  useEffect(() => {
    setIsVisible(false);

    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, RELOAD_DELAY_IN_MS);

    return () => clearTimeout(timeout);
  }, [route]);

  const isStatusActive =
    !!driver?.status &&
    (driver.status === "EN_ROUTE" ||
      driver.status === "ARRIVED" ||
      driver.status === "ON_BOARD");

  return isVisible && !!initialRegion ? (
    <Map
      token={process.env.REACT_APP_MAP_TOKEN}
      showsScale={2}
      distances={0}
      initialRegion={initialRegion}
      cameraBoundary={initialRegion}
      showsCompass={1}
      showsPointsOfInterest={showsPointsOfInterest}
      onLoad={handleOnLoad}
      isScrollEnabled={true}
      showsUserLocationControl={false}
      allowWheelToZoom={true}
      isZoomEnabled={true}
      colorScheme={
        theme.palette.mode === "dark" ? ColorScheme.Dark : ColorScheme.Light
      }
    >
      {!!route?.length && (
        <>
          <Marker
            title={"Pick Up"}
            subtitle={departure}
            subtitleVisibility={1}
            latitude={route[0].lat}
            longitude={route[0].lon}
            glyphImage={{
              1: PickUpImage,
            }}
            glyphColor={customColors[theme.palette.mode].white}
            color={customColors[theme.palette.mode].new}
          />
          <Marker
            title={"Drop Off"}
            subtitle={destination}
            subtitleVisibility={1}
            latitude={route[route.length - 1].lat}
            longitude={route[route.length - 1].lon}
            glyphImage={{
              1: DropOffImage,
            }}
            glyphColor={customColors[theme.palette.mode].white}
            color={customColors[theme.palette.mode].new}
          />
        </>
      )}
      {isStatusActive && (
        <Marker
          title={driver?.driverName ?? ""}
          subtitle={driver?.time ? unixTimeToUSTime(driver.time) : ""}
          subtitleVisibility={driver?.time ? 1 : 0}
          latitude={driver?.coordinates?.lat ?? 0}
          longitude={driver?.coordinates?.lon ?? 0}
          glyphImage={{
            1: CarImage,
          }}
          glyphColor={customColors[theme.palette.mode].white}
          color={customColors[theme.palette.mode].actionColor}
        />
      )}

      <Polyline
        points={points}
        lineWidth={5}
        strokeColor={customColors[theme.palette.mode].textBody}
        lineCap="round"
      />
      <Polyline
        points={preRoutePoints}
        lineWidth={5}
        strokeColor={customColors[theme.palette.mode].enRoute}
        lineCap="round"
      />
    </Map>
  ) : null;
};
