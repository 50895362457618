import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "store/globalContext";
import { useForm } from "react-hook-form";
import { Button, InputLabel, Stack } from "@mui/material";
import { DialogWindow } from "UI/DialogWindow";
import { FormTextField } from "UI/FormTextField";
import { FormSelectField } from "UI/FormSelectField";
import {
  emailValidation,
  clientNameValidation,
  phoneNumberValidation,
  required,
} from "models/validations";
import { FormMaskedTextField } from "UI";
import { useApiRequest } from "api/useApiRequest";
import { activateClientById } from "api/clientApi";
import { ReactComponent as UserIcon } from "assets/img/user-small-icon.svg";
import { AddSpecialityDialog } from "./AddSpecialityDialog";
import LocationSelector from "./LocationSelector";
import * as FP from "utils/fp-js";
import { useAppDispatch } from "store/hooks";
import { updateUserCompany } from "store/reducers/userReducer";

/** @type {import("types/commonTypes").NewCompanyDto & { emailRepeat: string; }}*/
const defaultFormData = {
  companyName: "",
  phoneNumber: "",
  email: "",
  adminFirstName: "",
  adminLastName: "",
  emailRepeat: "",
  headOfficePlaceId: "",
  headOfficeAwsId: "",
  specialityId: "",
};

export const FinishClientDetailsDialog = ({
  open,
  handleClose,
  refreshList,
  specialityOptions,
  loadSpecialities,
  client,
  id,
}) => {
  const dispatch = useAppDispatch();
  const { showWarning } = useContext(GlobalContext);
  const { callApiRequest } = useApiRequest();

  const [isAddFacilityOpen, setIsAddFacilityOpen] = useState(false);
  const [defaultLocationLabel, setDefaultLocationLabel] = useState();

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: defaultFormData,
  });

  const email = useRef("");
  email.current = watch("email", "");

  const onSubmit = async () => {
    const client = getValues();

    /** @type {{ [key: string]: number | string }} */
    const data = Object.entries(client)
      .filter(([name, value]) =>
        FP.match(true)
          .on(name === "emailRepeat", false)
          .otherwise(true)
      )
      .reduce((acc, [name, value]) => ({ ...acc, [name]: value }), {});
    data.headOfficePlaceName = data.companyName;

    callApiRequest({
      apiRequest: activateClientById,
      params: {
        data,
        id,
      },
      onSuccess: (result) => {
        refreshList();
        handleClose();
        dispatch(updateUserCompany(result));
        showWarning({
          title: "Details Updated",
          content: "Company details have been successfully updated",
          icon: <UserIcon />,
        });
      },
    });
  };

  const handleLocationSelect = (location) => {
    clearErrors("headOfficeAwsId");

    if (location === null) {
      // reset location
      setValue("headOfficeAwsId", "", { shouldDirty: false });
      setValue("headOfficePlaceId", "", { shouldDirty: false });
    } else {
      // set new location
      setValue("headOfficeAwsId", location?.awsId ?? "", {
        shouldDirty: !!location?.awsId,
      });
      setValue("headOfficePlaceId", location?.id ?? "", {
        shouldDirty: !!location?.id,
      });
    }
  };

  useEffect(() => {
    // headOfficeFacilityTypeId;
    if (client?.id) {
      reset({
        companyName: client.companyName,
        phoneNumber: client.phoneNumber,
        email: client.email,
        emailRepeat: "",
        headOfficePlaceId: client?.headOffice?.id,
        headOfficeAwsId: client?.headOffice?.awsId,
        specialityId: client?.specialityId,
      });
      // @ts-ignore
      setDefaultLocationLabel({ id: "", suggestion: client?.label ?? "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, open]);

  const handleSubmitWithAddressCheck = () => {
    const values = getValues();
    if (!values?.headOfficeAwsId && !values?.headOfficePlaceId) {
      setError("headOfficeAwsId", { message: "Is required" });
    }
    handleSubmit(onSubmit)();
  };

  if (!client?.id || !defaultLocationLabel) {
    return null;
  }

  return (
    <DialogWindow
      open={open}
      handleClose={handleClose}
      title={"Update Your Company Details"}
      content={
        <form onSubmit={handleSubmitWithAddressCheck}>
          <Stack>
            <Stack
              mt={2}
              gap={3}
              direction={"row"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Stack gap={2}>
                <Stack alignItems={"flex-start"}>
                  <InputLabel htmlFor="companyName">Client Name</InputLabel>
                  <FormTextField
                    id="companyName"
                    name="companyName"
                    control={control}
                    rules={clientNameValidation}
                    InputProps={{
                      autoComplete: "off",
                    }}
                  />
                </Stack>
                <Stack alignItems={"flex-start"}>
                  <InputLabel htmlFor="email">Email</InputLabel>
                  <FormTextField
                    id="email"
                    name="email"
                    control={control}
                    rules={emailValidation}
                    InputProps={{
                      autoComplete: "email",
                    }}
                  />
                </Stack>
                <Stack alignItems={"flex-start"}>
                  <InputLabel htmlFor="adminFirstName">
                    Admin Firstname
                  </InputLabel>
                  <FormTextField
                    id="adminFirstName"
                    name="adminFirstName"
                    control={control}
                    rules={clientNameValidation}
                    InputProps={{
                      autoComplete: "new-password",
                    }}
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                  />
                </Stack>
                <Stack alignItems={"flex-start"}>
                  <InputLabel htmlFor="adminLastName">
                    Admin Lastname
                  </InputLabel>
                  <FormTextField
                    id="adminLastName"
                    name="adminLastName"
                    control={control}
                    rules={clientNameValidation}
                    InputProps={{
                      autoComplete: "new-password",
                    }}
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                  />
                </Stack>
              </Stack>
              <Stack gap={2}>
                <Stack alignItems={"flex-start"}>
                  <InputLabel htmlFor="phoneNumber">Phone Number</InputLabel>
                  <FormMaskedTextField
                    id="phoneNumber"
                    name="phoneNumber"
                    control={control}
                    rules={phoneNumberValidation}
                    mask="999-999-9999"
                    InputProps={{
                      autoComplete: "off",
                    }}
                  />
                </Stack>
                <Stack alignItems={"flex-start"}>
                  <Stack alignItems={"flex-start"} width={"100%"}>
                    <InputLabel htmlFor="specialityId">Speciality</InputLabel>
                    <FormSelectField
                      id="specialityId"
                      name="specialityId"
                      control={control}
                      options={specialityOptions}
                      withNone={false}
                      rules={{ required }}
                    />
                  </Stack>
                  <Button onClick={() => setIsAddFacilityOpen(true)}>
                    Add New Speciality
                  </Button>
                </Stack>
                <Stack alignItems={"flex-start"} width={"100%"}>
                  <InputLabel htmlFor="location">
                    Main Office Address
                  </InputLabel>
                  <LocationSelector
                    id="location"
                    setValue={handleLocationSelect}
                    defaultValue={defaultLocationLabel}
                    error={errors?.headOfficeAwsId?.message}
                  />
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  width={"100%"}
                  gap={1}
                >
                  <Button
                    variant="contained"
                    onClick={handleSubmitWithAddressCheck}
                  >
                    Save and continue
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <AddSpecialityDialog
            open={isAddFacilityOpen}
            handleClose={() => setIsAddFacilityOpen(false)}
            loadSpecialities={loadSpecialities}
          />
        </form>
      }
    />
  );
};
