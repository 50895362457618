import React from "react";
import { Button, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from "assets/img/backIcon.svg";
import { customColors } from "models/customColors";

const StyleButton = styled(Button)`
  & path {
    fill: ${({ theme }) => customColors[theme.palette.mode].textBody};
  }

  margin-right: 10px;
`;

export const BackButton = () => {
  const navigate = useNavigate();

  return (
    <StyleButton onClick={() => navigate(-1)}>
      <BackIcon />
    </StyleButton>
  );
};
