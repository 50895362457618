import { useState } from "react";

export const useAddClient = () => {
  const [isAddClientVisible, setIsAddClientVisible] = useState(false);

  return {
    isAddClientVisible,
    setIsAddClientVisible,
  };
};
