import React from "react";
import { Grid, styled } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { customColors } from "models/customColors";

const Container = styled(Grid)`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  cursor: pointer;
  &:hover {
    path {
      color: ${({ theme }) => customColors[theme.palette.mode].actionColor};
    }
  }
`;

export const DataGridSelector = ({
  row = {},
  rows = [],
  keyName,
  selectedRows,
  setSelectedRows,
  header = false,
}) => {
  
  if (header) {
    const isAllUnselected = selectedRows.length === 0;

    const handleToggleAllSelection = (e) => {  
      e.stopPropagation();

      if(isAllUnselected) {
        setSelectedRows(rows.map((row) => row[keyName]));
      } else {
        setSelectedRows([]);
      }
    };

    return (
      <Container item xs={0.5} sm={0.5} md={0.5} onClick={handleToggleAllSelection}>
        {isAllUnselected ? (
          <CheckBoxOutlineBlankIcon fontSize="small" />
        ) : (
          <CheckBoxIcon fontSize="small" />
        )}
      </Container>
    );
  }
  const isSelected = selectedRows.some(
    (selectedRow) => selectedRow === row[keyName]
  );

  const handleToggleSelection = (e) => {
    e.stopPropagation();

    if(isSelected) {
      setSelectedRows((prev) => prev.filter((selectedRow) => selectedRow !== row[keyName]));
    } else {
      setSelectedRows((prev) => [...prev, row[keyName]]);
    }
  };

  return (
    <Container item xs={0.5} sm={0.5} md={0.5} onClick={handleToggleSelection}>
      {isSelected ? (
        <CheckBoxIcon fontSize="small" />
      ) : (
        <CheckBoxOutlineBlankIcon fontSize="small" />
      )}
    </Container>
  );
};
