import { useApiRequest } from "api/useApiRequest";
import { getDriverDetails, updateUserAccountStatus } from "api/userApi";
import { ReactComponent as CompleteIcon } from "assets/img/complete-icon.svg";
import { useCallback, useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { showError } from "store/reducers/errorReducer";
import { loadDriverPhoto, loadUserPhoto } from "utils";

import {
  deleteDriver,
  getDriverStatistic,
  updateDriverApproval,
} from "api/driverApi";
import { addUserToChat, createNewChat, searchChatByName } from "api/messageApi";
import { useApproveCarRequest } from "hooks/useApproveCarRequest";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "store/globalContext";
import {
  openChecksByDriverId,
  openNewCheckByDriverId,
} from "store/reducers/backgroundChecksReducer";
import { hideSpinner, showSpinner } from "store/reducers/spinnerReducer";
import { Box } from "@mui/material";

/** @type {import("types/commonTypes").DriverDetails | null} */
const defaultDriver = null;

const defaultValues = {
  status: "",
};

export const useDriverDetailsWindow = ({
  userId,
  closeDetails = () => null,
  refreshList = () => null,
}) => {
  const navigate = useNavigate();
  const { showWarning, openConfirmationWithCallback } =
    useContext(GlobalContext);
  const { callApiRequest } = useApiRequest();
  const dispatch = useAppDispatch();
  const mattermostUserId = useAppSelector(
    (state) => state.user.mattermostUserId
  );

  const { control, setValue, getValues, watch } = useForm({
    defaultValues,
  });

  const [mode, setMode] = useState("ACTIVE");

  const [isDriverRejectionDialogOpen, setIsDriverRejectionDialogOpen] =
    useState(false);

  const [isCarRejectionDialogOpen, setIsCarRejectionDialogOpen] =
    useState(false);
  const [carId, setCarId] = useState("");

  const [driver, setDriver] = useState(defaultDriver);
  const [profileImage, setProfileImage] = useState(null);
  const [imageTitle, setImageTitle] = useState(null);
  const [image, setImage] = useState(null);
  const [isAddKericoVehicleDialogOpen, setIsAddKericoVehicleDialogOpen] =
    useState(false);
  const [statistics, setStatistics] = useState(null);

  const [isQuizViewerOpen, setIsQuizViewerOpen] = useState(false);

  const loadLicenseImage = async () => {
    dispatch(showSpinner());
    setImageTitle("Driver's License");
    await loadDriverPhoto({
      driver,
      photoType: "License",
      setImage,
      setError: (error) => dispatch(showError(error)),
    });
    dispatch(hideSpinner());
  };

  /** @param {import("types/commonTypes").Vehicle} vehicle */
  const loadInsuranceImage = async (vehicle) => {
    dispatch(showSpinner());
    const photoId = vehicle?.insurancePhoto?.id;
    setImageTitle("Vehicle Insurance");
    await loadUserPhoto({
      photoId,
      setImage,
      setError: (error) => dispatch(showError(error)),
    });

    dispatch(hideSpinner());
  };

  const showDriverPhoto = () => {
    setImageTitle("Driver Photo");
    setImage(profileImage);
  };

  const loadData = useCallback(async () => {
    callApiRequest({
      apiRequest: getDriverDetails,
      params: userId,
      onSuccess: (result) => {
        setDriver(result);
      },
      onError: (error) => {
        // @ts-ignore
        dispatch(showError(error));
        setDriver(defaultDriver);
      },
    });

    callApiRequest({
      apiRequest: getDriverStatistic,
      params: userId,
      onSuccess: (result) => {
        setStatistics(result);
      },
      onError: (error) => {
        // @ts-ignore
        dispatch(showError(error));
        setStatistics(null);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const approveDriverRequest = () => {
    /** @type {import("types/commonTypes").ApprovalDto} */
    const data = {
      approved: true,
    };

    callApiRequest({
      apiRequest: updateDriverApproval,
      params: {
        data,
        driverId: userId,
      },
      onSuccess: () => {
        refreshList();
        loadData();
        showWarning({
          title: "Driver Approved",
          content: "Driver successfully registered!",
          icon: <CompleteIcon />,
        });
      },
    });
  };

  const handleStatusChange = (e) => {
    const accountStatus = e?.target?.value;

    if (accountStatus !== getValues("status")) {
      callApiRequest({
        apiRequest: updateUserAccountStatus,
        params: {
          data: {
            accountStatus,
            sessionStatus: driver?.sessionStatus,
          },
          userId,
        },
        onSuccess: () => {
          refreshList();
          loadData();
          showWarning({
            title: "Driver Account Status Changed",
            content: `Now the driver account has an ${accountStatus?.toLowerCase()} status.`,
            icon: <CompleteIcon />,
          });
        },
      });
    }
  };

  const handleDeleteDriver = () =>
    openConfirmationWithCallback({
      title: "Warning!",
      message: (
        <Box sx={{ textAlign: "left", fontSize: "16px", lineHeight: "22px" }}>
          Removing the driver will remove all driver-associated data, including
          earnings and accepted bookings. This action is irreversible. If you
          only need to limit the driver's access, please use block action
          instead.
        </Box>
      ),
      buttonLabels: ["Cancel", "Remove"],
      callbackFn: () =>
        callApiRequest({
          apiRequest: deleteDriver,
          params: userId,
          onSuccess: () => {
            refreshList();
            closeDetails();
            showWarning({
              title: "Driver Removed",
              content: `The driver was successfully removed.`,
              icon: <CompleteIcon />,
            });
          },
        }),
    });

  const handleCreateCheck = () => {
    dispatch(openNewCheckByDriverId(userId));
  };

  const handleViewChecks = () => {
    dispatch(openChecksByDriverId(userId));
  };

  const { approveCarRequest } = useApproveCarRequest({
    refreshList: () => {
      loadData();
      refreshList();
    },
    closeDetails,
  });

  const openChat = async () => {
    if (!driver?.id) {
      return;
    }

    // 1) Check if the chat exists
    const existingChat = await callApiRequest({
      apiRequest: searchChatByName,
      params: `driver_${driver?.id}`,
    });

    if (existingChat?.error) {
      dispatch(showError(existingChat.error));
      return;
    }

    if (existingChat?.value?.length === 1) {
      // try to add my self to the chat
      const channel_id = existingChat.value[0].id;

      const data = {
        chatId: channel_id,
        userId: mattermostUserId,
      };

      const result = await callApiRequest({
        apiRequest: addUserToChat,
        params: data,
      });

      if (result?.error) {
        dispatch(showError(`Error adding member to chat: ${result.error}`));
        return;
      }

      navigate(`/driver_chat/${driver?.id}/${existingChat.value[0].id}`);
      return;
    }

    // 2) Create new chat if it doesn't exist
    const params = {
      name: `driver_${driver?.id}`,
      team_id: process.env.REACT_APP_MATTERMOST_TEAM_ID,
      display_name: `Driver_${driver?.id}`,
      type: "O",
      purpose: `${driver?.firstName} ${driver?.lastName}, ${driver?.phone}`,
      header: "",
    };
    const result = await callApiRequest({
      apiRequest: createNewChat,
      params,
    });

    if (result?.error) {
      dispatch(showError(`Chat creation error: ${result.error}`));
      return;
    }

    const channel_id = result?.value?.id;
    const userIds = [mattermostUserId, driver?.mattermostUserId];

    // add members into the newly created chat
    for (const user_id of userIds) {
      const data = {
        chatId: channel_id,
        userId: user_id,
      };
      const result = await callApiRequest({
        apiRequest: addUserToChat,
        params: data,
      });

      if (result?.error) {
        dispatch(showError(`Error adding member to chat: ${result.error}`));
        return;
      }
    }

    navigate(`/driver_chat/${driver?.id}/${channel_id}`);
  };

  const currentStatus = watch("status");

  useEffect(() => {
    if (userId) {
      loadData();
      setMode("ACTIVE");
      return;
    }
    setDriver(defaultDriver);
  }, [loadData, userId]);

  useEffect(() => {
    loadDriverPhoto({
      driver,
      photoType: "Profile",
      setImage: setProfileImage,
    });

    setValue("status", driver?.status ?? "");
  }, [driver, setValue]);

  // @ts-ignore
  const isNotApproved = driver?.status === "APPROVAL";

  const vehicles =
    driver?.vehicles
      ?.sort((a, b) => (a?.make < b.make ? 1 : -1))
      ?.sort((a, b) => (a?.assignment > b.assignment ? 1 : -1))
      .filter(({ fleetStatus }) => fleetStatus === mode) ?? [];

  return {
    driver,
    statistics,
    vehicles,
    mode,
    setMode,
    handleStatusChange,
    handleDeleteDriver,
    approveDriverRequest,
    approveCarRequest,
    isNotApproved,
    isDriverRejectionDialogOpen,
    setIsDriverRejectionDialogOpen,
    isCarRejectionDialogOpen,
    setIsCarRejectionDialogOpen,
    carId,
    setCarId,
    loadLicenseImage,
    loadInsuranceImage,
    showDriverPhoto,
    image,
    imageTitle,
    isAddKericoVehicleDialogOpen,
    setIsAddKericoVehicleDialogOpen,
    openChat,
    control,
    currentStatus,
    profileImage,
    loadData,
    setImage,
    handleCreateCheck,
    handleViewChecks,
    isQuizViewerOpen,
    setIsQuizViewerOpen,
  };
};
