import { useEffect, useState } from "react";
import { useApiRequest } from "api/useApiRequest";
import { getRootFields } from "api/csvTemplateApi";

export const useRideRequestFields = () => {
  const { callApiRequest } = useApiRequest();

  // set of target fields for mapping
  const [rideRequestFields, setRideRequestFields] = useState(null);
  // default empty mapping template calculated from rideRequestFields
  const [defaultTemplate, setDefaultTemplate] = useState(null);

  useEffect(() => {
    callApiRequest({
      apiRequest: getRootFields,
      onSuccess: (data) => {
        if (!data) {
          return;
        }
        setRideRequestFields(data);
        setDefaultTemplate(
          data.reduce((acc, field) => {
            acc[field.name] = [];
            return acc;
          }, {})
        );
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    rideRequestFields,
    defaultTemplate,
  };
};
