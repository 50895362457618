import { Box, Stack, styled } from "@mui/material";
import { customColors } from "models/customColors";
import { StatusColorNames } from "models/bookingRequest";

export const GrayText = styled(Box)`
  font-size: 0.8rem;
  color: ${({ theme }) => customColors[theme.palette.mode].greyText};
`;

export const Yes = styled(Stack)`
  justify-content: center;
  align-items: center;
  min-height: 22px;
  color: ${({ theme }) => customColors[theme.palette.mode].error};
  background-color: ${({ theme }) => customColors[theme.palette.mode].error}29;
  border-radius: 4px;
`;

export const No = styled(Stack)`
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => customColors[theme.palette.mode].greyText}79;
`;

export const Status = styled(Stack)`
  justify-content: center;
  align-items: center;

  padding: 5px;
  border-radius: 8px;

  color: ${({
    // @ts-ignore
    status,
    theme,
  }) =>
    customColors[theme.palette.mode][StatusColorNames[status] ?? "greyText"]};
  background-color: ${({
    // @ts-ignore
    status,
    theme,
  }) =>
    `${
      customColors[theme.palette.mode][StatusColorNames[status] ?? "greyText"]
    }29`};
`;

export const RightPanel = styled(Stack)`
  flex: 0 0 auto;
`;