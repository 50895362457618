import { httpService } from "./httpService";

/**
 * @template T
 * @typedef {import('types/commonTypes').HttpResult<T>} HttpResult
 */

/**
 * @typedef {import('types/commonTypes').CompaniesResponse} CompaniesResponse
 * @param {import('types/commonTypes').TableRequest} data
 * @returns  {HttpResult<CompaniesResponse>}
 */
export const getClients = async (data) =>
  httpService({
    url: "/customers/companies/search",
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @typedef {import('types/commonTypes').Company} Company
 * @param {string} id
 * @returns  {HttpResult<Company>}
 */
export const getClientById = async (id) =>
  httpService({
    url: `/customers/companies/${id}`,
    options: {
      method: "GET",
    },
  });

/**
 * @param {string} id
 * @returns  {HttpResult<string>}
 */
export const deleteClientById = async (id) =>
  httpService({
    url: `/customers/companies/${id}`,
    options: {
      method: "DELETE",
    },
  });

/**
 * @param {{ data: import("types/commonTypes").NewCompanyDto; id: string; }} id
 * @returns  {HttpResult<string>}
 */
export const updateClientById = async ({ data, id }) =>
  httpService({
    url: `/customers/companies/${id}`,
    options: {
      method: "PUT",
      data,
    },
  });

/**
 * @param {{ data: import("types/commonTypes").NewCompanyDto; id: string; }} id
 * @returns  {HttpResult<import("types/commonTypes").Company>}
 */
export const activateClientById = async ({ data, id }) =>
  httpService({
    url: `/customers/companies/activate/${id}`,
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @typedef {import('types/commonTypes').Facility[]} Facilities
 * @returns  {HttpResult<Facilities>}
 */
export const getFacilities = async () =>
  httpService({
    url: "/specialities",
    options: {
      method: "GET",
    },
  });

/**
 * @typedef {import('types/commonTypes').Facility} Facility
 * @param {{ type: string; }} data
 * @returns  {HttpResult<Facility>}
 */
export const addSpeciality = async (data) =>
  httpService({
    url: "/specialities",
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @typedef {import('types/commonTypes').Company} Client
 * @param {import('types/commonTypes').NewCompanyDto} data
 * @returns  {HttpResult<Client>}
 */
export const addClient = async (data) =>
  httpService({
    url: "/customers/companies",
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @returns  {HttpResult<Company[]>}
 */
export const getClientList = async () =>
  httpService({
    url: `/customers/companies`,
    options: {
      method: "GET",
    },
  });
