import React from "react";
import { RadioSwitcher } from "UI/RadioSwitcher";

export const RequestItemsTableModeSelector = ({
  mode,
  setMode,
  validateRequestsDataGrid,
  allRequestsdataGrid,
}) => {
  const handleClickUnactiveItem = (e, value) => {
    setMode(value);
    if (value === "VALIDATE_REQUESTS") {
      validateRequestsDataGrid.loadData();
    } else {
      allRequestsdataGrid.loadData();
    }
  };

  return (
    <RadioSwitcher
      handleClickUnactiveItem={handleClickUnactiveItem}
      value={mode}
      options={[
        {
          value: "VALIDATE_REQUESTS",
          label: "Validate Requests",
        },
        {
          value: "ALL_REQUESTS",
          label: "All Requests",
        },
      ]}
    />
  );
};
