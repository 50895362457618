import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Divider, Grid, Stack, styled } from "@mui/material";
import { ActionButton } from "SC";
import { DataGridSearch } from "UI/DataGridSearch";
import { BookingsViewModeSelector } from "components/BookingsViewModeSelector";
import { RideCard } from "components/RideCard";
import { StatusLegend } from "components/StatusLegend";
import { useCalendarView } from "hooks/useCalendarView";
import { customColors } from "models/customColors";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setBookingViewMode } from "store/reducers/layoutReducer";
import {
  getDateInWeekByIndexFromStartDay,
  getRidesByIndexOfTheDayInWeekFromStartDate,
  getShortDayNameFromSunday,
  unixTimeToUSLongDate,
} from "utils";
import { ActionPanel } from "../pages/BookingsPage";
import { CloseWindowButton } from "UI/CloseWindowButton";
import { RideDetails } from "./RideDetails";

const Title = styled(Stack)`
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
`;

const HeaderContainer = styled(Grid)`
  flex: 0 0 auto;
  overflow-y: scroll;
  border: thin solid
    ${({ theme }) => customColors[theme.palette.mode].borderColor};
  border-left: none;
  border-bottom: none;

  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border: transparent;
  }
`;

const HeaderItem = styled(Grid)`
  padding: 6px 0 0 0;
  border-left: 1px solid
    ${({ theme }) => customColors[theme.palette.mode].borderColor};
  border-right: none;
`;

const ContentContainer = styled(Grid)`
  border-right: 1px solid transparent;
  border-bottom: thin solid
    ${({ theme }) => customColors[theme.palette.mode].borderColor};
  overflow-y: scroll;
`;

const ContentItem = styled(Grid)`
  min-height: 100%;
  border-left: 1px solid
    ${({ theme }) => customColors[theme.palette.mode].borderColor};
`;

export const HalfScreenPanel = styled(Stack)`
  flex: 0 0 auto;
  width: calc(60% - 20px);
`;

export const CalendarView = ({
  viewModeOptions,
  setIsCalculatorOpen,
  dataGrid,
  selectedId,
  setSelectedId,
  getClientLabelById,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const mode = useAppSelector((state) => state.layout.bookingViewMode ?? 0);
  const setMode = (mode) => dispatch(setBookingViewMode(mode));

  const {
    showPrevWeek,
    showNextWeek,
    showCurrentWeek,
    startDate,
    endDate,
    rides,
    daysOfWeek,
    updatedViewModeOptions,
  } = useCalendarView({ dataGrid, viewModeOptions });

  const isSelectedRideOutRange = (ridesInRange) => {
    const selectedRide = ridesInRange.find((ride) => ride.id === selectedId);
    return selectedId && !selectedRide;
  };

  const shouldBeHidden = (index, givenRidesInThisDay) => {
    const getRides = (dayIndex) =>
      getRidesByIndexOfTheDayInWeekFromStartDate({
        dayIndex,
        startDate,
        rides,
      });

    const ridesInThisDay = givenRidesInThisDay ?? getRides(index);
    const ridesInPrevDay = index > 0 ? getRides(index - 1) : [];
    const ridesInNextDay = getRides(index + 1);

    const isRideOut = (rides) => isSelectedRideOutRange(rides);

    if (isRideOut(ridesInThisDay) && isRideOut(ridesInNextDay)) {
      return index === 1 ? isRideOut(ridesInPrevDay) : true;
    }

    return false;
  };

  return (
    <>
      <Stack
        gap={"20px"}
        overflow={"hidden"}
        justifyContent={"space-between"}
        width={selectedId ? "calc(40% - 20px)" : "100%"}
      >
        <Stack
          height={"100%"}
          overflow="hidden"
          justifyContent="space-between"
          alignItems="stretch"
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={1}
            mb={1}
          >
            <BookingsViewModeSelector
              mode={mode}
              setMode={setMode}
              options={updatedViewModeOptions}
              closeRide={() => setSelectedId("")}
            />
            <DataGridSearch
              search={dataGrid.search}
              handleChangeSearch={dataGrid.handleChangeSearch}
              clearSearch={dataGrid.clearSearch}
            />
            {!selectedId && (
              <ActionPanel
                navigate={navigate}
                setIsCalculatorOpen={setIsCalculatorOpen}
              />
            )}
          </Stack>
          <Divider />
          {!selectedId && (
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={1}
              mt={1}
            >
              <Stack direction={"row"} gap={1}>
                <ActionButton onClick={showPrevWeek}>
                  <KeyboardArrowLeftIcon />
                </ActionButton>
                <ActionButton onClick={showCurrentWeek}>
                  Current Week
                </ActionButton>
                <ActionButton onClick={showNextWeek}>
                  <KeyboardArrowRightIcon />
                </ActionButton>
              </Stack>
              <Title mr={20} direction={"row"} gap={1}>
                <Box>{unixTimeToUSLongDate(startDate)}</Box>
                <Box>-</Box>
                <Box>{unixTimeToUSLongDate(endDate)}</Box>
              </Title>
              <StatusLegend />
            </Stack>
          )}
          <HeaderContainer
            mt={1}
            container
            columns={
              selectedId ? { xs: 2, sm: 2, md: 2 } : { xs: 7, sm: 7, md: 7 }
            }
          >
            {daysOfWeek.map((_, index) => {
              if (shouldBeHidden(index)) return null;

              return (
                <HeaderItem item xs={1} key={`header-of-day-${index}`}>
                  <Stack alignItems={"center"}>
                    {getShortDayNameFromSunday(index)}{" "}
                    {getDateInWeekByIndexFromStartDay(startDate, index)}
                  </Stack>
                  <Stack mt="6px" alignItems={"center"}>
                    <Divider sx={{ width: "90%" }} />
                  </Stack>
                </HeaderItem>
              );
            })}
          </HeaderContainer>
          <ContentContainer
            flex={1}
            container
            columns={
              selectedId ? { xs: 2, sm: 2, md: 2 } : { xs: 7, sm: 7, md: 7 }
            }
            maxHeight={"100%"}
          >
            {daysOfWeek.map((_, index) => {
              const ridesInThisDay = getRidesByIndexOfTheDayInWeekFromStartDate(
                {
                  dayIndex: index,
                  startDate,
                  rides,
                }
              );
              if (shouldBeHidden(index, ridesInThisDay)) return null;

              return (
                <ContentItem item xs={1} key={`content-of-day-${index}`}>
                  {ridesInThisDay.map((ride) => (
                    <RideCard
                      ride={ride}
                      selectedId={selectedId}
                      setSelectedId={setSelectedId}
                      key={ride.id}
                    />
                  ))}
                </ContentItem>
              );
            })}
          </ContentContainer>
        </Stack>
      </Stack>
      {selectedId && (
        <HalfScreenPanel>
          <CloseWindowButton
            top={-35}
            right={-35}
            closeHandler={() => setSelectedId("")}
          />
          <RideDetails
            id={selectedId}
            getClientLabelById={getClientLabelById}
            refreshList={dataGrid.loadData}
          />
        </HalfScreenPanel>
      )}
    </>
  );
};
