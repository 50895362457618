import { httpService } from "./httpService";

/**
 * @template T
 * @typedef {import('types/commonTypes').HttpResult<T>} HttpResult
 */

/**
 * @typedef {import('types/commonTypes').VehicleDto} VehicleDto
 * @param {import('types/commonTypes').TableRequest} data
 * @returns  {HttpResult<import('types/commonTypes').VehiclesResponse>}
 */
export const getVehicles = async (data) =>
  httpService({
    url: "/vehicles/search",
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @param {import('types/commonTypes').TableRequest} data
 * @returns  {HttpResult<import('types/commonTypes').VehiclesResponse>}
 */
export const getInactiveVehicles = async (data) =>
  httpService({
    url: "/vehicles/inactive/search",
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @param {import('types/commonTypes').TableRequest} data
 * @returns  {HttpResult<import('types/commonTypes').VehiclesResponse>}
 */
export const getKericoVehicles = async (data) =>
  httpService({
    url: "/vehicles/kerico/search",
    options: {
      method: "POST",
      data,
    },
  });

/**
 * @typedef {import('types/commonTypes').VehicleDetails} VehicleDetails
 * @param {string} id
 * @returns  {HttpResult<VehicleDetails>}
 */
export const getVehicleDetails = async (id) =>
  httpService({
    url: `/vehicles/${id}`,
    options: {
      method: "GET",
    },
  });

/**
 * @param {{ carId: string; data: import('types/commonTypes').ApprovalDto }} props
 * @returns  {HttpResult<VehicleDetails>}
 */
export const updateCarApproval = async ({ carId, data }) =>
  httpService({
    url: `/vehicles/${carId}/approve`,
    options: {
      method: "POST",
      data,
    },
  });

  /**
 * @param {{ userId: string; vehicleId: string; }} props
 * @returns  {HttpResult<import('types/commonTypes').Vehicle>}
 */
export const assignDriverToKericoVehicle = async ({ userId, vehicleId }) =>
httpService({
  url: `/vehicles/${vehicleId}/assign/${userId}`,
  options: {
    method: "PUT",
  },
});
