import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, Divider, IconButton, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  inrceaseSentMessageCount,
  updatePrevChat,
} from "store/reducers/chatsReducer";
import { FormTextField } from "UI";
import { useApiRequest } from "api/useApiRequest";
import {
  createMessageInChat,
  getAvatarByMattermostId,
  getChatPostPage,
  getChatUsers,
} from "api/messageApi";
import { useForm } from "react-hook-form";
import { getReadableChatName, unixTimeToUSDate } from "utils";
import { useChatUsers } from "hooks/useChatUsers";
import {
  Container,
  Chat,
  IncomingHolder,
  SystemMessage,
  ChatAvatar,
  ChatDivider,
  ChatTitle,
  Members,
} from "./Messenger.style";
import { OutgoingMessage } from "./OutgoingMessage";
import { IncomingMessage } from "./IncomingMessage";
import { MESSAGE_PER_PAGE_IN_CHATS } from "models/constants";
import { useFormDataSync } from "hooks/useFormDataSync";
import { ReactComponent as SendIcon } from "assets/img/send-icon.svg";
import { ReactComponent as UsersIcon } from "assets/img/users-icon.svg";
import { customColors } from "models/customColors";

const systemMessagesByType = {
  system_join_channel: "joined the channel",
  system_leave_channel: "left the channel",
  system_add_to_channel: "added user to the channel",
  system_remove_from_channel: "removed user from the channel",
  system_displayname_change: "changed display name",
  system_channel_deleted: "deleted the channel",
  system_channel_restored: "restored the channel",
};

const defaultValues = {
  message: "",
};

export const Messenger = ({ chatId, withHeader = false }) => {
  const scrollRef = useRef(null);
  const dispatch = useAppDispatch();

  const { callApiRequest } = useApiRequest();

  const chat = useAppSelector((state) =>
    state.chats.new?.find((chat) => chat.id === chatId)
  );

  const [users, setUsers] = useState([]);
  const [usersOnPage, setUsersOnPage] = useState([]);
  const [avatars, setAvatars] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [reloadSignal, setReloadSignal] = useState(0);
  const [scrollSignal, setScrollSignal] = useState(0);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState([]);
  const [posts, setPosts] = useState({});

  const updatePostOrder = (newOrder) => {
    setOrder((order) => {
      const reallyNew = newOrder
        .reverse()
        .filter((postId) => !order.includes(postId));

      return [...reallyNew, ...order];
    });
  };

  const updatePosts = (newPosts) => {
    setPosts((posts) => ({ ...posts, ...newPosts }));
  };

  const addNewPost = (newPost) => {
    setPosts((posts) => ({ ...posts, [newPost.id]: newPost }));
    setOrder((order) => [...order, newPost.id]);
  };

  const userRole = useAppSelector((state) => state.user.role);
  const mattermostId = useAppSelector((state) => state.user.mattermostUserId);

  const { loadUsersData, getUserLabelById } = useChatUsers();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues,
  });

  useFormDataSync({
    isDirty,
    open: !!chatId,
    reset,
  });

  const loadData = useCallback(async () => {
    await loadUsersData(chatId);

    callApiRequest({
      apiRequest: getChatUsers,
      params: chatId,
      onSuccess: (result) => {
        setUsers(result ?? []);
      },
    });

    callApiRequest({
      apiRequest: getChatPostPage,
      params: {
        chatId,
        queryParams: {
          page,
          per_page: MESSAGE_PER_PAGE_IN_CHATS,
        },
      },
      skipSpinner: true,
      onError: () => {
        setOrder([]);
        setPosts({});
        setIsLoaded(true);
      },
      onSuccess: (result) => {
        if (page === 0) {
          dispatch(updatePrevChat(chatId));
          setOrder(result?.order?.reverse() ?? []);
          setPosts(result?.posts ?? {});
          setIsLoaded(true);
          setScrollSignal((signal) => signal + 1);
          return;
        }
        updatePostOrder(result?.order ?? []);
        updatePosts(result?.posts ?? []);
        setIsLoaded(true);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId, page, reloadSignal]);

  const onSumbit = (data) => {
    const message = data.message.trim();

    if (!message) {
      reset(defaultValues);
      return;
    }

    callApiRequest({
      apiRequest: createMessageInChat,
      params: {
        channel_id: chatId,
        message,
      },
      onSuccess: (result) => {
        reset(defaultValues);
        addNewPost(result);
        setScrollSignal((signal) => signal + 1);
        dispatch(inrceaseSentMessageCount(chatId));
      },
    });
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (isLoaded && scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [isLoaded, scrollSignal]);

  useEffect(() => {
    setPage(0);
    setReloadSignal((signal) => signal + 1);
  }, [chat?.last_post_at]);

  useEffect(() => {
    const records = [
      ...new Set(Object.values(posts).map((post) => post?.user_id)),
    ];
    if (records?.length) {
      setUsersOnPage((userOnPage) => {
        const newUsers = records.filter(
          (userId) => !userOnPage.includes(userId)
        );
        if (!newUsers.length) {
          return userOnPage;
        }
        return [...userOnPage, ...newUsers];
      });
    }
  }, [posts]);

  useEffect(() => {
    usersOnPage.forEach((userId) => {
      if (userId in avatars) {
        return;
      }
      const getAvatar = async () => {
        try {
          const result = await getAvatarByMattermostId(userId);
          // @ts-ignore
          if (!result?.error) {
            const reader = new FileReader();
            reader.onload = () => {
              const base64data = reader.result;
              setAvatars((avatars) => ({
                ...avatars,
                // @ts-ignore
                [userId]: base64data,
              }));
            };
            // @ts-ignore
            reader.readAsDataURL(result.blob);
          }
        } catch (e) {
          console.error(e);
        }
      };
      getAvatar();
    });
  }, [usersOnPage, avatars]);

  if (!chatId) {
    return null;
  }

  const chatName = getReadableChatName(chat);

  const orderCount = order?.length;
  const postsCount = order?.filter((postId) => !posts[postId]?.type)?.length;
  const isMoreVisible =
    (chat?.total_msg_count ?? 0) > postsCount ||
    orderCount >= MESSAGE_PER_PAGE_IN_CHATS - 1;

  const isClientUserInClientChat =
    chat?.name?.startsWith("client_") &&
    ["CUSTOMER_ADMIN", "CUSTOMER_MANAGER"].includes(userRole);

  return (
    <Container>
      {withHeader && (
        <>
          <Stack
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            gap={3}
          >
            <ChatAvatar>
              <UsersIcon />
            </ChatAvatar>
            {!isClientUserInClientChat && (
              <Stack gap={0.3}>
                <ChatTitle>{chatName ?? "Deleted Chat"}</ChatTitle>
                {chat?.purpose && <Members>{chat.purpose}</Members>}
                <Members sx={{ fontSize: "12px" }}>
                  Members: {users?.length ?? 0}
                </Members>
              </Stack>
            )}
          </Stack>
          <ChatDivider />
        </>
      )}
      <Chat ref={scrollRef}>
        {!isLoaded && <Stack>Loading...</Stack>}
        {isMoreVisible && isLoaded && (
          <Button onClick={() => setPage((page) => page + 1)}>More...</Button>
        )}
        {isLoaded &&
          order?.map((postId) => {
            const { id, create_at, message, user_id, type } =
              posts[postId] ?? {};

            if (type) {
              return (
                <IncomingHolder key={id}>
                  <Stack
                    direction={"row"}
                    justifyContent={"flex-start"}
                    gap={1}
                    ml={1}
                  >
                    <SystemMessage>
                      {unixTimeToUSDate(create_at)} {getUserLabelById(user_id)}{" "}
                      {systemMessagesByType[type]}
                    </SystemMessage>
                  </Stack>
                </IncomingHolder>
              );
            }

            return user_id === mattermostId ? (
              <OutgoingMessage
                key={id}
                create_at={create_at}
                message={message}
              />
            ) : (
              <IncomingMessage
                key={id}
                create_at={create_at}
                message={message}
                userLabel={getUserLabelById(user_id)}
                avatar={avatars[user_id]}
              />
            );
          })}
        <Divider ref={scrollRef} />
      </Chat>
      {isLoaded && (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          mt={2}
        >
          <Box flex={1} mr={1}>
            <Stack alignItems={"flex-start"}>
              <FormTextField
                id="message"
                name="message"
                control={control}
                InputProps={{
                  autoComplete: "off",
                }}
                placeholder="Type your message here..."
                multiline
                disabled={chatName === "Deleted Chat"}
              />
            </Stack>
          </Box>
          <IconButton
            color="primary"
            disabled={!isDirty}
            onClick={handleSubmit(onSumbit)}
            size="large"
            sx={{
              backgroundColor: (theme) =>
                customColors[theme.palette.mode].textBody,
              "& path": {
                fill: (theme) => customColors[theme.palette.mode].background,
              },
              "&.Mui-disabled": {
                backgroundColor: (theme) =>
                  customColors[theme.palette.mode].placeHolder,
              },
              "&:hover": {
                backgroundColor: (theme) =>
                  customColors[theme.palette.mode].actionColor,
              },
              "&:focus": {
                backgroundColor: (theme) =>
                  customColors[theme.palette.mode].actionColor,
              },
            }}
          >
            <SendIcon />
          </IconButton>
        </Stack>
      )}
    </Container>
  );
};
