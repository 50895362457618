import React from "react";
import { splitLine } from "utils";
import { TablePreview } from "./TablePreview";

const LINES_LIMIT_FOR_PREVIEW = 500;

export const CsvDataPreview = ({
  data = "",
  separator = ",",
  isNamesInFirstRow = true,
  stripQuotes = false,
}) => {
  const parsedLines = data.split(/\r?\n/);

  if (parsedLines.length === 0) {
    return null;
  }

  const headerLine = parsedLines[0];
  const headerFields = splitLine({
    data: headerLine,
    separator: separator,
  });

  const lines = isNamesInFirstRow
    ? parsedLines.slice(1, LINES_LIMIT_FOR_PREVIEW + 1)
    : parsedLines.slice(0, LINES_LIMIT_FOR_PREVIEW);
  const rows = lines.map((line) =>
    splitLine({
      data: line,
      separator: separator,
    })
  );

  return (
    <TablePreview
      headerRow={
        isNamesInFirstRow
          ? headerFields
          : headerFields.map((_, index) => `#${index + 1}`)
      }
      rows={rows}
      stripQuotes={stripQuotes}
      limit={LINES_LIMIT_FOR_PREVIEW}
    />
  );
};
