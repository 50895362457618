import { useCallback, useEffect } from "react";

export const useDefaultContractValues = ({ setValue, defaultContract, hasOwnContract }) => {  
  const setDefaultContractValues = useCallback(
    () => {
      if (!defaultContract) return;

      setValue("contractName", "", { shouldDirty: false });
      setValue("baseDeliveryFee", defaultContract?.baseDeliveryFee ?? 0, {
        shouldDirty: false,
      });
      setValue("basePricePerMile", defaultContract?.basePricePerMile ?? 0, {
        shouldDirty: false,
      });
      setValue(
        "wheelchairPricePerMile",
        defaultContract?.wheelchairPricePerMile ?? 0,
        { shouldDirty: false }
      );
      setValue("companyVehicleRate", defaultContract?.companyVehicleRate ?? 0, {
        shouldDirty: false,
      });
      setValue("privateVehicleRate", defaultContract?.privateVehicleRate ?? 0, {
        shouldDirty: false,
      });
      setValue(
        "wheelchairDeliveryFee",
        defaultContract?.wheelchairDeliveryFee ?? 0,
        { shouldDirty: false }
      );
      setValue(
        "freeWaitingTimeMin",
        defaultContract?.freeWaitingTimeMin ?? 0,
        { shouldDirty: false }
      );
      setValue(
        "waitingRatePerMin",
        defaultContract?.waitingRatePerMin ?? 0,
        { shouldDirty: false }
      );
      setValue(
        "recalculationMargin",
        defaultContract?.recalculationMargin ?? 0,
        { shouldDirty: false }
      );
    },
    [defaultContract, setValue]
  );

  useEffect(() => {
    if(hasOwnContract) return;

    setDefaultContractValues();
  }, [hasOwnContract, setDefaultContractValues]);

  return {
    setDefaultContractValues,
  };
};
