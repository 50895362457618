import React from "react";
import { useForm } from "react-hook-form";
import { Button, InputLabel, Stack } from "@mui/material";
import { DialogWindow } from "UI/DialogWindow";
import { FormTextField } from "UI/FormTextField";
import { required } from "models/validations";
import { useApiRequest } from "api/useApiRequest";
import { useFormDataSync } from "hooks/useFormDataSync";
import { addSpeciality } from "api/clientApi";

const defaultFormData = {
  type: "",
};

export const AddSpecialityDialog = ({
  open,
  handleClose,
  loadSpecialities,
}) => {
  const { callApiRequest } = useApiRequest();

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: defaultFormData,
  });

  const { handleConfirmedClose, handleForcedClose } = useFormDataSync({
    isDirty,
    handleClose,
    open,
    reset,
  });

  const onSubmit = async () => {
    const params = getValues();

    callApiRequest({
      apiRequest: addSpeciality,
      params,
      onSuccess: () => {
        loadSpecialities();
        handleForcedClose();
      },
    });
  };

  return (
    <DialogWindow
      open={open}
      withCloseButton
      handleClose={handleConfirmedClose}
      title={"Add Speciality"}
      content={
        <form>
          <Stack>
            <Stack mt={2} gap={3} width={"100%"}>
              <Stack gap={2}>
                <Stack alignItems={"flex-start"}>
                  <InputLabel htmlFor="type">Speciality</InputLabel>
                  <FormTextField
                    id="type"
                    name="type"
                    placeholde="Enter the name"
                    control={control}
                    rules={{ required }}
                    InputProps={{
                      autoComplete: "off",
                    }}
                  />
                </Stack>
                <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                  Add New Speciality
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      }
    />
  );
};
