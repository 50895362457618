import React, { useState } from "react";
import { Stack } from "@mui/material";
import * as SC from "SC";
import { useDataGrid } from "hooks/useDataGrid";
import { useAppSelector } from "store/hooks";
import { DataGrid } from "UI/DataGrid";
import { getDrivers, getDriversByWorkStatus } from "api/driverApi";
import { DriverDetails } from "components/DriverDetails";
import { DriverTableModeSelector } from "components/DriverTableModeSelector";
import { CloseWindowButton } from "UI/CloseWindowButton";
import {
  Content,
  accountStatusColumns,
  getShortAccountStatusColumns,
  workStatusColumns,
  getShortWorkStatusColumns,
} from "./DriversPage.data";
import { useNavigationData } from "hooks/useNavigationData";
import { CreateCheckDialog } from "components/CreateCheckDialog";
import { DriverChecksDialog } from "components/DriverChecksDialog";

/** @type {"WORK_STATUS" | "ACCOUNT_STATUS"} */
const defaultMode = "WORK_STATUS";

export const DriversPage = () => {
  const userId = useAppSelector((state) => state.user.id);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [mode, setMode] = useState(defaultMode);

  const shortWorkStatusColumns = getShortWorkStatusColumns(selectedUserId);
  const shortAccountStatusColumns =
    getShortAccountStatusColumns(selectedUserId);

  const workStatusdataGrid = useDataGrid({
    apiHandler: getDriversByWorkStatus,
    defaultSorter: {
      name: "FULL_NAME",
      order: "ASC",
    },
    defaultLimit: 25,
    gridConfigIndex: `${userId}-drivers-work-status`,
    reloadIntervalInSeconds: 60,
  });

  const accountStatusdataGrid = useDataGrid({
    apiHandler: getDrivers,
    defaultSorter: {
      name: "FULL_NAME",
      order: "ASC",
    },
    defaultLimit: 25,
    gridConfigIndex: `${userId}-drivers-account-status`,
    reloadIntervalInSeconds: 60,
  });

  const handleRowClick = (row) => {
    if (selectedUserId === row?.userId) {
      setSelectedUserId("");
      return;
    }

    setSelectedUserId(row?.userId ?? "");
  };

  const isWorkMode = mode === "WORK_STATUS";

  useNavigationData({ setMode, setSearch: accountStatusdataGrid.setSearch });

  return (
    <SC.Container>
      <Stack direction={"row"} height={"100%"} position={"relative"} gap={4}>
        <SC.Layout sx={{ flex: 1 }}>
          {/* <Stack direction={"row"} alignItems={"center"}>
            <SC.Title>Drivers</SC.Title>
          </Stack> */}
          <Content>
            {isWorkMode ? (
              <DataGrid
                columns={
                  selectedUserId ? shortWorkStatusColumns : workStatusColumns
                }
                handleRowClick={handleRowClick}
                {...workStatusdataGrid}
                selectedId={selectedUserId}
                actions={
                  <DriverTableModeSelector
                    mode={mode}
                    setMode={setMode}
                    accountStatusdataGrid={accountStatusdataGrid}
                    workStatusdataGrid={workStatusdataGrid}
                    setSelectedUserId={setSelectedUserId}
                  />
                }
                keyName="userId"
              />
            ) : (
              <DataGrid
                columns={
                  selectedUserId
                    ? shortAccountStatusColumns
                    : accountStatusColumns
                }
                handleRowClick={handleRowClick}
                {...accountStatusdataGrid}
                selectedId={selectedUserId}
                actions={
                  <DriverTableModeSelector
                    mode={mode}
                    setMode={setMode}
                    accountStatusdataGrid={accountStatusdataGrid}
                    workStatusdataGrid={workStatusdataGrid}
                    setSelectedUserId={setSelectedUserId}
                  />
                }
                keyName="userId"
              />
            )}
          </Content>
        </SC.Layout>
        {selectedUserId && (
          <>
            <CloseWindowButton
              top={-35}
              right={-35}
              closeHandler={() => setSelectedUserId("")}
            />
            <Stack width={"57%"}>
              <DriverDetails
                userId={selectedUserId}
                refreshList={
                  isWorkMode
                    ? workStatusdataGrid.loadData
                    : accountStatusdataGrid.loadData
                }
                closeDetails={() => setSelectedUserId("")}
                withMessageIcon
              />
            </Stack>
          </>
        )}
      </Stack>
      <CreateCheckDialog />
      <DriverChecksDialog />
    </SC.Container>
  );
};
