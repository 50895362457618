import React, { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";
import { InputAdornment, InputLabel, Stack, TextField } from "@mui/material";
import { Title } from "SC";
import { useClient } from "hooks/useClient";

export const ClientContractDetails = () => {
  const id = useAppSelector((state) => state.user.companyId);
  const [contract, setContract] = useState(null);

  const { client } = useClient({ id });

  useEffect(() => {
    if (client?.contract) {
      setContract(client.contract);
    }
  }, [client]);

  if (!contract) {
    return (
      <Stack ml={9} gap={2}>
        <Title>Contract Details</Title>
      </Stack>
    );
  }

  return (
    <Stack ml={9} gap={3}>
      <Title>Contract Details</Title>
      <Stack alignItems={"flex-start"}>
        <InputLabel htmlFor="basePricePerMile">
          {contract?.name && contract.name !== ""
            ? `Description: ${contract.name}`
            : ""}
        </InputLabel>
      </Stack>
      <Stack direction={"row"} justifyContent={"flex-start"} gap={6}>
        <Stack gap={1}>
          <Stack alignItems={"flex-start"}>
            <InputLabel htmlFor="basePricePerMile">Price per mile</InputLabel>
            <TextField
              id="basePricePerMile"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              disabled
              value={contract?.basePricePerMile}
            />
          </Stack>
          <Stack alignItems={"flex-start"}>
            <InputLabel htmlFor="baseDeliveryFee">Delivery Fee</InputLabel>
            <TextField
              id="baseDeliveryFee"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              disabled
              value={contract?.baseDeliveryFee}
            />
          </Stack>
          <Stack alignItems={"flex-start"}>
            <InputLabel htmlFor="wheelchairPricePerMile">
              Wheelchair Price per mile
            </InputLabel>
            <TextField
              id="wheelchairPricePerMile"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              disabled
              value={contract?.wheelchairPricePerMile}
            />
          </Stack>
          <Stack alignItems={"flex-start"}>
            <InputLabel htmlFor="wheelchairDeliveryFee">
              Wheelchair Delivery Fee
            </InputLabel>
            <TextField
              id="wheelchairDeliveryFee"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              disabled
              value={contract?.wheelchairDeliveryFee}
            />
          </Stack>
        </Stack>
        <Stack gap={1}>
          <Stack alignItems={"flex-start"}>
            <InputLabel htmlFor="privateVehicleRate">
              Private Vehicle Rate
            </InputLabel>
            <TextField
              id="privateVehicleRate"
              InputProps={{
                inputProps: {
                  style: {
                    textAlign: "right",
                  },
                },
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              disabled
              value={contract?.privateVehicleRate}
            />
          </Stack>
          <Stack alignItems={"flex-start"}>
            <InputLabel htmlFor="companyVehicleRate">
              Company Vehicle Rate
            </InputLabel>
            <TextField
              id="companyVehicleRate"
              InputProps={{
                inputProps: {
                  style: {
                    textAlign: "right",
                  },
                },
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              disabled
              value={contract?.companyVehicleRate}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
