import { Button, Stack, styled } from "@mui/material";
import { useAppDispatch } from "store/hooks";
import { openChecksByDriverId, openNewCheckByDriverId } from "store/reducers/backgroundChecksReducer";

export const SmallButton = styled(Button)`
  padding: 0 4px;
  min-width: 25px;
  min-height: unset;
  border-radius: 4px;
`;

export const BackgroundCheckDriver = ({ id }) => {
  const dispatch = useAppDispatch();

  const handleAddCheck = (e) => {
    e.stopPropagation();
    dispatch(openNewCheckByDriverId(id));
  };

  const handleViewCheck = (e) => {
    e.stopPropagation();
    dispatch(openChecksByDriverId(id));
  };

  return (
    <Stack
      direction={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={1}
    >
      <SmallButton variant="outlined" onClick={handleAddCheck}>
        +
      </SmallButton>
      <SmallButton variant="outlined" onClick={handleViewCheck}>
        View
      </SmallButton>
    </Stack>
  );
};
