import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Stack } from "@mui/material";

export const CloseWindowButton = ({ top = 0, right = 0, closeHandler }) => (
  <Stack
    alignItems={"flex-end"}
    sx={{
      margin: 0,
      position: "absolute",
      top: `${top}px`,
      right: `${right}px`,
    }}
  >
    <IconButton aria-label="close" onClick={closeHandler} size="small">
      <CloseIcon fontSize="large" />
    </IconButton>
  </Stack>
);
