import React, { useContext } from "react";
import { Stack, Tooltip, styled } from "@mui/material";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { customColors } from "models/customColors";
import { useAppSelector } from "store/hooks";
import { useIsDataChanged } from "hooks/useIsDataChanged";
import { GlobalContext } from "store/globalContext";

const Link = styled(RouterLink)`
  text-decoration: none;
`;

const MenuItem = styled(Stack)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  padding: 12px 18px;
  border-radius: 12px;

  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  line-height: 20px;
  word-wrap: break-word;

  & path {
    fill: ${({ theme }) => customColors[theme.palette.mode].iconColor};
  }

  &.active {
    background-color: ${({ theme }) =>
      customColors[theme.palette.mode].actionColor};
    color: ${({ theme }) => customColors[theme.palette.mode].white};
  }

  &:hover {
    background-color: ${({ theme }) =>
      customColors[theme.palette.mode].hoverSidebar};
    color: ${({ theme }) => customColors[theme.palette.mode].additionalAccent};
  }

  &:hover path {
    fill: ${({ theme }) => customColors[theme.palette.mode].additionalAccent};
  }

  &.active path {
    fill: ${({ theme }) => customColors[theme.palette.mode].white};
  }

  &.active:hover {
    background-color: ${({ theme }) =>
      customColors[theme.palette.mode].actionColor};
    color: ${({ theme }) => customColors[theme.palette.mode].white};

    cursor: default;
  }

  user-select: none;
`;

export const NavMenu = ({ menu }) => {
  const { openConfirmationWithCallback } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isMenuExpanded = useAppSelector(
    (state) => state?.layout?.isMenuExpanded
  );
  const { isDataChanged, setIsDataChanged } = useIsDataChanged();

  const handleMenuClick = (e, path) => {
    if (isDataChanged) {
      e.stopPropagation();
      e.preventDefault();

      openConfirmationWithCallback({
        title: "Are you sure?",
        message: "All entered data will be lost.",
        callbackFn: () => {
          setIsDataChanged(false);
          navigate(path);
        },
      });
    }
  };

  return (
    <Stack gap={"5px"}>
      {menu.map(({ icon, label, path }) => (
        <Link key={label} to={path} onClick={(e) => handleMenuClick(e, path)}>
          {isMenuExpanded ? (
            <MenuItem key={label} className={pathname === path ? "active" : ""}>
              {icon}
              {isMenuExpanded && label}
            </MenuItem>
          ) : (
            <Tooltip title={label} placement="right" disableInteractive>
              <MenuItem
                key={label}
                className={pathname === path ? "active" : ""}
              >
                {icon}
                {isMenuExpanded && label}
              </MenuItem>
            </Tooltip>
          )}
        </Link>
      ))}
    </Stack>
  );
};
