import React, { useLayoutEffect, useState } from "react";
import { Box, Stack, useTheme, styled, Button } from "@mui/material";
import * as SC from "SC";
import { useDataGrid } from "hooks/useDataGrid";
import { useAppSelector } from "store/hooks";
import { DataGrid } from "UI/DataGrid";
import { customColors } from "models/customColors";
import { convertToReadable, unixTimeToUSTime } from "utils";
import { getActiveRides } from "api/rideApi";
import { RideDetails } from "components/RideDetails";
import { useClientList } from "hooks/useClientList";
import { TextFieldWithEllipsis } from "components/TextFiledWithEllipsis";
import { CloseWindowButton } from "UI/CloseWindowButton";
import { Content, Status } from "pages/BookingsPage.style";
import { ActiveRidesMap } from "UI/ActiveRidesMap";
import { RideInfoField } from "components/RideInfoField";

const namesSx = {
  fontFamily: "Inter",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "18px",
  letterSpacing: "0px",
  textAlign: "left",
};

const TableLabel = styled(Box)`
  margin-bottom: 1rem;
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
`;

export const DashboardPage = () => {
  const theme = useTheme();
  const { getClientLabelById } = useClientList();

  const userId = useAppSelector((state) => state.user.id);

  const [selectedId, setSelectedId] = useState("");
  const [reloadSignal, setReloadSignal] = useState(false);
  const sendRedrawMapSignal = () =>
    setReloadSignal((prevSignal) => !prevSignal);

  const columns = [
    {
      label: "Client",
      field: "companyName",
      xs: 8,
      sm: 8,
      md: 8,
      sortableName: "COMPANY_NAME",
      renderer: (row) => <RideInfoField {...row} />,
    },
    {
      label: "Driver",
      field: "driverName",
      xs: 4,
      sm: 4,
      md: 4,
      sortableName: "DRIVER_NAME",
      renderer: (row) => (
        <Stack gap={0.75}>
          <TextFieldWithEllipsis sx={namesSx}>
            {row?.driverName ?? "Unassigned"}
          </TextFieldWithEllipsis>
          <Status
            // @ts-ignore
            row={row}
            status={row?.status}
          >
            {convertToReadable(row?.status ?? "")}
          </Status>
        </Stack>
      ),
    },
  ];

  const dataGrid = useDataGrid({
    apiHandler: getActiveRides,
    defaultSorter: {
      name: "COMPANY_NAME",
      order: "ASC",
    },
    defaultLimit: 25,
    gridConfigIndex: `${userId}-active-bookings`,
    reloadIntervalInSeconds: 10,
  });

  const handleRowClick = (row) => {
    if (selectedId === row?.id) {
      setSelectedId("");
      return;
    }

    setSelectedId(row?.id ?? "");
  };

  const markers =
    // @ts-ignore
    dataGrid?.rows
      ?.filter(
        ({ lastKnownPositionLat, lastKnownPositionLon }) =>
          lastKnownPositionLat && lastKnownPositionLon
      )
      ?.map(
        ({
          id,
          passengerName,
          driverName,
          lastKnownPositionLat,
          lastKnownPositionLon,
          lastKnownPositionMs,
          driverWorkStatus,
        }) => ({
          id,
          title: passengerName,
          subtitle:
            !!driverName &&
            `driver: ${driverName} ${
              driverWorkStatus === "Off_Shift" && lastKnownPositionMs > 0
                ? unixTimeToUSTime(lastKnownPositionMs)
                : ""
            }`,
          driverWorkStatus,
          coordinates: {
            lat: lastKnownPositionLat,
            lon: lastKnownPositionLon,
          },
        })
      ) ?? [];

  useLayoutEffect(() => {
    // @ts-ignore
    if (dataGrid?.rows?.length) {
      sendRedrawMapSignal();
    }
    // @ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGrid?.rows?.length]);

  return (
    <SC.Container>
      <Stack direction={"row"} height={"100%"} position={"relative"}>
        <Content>
          <SC.HalfScreenPanel>
            <TableLabel>Real-Time Rides</TableLabel>
            <DataGrid
              columns={columns}
              handleRowClick={handleRowClick}
              {...dataGrid}
              actionsAfter={
                <Button variant="outlined" color="primary" disabled>
                  All Regions
                </Button>
              }
              keyName="id"
              selectedId={selectedId}
            />
          </SC.HalfScreenPanel>
          {selectedId ? (
            <SC.HalfScreenPanel>
              <CloseWindowButton
                top={-35}
                right={-35}
                closeHandler={() => setSelectedId("")}
              />
              <RideDetails
                id={selectedId}
                getClientLabelById={getClientLabelById}
                refreshList={dataGrid.refreshData}
              />
            </SC.HalfScreenPanel>
          ) : (
            <SC.HalfScreenPanel
              sx={{
                borderRadius: "20px",
                overflow: "hidden",
                backgroundColor: customColors[theme.palette.mode].strokeColor,
              }}
            >
              <ActiveRidesMap
                setSelectedId={setSelectedId}
                markers={markers}
                reloadSignal={reloadSignal}
              />
            </SC.HalfScreenPanel>
          )}
        </Content>
      </Stack>
    </SC.Container>
  );
};
