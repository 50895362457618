import React from "react";
import { Box, Stack, styled } from "@mui/material";
import { customColors } from "models/customColors";

export const Content = styled(Stack)`
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
`;

export const ActiveSelected = styled(Box)`
  width: 16px;
  height: 16px;
  border: 3px solid
    ${({ theme }) => customColors[theme.palette.mode].background};
  border-radius: 16px;
  background-color: ${({
    theme,
    // @ts-ignore
    status,
  }) =>
    status
      ? customColors[theme.palette.mode][StatusColorNames[status] ?? "greyText"]
      : customColors[theme.palette.mode].success};
`;

export const InactiveSelected = styled(ActiveSelected)`
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].strokeColor};
`;

export const Selector = ({ selectedId, row, status }) => (
  <Stack justifyContent={"center"} alignItems={"center"} width={"100%"}>
    {selectedId === row?.id ? (
      // @ts-ignore
      <ActiveSelected status={status} />
    ) : (
      <InactiveSelected />
    )}
  </Stack>
);

export const StatusColorNames = {
  NEW: "new",
  UNMATCHED: "unmatched",
  CONFIRMATION: "confirmation",
  SCHEDULED: "scheduled",
  EN_ROUTE: "enRoute",
  ARRIVED: "arrived",
  ON_BOARD: "onboard",
  COMPLETED: "completed",
  CANCELLED: "canceled",
};

export const Status = styled(Stack)`
  justify-content: center;
  align-items: center;

  padding: 5px;
  border-radius: 8px;

  color: ${({
    // @ts-ignore
    status,
    theme,
  }) =>
    customColors[theme.palette.mode][StatusColorNames[status] ?? "greyText"]};
  background-color: ${({
    // @ts-ignore
    status,
    theme,
  }) =>
    `${
      customColors[theme.palette.mode][StatusColorNames[status] ?? "greyText"]
    }29`};
`;
