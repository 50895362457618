import React from "react";
import { useAppSelector } from "store/hooks";
import { ErrorMessage } from "@hookform/error-message";
import { Stack, OutlinedInput, Box } from "@mui/material";
import { usePasswordRecoveryPage } from "./PasswordRecoveryPage.hook";
import * as UI from "UI";
import * as SC from "SC";
import { CheckEmailWarning } from "components/CheckEmailWarning";

export const PasswordRecoveryPage = () => {
  const spinner = useAppSelector((state) => state.spinner);
  const { errors, onSubmit, usernameRegisterProps, isEmailSent } =
    usePasswordRecoveryPage();

  if (isEmailSent) {
    return (
      <CheckEmailWarning
        title={"Password reset"}
        description={
          "Check your e-mail for instructions on how to change your password."
        }
      />
    );
  }

  return (
    <SC.AuthFormContainer>
      <Stack gap={2}>
        <Stack justifyContent={"center"} gap={1}>
          <SC.AuthTitle sx={SC.AuthTitleSx}>Reset Password</SC.AuthTitle>
          <SC.PlainText sx={{ maxWidth: "381px", textAlign: "center" }}>
            To reset your password, please enter the email address associated
            with your Kerico account
          </SC.PlainText>
        </Stack>
        <Stack gap={4}>
          <Stack alignItems={"flex-start"}>
            <SC.InputLabel htmlFor="email">Your e-mail</SC.InputLabel>
            <OutlinedInput
              id="email"
              name="email"
              type="text"
              error={!!errors?.email}
              autoComplete="off"
              {...usernameRegisterProps}
              sx={{ alignSelf: "stretch" }}
            />
            <ErrorMessage
              errors={errors}
              name="email"
              render={({ message }) => <UI.ErrorString text={message} />}
            />
          </Stack>
          <SC.ControlButton
            type="submit"
            variant="contained"
            disabled={!!spinner}
            onClick={onSubmit}
          >
            Send link
          </SC.ControlButton>
        </Stack>
      </Stack>
      <Box sx={{ height: "10vh" }}></Box>
    </SC.AuthFormContainer>
  );
};
