import { getRideDetails } from "api/rideApi";
import { useApiRequest } from "api/useApiRequest";
import { useCallback } from "react";
import { useEffect, useState } from "react";

export const useRideDetails = ({ id, skipAutoloading = false }) => {
  const { callApiRequest } = useApiRequest();
  const [ride, setRide] = useState(null);

  const loadRideDetails = useCallback(
    async () =>
      callApiRequest({
        apiRequest: getRideDetails,
        params: id,
        onSuccess: (result) => {
          setRide(result);
        },
        onError: () => {
          // @ts-ignore
          setRide(null);
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, skipAutoloading]
  );

  useEffect(() => {
    if (id && !skipAutoloading) {
      loadRideDetails();
    }
  }, [id, loadRideDetails, skipAutoloading]);

  return {
    ride,
    setRide,
    loadRideDetails,
  };
};
