import React from "react";
import { Box, Stack, styled } from "@mui/material";
import { customColors } from "models/customColors";

export const StepIndicator = styled(Box)`
  width: 56px;
  height: 5px;
  border-radius: 15px;
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].strokeColor};
`;

export const StepIndicatorActive = styled(StepIndicator)`
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].actionColor};
`;

export const ProgressBar = ({ step, steps }) => {
  const values = [...Array(steps).fill().keys()];

  return (
    <Stack
      direction={"row"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      gap={1}
    >
      {values.map((key) =>
        Number(key) <= step ? (
          <StepIndicatorActive key={`creation-step-${key}`} />
        ) : (
          <StepIndicator key={`creation-step-${key}`} />
        )
      )}
    </Stack>
  );
};
