import { localStorageNameEnum } from "../models/constants";
import { statusMessages } from "./httpService";

/**
 * Returns only two variants of the result:
 *  1) In case of successful result:  blob
 *  2) When something goes wrong: { error: <error message> }
 * @param {string} id
 * @returns {Promise<{ blob: Blob; contentType: string; } | { error: string; }>}
 */
export const httpLoadAvatarService = async (id) => {
  const storedToken = window.sessionStorage.getItem(
    localStorageNameEnum.AUTH_TOKEN
  );

  const headers = {};
  headers.Authorization = `Bearer ${storedToken}`;

  try {
    const response = await fetch(
      `${process.env.REACT_APP_MATTERMOST_API_URL}/users/${id}/image`,
      {
        // @ts-ignore
        headers,
        method: "GET",
      }
    );

    if (response?.status !== 200) {
      throw new Error(statusMessages[response?.status]);
    }

    const contentType = response?.headers?.get("Content-Type");
    const blob = await response.blob();

    return {
      blob,
      contentType,
    };
  } catch (error) {
    console.error(`httpDownloadService`, error);
    return {
      error: String(error.message).replace(/Error:/g, ""),
      // error: 'Network error'
    };
  }
};
