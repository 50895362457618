import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { ErrorDialog } from "components/ErrorDialog";
import { useAppSelector } from "store/hooks";
import { ModalSpinner } from "components/ModalSpinner";
import { ReactComponent as LogoType } from "assets/img/logo.svg";
import { ReactComponent as BackIcon } from "assets/img/backIcon.svg";
import * as SC from "./AuthCommonLayout.style";

export const AuthCommonLayout = () => {
  const navigate = useNavigate();
  const spinner = useAppSelector((state) => state.spinner);
  const { pathname } = useLocation();
  const isTermsPage = pathname.startsWith("/terms");
  const isGoBackVisible = pathname.startsWith("/forgot") || isTermsPage;

  const isDev = process.env.REACT_APP_BRANCH === "dev";

  return (
    <SC.AuthContainer
      className="AuthContainer"
      justifyContent={"center"}
      alignItems={"stretch"}
    >
      <SC.OutletContainer className="OutletContainer">
        {isDev && (
          <Box
            sx={{
              fontSize: "0.8rem",
              color: "red",
              position: "fixed",
              left: "5px",
              top: "5px",
            }}
          >
            dev environment
          </Box>
        )}
        <SC.LogoHolder>
          {isGoBackVisible ? (
            <SC.BackButton
              onClick={() => (isTermsPage ? navigate(-1) : navigate("/"))}
            >
              <SC.BackButtonContent sx={SC.BackButtonContentSx}>
                <BackIcon />
              </SC.BackButtonContent>
            </SC.BackButton>
          ) : (
            <LogoType />
          )}
        </SC.LogoHolder>
        <Outlet />
        <ErrorDialog />
      </SC.OutletContainer>
      <ModalSpinner open={spinner} />
    </SC.AuthContainer>
  );
};
