import React from "react";
import { RadioSwitcher } from "UI/RadioSwitcher";

export const DriverTableModeSelector = ({
  mode,
  setMode,
  workStatusdataGrid,
  accountStatusdataGrid,
  setSelectedUserId,
}) => {
  const handleClickUnactiveItem = (e, value) => {
    setMode(value);
    setSelectedUserId("");
    if (value === "WORK_STATUS") {
      workStatusdataGrid.loadData();
    } else {
      accountStatusdataGrid.loadData();
    }
  };

  return (
    <RadioSwitcher
      handleClickUnactiveItem={handleClickUnactiveItem}
      value={mode}
      options={[
        {
          value: "WORK_STATUS",
          label: "Work Status",
        },
        {
          value: "ACCOUNT_STATUS",
          label: "Approval Status",
        },
      ]}
    />
  );
};
