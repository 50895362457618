import React from "react";
import { Stack, styled } from "@mui/material";
import * as SC from "SC";
import { useParams } from "react-router-dom";
import { RideDetails } from "components/RideDetails";
import { useClientList } from "hooks/useClientList";
import { Messenger } from "components/Messenger";
import { useRideDetails } from "hooks/useRideDetails";
import { customColors } from "models/customColors";

const Container = styled(SC.Container)`
  padding: 20px;
`;

export const Content = styled(Stack)`
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-between;

  padding: 20px;

  overflow-y: hidden;
  overflow-x: hidden;

  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].additionalStroke};
  border-radius: 16px;
`;

const MessengerHolder = styled(Stack)`
  justify-content: space-between;
  padding: 20px 40px;
  width: calc(50% - 90px);
  overflow: hidden;

  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].background};
  border-radius: 16px;
`;

const HalfScreenPanel = styled(Stack)`
  flex: 0 0 auto;
  width: calc(50% - 40px);
  padding: 20px 5px 20px 25px;

  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].background};
  border-radius: 16px;
`;

export const RideChatPage = () => {
  const { id } = useParams();
  const { getClientLabelById } = useClientList();

  const { ride } = useRideDetails({ id });

  return (
    <Container>
      <Stack direction={"row"} height={"100%"}>
        <Content>
          <MessengerHolder>
            {!!ride?.channelId && <Messenger chatId={ride.channelId} withHeader />}
          </MessengerHolder>
          {id && (
            <HalfScreenPanel>
              <RideDetails
                id={id}
                getClientLabelById={getClientLabelById}
                refreshList={() => null}
              />
            </HalfScreenPanel>
          )}
        </Content>
      </Stack>
    </Container>
  );
};
