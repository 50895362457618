import React from "react";
import { Box, styled } from "@mui/material";

const Container = styled(Box)`
  display: inline-block;
  padding-right: 10px;
  max-width: calc(100% - 10px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const TextFieldWithEllipsis = ({
  sx = {},
  children,
  maxLength = 80,
}) => <Container sx={sx}>{cutText(children, maxLength)}</Container>;

/**
 * Cuts the text if its length is more than the given length and adds "..." at the end.
 *
 * @param {string} text - The text to be cut.
 * @param {number} length - The maximum length of the text.
 * @returns {string} The cut text with "..." added at the end if it was cut.
 */
function cutText(text, length) {
  if (text?.length && text?.length > length) {
    return text.substring(0, length) + "...";
  } else {
    return text;
  }
}
