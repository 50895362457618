import React, { useEffect, useState } from "react";
import { Stack, styled } from "@mui/material";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useApiRequest } from "api/useApiRequest";
import { getPollingDataByRole } from "api/userApi";
import { customColors } from "models/customColors";
import { useNavigate } from "react-router-dom";
import { setNavigationData } from "store/reducers/navigationDataReducer";
import { INDICATORS_UPDATE_PERIOD } from "models/constants";

/** @type {import("types/commonTypes").IndicatorsData | null} */
const defaultData = {
  driversForApproval: 0,
  unmatchedRides: [],
  vehiclesForApproval: 0,
};

const IndicatorHolder = styled(Stack)`
  flex: 0 1 auto;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;

  margin: 0;
  padding: 8px 8px;

  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;

  border-radius: 12px;
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].tableBackground};

  cursor: pointer;
  user-select: none;
`;

export const Indicator = styled(Stack)`
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;

  border-radius: 50%;
  color: ${({ theme }) => customColors[theme.palette.mode].white};
  background-color: ${({ theme }) => customColors[theme.palette.mode].new};
`;

export const Indicators = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { callApiRequest } = useApiRequest();
  const role = useAppSelector((state) => state?.user?.role);

  const [data, setData] = useState(defaultData);

  const loadData = async () =>
    callApiRequest({
      apiRequest: getPollingDataByRole,
      params: role,
      onSuccess: (result) => setData(result),
      skipErrorHandling: true,
    });

  useEffect(() => {
    loadData();

    const interval = setInterval(() => {
      loadData();
    }, INDICATORS_UPDATE_PERIOD);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack direction={"row"} gap={1} ml={2} mr={2} width={"40%"}>
      {!!data?.driversForApproval && (
        <IndicatorHolder
          onClick={() => {
            dispatch(
              setNavigationData({ mode: "ACCOUNT_STATUS", search: "Approval" })
            );
            navigate("/drivers");
          }}
        >
          New Drivers: <Indicator>{data?.driversForApproval}</Indicator>
        </IndicatorHolder>
      )}
      {!!data?.vehiclesForApproval && (
        <IndicatorHolder
          onClick={() => {
            dispatch(
              setNavigationData({
                mode: "INACTIVE_CARS",
                search: "Awaiting",
              })
            );
            navigate("/fleet_management");
          }}
        >
          New Vehicles: <Indicator>{data?.vehiclesForApproval}</Indicator>
        </IndicatorHolder>
      )}
      {!!data?.unmatchedRides?.length && (
        <IndicatorHolder
          onClick={() => {
            dispatch(
              setNavigationData({
                search: "Unmatched",
              })
            );
            navigate("/booking");
          }}
        >
          Unmatched Rides: <Indicator>{data?.unmatchedRides?.length}</Indicator>
        </IndicatorHolder>
      )}
    </Stack>
  );
};
