import React from "react";
import CheckEmailImage from "assets/img/email_sent.png";
import { Box, Stack } from "@mui/material";

export const CheckEmailWarning = ({ title, description }) => {
  return (
    <Stack
      direction={"column"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      sx={{ width: "100%", height: "80%" }}
      gap={3}
    >
      <img src={CheckEmailImage} width="100px" alt="check email icon" />
      <h2>{title}</h2>
      <Box sx={{ textAlign: "center", width: "80%", maxWidth: "475px" }}>
        {description}
      </Box>
    </Stack>
  );
};
