import { useEffect } from "react";
import { Box, Stack, styled } from "@mui/material";
import * as CSC from "SC";
import { DialogWindow } from "UI/DialogWindow";
import { FormTextField } from "UI/FormTextField";
import { required } from "models/validations";
import { requestItemStatuses } from "models/bookingRequest";

const Info = styled(Box)`
  font-family: Inter;
  font-size: 21px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
`;

const Title = styled(Box)`
  font-family: Inter;
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
`;

export const RideRequestRejectDialog = ({
  open,
  control,
  handleClose,
  onSubmit,
  isDirty,
  setValue,
  watch,
  reset,
}) => {
  const rejectReason = watch("rejectReason");

  useEffect(() => {
    if (rejectReason) {
      setValue("status", requestItemStatuses.UNACCEPTED);
      return;
    }
    reset();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejectReason]);

  return (
    <DialogWindow
      open={open}
      withCloseButton
      handleClose={handleClose}
      title={<Title>Booking Request Rejection</Title>}
      content={
        <form>
          <Stack maxWidth={"512px"} gap={2}>
            <Info>
              Please provide specific feedback or reasons for rejecting this
              booking request
            </Info>
            <Stack mt={2} gap={3} justifyContent={"center"} width={"100%"}>
              <FormTextField
                id="rejectReason"
                name="rejectReason"
                placeholder="Reason for Rejection"
                control={control}
                rules={{ required }}
                InputProps={{
                  autoComplete: "off",
                }}
              />
            </Stack>
          </Stack>
        </form>
      }
      actions={
        <Stack gap={2}>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            gap={1}
            mt={2}
          >
            <CSC.DialogMainButton
              variant="outlined"
              size="large"
              onClick={handleClose}
            >
              Cancel
            </CSC.DialogMainButton>
            <CSC.DialogMainButton
              variant="contained"
              size="large"
              onClick={onSubmit}
              disabled={!isDirty}
              color="error"
            >
              Reject
            </CSC.DialogMainButton>
          </Stack>
        </Stack>
      }
    />
  );
};
