import React, { useContext, useEffect, useRef } from "react";
import { Button, InputLabel, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { DialogWindow } from "UI/DialogWindow";
import { FormTextField } from "UI/FormTextField";
import { FormSelectField } from "UI/FormSelectField";
import {
  getEmailConfirmValidation,
  emailValidation,
  nameValidation,
  required,
  phoneNumberRequiredValidation,
} from "models/validations";
import { FormMaskedTextField } from "UI";
import { useApiRequest } from "api/useApiRequest";
import { addKericoUser } from "api/userApi";
import { useFormDataSync } from "hooks/useFormDataSync";
import { GlobalContext } from "store/globalContext";
import { ReactComponent as UserIcon } from "assets/img/user-small-icon.svg";

import { ChipSetsHolder, EditableChip } from "UI/EditableChip";
import CountrySelect from "./CountySelector";

/** @type {import("types/commonTypes").NewUserDto & { emailRepeat: string; }}*/
const defaultFormData = {
  firstName: "",
  lastName: "",
  email: "",
  emailRepeat: "",
  phone: "",
  counties: [],
  role: "",
};

export const AddKericoUserDialog = ({
  open,
  handleClose,
  refreshList,
  countiesOptions,
  selectedCounties,
  setSelectedCounties,
}) => {
  const { showWarning } = useContext(GlobalContext);
  const { callApiRequest } = useApiRequest();

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: defaultFormData,
  });

  const { handleConfirmedClose, handleForcedClose } = useFormDataSync({
    isDirty,
    handleClose,
    open,
    reset,
  });

  const email = useRef("");
  email.current = watch("email", "");
  const role = watch("role");

  const onSubmit = async () => {
    const user = getValues();
    const params = Object.entries(user)
      .filter(([name]) => name !== "emailRepeat")
      .reduce((acc, [name, value]) => ({ ...acc, [name]: value }), {});

    callApiRequest({
      apiRequest: addKericoUser,
      params,
      onSuccess: () => {
        refreshList();
        handleForcedClose();
        showWarning({
          title: "Kerico User Added",
          content: "Kerico User has been successfully added",
          icon: <UserIcon />,
        });
      },
    });
  };

  const handleDelete = (id) => {
    const counties = getValues("counties");
    const newValue = counties.filter((value) => value !== id);
    setSelectedCounties(newValue);
    setValue("counties", newValue, { shouldDirty: true });
  };

  useEffect(() => {
    if (role === "SUPER_ADMIN") {
      setSelectedCounties([]);
      setValue("counties", [], { shouldDirty: false });
    }
  }, [role, setSelectedCounties, setValue]);

  useEffect(() => {
    if (!open) {
      setSelectedCounties([]);
      reset(defaultFormData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <DialogWindow
      open={open}
      withCloseButton
      handleClose={handleConfirmedClose}
      title={"Add Kerico User"}
      content={
        <form>
          <Stack mt={2} gap={3} direction={"row"} justifyContent={"center"}>
            <Stack gap={2}>
              <Stack alignItems={"flex-start"}>
                <InputLabel htmlFor="firstName">First Name</InputLabel>
                <FormTextField
                  id="firstName"
                  name="firstName"
                  control={control}
                  rules={nameValidation}
                  InputProps={{
                    autoComplete: "off",
                  }}
                />
              </Stack>
              <Stack alignItems={"flex-start"}>
                <InputLabel htmlFor="lastName">Last Name</InputLabel>
                <FormTextField
                  id="lastName"
                  name="lastName"
                  control={control}
                  rules={nameValidation}
                  InputProps={{
                    autoComplete: "off",
                  }}
                />
              </Stack>
              <Stack alignItems={"flex-start"}>
                <InputLabel htmlFor="phone">Phone Number</InputLabel>
                <FormMaskedTextField
                  id="phone"
                  name="phone"
                  control={control}
                  rules={phoneNumberRequiredValidation}
                  mask="999-999-9999"
                  InputProps={{
                    autoComplete: "off",
                  }}
                />
              </Stack>
              <Stack alignItems={"flex-start"}>
                <InputLabel htmlFor="email">Email</InputLabel>
                <FormTextField
                  id="email"
                  name="email"
                  control={control}
                  rules={emailValidation}
                  InputProps={{
                    autoComplete: "email",
                  }}
                />
              </Stack>
              <Stack alignItems={"flex-start"}>
                <InputLabel htmlFor="emailRepeat">Confirm email</InputLabel>
                <FormTextField
                  id="emailRepeat"
                  name="emailRepeat"
                  control={control}
                  rules={getEmailConfirmValidation(email.current)}
                  InputProps={{
                    autoComplete: "newpassword",
                  }}
                />
              </Stack>
            </Stack>
            <Stack gap={2}>
              <Stack alignItems={"flex-start"} width={"100%"}>
                <InputLabel htmlFor="role">Role</InputLabel>
                <FormSelectField
                  id="role"
                  name="role"
                  control={control}
                  options={{
                    SUPER_ADMIN: "Super Admin",
                    KERICO_MANAGER: "Manager",
                    KERICO_DISPATCHER: "Dispatcher",
                  }}
                  withNone={false}
                  rules={{ required }}
                />
              </Stack>
              {(role === "KERICO_MANAGER" || role === "KERICO_DISPATCHER") && (
                <Stack gap={1}>
                  <Stack alignItems={"flex-start"}>
                    <InputLabel htmlFor="counties">Counties</InputLabel>
                    <ChipSetsHolder id="counties">
                      {selectedCounties.map((countyId) => {
                        const label = countiesOptions.find(
                          ({ value }) => value === countyId
                        )?.label;

                        return (
                          <EditableChip
                            key={countyId}
                            value={label}
                            onDelete={() => handleDelete(countyId)}
                          />
                        );
                      })}
                    </ChipSetsHolder>
                  </Stack>
                  <CountrySelect
                    counties={countiesOptions}
                    getValues={getValues}
                    setValue={setValue}
                    setSelectedCounties={setSelectedCounties}
                  />
                </Stack>
              )}
              <Stack
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                width={"100%"}
                gap={1}
                mt={2}
              >
                <Button variant="outlined" onClick={handleConfirmedClose}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                  Add Kerico User
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      }
    />
  );
};
