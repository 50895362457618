import React, { useContext, useRef } from "react";
import { GlobalContext } from "store/globalContext";
import { Button, InputLabel, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { DialogWindow } from "UI/DialogWindow";
import { FormTextField } from "UI/FormTextField";
import { FormSelectField } from "UI/FormSelectField";
import {
  emailValidation,
  nameValidation,
  required,
  phoneNumberRequiredValidation,
} from "models/validations";
import { FormMaskedTextField } from "UI";
import { useApiRequest } from "api/useApiRequest";
import { updateClientUser } from "api/userApi";
import { useAppDispatch } from "store/hooks";
import { resetIsDataChanged } from "store/reducers/isDataChangedReducer";
import { useFormDataSync } from "hooks/useFormDataSync";
import { ReactComponent as UserIcon } from "assets/img/user-small-icon.svg";

export const EditClientUserDialog = ({
  currentUser,
  handleClose,
  refreshList,
}) => {
  const dispatch = useAppDispatch();
  const { showWarning } = useContext(GlobalContext);

  const { callApiRequest } = useApiRequest();

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    values: currentUser,
  });

  const { handleConfirmedClose } = useFormDataSync({
    isDirty,
    handleClose,
    open: !!currentUser,
    reset,
  });

  const email = useRef("");
  email.current = watch("email", "");

  const onSubmit = async () => {
    const {
      id,
      firstName,
      lastName,
      email,
      phone,
      role,
      mfaEnabled,
    } = getValues();

    callApiRequest({
      apiRequest: updateClientUser,
      params: {
        id,
        data: {
          firstName,
          lastName,
          email,
          phone,
          role,
          isMfaEnabled: mfaEnabled,
        },
      },
      onSuccess: () => {
        dispatch(resetIsDataChanged());
        refreshList();
        handleClose();
        showWarning({
          title: "User Updated",
          content: "User has been successfully updated",
          icon: <UserIcon />,
        });
      },
    });
  };

  return (
    <DialogWindow
      open={!!currentUser?.id}
      withCloseButton
      handleClose={handleConfirmedClose}
      title={"Edit Client User"}
      content={
        <form>
          <Stack mt={2} gap={3} direction={"row"} justifyContent={"center"}>
            <Stack gap={2}>
              <Stack alignItems={"flex-start"}>
                <InputLabel htmlFor="firstName">First Name</InputLabel>
                <FormTextField
                  id="firstName"
                  name="firstName"
                  control={control}
                  rules={nameValidation}
                  InputProps={{
                    autoComplete: "off",
                  }}
                />
              </Stack>
              <Stack alignItems={"flex-start"}>
                <InputLabel htmlFor="lastName">Last Name</InputLabel>
                <FormTextField
                  id="lastName"
                  name="lastName"
                  control={control}
                  rules={nameValidation}
                  InputProps={{
                    autoComplete: "off",
                  }}
                />
              </Stack>
              <Stack alignItems={"flex-start"}>
                <InputLabel htmlFor="email">Email</InputLabel>
                <FormTextField
                  id="email"
                  name="email"
                  control={control}
                  rules={emailValidation}
                  InputProps={{
                    autoComplete: "email",
                  }}
                />
              </Stack>
            </Stack>
            <Stack gap={2}>
              <Stack alignItems={"flex-start"} width={"100%"}>
                <InputLabel htmlFor="role">Role</InputLabel>
                <FormSelectField
                  id="role"
                  name="role"
                  control={control}
                  options={{
                    CUSTOMER_ADMIN: "Admin",
                    CUSTOMER_MANAGER: "Manager",
                  }}
                  withNone={false}
                  rules={{ required }}
                />
              </Stack>
              <Stack alignItems={"flex-start"}>
                <InputLabel htmlFor="phone">Phone Number</InputLabel>
                <FormMaskedTextField
                  id="phone"
                  name="phone"
                  control={control}
                  rules={phoneNumberRequiredValidation}
                  mask="999-999-9999"
                  InputProps={{
                    autoComplete: "off",
                  }}
                />
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                width={"100%"}
                gap={1}
              >
                <Button variant="outlined" onClick={handleConfirmedClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                  disabled={!isDirty}
                >
                  Save Changes
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      }
    />
  );
};
