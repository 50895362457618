import { colors } from "@mui/material";

export const customColors = {
  light: {
    background: "#ffffff",
    color: "#000000",
    white: "#ffffff",
    borderColor: "rgba(0,0,0,0.1)",
    actionColor: "#1173F5",
    additionalAccent: "#0D2E5A",
    textBody: "#141517",
    additionalStroke: "#DBE7F6",
    additionalButton: "#F4F8FD",
    inputBack: "#EFF3F6",
    greyText: "#707680",
    iconColor: "#868A95",
    placeHolder: "#ACACAC",
    strokeColor: "#D3D3D3",
    tableBackground: "#F7F9F9",
    hoverSidebar: "#F4F5F5",
    success: "#3FCD5E",
    arrived: "#A131C8",
    error: "#ED4343",
    new: "#FF472E",
    unmatched: "#FF078E",
    confirmation: "#00C7D3",
    scheduled: "#029802",
    newBackground: "#FFF0F0",
    assigned: "#A131C8",
    delayed: "#FC9802",
    enRoute: "#1173F5",
    onboard: "#3FCD5E",
    completed: "#868A95",
    canceled: "#E68A95",
    selectedRecord: "#1173F5",
    text: {
      plain: "#4C4D50",
      inactive: colors.grey[500],
      highlighted: "#007AFF",
    },
    menu: {
      selected: {
        text: "#008CDB",
        background: "#008CDB1A",
      },
      hover: {
        background: "rgba(240, 244, 255, 1)",
      },
      notSelected: {
        text: "#1C1C1C",
        background: "rgba(93, 96, 221, 0)",
      },
      active: "#008CDB",
      activeBackground: "#008CDB1A",
    },
  },
  dark: {
    background: "#242526",
    color: "#FFFFFF",
    white: "#ffffff",
    borderColor: "rgba(255,255,255,0.1)",
    actionColor: "#1173F5",
    additionalAccent: "#5AC8FA",
    textBody: "#FFFFFF",
    additionalStroke: "#323B40",
    additionalButton: "#262B2F",
    inputBack: "#2F3439",
    greyText: "#707680",
    iconColor: "#868A95",
    placeHolder: "#ACACAC",
    strokeColor: "#353B41",
    tableBackground: "#181616",
    hoverSidebar: "#303034",
    success: "#3FCD5E",
    arrived: "#A131C8",
    error: "#ED4343",
    new: "#FF472E",
    unmatched: "#F6DE16",
    confirmation: "#00C7D3",
    scheduled: "#FC9802",
    newBackground: "#FFF0F0",
    assigned: "#A131C8",
    delayed: "#FC9802",
    enRoute: "#1173F5",
    onboard: "#3FCD5E",
    completed: "#868A95",
    canceled: "#E68A95",
    selectedRecord: "#1173F5",
    text: {
      plain: "#4C4D50",
      inactive: colors.grey[500],
      highlighted: "#007AFF",
    },
    menu: {
      selected: {
        text: "#008CDB",
        background: "#008CDB1A",
      },
      hover: {
        background: "rgba(240, 244, 255, 1)",
      },
      notSelected: {
        text: "#1C1C1C",
        background: "rgba(93, 96, 221, 0)",
      },
      active: "#008CDB",
      activeBackground: "#008CDB1A",
    },
  },
};
