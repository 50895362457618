import React, { useContext, useEffect, useRef } from "react";
import { GlobalContext } from "store/globalContext";
import { Button, InputLabel, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { DialogWindow } from "UI/DialogWindow";
import { FormTextField } from "UI/FormTextField";
import { FormSelectField } from "UI/FormSelectField";
import {
  emailValidation,
  nameValidation,
  required,
  phoneNumberRequiredValidation,
} from "models/validations";
import { FormMaskedTextField } from "UI";
import { useApiRequest } from "api/useApiRequest";
import { updateKericoUser } from "api/userApi";
import { useAppDispatch } from "store/hooks";
import { resetIsDataChanged } from "store/reducers/isDataChangedReducer";
import { useFormDataSync } from "hooks/useFormDataSync";
import { ReactComponent as UserIcon } from "assets/img/user-small-icon.svg";
import CountrySelect from "./CountySelector";
import { ChipSetsHolder, EditableChip } from "UI/EditableChip";

export const EditKericoUserDialog = ({
  currentUser,
  handleClose,
  refreshList,
  countiesOptions,
  selectedCounties,
  setSelectedCounties,
}) => {
  const dispatch = useAppDispatch();
  const { showWarning } = useContext(GlobalContext);

  const { callApiRequest } = useApiRequest();

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    values: currentUser,
  });

  const { handleConfirmedClose } = useFormDataSync({
    isDirty,
    handleClose,
    open: !!currentUser,
    reset,
  });

  const email = useRef("");
  email.current = watch("email", "");
  const role = watch("role");

  const onSubmit = async () => {
    const {
      id,
      firstName,
      lastName,
      email,
      phone,
      counties,
      role,
      mfaEnabled,
    } = getValues();

    callApiRequest({
      apiRequest: updateKericoUser,
      params: {
        id,
        data: {
          firstName,
          lastName,
          email,
          phone,
          counties,
          role,
          isMfaEnabled: mfaEnabled,
        },
      },
      onSuccess: () => {
        dispatch(resetIsDataChanged());
        refreshList();
        handleClose();
        showWarning({
          title: "Kerico User Updated",
          content: "Kerico User has been successfully updated",
          icon: <UserIcon />,
        });
      },
    });
  };

  const handleDelete = (id) => {
    const counties = getValues("counties");
    const newValue = counties.filter((value) => value !== id);
    setSelectedCounties(newValue);
    setValue("counties", newValue, { shouldDirty: true });
  };

  useEffect(() => {
    if (role === "SUPER_ADMIN") {
      setSelectedCounties([]);
      setValue("counties", [], { shouldDirty: false });
    }
  }, [role, setSelectedCounties, setValue]);

  useEffect(() => {
    if (!currentUser?.id) {
      setSelectedCounties([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <DialogWindow
      open={!!currentUser?.id}
      withCloseButton
      handleClose={handleConfirmedClose}
      title={"Edit Kerico User"}
      content={
        <form>
          <Stack mt={2} gap={3} direction={"row"} justifyContent={"center"}>
            <Stack gap={2}>
              <Stack alignItems={"flex-start"}>
                <InputLabel htmlFor="firstName">First Name</InputLabel>
                <FormTextField
                  id="firstName"
                  name="firstName"
                  control={control}
                  rules={nameValidation}
                  InputProps={{
                    autoComplete: "off",
                  }}
                />
              </Stack>
              <Stack alignItems={"flex-start"}>
                <InputLabel htmlFor="lastName">Last Name</InputLabel>
                <FormTextField
                  id="lastName"
                  name="lastName"
                  control={control}
                  rules={nameValidation}
                  InputProps={{
                    autoComplete: "off",
                  }}
                />
              </Stack>
              <Stack alignItems={"flex-start"}>
                <InputLabel htmlFor="phone">Phone Number</InputLabel>
                <FormMaskedTextField
                  id="phone"
                  name="phone"
                  control={control}
                  rules={phoneNumberRequiredValidation}
                  mask="999-999-9999"
                  InputProps={{
                    autoComplete: "off",
                  }}
                />
              </Stack>
              <Stack alignItems={"flex-start"}>
                <InputLabel htmlFor="email">Email</InputLabel>
                <FormTextField
                  id="email"
                  name="email"
                  control={control}
                  rules={emailValidation}
                  InputProps={{
                    autoComplete: "email",
                  }}
                />
              </Stack>
            </Stack>
            <Stack gap={2}>
              <Stack alignItems={"flex-start"} width={"100%"}>
                <InputLabel htmlFor="role">Role</InputLabel>
                <FormSelectField
                  id="role"
                  name="role"
                  control={control}
                  options={{
                    SUPER_ADMIN: "Super Admin",
                    KERICO_MANAGER: "Manager",
                    KERICO_DISPATCHER: "Dispatcher",
                  }}
                  withNone={false}
                  rules={{ required }}
                />
              </Stack>
              {role !== "SUPER_ADMIN" && (
                <Stack gap={1}>
                  <Stack alignItems={"flex-start"}>
                    <InputLabel htmlFor="counties">Counties</InputLabel>
                    <ChipSetsHolder id="counties">
                      {selectedCounties.map((countyId) => {
                        const label = countiesOptions.find(
                          ({ value }) => value === countyId
                        )?.label;

                        return (
                          <EditableChip
                            key={countyId}
                            value={label}
                            onDelete={() => handleDelete(countyId)}
                          />
                        );
                      })}
                    </ChipSetsHolder>
                  </Stack>
                  <CountrySelect
                    counties={countiesOptions}
                    getValues={getValues}
                    setValue={setValue}
                    setSelectedCounties={setSelectedCounties}
                  />
                </Stack>
              )}
              <Stack
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                width={"100%"}
                gap={1}
              >
                <Button variant="outlined" onClick={handleConfirmedClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                  disabled={!isDirty}
                >
                  Save Changes
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      }
    />
  );
};
