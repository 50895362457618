import {
  Box,
  Button,
  InputLabel,
  Stack,
  TextField,
  styled,
} from "@mui/material";
import { DialogWindow } from "UI/DialogWindow";
import { createBackgroundCheck } from "api/driverApi";
import { useApiRequest } from "api/useApiRequest";
import { ReactComponent as UserIcon } from "assets/img/user-small-icon.svg";
import { customColors } from "models/customColors";
import { useContext } from "react";
import { GlobalContext } from "store/globalContext";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { closeChecksWindow } from "store/reducers/backgroundChecksReducer";
import { useDriverDetails } from "hooks/useDriverDetails";

const Info = styled(Box)`
  font-size: 0.9rem;
  color: ${({ theme }) => customColors[theme.palette.mode].greyText};
`;

export const CreateCheckDialog = () => {
  const { showWarning } = useContext(GlobalContext);
  const { callApiRequest } = useApiRequest();
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.backgroundChecks.selectedId);
  const spinner = useAppSelector((state) => state.spinner);
  const isNewCheckWindowOpen = useAppSelector(
    (state) => state.backgroundChecks.isNewCheckWindowOpen
  );

  const { driver } = useDriverDetails(userId);

  const handleCreateCheck = async () => {
    const params = {
      userId: userId,
    };

    callApiRequest({
      apiRequest: createBackgroundCheck,
      params,
      onSuccess: () => {
        showWarning({
          title: "Check Invite sent",
          content: "The invitation for driver check has been successfully sent",
          icon: <UserIcon />,
        });
        dispatch(closeChecksWindow());
      },
    });
  };

  return (
    <DialogWindow
      open={isNewCheckWindowOpen && !!driver?.id}
      withCloseButton
      handleClose={() => dispatch(closeChecksWindow())}
      title={"Run New Background Check"}
      content={
        <Stack maxWidth={"360px"} gap={3}>
          <Info>
            Once submitted, the driver will receive a notification regarding the
            background check process
          </Info>
          <Stack gap={1}>
            <Stack alignItems={"flex-start"} gap={1}>
              <InputLabel htmlFor="driverName">Driver Name</InputLabel>
              <TextField
                id={"driverName"}
                value={[driver?.firstName, driver?.lastName]
                  .filter(Boolean)
                  .join(" ")}
                sx={{
                  width: "100%",
                }}
              />
            </Stack>
            <Stack alignItems={"flex-start"} gap={1}>
              <InputLabel htmlFor="driverEmail">Email</InputLabel>
              <TextField
                id={"driverEmail"}
                value={[driver?.email].filter(Boolean).join(" ")}
                sx={{
                  width: "100%",
                }}
              />
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            gap={1}
          >
            <Button
              variant="outlined"
              onClick={() => dispatch(closeChecksWindow())}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={handleCreateCheck} disabled={spinner}>
              Initiate Background Check
            </Button>
          </Stack>
        </Stack>
      }
    />
  );
};
