import { useCallback, useState } from "react";

/** @typedef {import("types/confirmationTypes").ConfirmationsWithCallbackProps} ConfirmationsWithCallbackProps */

/**
 * @type {ConfirmationsWithCallbackProps}
 */
export const defaultConfirmationsWidthCallbackContextState = {
  title: undefined,
  message: undefined,
  callbackFn: undefined,
  conditionalCallbackFn: undefined,
  withOneButton: false,
};

export const useConfirmationsWithCallback = () => {
  const [confirmationWithCallback, setConfirmationWithCallback] = useState(
    defaultConfirmationsWidthCallbackContextState
  );

  const openConfirmationWithCallback = useCallback(
    /** @param {ConfirmationsWithCallbackProps} value */
    (value) => setConfirmationWithCallback(value),
    []
  );

  const closeConfirmationWithCallback = useCallback(
    () =>
      setConfirmationWithCallback(
        defaultConfirmationsWidthCallbackContextState
      ),
    []
  );

  return {
    confirmationWithCallback,
    openConfirmationWithCallback,
    closeConfirmationWithCallback,
  };
};
