import React, { useCallback, useEffect, useMemo } from "react";
import { Box, Button, Stack, styled } from "@mui/material";
import { DialogWindow } from "UI";
import { useApiRequest } from "api/useApiRequest";
import {
  addUserToChat,
  deleteUserFromChat,
  getMattermostUsers,
} from "api/messageApi";
import { TextFieldWithEllipsis } from "./TextFiledWithEllipsis";
import { convertToReadable, getReadableChatName } from "utils";
import { useDataGrid } from "hooks/useDataGrid";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { DataGrid } from "UI/DataGrid";
import { SelectedUsers } from "./SelectedUsers";
import { customColors } from "models/customColors";
import { ConditionalWrapper } from "UI/ConditionalWrapper";
import { loadChats } from "store/reducers/chatsReducer";
import { useChatUsers } from "hooks/useChatUsers";

const LeftPanel = styled(Stack)`
  width: 35vw;
  min-width: 400px;
  height: 60vh;
  min-height: 300px;
  gap: 16px;
`;

const RightPanel = styled(LeftPanel)`
  width: 30vw;
  min-width: 300px;
  gap: 0;

  overflow: hidden;
`;

const SectionTitle = styled(Box)`
  font-weight: bold;
`;
const SelectedUserWrapper = styled(Stack)`
  color: ${({ theme }) => customColors[theme.palette.mode].placeHolder};
`;

const AddButton = styled(Button)`
  height: 16px;
  padding: 0;
`;

const isUserInChat = (mattermostId, chatUsers) => {
  if (mattermostId === "unknown") return true;

  return chatUsers?.some((user) => user.user_id === mattermostId);
};

export const EditChatUsersDialog = ({ chat, handleClose, refreshList }) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.user.id);
  const { callApiRequest } = useApiRequest();
  const mattermostUserId = useAppSelector(
    (state) => state.user.mattermostUserId
  );

  const { chatUsers, chatUserDetails, setChatUserDetails, loadUsersData } =
    useChatUsers();

  const dataGrid = useDataGrid({
    apiHandler: getMattermostUsers,
    defaultSorter: {
      name: "FULL_NAME",
      order: "ASC",
    },
    defaultLimit: 25,
    gridConfigIndex: `${userId}-all-users`,
  });

  const addUserToChatByMattermostId = useCallback(
    async ({ id }) => {
      callApiRequest({
        apiRequest: addUserToChat,
        params: {
          chatId: chat?.id,
          userId: id,
        },
        onSuccess: () => {
          loadUsersData(chat?.id);
          dataGrid.loadData();
          dispatch(loadChats(mattermostUserId));
        },
      });
    },
    [
      callApiRequest,
      chat?.id,
      dataGrid,
      dispatch,
      loadUsersData,
      mattermostUserId,
    ]
  );

  const columns = useMemo(
    () => [
      {
        label: "User",
        field: "fullName",
        xs: 4,
        sm: 4,
        md: 4,
        sortableName: "FULL_NAME",
        renderer: ({ fullName, id }) => (
          <ConditionalWrapper
            condition={isUserInChat(id, chatUsers)}
            Wrapper={SelectedUserWrapper}
          >
            <TextFieldWithEllipsis>
              {!!fullName ? fullName : "-"}
            </TextFieldWithEllipsis>
          </ConditionalWrapper>
        ),
      },
      {
        label: "Role",
        field: "role",
        xs: 3.5,
        sm: 3.5,
        md: 3.5,
        sortableName: "ROLE",
        renderer: ({ role, id }) => (
          <ConditionalWrapper
            condition={isUserInChat(id, chatUsers)}
            Wrapper={SelectedUserWrapper}
          >
            <TextFieldWithEllipsis>
              {convertToReadable(role)}
            </TextFieldWithEllipsis>
          </ConditionalWrapper>
        ),
      },
      {
        label: "Phone",
        field: "phone",
        xs: 3,
        sm: 3,
        md: 3,
        sortableName: "PHONE",
        renderer: ({ id, phone }) => (
          <ConditionalWrapper
            condition={isUserInChat(id, chatUsers)}
            Wrapper={SelectedUserWrapper}
          >
            <TextFieldWithEllipsis>{phone}</TextFieldWithEllipsis>
          </ConditionalWrapper>
        ),
      },
      {
        xs: 0.7,
        sm: 0.7,
        md: 0.7,
        renderer: ({ id }) =>
          isUserInChat(id, chatUsers) ? (
            <></>
          ) : (
            <AddButton
              color="success"
              onClick={() => addUserToChatByMattermostId({ id })}
              size="small"
              type="button"
            >
              Add
            </AddButton>
          ),
      },
    ],
    [addUserToChatByMattermostId, chatUsers]
  );

  const deleteUserFromChatByMattermostId = async (id) => {
    callApiRequest({
      apiRequest: deleteUserFromChat,
      params: {
        chatId: chat?.id,
        userId: id,
      },
      onSuccess: () => {
        loadUsersData(chat?.id);
        dataGrid.loadData();
        dispatch(loadChats(mattermostUserId));
      },
    });
  };

  useEffect(() => {
    if (chat?.id) {
      loadUsersData(chat?.id);
      return;
    }
    setChatUserDetails(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat]);

  if (!chat) return null;

  return (
    <DialogWindow
      open={true}
      withCloseButton
      handleClose={handleClose}
      title={`Manage ${getReadableChatName(chat)} Chat Users`}
      content={
        <Stack direction={"row"} gap={10}>
          <LeftPanel>
            <SectionTitle textAlign={"left"}>Available Users</SectionTitle>
            <DataGrid
              columns={columns}
              handleRowClick={() => null}
              {...dataGrid}
            />
          </LeftPanel>
          <RightPanel>
            <SectionTitle textAlign={"left"}>
              Selected Users{" "}
              {!!chatUserDetails?.length && `(${chatUserDetails.length})`}
            </SectionTitle>
            <SelectedUsers
              users={chatUserDetails}
              deleteUserFromChatByMattermostId={
                deleteUserFromChatByMattermostId
              }
            />
          </RightPanel>
        </Stack>
      }
    />
  );
};
