import React from "react";
import { Box, Button, InputLabel, Stack, TextField, useTheme } from "@mui/material";
import * as SC from "SC";
import { FormSelectField } from "UI/FormSelectField";
import { required } from "models/validations";
import { ProgressBar } from "components/ProgressIndicator";
import { Title } from "./ImportCsvFilePage.style";
import { ImportStepThree } from "components/ImportStepThree";
import { ImportStepTwo } from "components/ImportStepTwo";
import { useImportCsvFilePage } from "./ImportCsvFilePage.hook";
import { TablePreview } from "components/TablePreview";
import { customColors } from "models/customColors";

export const ImportCsvFilePage = () => {
  const theme = useTheme();
  const {
    step,
    setStep,
    clientListOptions,
    control,
    handleFileSelect,
    separatorOptions,
    isAllFieldsMapped,
    isReadyForUpload,
    isMapperDisabled,
    startMapping,
    handleImport,
    headerRow,
    template,
    setTemplate,
    defaultTemplate,
    rideRequestFields,
    watch,
    previewHeaderRow,
    previewRows,
    isPreviewVisible,
    getValues,
    setValue,
  } = useImportCsvFilePage();

  return (
    <SC.Container sx={{ padding: "30px", overflow: "hidden" }}>
      <Stack
        justifyContent={"space-between"}
        gap={3}
        sx={{
          height: "100%",
        }}
      >
        {step === 0 && (
          <Stack gap={2} height={"100%"} width={"100%"}>
            <Stack gap={1}>
              <Title>Import CSV File</Title>
              <ProgressBar step={step} steps={3} />
            </Stack>
            <Stack alignItems={"flex-start"} width={"100%"} maxWidth={"640px"}>
              <InputLabel htmlFor="clientId">Select client company</InputLabel>
              <FormSelectField
                id="clientId"
                name="clientId"
                control={control}
                options={clientListOptions}
                withNone={false}
                rules={{ required }}
              />
            </Stack>
            {isReadyForUpload && (
              <TextField
              type="file"
              size="small"
              // @ts-ignore
              inputProps={{
                id: "csvFileInput",
                accept: ".csv",
                multiple: false,
                style: {
                  color: customColors[theme.palette.mode].actionColor,
                },
              }}
              sx={{ width: "100%", maxWidth: "640px" }}
              onChange={handleFileSelect}
            />
            )}
          </Stack>
        )}
        {step === 1 && (
          <Stack
            gap={2}
            height={"100%"}
            width={"100%"}
            sx={{ maxWidth: "100%", overflow: "hidden" }}
          >
            <Stack gap={1}>
              <Title>Adjust importing options</Title>
              <ProgressBar step={step} steps={3} />
            </Stack>
            <ImportStepTwo
              control={control}
              watch={watch}
              separatorOptions={separatorOptions}
            />
            <Stack direction={"row"} gap={1}>
              <Button variant="outlined" onClick={() => setStep(0)}>
                Back to upload
              </Button>
              <Button
                variant="contained"
                onClick={startMapping}
                disabled={isMapperDisabled}
              >
                Map uploaded data
              </Button>
            </Stack>
          </Stack>
        )}
        {step === 2 && (
          <Stack gap={2} height={"100%"} width={"100%"} overflow={"hidden"} position={"relative"}>
            <Stack gap={1}>
              <Title>Map imported data</Title>
              <ProgressBar step={step} steps={3} />
            </Stack>
            <ImportStepThree
              headerRow={headerRow}
              rideRequestFields={rideRequestFields}
              template={template}
              setTemplate={setTemplate}
              defaultTemplate={defaultTemplate}
              getValues={getValues}
              setValue={setValue}
              isAllFieldsMapped={isAllFieldsMapped}
              watch={watch}
            />
            <Box flex={1} />
            {isPreviewVisible && (
              <TablePreview headerRow={previewHeaderRow} rows={previewRows} />
            )}
            <Stack direction={"row"} alignItems={"flex-end"} flex={1} gap={1}>
              <Button variant="outlined" onClick={() => setStep(1)}>
                Step back
              </Button>
              <Button
                variant="contained"
                onClick={handleImport}
                disabled={!isAllFieldsMapped}
              >
                Import ride requests
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>
    </SC.Container>
  );
};
