import { Button, Stack } from "@mui/material";
import * as SC from "SC";
import { CloseWindowButton } from "UI/CloseWindowButton";
import { DataGrid } from "UI/DataGrid";
import { ReactComponent as BackIcon } from "assets/img/backIcon.svg";
import * as ASC from "components/AuthCommonLayout.style";
import { Prop, RequestItemDetails } from "components/RequestItemDetails";
import { RequestItemsTableModeSelector } from "components/RequestItemsTableModeSelector";
import { Label, Value } from "components/RideData";
import { RideDetails } from "components/RideDetails";
import { Content } from "pages/BookingsPage.style";
import { Status as FileStatus } from "pages/RequestFilesPage";
import { useNavigate } from "react-router-dom";
import { convertToReadable, unixTimeToUSDate } from "utils";
import * as FP from "utils/fp-js";
import { useRequestItemsPage } from "./RequestItemsPage.hook";
import { GrayText, RightPanel } from "./RequestItemsPage.style";

export const RequestItemsPage = () => {
  const navigate = useNavigate();

  const {
    id,
    dataGrid,
    validateDataGrid,
    request,
    setItem,
    mode,
    setMode,
    selectedId,
    handleTransferSelected,
    handleRowClick,
    handleArchiveRequest,
    handleRestoreRequest,
    handleLoadSelectedItem,
    columns,
    shortColumns,
    getClientLabelById,
    item,
    windowType,
    windowTypes,
    selectedRideIt,
    setSelectedRideId,
    closeWindow,
    setWindowType,
  } = useRequestItemsPage();

  const tableWidth = FP.match(true)
    .on(selectedId && windowType === windowTypes.REQUEST, "calc(40% - 20px)")
    .on(selectedRideIt && windowType === windowTypes.RIDE, "calc(50% - 20px)")
    .otherwise("100%");

  const rightPanelWidth = FP.match(true)
    .on(selectedId && windowType === windowTypes.REQUEST, "calc(60% - 20px)")
    .on(selectedRideIt && windowType === windowTypes.RIDE, "50%")
    .otherwise("100%");

  return (
    <SC.Container>
      {!!request && (
        <Stack direction={"row"} height={"100%"} position={"relative"}>
          <Content>
            <Stack gap={"20px"} width={tableWidth}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={"20px"}
              >
                <Stack direction={"row"} alignItems={"center"} gap={"20px"}>
                  <ASC.BackButton onClick={() => navigate(-1)}>
                    <SC.BackButtonContent sx={SC.BackButtonContentSx}>
                      <BackIcon />
                    </SC.BackButtonContent>
                  </ASC.BackButton>
                  <Stack gap={0.25}>
                    <strong>File Request #{id}</strong>
                    <GrayText>
                      from {unixTimeToUSDate(request.createdAt)}
                    </GrayText>
                  </Stack>
                  <FileStatus
                    // @ts-ignore
                    status={request?.status}
                    sx={{
                      padding: "0.5rem 1rem",
                    }}
                  >
                    {convertToReadable(request?.status)}
                  </FileStatus>
                  {!selectedId && (
                    <Prop>
                      <Label>Client:</Label>
                      <Value>{getClientLabelById(request?.companyId)}</Value>
                    </Prop>
                  )}
                </Stack>
                <Stack direction={"row"} gap={1}>
                  {dataGrid?.selectedRows?.length > 0 && (
                    <Button
                      variant="contained"
                      onClick={handleTransferSelected}
                    >
                      Transfer Rides
                    </Button>
                  )}
                  {!selectedId && (
                    <>
                      {["PROCESSING", "ERROR"].includes(request?.status) && (
                        <Button
                          variant="outlined"
                          onClick={handleArchiveRequest}
                        >
                          Archive Request
                        </Button>
                      )}
                      {request?.status === "ARCHIVED" && (
                        <Button
                          variant="outlined"
                          onClick={handleRestoreRequest}
                        >
                          Restore Request
                        </Button>
                      )}
                    </>
                  )}
                </Stack>
              </Stack>
              <DataGrid
                columns={selectedId ? shortColumns : columns}
                handleRowClick={handleRowClick}
                {...(mode === "VALIDATE_REQUESTS"
                  ? validateDataGrid
                  : dataGrid)}
                actions={
                  <RequestItemsTableModeSelector
                    mode={mode}
                    setMode={setMode}
                    validateRequestsDataGrid={validateDataGrid}
                    allRequestsdataGrid={dataGrid}
                  />
                }
                keyName="id"
                selectedId={selectedId}
              />
            </Stack>
            {selectedId && windowType === windowTypes.REQUEST && (
              <RightPanel width={rightPanelWidth}>
                <CloseWindowButton
                  top={-35}
                  right={-35}
                  closeHandler={closeWindow}
                />
                <RequestItemDetails
                  request={request}
                  requestItem={item}
                  refreshList={() => {
                    dataGrid?.loadData();
                    validateDataGrid?.loadData();
                  }}
                  getClientLabelById={getClientLabelById}
                  setRequestItem={setItem}
                  refreshRequestItem={handleLoadSelectedItem}
                  setSelectedRideId={setSelectedRideId}
                  setWindowType={setWindowType}
                />
              </RightPanel>
            )}
            {selectedRideIt && windowType === windowTypes.RIDE && (
              <RightPanel width={rightPanelWidth}>
                <CloseWindowButton
                  top={-35}
                  right={-35}
                  closeHandler={() => {
                    setSelectedRideId(null);
                    setWindowType(windowTypes.REQUEST);
                  }}
                />
                <RideDetails
                  id={selectedRideIt}
                  getClientLabelById={getClientLabelById}
                  refreshList={dataGrid.loadData}
                />
              </RightPanel>
            )}
          </Content>
        </Stack>
      )}
    </SC.Container>
  );
};
