import React, { useEffect, useState } from "react";
import { Stack, styled } from "@mui/material";
import { useAppDispatch } from "store/hooks";
import { customColors } from "models/customColors";
import { useNavigate, useParams } from "react-router-dom";
import { setNavigationData } from "store/reducers/navigationDataReducer";
import { ModalSpinner } from "components/ModalSpinner";
import { useApiRequest } from "api/useApiRequest";
import { getInvoiceById } from "api/invoiceApi";
import { showError } from "store/reducers/errorReducer";

const Container = styled(Stack)`
  flex: 1 1 auto;
  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].background};
`;

export const Invoice = () => {
  const dispatch = useAppDispatch();
  const { callApiRequest } = useApiRequest();
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState(null);

  const loadData = async (id) =>
    callApiRequest({
      apiRequest: getInvoiceById,
      params: id,
      onSuccess: (result) => {
        setData(result);
      },
      onError: (error) => {
        // @ts-ignore
        dispatch(showError(error));
        setData(null);
      },
    });

  useEffect(() => {
    if (!id) return;
    loadData(id);
    dispatch(
      setNavigationData({
        search: id,
        selectedId: id,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if(data?.company?.id) {
      navigate(`/company_invoices/${data?.company?.id}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Container
      width={"100%"}
      height={"70vh"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      gap={3}
      pt={3}
    >
      <ModalSpinner open />
    </Container>
  );
};
