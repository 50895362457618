import React from "react";

import { Stack, Box, styled } from "@mui/material";
import * as SC from "SC";

const Container = styled(Stack)`
  padding: 0 4rem 4rem 4rem;
  width: 90%;
  max-width: 800px;

  @media screen and (orientation: portrait) and (max-device-width: 1024px) {
    padding: 0 1rem 1rem 1rem;
    font-size: 0.8rem;
  }
`;
const Heading1 = styled(Box)`
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
`;

const SubHeading = styled(Box)`
  margin: 1rem 0;
  text-decoration: underline;
`;

const SubHeadingContent = styled(Box)`
  padding: 0 0 0 3rem;
`;

const Heading = styled(Box)`
  margin-top: 2rem;
  font-weight: 600;
`;

export const TermsPage = () => {
  return (
    <Container>
      <SC.AuthTitle sx={{ ...SC.AuthTitleSx }}>
        Privacy Policy
        <br />
        for Kerico Health Care
      </SC.AuthTitle>
      <p>Last updated: March 01, 2024</p>
      <p>
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You.
      </p>
      <p>
        We use Your Personal data to provide and improve the Service. By using
        the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy.
      </p>
      <Heading1>Interpretation and Definitions</Heading1>
      <Heading>Interpretation</Heading>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>
      <Heading>Definitions</Heading>
      <p>For the purposes of this Privacy Policy:</p>
      <ul>
        <li>
          <strong>Account</strong> means a unique account created for You to
          access our Service or parts of our Service.
        </li>
        <li>
          <strong>Business</strong>, for the purpose of the CCPA (California
          Consumer Privacy Act), refers to the Company as the legal entity that
          collects Consumers' personal information and determines the purposes
          and means of the processing of Consumers' personal information, or on
          behalf of which such information is collected and that alone, or
          jointly with others, determines the purposes and means of the
          processing of consumers' personal information, that does business in
          the State of California.
        </li>
        <li>
          <strong>Company</strong> (referred to as either "the Company", "We",
          "Us" or "Our" in this Agreement) refers to Kerico Home Clinic Servcies
          LLC, 6575 West loop south, Suite 500, Bellaire, TX 77401.
        </li>
        <li>
          <strong>Consumer</strong>, for the purpose of the CCPA (California
          Consumer Privacy Act), means a natural person who is a California
          resident. A resident, as defined in the law, includes (1) every
          individual who is in the USA for other than a temporary or transitory
          purpose, and (2) every individual who is domiciled in the USA who is
          outside the USA for a temporary or transitory purpose.
        </li>
        <li>
          <strong>Cookies</strong> are small files that are placed on Your
          computer, mobile device or any other device by a website, containing
          the details of Your browsing history on that website among its many
          uses.
        </li>
        <li>
          <strong>Country</strong> refers to: Texas, United States.
        </li>
        <li>
          <strong>Device</strong> means any device that can access the Service
          such as a computer, a cellphone or a digital tablet.
        </li>
        <li>
          <strong>Do Not Track (DNT)</strong> is a concept that has been
          promoted by US regulatory authorities, in particular the U.S. Federal
          Trade Commission (FTC), for the Internet industry to develop and
          implement a mechanism for allowing internet users to control the
          tracking of their online activities across websites.
        </li>
        <li>
          <strong>Personal Data</strong> is any information that relates to an
          identified or identifiable individual.
        </li>
        <li>
          <strong>Sale</strong>, for the purpose of the CCPA (California
          Consumer Privacy Act), means selling, renting, releasing, disclosing,
          disseminating, making available, transferring, or otherwise
          communicating orally, in writing, or by electronic or other means, a
          Consumer's Personal information to another business or a third party
          for monetary or other valuable consideration.
        </li>
        <li>
          <strong>Service</strong> refers to the Website.
        </li>
        <li>
          <strong>Service Provider</strong> means any natural or legal person
          who processes the data on behalf of the Company. It refers to
          third-party companies or individuals employed by the Company to
          facilitate the Service, to provide the Service on behalf of the
          Company, to perform services related to the Service or to assist the
          Company in analyzing how the Service is used.
        </li>
        <li>
          <strong>Third-party Social Media Service</strong> refers to any
          website or any social network website through which a User can log in
          or create an account to use the Service.
        </li>
        <li>
          <strong>Usage Data</strong> refers to data collected automatically,
          either generated by the use of the Service or from the Service
          infrastructure itself (for example, the duration of a page visit).
        </li>
        <li>
          <strong>Website</strong> refers to Kerico health care, accessible from
          www.kericocare.com.
        </li>
        <li>
          <strong>You</strong> means the individual accessing or using the
          Service, or the company, or other legal entity on behalf of which such
          individual is accessing or using the Service, as applicable.
        </li>
      </ul>
      <Heading1>Collecting and Using Your Personal Data</Heading1>
      <SubHeading>Types of Data Collected</SubHeading>
      <SubHeadingContent>
        <Heading>Personal Data</Heading>
        <p>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </p>
        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Address, State, Province, ZIP/Postal code, City</li>
          <li>
            Bank account information in order to pay for products and/or
            services within the Service
          </li>
        </ul>
        <Heading>Usage Data</Heading>
        <p>
          When You pay for a product and/or a service via bank transfer, We may
          ask You to provide information to facilitate this transaction and to
          verify Your identity. Such information may include, without
          limitation:
        </p>
        <ul>
          <li>Date of birth</li>
          <li>Passport or National ID card</li>
          <li>Bank card statement</li>
          <li>Other information linking You to an address</li>
        </ul>
        <p>
          Usage Data
          <br />
          Usage Data is collected automatically when using the Service.
        </p>
        <p>
          Usage Data may include information such as Your Device's Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </p>
      </SubHeadingContent>
      <p>
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data.
      </p>
      <p>
        We may also collect information that Your browser sends whenever You
        visit our Service or when You access the Service by or through a mobile
        device.
      </p>
      <p>
        Tracking Technologies and Cookies
        <br />
        We use Cookies and similar tracking technologies to track the activity
        on Our Service and store certain information. Tracking technologies used
        are beacons, tags, and scripts to collect and track information and to
        improve and analyze Our Service.
      </p>
      <p>
        You can instruct Your browser to refuse all Cookies or to indicate when
        a Cookie is being sent. However, if You do not accept Cookies, You may
        not be able to use some parts of our Service.
      </p>
      <p>
        Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
        remain on your personal computer or mobile device when You go offline,
        while Session Cookies are deleted as soon as You close your web browser.
        Learn more about cookies: All About Cookies.
      </p>
      <p>
        We use both session and persistent Cookies for the purposes set out
        below:
      </p>
      <SubHeadingContent>
        <p>
          Necessary / Essential Cookies
          <br />
          Type: Session Cookies
          <br />
          Administered by: Us
          <br />
          Purpose: These Cookies are essential to provide You with services
          available through the Website and to enable You to use some of its
          features. They help to authenticate users and prevent fraudulent use
          of user accounts. Without these Cookies, the services that You have
          asked for cannot be provided, and We only use these Cookies to provide
          You with those services.
        </p>
        <p>
          Cookies Policy / Notice Acceptance Cookies
          <br />
          Type: Persistent Cookies
          <br />
          Administered by: Us <br />
          Purpose: These Cookies identify if users have accepted the use of
          cookies on the Website.
        </p>
        <p>
          Functionality Cookies
          <br />
          Type: Persistent Cookies
          <br />
          Administered by: Us
          <br />
          Purpose: These Cookies allow us to remember choices You make when You
          use the Website, such as remembering your login details or language
          preference. The purpose of these Cookies is to provide You with a more
          personal experience and to avoid You having to re-enter your
          preferences every time You use the Website.
        </p>
        <p>
          Tracking and Performance Cookies
          <br />
          Type: Persistent Cookies
          <br />
          Administered by: Third-Parties
          <br />
          Purpose: These Cookies are used to track information about traffic to
          the Website and how users use the Website. The information gathered
          via these Cookies may directly or indirectly identify you as an
          individual visitor. This is because the information collected is
          typically linked to a pseudonymous identifier associated with the
          device you use to access the Website. We may also use these Cookies to
          test new advertisements, pages, features or new functionality of the
          Website to see how our users react to them.
        </p>
        <p>
          Targeting and Advertising Cookies
          <br />
          Type: Persistent Cookies
          <br />
          Administered by: Third-Parties
          <br />
          Purpose: These Cookies track your browsing habits to enable Us to show
          advertising which is more likely to be of interest to You. These
          Cookies use information about your browsing history to group You with
          other users who have similar interests. Based on that information, and
          with Our permission, third party advertisers can place Cookies to
          enable them to show adverts which We think will be relevant to your
          interests while You are on third party websites.
        </p>
        <p>
          For more information about the cookies we use and your choices
          regarding cookies, please visit our Cookies Policy or the Cookies
          section of our Privacy Policy.
        </p>
      </SubHeadingContent>
      <Heading>Use of Your Personal Data</Heading>
      <SubHeading>
        The Company may use Personal Data for the following purposes:
      </SubHeading>
      <SubHeadingContent>
        <p>
          To provide and maintain our Service, including to monitor the usage of
          our Service.
        </p>
        <p>
          To manage Your Account: to manage Your registration as a user of the
          Service. The Personal Data You provide can give You access to
          different functionalities of the Service that are available to You as
          a registered user.
        </p>
        <p>
          For the performance of a contract: the development, compliance and
          undertaking of the purchase contract for the products, items or
          services You have purchased or of any other contract with Us through
          the Service.
        </p>
        <p>
          To contact You: To contact You by email, telephone calls, SMS, or
          other equivalent forms of electronic communication, such as a mobile
          application's push notifications regarding updates or informative
          communications related to the functionalities, products or contracted
          services, including the security updates, when necessary or reasonable
          for their implementation.
        </p>
        <p>
          To provide You with news, special offers and general information about
          other goods, services and events which we offer that are similar to
          those that you have already purchased or enquired about unless You
          have opted not to receive such information.
        </p>
        <p>
          To manage Your requests: To attend and manage Your requests to Us.
        </p>
      </SubHeadingContent>
      <SubHeading>
        We may share your personal information in the following situations:
      </SubHeading>
      <SubHeadingContent>
        <p>
          With Service Providers: We may share Your personal information with
          Service Providers to monitor and analyze the use of our Service, to
          show advertisements to You to help support and maintain Our Service,
          to advertise on third party websites to You after You visited our
          Service, for payment processing, to contact You.
        </p>
        <p>
          For Business transfers: We may share or transfer Your personal
          information in connection with, or during negotiations of, any merger,
          sale of Company assets, financing, or acquisition of all or a portion
          of our business to another company. With Affiliates: We may share Your
          information with Our affiliates, in which case we will require those
          affiliates to honor this Privacy Policy. Affiliates include Our parent
          company and any other subsidiaries, joint venture partners or other
          companies that We control or that are under common control with Us.
        </p>
        <p>
          With Business partners: We may share Your information with Our
          business partners to offer You certain products, services or
          promotions. With other users: when You share personal information or
          otherwise interact in the public areas with other users, such
          information may be viewed by all users and may be publicly distributed
          outside. If You interact with other users or register through a
          Third-Party Social Media Service, Your contacts on the Third-Party
          Social Media Service may see Your name, profile, pictures and
          description of Your activity. Similarly, other users will be able to
          view descriptions of Your activity, communicate with You and view Your
          profile.
        </p>
      </SubHeadingContent>
      <SubHeading>Retention of Your Personal Data</SubHeading>
      <SubHeadingContent>
        <p>
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </p>
        <p>
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </p>
      </SubHeadingContent>
      <SubHeading>Transfer of Your Personal Data</SubHeading>
      <SubHeadingContent>
        <p>
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction.
        </p>
        <p>
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </p>
        <p>
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </p>
      </SubHeadingContent>
      <Heading>Disclosure of Your Personal Data</Heading>
      <SubHeading>Business Transactions</SubHeading>
      <p>
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy.
      </p>
      <SubHeading>Law enforcement</SubHeading>
      <p>
        Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
      </p>
      <SubHeading>Other legal requirements</SubHeading>
      <p>
        The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:
      </p>
      <SubHeading>Comply with a legal obligation</SubHeading>
      <p>
        Protect and defend the rights or property of the Company
        <br />
        Prevent or investigate possible wrongdoing in connection with the
        Service
        <br />
        Protect the personal safety of Users of the Service or the public
        <br />
        Protect against legal liability
      </p>
      <SubHeading>Security of Your Personal Data</SubHeading>
      <p>
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security.
      </p>
      <SubHeading>
        Detailed Information on the Processing of Your Personal Data
      </SubHeading>
      <p>
        Service Providers have access to Your Personal Data only to perform
        their tasks on Our behalf and are obligated not to disclose or use it
        for any other purpose.
      </p>
      <SubHeading>Analytics</SubHeading>
      <p>
        We may use third-party Service providers to monitor and analyze the use
        of our Service.
      </p>
      <SubHeading>Google Analytics</SubHeading>
      <p>
        Google Analytics is a web analytics service offered by Google that
        tracks and reports website traffic. Google uses the data collected to
        track and monitor the use of our Service. This data is shared with other
        Google services. Google may use the collected data to contextualize and
        personalize the ads of its own advertising network.
      </p>
      <p>
        You can opt-out of having made your activity on the Service available to
        Google Analytics by installing the Google Analytics opt-out browser add
        on. The add on prevents the Google Analytics JavaScript (ga.js,
        analytics.js, and dc.js) from sharing information with Google Analytics
        about visits activity.
      </p>
      <p>
        For more information on the privacy practices of Google, please visit
        the Google Privacy & Terms web page:{" "}
        <a
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=en
        </a>
      </p>
      <SubHeading>Advertising</SubHeading>
      <p>
        We may use Service providers to show advertisements to You to help
        support and maintain Our Service.​
      </p>
      <Heading>Google AdSense & DoubleClick Cookie</Heading>
      <p>
        Google, as a third party vendor, uses cookies to serve ads on our
        Service. Google's use of the DoubleClick cookie enables it and its
        partners to serve ads to our users based on their visit to our Service
        or other websites on the Internet.
      </p>
      <p>
        You may opt out of the use of the DoubleClick Cookie for interest-based
        advertising by visiting the Google Ads Settings web page:{" "}
        <a
          href="http://www.google.com/ads/preferences/"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.google.com/ads/preferences/
        </a>
      </p>
      <p>
        Email Marketing
        <br />
        We may use Your Personal Data to contact You with newsletters, marketing
        or promotional materials and other information that may be of interest
        to You. You may opt-out of receiving any, or all, of these
        communications from Us by following the unsubscribe link or instructions
        provided in any email We send or by contacting Us.
      </p>
      <p>
        We may use Email Marketing Service Providers to manage and send emails
        to You.
      </p>
      <Heading>Mailchimp</Heading>
      <p>
        Mailchimp is an email marketing sending service provided by The Rocket
        Science Group LLC.
      </p>
      <p>
        For more information on the privacy practices of Mailchimp, please visit
        their Privacy policy:{" "}
        <a
          href="https://mailchimp.com/legal/privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://mailchimp.com/legal/privacy/
        </a>
      </p>
      <Heading>Get GetResponse</Heading>
      <p>
        GetResponse is an email marketing sending service provided by
        GetResponse.
      </p>
      <p>
        For more information on the privacy practices of GetResponse, please
        visit their Privacy policy:{" "}
        <a
          href="https://www.getresponse.com/legal/privacy.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.getresponse.com/legal/privacy.html
        </a>
      </p>
      <Heading>Payments</Heading>
      <p>
        We may provide paid products and/or services within the Service. In that
        case, we may use third-party services for payment processing (e.g.
        payment processors).
      </p>
      <p>
        We will not store or collect Your payment card details. That information
        is provided directly to Our third-party payment processors whose use of
        Your personal information is governed by their Privacy Policy. These
        payment processors adhere to the standards set by PCI-DSS as managed by
        the PCI Security Standards Council, which is a joint effort of brands
        like Visa, Mastercard, American Express and Discover. PCI-DSS
        requirements help ensure the secure handling of payment information.
      </p>
      <ul>
        <li>
          <strong>Stripe</strong>
          <br />
          Their Privacy Policy can be viewed at{" "}
          <a
            href="https://stripe.com/us/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://stripe.com/us/privacy
          </a>
        </li>
        <li>
          <strong>Worldpay</strong>
          <br />
          Their Privacy Policy can be viewed at{" "}
          <a
            href="https://www.worldpay.com/en-gb/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.worldpay.com/en-gb/privacy-policy
          </a>
        </li>
        <li>
          <strong>PayPal</strong>
          <br />
          Their Privacy Policy can be viewed at{" "}
          <a
            href="https://www.paypal.com/webapps/mpp/ua/privacy-full"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.paypal.com/webapps/mpp/ua/privacy-full
          </a>
        </li>
      </ul>
      <p>
        When You use Our Service to pay a product and/or service via bank
        transfer, We may ask You to provide information to facilitate this
        transaction and to verify Your identity.
      </p>
      <Heading>​Behavioral Remarketing</Heading>
      <p>
        The Company uses remarketing services to advertise on third party
        websites to You after You visited our Service. We and Our third-party
        vendors use cookies to inform, optimize and serve ads based on Your past
        visits to our Service
      </p>
      <Heading>Google Ads (AdWords)</Heading>
      <p>Google Ads (AdWords) remarketing service is provided by Google Inc.</p>
      <p>
        You can opt-out of Google Analytics for Display Advertising and
        customize the Google Display Network ads by visiting the Google Ads
        Settings page:{" "}
        <a
          href="http://www.google.com/settings/ads"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.google.com/settings/ads
        </a>
      </p>
      <p>
        Google also recommends installing the Google Analytics Opt-out Browser
        Add-on -{" "}
        <a
          href="https://tools.google.com/dlpage/gaoptout"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://tools.google.com/dlpage/gaoptout
        </a>
        - for your web browser. Google Analytics Opt-out Browser Add-on provides
        visitors with the ability to prevent their data from being collected and
        used by Google Analytics.
      </p>
      <p>
        For more information on the privacy practices of Google, please visit
        the Google Privacy & Terms web page:{" "}
        <a
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=en
        </a>
      </p>
      <Heading>Facebook</Heading>
      <p>Facebook remarketing service is provided by Facebook Inc.</p>
      <p>
        You can learn more about interest-based advertising from Facebook by
        visiting this page:{" "}
        <a
          href="https://www.facebook.com/help/164968693837950"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/help/164968693837950
        </a>
      </p>
      <p>
        To opt-out from Facebook's interest-based ads, follow these instructions
        from Facebook:{" "}
        <a
          href="https://www.facebook.com/help/568137493302217"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/help/568137493302217
        </a>
      </p>
      <p>
        Facebook adheres to the Self-Regulatory Principles for Online Behavioral
        Advertising established by the Digital Advertising Alliance. You can
        also opt-out from Facebook and other participating companies through the
        Digital Advertising Alliance in the USA{" "}
        <a
          href="http://www.aboutads.info/choices/"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.aboutads.info/choices/
        </a>
        , the Digital Advertising Alliance of Canada in Canada{" "}
        <a
          href="http://youradchoices.ca/"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://youradchoices.ca/
        </a>
        or the European Interactive Digital Advertising Alliance in Europe{" "}
        <a
          href="http://www.youronlinechoices.eu/"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.youronlinechoices.eu/
        </a>
        , or opt-out using your mobile device settings.
      </p>
      <p>
        For more information on the privacy practices of Facebook, please visit
        Facebook's Data Policy:{" "}
        <a
          href="https://www.facebook.com/privacy/explanation"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/privacy/explanation
        </a>
      </p>
      <Heading>CCPA Privacy</Heading>
      <SubHeading>Your Rights under the CCPA</SubHeading>
      <p>
        Under this Privacy Policy, and by law if You are a resident of
        California, You have the following rights:
      </p>
      <SubHeadingContent>
        The right to notice. You must be properly notified which categories of
        Personal Data are being collected and the purposes for which the
        Personal Data is being used.
        <br />
        The right to access / the right to request. The CCPA permits You to
        request and obtain from the Company information regarding the disclosure
        of Your Personal Data that has been collected in the past 12 months by
        the Company or its subsidiaries to a third-party for the third party's
        direct marketing purposes.
        <br />
        The right to say no to the sale of Personal Data. You also have the
        right to ask the Company not to sell Your Personal Data to third
        parties. You can submit such a request by visiting our "Do Not Sell My
        Personal Information" section or web page.
        <br />
        The right to know about Your Personal Data. You have the right to
        request and obtain from the Company information regarding the disclosure
        of the following:
        <br />
        The categories of Personal Data collected
        <br />
        The sources from which the Personal Data was collected
        <br />
        The business or commercial purpose for collecting or selling the
        Personal Data
        <br />
        Categories of third parties with whom We share Personal Data The
        specific pieces of Personal Data we collected aboutYou
        <br />
        The right to delete Personal Data.
        <br />
        You also have the right to request the deletion of Your Personal Data
        that have been collected in the past 12 months.
        <br />
        The right not to be discriminated against.
        <br />
        You have the right not to be discriminated against for exercising any of
        Your Consumer's rights, including by:
        <br />
        Denying goods or services to You
        <br />
        Charging different prices or rates for goods or services, including the
        use of discounts or other benefits or imposing penalties
        <br />
        Providing a different level or quality of goods or services to You
        <br />
        Suggesting that You will receive a different price or rate for goods or
        services or a different level or quality of goods or services.
        <p>Exercising Your CCPA Data Protection Rights</p>
        <p>
          In order to exercise any of Your rights under the CCPA, and if you are
          a California resident, You can email or call us or visit our "Do Not
          Sell My Personal Information" section or web page.
        </p>
        <p>
          The Company will disclose and deliver the required information free of
          charge within 45 days of receiving Your verifiable request. The time
          period to provide the required information may be extended once by an
          additional 45 days when reasonable necessary and with prior notice.
        </p>
        <p>Do Not Sell My Personal Information</p>
        <p>
          We do not sell personal information. However, the Service Providers we
          partner with (for example, our advertising partners) may use
          technology on the Service that "sells" personal information as defined
          by the CCPA law.
        </p>
        <p>
          If you wish to opt out of the use of your personal information for
          interest-based advertising purposes and these potential sales as
          defined under CCPA law, you may do so by following the instructions
          below.
        </p>
        <p>
          Please note that any opt out is specific to the browser You use. You
          may need to opt out on every browser that you use.
        </p>
      </SubHeadingContent>
      <Heading>Website</Heading>
      <p>
        You can opt out of receiving ads that are personalized as served by our
        Service Providers by following our instructions presented on the
        Service:
      </p>
      <p>
        From Our "Cookie Consent" notice banner
        <br />
        Or from Our "CCPA Opt-out" notice banner
        <br />
        Or from Our "Do Not Sell My Personal Information" notice banner
        <br />
        Or from Our "Do Not Sell My Personal Information" link
        <br />
        The opt out will place a cookie on Your computer that is unique to the
        browser You use to opt out. If you change browsers or delete the cookies
        saved by your browser, you will need to opt out again.
      </p>
      <Heading>Mobile Devices</Heading>
      <p>
        Your mobile device may give you the ability to opt out of the use of
        information about the apps you use in order to serve you ads that are
        targeted to your interests:
      </p>
      <SubHeading>Information Collection:</SubHeading>
      <SubHeadingContent>
        <p>
          Personal Information: We may collect personal information, such as
          your phone number, during registration or password recovery processes
          for our mobile application. This information is necessary to
          facilitate SMS notifications and ensure the security of your account.
        </p>
        <p>
          Opt-In Consent: By providing your phone number and opting to receive
          SMS notifications, you explicitly consent to the collection and use of
          your personal information for this purpose. You may opt-out of
          receiving SMS notifications at any time by following the instructions
          provided in the messages or by contacting our support team.
        </p>
      </SubHeadingContent>
      <SubHeading>Use of Information:</SubHeading>
      <SubHeadingContent>
        <p>
          SMS Notifications
          <br />
          Your phone number will be used to send SMS notifications related to
          account verification, password recovery, and important updates
          regarding our services. These notifications are essential for ensuring
          the security and functionality of our mobile application.
        </p>
        <p>
          Service Communications
          <br />
          We may also use your phone number to communicate with you regarding
          service-related issues, such as account maintenance, technical
          updates, or changes to our Privacy Policy or Terms of Service.
        </p>
      </SubHeadingContent>
      <p>
        "Opt out of Interest-Based Ads" or "Opt out of Ads Personalization" on
        Android devices
      </p>
      <p>
        "Limit Ad Tracking" on iOS devices
        <br />
        You can also stop the collection of location information from Your
        mobile device by changing the preferences on your mobile device.
      </p>
      <p>
        "Do Not Track" Policy as Required by California Online Privacy
        Protection Act (CalOPPA) Our Service does not respond to Do Not Track
        signals.
      </p>
      <p>
        However, some third-party websites do keep track of Your browsing
        activities. If You are visiting such websites, You can set Your
        preferences in Your web browser to inform websites that You do not want
        to be tracked. You can enable or disable DNT by visiting the preferences
        or settings page of Your web browser.
      </p>
      <p>
        Your California Privacy Rights (California's Shine the Light law)
        <br />
        Under California Civil Code Section 1798 (California's Shine the Light
        law), California residents with an established business relationship
        with us can request information once a year about sharing their Personal
        Data with third parties for the third parties' direct marketing
        purposes.
      </p>
      <p>
        If you'd like to request more information under the California Shine the
        Light law, and if you are a California resident, You can contact Us
        using the contact information provided below.
      </p>
      <p>
        California Privacy Rights for Minor Users (California Business and
        Professions Code Section 22581)
      </p>
      <p>
        California Business and Professions Code section 22581 allow California
        residents under the age of 18 who are registered users of online sites,
        services or applications to request and obtain removal of content or
        information they have publicly posted.
      </p>
      <p>
        To request removal of such data, and if you are a California resident,
        You can contact Us using the contact information provided below, and
        include the email address associated with Your account.
      </p>
      <p>
        Be aware that Your request does not guarantee complete or comprehensive
        removal of content or information posted online and that the law may not
        permit or require removal in certain circumstances.
      </p>
      <Heading>Links to Other Websites</Heading>
      <p>
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that
        third party's site. We strongly advise You to review the Privacy Policy
        of every site You visit.
      </p>
      <p>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </p>
      <SubHeading>Changes to this Privacy Policy</SubHeading>
      <p>
        We may update our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page.
      </p>
      <p>
        We will let You know via email and/or a prominent notice on Our Service,
        prior to the change becoming effective and update the "Last updated"
        date at the top of this Privacy Policy.
      </p>
      <p>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>
      <Heading>Contact Us</Heading>
      <p>
        If you have any questions about this Privacy Policy, You can contact us:
      </p>
      <p>
        By email: <a href="mailto:info@kericocare.com">info@kericocare.com</a>
        <br />
        By visiting this page on our website:{" "}
        <a
          href="https://www.kericocare.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.kericocare.com/privacy
        </a>
        <br />
        By phone number: (877) 887-6764
        <br />
        By mail: 6575 WEST LOOP SOUTH Suite 500 Bellaire Texas 77401
      </p>
    </Container>
  );
};
