import { getRoutePreview } from "api/routeApi";
import { useApiRequest } from "api/useApiRequest";
import { useEffect, useState } from "react";

export const useRoute = ({ watch, getValues }) => {
  const { callApiRequest } = useApiRequest();
  const [route, setRoute] = useState([]);
  const departure = watch("departure");
  const destination = watch("destination");
  
  useEffect(() => {
    const { departureTimeMs, departure, destination, rideType } = getValues();
    if (!departure?.id || !destination?.id) return;

    const isScheduled = rideType === "SCHEDULED";

    callApiRequest({
      apiRequest: getRoutePreview,
      params: {
        departureTimeMs: isScheduled
          ? parseInt(new Date(departureTimeMs).getTime().toFixed(0))
          : null,
        departure,
        destination,
      },
      onSuccess: (result) => {
        if (result?.route) {
          setRoute(result.route);
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departure, destination]);

  return {
    route,
  };
};
