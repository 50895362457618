import { useCallback, useContext, useEffect } from "react";
import { GlobalContext } from "store/globalContext";

export const useLocalFormDataSync = ({
  isDirty,
  reset = () => null,
  open = true,
  handleClose = () => null,
}) => {
  const { openConfirmationWithCallback } = useContext(GlobalContext);

  const handleForcedClose = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const handleConfirmedAction = useCallback(
    (callbackFn) => {
      if (isDirty) {
        openConfirmationWithCallback({
          title: "Are you sure?",
          message: "All entered data will be lost.",
          callbackFn,
        });
        return;
      }
      callbackFn();
    },
    [isDirty, openConfirmationWithCallback]
  );

  const handleConfirmedClose = useCallback(() => {
    handleConfirmedAction(handleClose);
  }, [handleClose, handleConfirmedAction]);

  useEffect(() => {
    reset();
  }, [open, reset]);

  return {
    handleConfirmedClose,
    handleForcedClose,
    handleConfirmedAction,
  };
};
