import { createAction, createReducer } from "@reduxjs/toolkit";

/**
 * @template T
 * @typedef {import('types/stateTypes').PayloadPreparator<T>} PayloadPreparator
 */

/**
 * @typedef {import('types/commonTypes').NavigationData} NavigationData
 * @type {NavigationData}
 */
const initialState = {};

export const setNavigationData = createAction(
  "navigationData/set",
  /**
   * @type {PayloadPreparator<NavigationData>}
   */
  (message) => ({ payload: message })
);

export const resetNavigationData = createAction("navigationData/reset");

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(setNavigationData, (state, { payload }) => {
    state.mode = payload?.mode;
    state.search = payload?.search;
    state.selectedId = payload?.selectedId;
    state.targetSettingsTab = payload?.targetSettingsTab;
  });

  builder.addCase(resetNavigationData, () => initialState);
});

export default reducer;
