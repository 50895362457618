import React, { useEffect, useState } from "react";
import { Box, Stack, styled, useTheme } from "@mui/material";
import { useApiRequest } from "api/useApiRequest";
import { getLastRidePosition } from "api/rideApi";
import { RideData } from "./RideData";
import { RouteMapView } from "UI/RouteMapView";
import { customColors } from "models/customColors";
import { ContentLoading } from "SC";
import { UPDATE_DRIVER_POSITION_INTERVAL_IN_MS } from "models/constants";
import { useRideDetails } from "hooks/useRideDetails";

const Container = styled(Stack)`
  padding: 0 20px 0 0;
  max-width: calc(100% - 20px);
  height: 100%;
  overflow-y: auto;
`;

export const RideDetails = ({
  id,
  getClientLabelById,
  refreshList = () => null,
}) => {
  const { callApiRequest } = useApiRequest();
  const [driver, setDriver] = useState(null);
  const theme = useTheme();
  const { ride, loadRideDetails  } = useRideDetails({ id });

  const loadPosition = async (ride) => {
    if (
      !!ride?.status &&
      ["EN_ROUTE", "ARRIVED", "ON_BOARD"].includes(ride.status)
    ) {
      callApiRequest({
        apiRequest: getLastRidePosition,
        params: id,
        skipErrorHandling: true,
        onSuccess: (result) => {
          const newDriverPosition = {
            ...result,
            status: ride?.status,
            driverName: ride?.driver?.driverName,
            carLabel: ride?.vehicle?.label,
          };
          setDriver(newDriverPosition);
        },
        onError: (error) => {
          // @ts-ignore
          // dispatch(showError(error));
          setDriver(null);
        },
      });
    }
  };

  useEffect(() => {
    loadPosition(ride);

    const interval = setInterval(() => {
      loadPosition(ride);
    }, UPDATE_DRIVER_POSITION_INTERVAL_IN_MS);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ride]);

  return (
    <Container gap={2}>
      {!ride?.id ? (
        <ContentLoading />
      ) : (
        <>
          <Box
            flex={1}
            sx={{
              backgroundColor: customColors[theme.palette.mode].strokeColor,
              borderRadius: "20px",
              overflow: "hidden",
              minHeight: "200px",
            }}
          >
            <RouteMapView
              route={ride?.plannedRoute?.route}
              preRoute={
                ride?.status === "EN_ROUTE" ? ride?.dispatchRoute?.route : []
              }
              departure={ride?.departure?.label}
              destination={ride?.destination?.label}
              driver={driver}
            />
          </Box>
          <RideData
            {...ride}
            passengerName={`${ride?.passengerName}, ${ride?.phone} ${
              ride?.email ? `, ${ride?.email}` : ""
            }`}
            getClientLabelById={getClientLabelById}
            refreshData={() => {
              loadRideDetails();
              refreshList();
            }}
          />
        </>
      )}
    </Container>
  );
};
