import { useEffect, useState } from "react";
import { useDataReload } from "hooks/useDataReload";
import { useApiRequest } from "api/useApiRequest";

const DEFAULT_RELOAD_INTERVAL_IN_SEC = 60;

export const useDataGrid = ({
  apiHandler,
  reloadIntervalInSeconds = DEFAULT_RELOAD_INTERVAL_IN_SEC,
  defaultSorter = {
    name: "id",
    order: "DESC",
  },
  defaultLimit = 25,
  additionalParams = {},
  gridConfigIndex,
  withSelector = false,
}) => {
  const defaultVisibility = JSON.parse(
    window.localStorage.getItem(gridConfigIndex) ?? "[]"
  );

  const { callApiRequest } = useApiRequest();
  const [search, setSearch] = useState("");
  const [sorter, setSorter] = useState(defaultSorter);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(defaultLimit);
  const [totalCount, setTotalCount] = useState(0);
  const [rows, setRows] = useState();
  const [visibility, setVisibilityState] = useState(defaultVisibility);

  const [selectedRows, setSelectedRows] = useState([]);

  /** @type {React.Dispatch<any>} */
  const setVisibility = (value) => {
    setVisibilityState(value);
    window.localStorage.setItem(gridConfigIndex, JSON.stringify(value));
  };

  const getApiRequestParams = () => {
    /** @type {import("types/commonTypes").TableRequest} */
    const params = {
      search,
      // @ts-ignore
      sortOrder: sorter.order,
      sortByField: sorter.name,
      pageNum: currentPage - 1,
      recordsPerPage: limit,
    };

    return {
      ...params,
      ...additionalParams,
    };
  };

  const loadData = async () => {
    callApiRequest({
      apiRequest: apiHandler,
      params: getApiRequestParams(),
      onSuccess: (result) => {
        // @ts-ignore
        setRows(result?.entries ?? []);
        setTotalCount((result?.totalPages ?? 0) * limit);
        // setSelectedRows([]);
      },
      onError: (error) => {
        // @ts-ignore
        setRows([]);
        setSelectedRows([]);
      },
    });
  };

  const handleSort = (sortableName) => {
    if (!sortableName) return;

    if (sorter.name === sortableName) {
      setSorter({
        name: sortableName,
        order: sorter.order === "ASC" ? "DESC" : "ASC",
      });
      return;
    }

    setSorter({
      name: sortableName,
      order: "ASC",
    });

    setCurrentPage(1);
  };

  const handleChangeSearch = (e) => {
    const value = e?.target?.value;

    setSearch(value);
    setCurrentPage(1);
  };

  const clearSearch = () => setSearch("");

  const totalPages = Math.ceil(totalCount / limit);

  const handleChangeRecordsPerPage = (e) => {
    const value = e?.target?.value;
    setLimit(value);
    setCurrentPage(1);
  };

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  useDataReload({
    loadData,
    skipFirstLoad: true,
    reloadIntervalInSeconds,
  });

  useEffect(() => {
    if (rows) {
      loadData();
      setSelectedRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorter, currentPage, limit]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      loadData();
      setSelectedRows([]);
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return {
    search,
    setSearch,
    handleChangeSearch,
    clearSearch,
    sorter,
    setSorter,
    handleSort,
    currentPage,
    totalPages,
    handleChangePage,
    handleChangeRecordsPerPage,
    setCurrentPage,
    limit,
    setLimit,
    getApiRequestParams,
    rows,
    loadData,
    totalCount,
    visibility,
    setVisibility,
    defaultLimit,
    withSelector,
    selectedRows,
    setSelectedRows,
  };
};
