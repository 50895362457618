import React, { useEffect, useState } from "react";
import { Box, Divider, Stack, styled } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DialogWindow } from "UI";
import { useApiRequest } from "api/useApiRequest";
import { getDriverQuizResult } from "api/driverApi";
import { DefaultAvatar } from "./DefaultAvatar";
import { Photo } from "./DriverDetails.style";
import { customColors } from "models/customColors";
import { unixTimeToUSDate } from "utils";
import { ReactComponent as RightIcon } from "assets/img/right-icon.svg";
import { ReactComponent as WrongIcon } from "assets/img/wrong-icon.svg";

const Container = styled(Stack)`
  position: relative;
  width: 90vw;
  max-width: 762px;

  height: calc(100vh - 240px);
`;
const Name = styled(Box)`
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
`;
const QuizResult = styled(Box)`
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: ${({ theme }) => customColors[theme.palette.mode].greyText};
`;

const QuizDate = styled(Box)`
  padding: 13px;
  border-radius: 13px;
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].placeHolder};
`;
const SectionName = styled(Box)`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
`;

const StrongText = styled("span")`
  font-weight: bold;
  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
`;

const Answer = styled(Box)`
  text-align: left;
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

  &.wrong {
    color: ${({ theme }) => customColors[theme.palette.mode].error};
  }

  user-select: none;
`;

export const QuizViewer = ({ driver, profileImage, handleClose }) => {
  const { callApiRequest } = useApiRequest();
  const [quizResults, setQuizResults] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadData = async () =>
      callApiRequest({
        apiRequest: getDriverQuizResult,
        params: driver?.id,
        onSuccess: (result) => {
          setQuizResults(result);
          setIsLoading(false);
        },
      });

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const given = quizResults?.sectionResults?.reduce(
    (acc, { given }) => acc + given,
    0
  );
  const count = quizResults?.sectionResults?.reduce(
    (acc, { all }) => acc + all,
    0
  );

  return (
    <DialogWindow
      open={true}
      handleClose={handleClose}
      title={"Drivers Quiz Results"}
      content={
        <Container>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
            spacing={2}
            mb={4}
          >
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems={"center"}
              spacing={1}
            >
              {!profileImage && <DefaultAvatar user={driver} radius="57px" />}
              {profileImage && (
                <Photo
                  sx={{
                    backgroundSize: `${profileImage ? "cover" : "auto"}`,
                    backgroundImage: `url("${profileImage}")`,
                    flex: "0 0 auto",
                  }}
                  onClick={() => null}
                />
              )}
              <Stack spacing={1}>
                <Name>
                  {driver?.firstName ?? "Firstname"}{" "}
                  {driver?.lastName ?? "Lastname"}
                </Name>
                <QuizResult>
                  Quiz Results:{" "}
                  <StrongText>{quizResults?.percent ?? 0}%</StrongText>(
                  <StrongText>{given}</StrongText>/{count})
                </QuizResult>
              </Stack>
            </Stack>
            {quizResults?.quizDate && (
              <QuizDate>{unixTimeToUSDate(quizResults?.quizDate)}</QuizDate>
            )}
          </Stack>
          {isLoading && "Loading..."}
          {!isLoading &&
            quizResults?.sectionResults?.map(
              ({ sectionName, given, all, quizAnswerResultDtoList }) => {
                return (
                  <Accordion
                    key={sectionName}
                    elevation={2}
                    sx={{ borderRadius: 2 }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Stack
                        width={"100%"}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        mr={1}
                      >
                        <SectionName>{sectionName}</SectionName>
                        <QuizResult>
                          <StrongText>{given ?? 0}</StrongText>/{all ?? 0}
                        </QuizResult>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack>
                        {quizAnswerResultDtoList?.map(
                          ({ question, answer, given }, index) => (
                            <React.Fragment key={question ?? answer}>
                              <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                                alignItems={"flex-start"}
                                gap={2}
                                mt={0.5}
                                mb={0.5}
                              >
                                <Answer
                                  flex={1}
                                  className={given ? "" : "wrong"}
                                >
                                  {question ?? answer}
                                </Answer>
                                <Box>
                                  {given ? <RightIcon /> : <WrongIcon />}
                                </Box>
                              </Stack>
                              {index <
                                (quizAnswerResultDtoList?.length ?? 0) - 1 && (
                                <Divider />
                              )}
                            </React.Fragment>
                          )
                        )}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                );
              }
            )}
        </Container>
      }
      withCloseButton
    />
  );
};
