import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { LoginPage } from "pages/LoginPage";
import { PasswordRecoveryPage } from "pages/PasswordRecoveryPage";
import { PasswordChangePage } from "pages/PasswordChangePage";
import { TermsPage } from "pages/TermsPage";
import { AuthCommonLayout } from "components/AuthCommonLayout";
// import { RegistrationPage } from "pages/RegistartionPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthCommonLayout />,
    children: [
      {
        path: "/",
        element: <LoginPage />,
      },
      // {
      //   path: "/register",
      //   element: <RegistrationPage />,
      // },
      {
        path: "/confirm_registration/:token",
        element: <PasswordChangePage />,
      },
      {
        path: "/forgot",
        element: <PasswordRecoveryPage />,
      },
      {
        path: "/password_change/:token",
        element: <PasswordChangePage />,
      },
      {
        path: "/login",
        element: <Navigate to={"/"} />,
      },
      {
        path: "/terms",
        element: <TermsPage />,
      },
      {
        path: "*",
        element: <LoginPage />,
      },
    ],
  },
]);

const Auth = () => <RouterProvider router={router} />;

export default Auth;
