import React from "react";
import { Box, Stack, useTheme } from "@mui/material";
import { customColors } from "models/customColors";
import { removeCountry } from "utils";
import { ReactComponent as DepartureIcon } from "assets/img/departure-icon.svg";
import { ReactComponent as DestinationIcon } from "assets/img/destination-icon.svg";
import { TextFieldWithEllipsis } from "./TextFiledWithEllipsis";

const namesSx = {
  fontFamily: "Inter",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "18px",
  letterSpacing: "0px",
  textAlign: "left",
};

export const RideInfoField = ({
  companyName,
  passengerName,
  departureLabel,
  destinationLabel,
  region,
}) => {
  const theme = useTheme();

  const addressSx = {
    fontSize: "12px",
    color: customColors[theme.palette.mode].greyText,
  };

  return (
    <Stack gap={0.75}>
      <TextFieldWithEllipsis sx={namesSx}>
        {companyName}, {region} &bull; Patient: {passengerName}
      </TextFieldWithEllipsis>
      <Stack direction={"row"} alignItems={"center"} gap={0.5}>
        <DepartureIcon width={"16px"} />
        <TextFieldWithEllipsis maxLength={40} sx={addressSx}>
          {removeCountry(departureLabel)}
        </TextFieldWithEllipsis>
        <Box sx={{ width: "30px" }}>-{">"}</Box>
        <DestinationIcon />
        <TextFieldWithEllipsis maxLength={40} sx={addressSx}>
          {removeCountry(destinationLabel)}
        </TextFieldWithEllipsis>
      </Stack>
    </Stack>
  );
};
