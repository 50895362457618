import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Stack } from "@mui/material";
import { DialogWindow } from "UI/DialogWindow";
import { required } from "models/validations";
import { useApiRequest } from "api/useApiRequest";
import { useFormDataSync } from "hooks/useFormDataSync";
import {
  getStatusAssingByAdminByRideId,
} from "api/rideApi";
import { FormTextField } from "UI";
import { GlobalContext } from "store/globalContext";
import { ReactComponent as DoneIcon } from "assets/img/booking-scheduled-icon.svg";

const defaultValues = {
  status: "COMPLETED",
  comment: "",
};

export const CompleteBookingDialog = ({
  open,
  handleClose,
  refreshData,
  rideId,
}) => {
  const { callApiRequest } = useApiRequest();
  const { openConfirmationWithCallback, showWarning } =
    useContext(GlobalContext);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues,
  });

  const { handleConfirmedClose, handleForcedClose } = useFormDataSync({
    isDirty,
    handleClose,
    open,
    reset,
  });

  const onSubmit = async (params) =>
    openConfirmationWithCallback({
      title: "Ride completion",
      message: "Are you sure you want to complete this ride?",
      callbackFn: () => {
        callApiRequest({
          apiRequest:  getStatusAssingByAdminByRideId(rideId),
          params,
          onSuccess: () => {
            showWarning({
              title: "Ride Completed",
              content: "The ride has been successfully completed",
              icon: <DoneIcon />,
            });
            refreshData();
            handleForcedClose();
          },
        });
      },
      buttonLabels: ["No", "Yes"],
    });

  useEffect(() => {
    reset(defaultValues);
  }, [open, reset]);

  return (
    <DialogWindow
      open={open}
      withCloseButton
      handleClose={handleConfirmedClose}
      title={`Ride Completion`}
      content={
        <form>
          <Stack mt={2} gap={3} width={"100%"}>
            <Box>Please provide specific reason for completion of the ride</Box>
            <Stack gap={2}>
              <Stack alignItems={"flex-start"}>
                <FormTextField
                  id="comment"
                  name="comment"
                  control={control}
                  rules={{ required }}
                  InputProps={{
                    autoComplete: "off",
                  }}
                />
              </Stack>
              <Stack direction={"row"} justifyContent={"center"} gap={1}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleConfirmedClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleSubmit(onSubmit)}
                >
                  Complete Ride
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      }
    />
  );
};
