import React, { useEffect, useRef } from "react";
import { Button, Stack, styled } from "@mui/material";
import { DialogWindow } from "UI";

const Container = styled(Stack)`
  position: relative;
`;

const ImageHolder = styled(Stack)`
  max-width: 80vw;
  max-height: 70vh;

  overflow: auto;
`;

const ButtonsHolder = styled(Stack)`
  position: absolute;
  top: 5px;
  right: 25px;
  flex-direction: row;
  justify-content: flex-end;
  gap: 5px;

  z-index: 1000;
`;

const ControlButton = styled(Button)`
  color: black;
  background-color: #ffffff60;
  &:hover {
    background-color: #fffffff0;
  }
  border-radius: 30px;
`;

const Image = styled("img")`
  /* width: fit-content;
  height: fit-content; */
`;

export const ImageViewer = ({ image, setImage, imageTitle }) => {
  const currentImageRef = useRef(null);
  const frameRef = useRef(null);

  const fitImageToFrame = () => {
    const frame = frameRef.current;
    const currentImage = currentImageRef.current;
    if (!frame || !currentImage) return;

    const frameRect = frame.getBoundingClientRect();
    const currentImageRect = currentImage.getBoundingClientRect();
    const isPortrait = currentImageRect.height > currentImageRect.width;

    if (isPortrait) {
      currentImage.style.height = `${frameRect.height}px`;
      currentImage.style.width = `auto`;
    } else {
      const heightScale = frameRect.height / currentImageRect.height;
      const scaledWidth = currentImageRect.height * heightScale;
      if (scaledWidth > frameRect.width) {
        currentImage.style.width = `${frameRect.width}px`;
        currentImage.style.height = `auto`;
      } else {
        currentImage.style.height = `${frameRect.height}px`;
        currentImage.style.width = `auto`;
      }
    }
  };

  const scaleToHalfSize = () => {
    const currentImage = currentImageRef.current;
    currentImage.style.width = "fit-content";
    currentImage.style.height = "fit-content";

    const currentImageRect = currentImage.getBoundingClientRect();
    const isPortrait = currentImageRect.height > currentImageRect.width;
    if (isPortrait) {
      currentImage.style.height = `${currentImageRect.height / 2}px`;
      currentImage.style.width = "auto";
    } else {
      currentImage.style.width = `${currentImageRect.width / 2}px`;
      currentImage.style.height = "auto";
    }
  };

  const scaleToRealSize = () => {
    const currentImage = currentImageRef.current;
    currentImage.style.width = "fit-content";
    currentImage.style.height = "fit-content";
  };

  useEffect(() => {
    const FIT_TO_FRAME_DELAY_IN_MS = 200;
    if (image) {
      setTimeout(() => fitImageToFrame(), FIT_TO_FRAME_DELAY_IN_MS);
    }
  }, [image]);

  return (
    <DialogWindow
      open={image}
      handleClose={() => setImage("")}
      title={imageTitle}
      content={
        <Container ref={frameRef}>
          <ButtonsHolder>
            <ControlButton onClick={fitImageToFrame}>Fit</ControlButton>
            <ControlButton onClick={scaleToHalfSize}>50%</ControlButton>
            <ControlButton onClick={scaleToRealSize}>100%</ControlButton>
          </ButtonsHolder>
          <ImageHolder>
            <Image ref={currentImageRef} src={image} alt="" />
          </ImageHolder>
        </Container>
      }
      withCloseButton
    />
  );
};
