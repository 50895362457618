import React from "react";
import { Box, Button, Stack, styled } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { customColors } from "models/customColors";

export const Title = styled(Box)`
  font-family: "Inter Semi Bold";
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;

  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
`;

export const Info = styled(Box)`
  font-family: "Inter";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${({ theme }) => customColors[theme.palette.mode].greyText};
`;

const Link = styled(RouterLink)`
  text-decoration: none;
`;

export const SubMenuContent = ({ title, info, buttonLabel, path, onClick }) => {
  return (
    <Stack gap={2} ml={"40px"}>
      <Title>{title}</Title>
      <Info>{info}</Info>
      {onClick ? (
        <Box>
          <Button variant="contained" onClick={onClick}>
            {buttonLabel}
          </Button>
        </Box>
      ) : (
        <Link to={path}>
          <Button variant="contained">{buttonLabel}</Button>
        </Link>
      )}
    </Stack>
  );
};
