import { Box, Stack, styled, MenuItem } from "@mui/material";
import { customColors } from "models/customColors";
import { FormSelectField } from "UI/FormSelectField";

export const Container = styled(Stack)`
  padding: 10px;
  
  overflow-y: auto;
`;

export const Photo = styled(Box)`
  width: 57px;
  height: 57px;

  border-radius: 50%;

  background-repeat: "no-repeat";
  background-position: "center";

  cursor: pointer;
`;

export const Title = styled(Box)`
  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
`;

export const Detail = styled(Stack)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const Label = styled(Box)`
  color: ${({ theme }) => customColors[theme.palette.mode].greyText};
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
`;

export const Value = styled(Box)`
  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
`;

export const CarData = styled(Stack)`
  padding: 10px 20px;
  border-radius: 16px;
  border: 1px solid
    ${({ theme }) => customColors[theme.palette.mode].additionalStroke};
  background: ${({ theme }) => customColors[theme.palette.mode].background};
`;

export const StatisticItem = styled(Stack)`
  flex: 1 1 auto;
  padding: 10px;
  align-items: center;
  border-radius: 13px;
  border: 1px solid
    ${({ theme }) => customColors[theme.palette.mode].additionalStroke};
  background: ${({ theme }) => customColors[theme.palette.mode].background};
`;

export const StatisticLabel = styled(Box)`
  color: ${({ theme }) => customColors[theme.palette.mode].placeHolder};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`;

export const StatisticValue = styled(Box)`
  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.48px;
`;

export const ActiveSelector = styled(FormSelectField)`
  width: 110px;
  & > div {
    background-color: transparent;
  }

  & #status {
    font-weight: bold;
    color: ${({ theme }) => customColors[theme.palette.mode].success};
    background-color: ${({ theme }) =>
      `${customColors[theme.palette.mode].success}29`};
  }
`;

export const InactiveSelector = styled(ActiveSelector)`
  & #status {
    font-weight: bold;
    color: ${({ theme }) => customColors[theme.palette.mode].greyText};
    background-color: ${({ theme }) =>
      `${customColors[theme.palette.mode].greyText}29`};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  color: ${({ theme }) => customColors[theme.palette.mode].error};
`;

export const RejectReason = styled(Box)`
  color: ${({ theme }) => customColors[theme.palette.mode].error};
`;

export const OffShiftStatus = styled(Box)`
  padding: 10px 30px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  color: ${({ theme }) => customColors[theme.palette.mode].greyText};
  background-color: ${({ theme }) =>
    `${customColors[theme.palette.mode].greyText}29`};
`;

export const OnShiftStatus = styled(OffShiftStatus)`
  color: ${({ theme }) => customColors[theme.palette.mode].success};
  background-color: ${({ theme }) =>
    `${customColors[theme.palette.mode].success}29`};
`;

export const ApprovalIndicator = styled(OffShiftStatus)`
  color: ${({ theme }) => customColors[theme.palette.mode].new};
  background: ${({ theme }) => customColors[theme.palette.mode].newBackground};
`;
