import { createTheme, responsiveFontSizes } from "@mui/material";
import { customColors } from "models/customColors";

/** @param {"dark" | "light"} mode */
export const createThemeByMode = (mode = "light") =>
  responsiveFontSizes(
    createTheme({
      typography: {
        fontFamily: ["Inter", "sans-serif"].join(","),
      },
      palette: {
        // primary: {
        //   main: "rgba(114, 118, 168, 1)",
        //   dark: "rgba(93, 96, 221, 1)",
        // },
        // secondary: {
        //   light: "#ff7961",
        //   main: "#F47969",
        //   dark: "#EE513C",
        //   contrastText: "#fff",
        // },
        text: {
          primary: customColors[mode].textBody,
        },
        mode,
      },
      components: {
        MuiButton: {
          defaultProps: {
            disableElevation: true,
          },
          styleOverrides: {
            root: {
              borderRadius: 10,
              minHeight: 40,
              textTransform: "none",
              fontSize: 14,
            },
          },
        },
        MuiDialog: {
          styleOverrides: {
            paper: {
              borderRadius: "1rem",
              gap: "20px",
              maxHeight: "calc(100% - 20px)",
              maxWidth: "unset",
              fontSize: "24px",
              backgroundColor: customColors[mode].background,
              backgroundImage: "unset",
            },
          },
        },
        MuiDialogTitle: {
          styleOverrides: {
            root: {
              textAlign: "center",
              fontWeight: "bold",
              padding: "35px 80px",
              fontSize: "24px",
              backgroundColor: customColors[mode].inputBack,
            },
          },
        },
        MuiDialogContent: {
          styleOverrides: {
            root: {
              textAlign: "center",
              fontSize: "1.1rem",
              padding: "0 80px",
            },
          },
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              justifyContent: "center",
              alignItems: "center",
              padding: "0 80px 30px 80px",
            },
          },
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              backgroundColor: "#284A70",
              borderRadius: "20px",
              padding: "8px 15px",
              border: "1px solid #284A70",
            },
            arrow: {
              color: "#284A70",
            },
          },
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              "& .MuiInputBase-root": {
                "& fieldset": {
                  borderColor: "transparent",
                },
              },
              borderRadius: "8px",
              "& .MuiInputBase-root.Mui-disabled": {
                "& > fieldset": {
                  borderColor: "transparent",
                },
              },
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              "& input": {
                borderColor: "transparent",
              },
              "& fieldset": {
                borderColor: "transparent",
              },

              borderRadius: "8px",
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              borderRadius: "6px",
              backgroundColor: customColors[mode].inputBack,
              borderColor: "transparent",
            },
          },
        },
        MuiFormLabel: {
          styleOverrides: {
            root: {
              fontSize: "16px",
              color: customColors[mode].textBody,
            },
          },
        },
      },
    })
  );
