import React, { useContext, useState } from "react";
import {
  Button,
  Stack,
  styled,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import * as SC from "SC";
import { BackButton } from "components/BackButton";
import { useDataGrid } from "hooks/useDataGrid";
import { deleteClientUserById, getClientUsers } from "api/userApi";
import { useAppSelector } from "store/hooks";
import { DataGrid } from "UI/DataGrid";
import { TextFieldWithEllipsis } from "components/TextFiledWithEllipsis";
import { convertToReadable } from "utils";
import { AddClientUserDialog } from "components/AddClientUserDialog";
import { EditClientUserDialog } from "components/EditClientUserDialog";
import { customColors } from "models/customColors";
import { GlobalContext } from "store/globalContext";
import { useApiRequest } from "api/useApiRequest";
import { ReactComponent as CompleteIcon } from "assets/img/complete-icon.svg";

export const StyledMenuItem = styled(MenuItem)`
  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
`;

export const DeleteMenuItem = styled(MenuItem)`
  color: ${({ theme }) => customColors[theme.palette.mode].error};
`;

const Content = styled(Stack)`
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 10px;

  height: 100%;

  overflow-y: auto;
  overflow-x: hidden;
`;

export const ManageClientUsers = () => {
  const { showWarning, openConfirmationWithCallback } =
    useContext(GlobalContext);
  const { callApiRequest } = useApiRequest();

  const userId = useAppSelector((state) => state.user.id);
  const companyId = useAppSelector((state) => state.user.companyId);
  const [currentUser, setCurrentUser] = useState();
  const [isAddUserVisible, setIsAddUserVisible] = useState(false);
  const [menuRow, setMenuRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event, row) => {
    setMenuRow(row);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const editUser = (row) => {
    const convertedData = {
      ...row,
    };
    if (row?.phone?.length !== 12) {
      convertedData.phone = "";
    }
    setCurrentUser(convertedData);
  };

  const handleDeleteUser = (row) =>
    openConfirmationWithCallback({
      title: "Are you sure?",
      message: <>This user {row?.firstName} {row?.lastName} will be completely removed. Proceed</>,
      buttonLabels: ["No", "Yes"],
      callbackFn: () =>
        callApiRequest({
          apiRequest: deleteClientUserById,
          params: row?.id,
          onSuccess: () => {
            showWarning({
              title: "User Removed",
              content: `The user was successfully removed.`,
              icon: <CompleteIcon />,
            });
          },
        }),
    });

  const columns = [
    {
      label: "Firstname",
      field: "firstName",
      xs: 2,
      sm: 2,
      md: 2,
      sortableName: "FIRST_NAME",
    },
    {
      label: "Lastname",
      field: "lastName",
      xs: 2,
      sm: 2,
      md: 2,
      sortableName: "LAST_NAME",
    },
    {
      label: "Role",
      field: "role",
      xs: 2.5,
      sm: 2.5,
      md: 2.5,
      sortableName: "ROLE",
      renderer: ({ role }) => convertToReadable(role),
    },
    {
      label: "Email",
      field: "email",
      xs: 2.75,
      sm: 2.75,
      md: 2.75,
      sortableName: "EMAIL",
      renderer: ({ email }) => (
        <TextFieldWithEllipsis>{email}</TextFieldWithEllipsis>
      ),
    },
    {
      label: "Phone Number",
      field: "phone",
      xs: 2,
      sm: 2,
      md: 2,
      sortableName: "PHONE",
    },
    {
      label: "Actions",
      xs: 0.75,
      sm: 0.75,
      md: 0.75,
      renderer: (row) => (
        <Stack justifyContent={"center"} alignItems={"center"} mt={1}>
          <IconButton onClick={(e) => handleMenuClick(e, row)}>
            <MoreHorizIcon />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const dataGrid = useDataGrid({
    apiHandler: getClientUsers,
    defaultSorter: {
      name: "FIRST_NAME",
      order: "ASC",
    },
    defaultLimit: 25,
    gridConfigIndex: `${userId}-client-users`,
  });

  return (
    <SC.Container>
      <SC.Layout>
        <Stack direction={"row"} alignItems={"center"}>
          <BackButton />
          <SC.Title>Manage Users</SC.Title>
          <Button
            variant="contained"
            onClick={() => setIsAddUserVisible(true)}
            sx={{ marginLeft: "1rem" }}
          >
            Add User
          </Button>
        </Stack>
        <Content>
          <DataGrid
            columns={columns}
            handleRowClick={() => null}
            {...dataGrid}
          />
        </Content>
      </SC.Layout>
      <AddClientUserDialog
        open={isAddUserVisible}
        handleClose={() => setIsAddUserVisible(false)}
        refreshList={dataGrid.loadData}
        clientId={companyId}
      />
      <EditClientUserDialog
        currentUser={currentUser}
        handleClose={() => setCurrentUser(undefined)}
        refreshList={dataGrid.loadData}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <StyledMenuItem onClick={() => editUser(menuRow)}>
          Edit user details
        </StyledMenuItem>
        <DeleteMenuItem onClick={() => handleDeleteUser(menuRow)}>
          Delete user
        </DeleteMenuItem>
      </Menu>
    </SC.Container>
  );
};
