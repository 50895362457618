import { Box, Grid, Stack, styled } from "@mui/material";
import { customColors } from "models/customColors";

export const responsiveFontSize = `
  font-family: Inter;
  font-size: 14px;
    
  @media (max-width: 1600px) {
    font-size: 13px;
  }

  @media (max-width: 1440px) {
    font-size: 12px;
  }

  @media (max-width: 1280px) {
    font-size: 10px;
  }
`;

export const RecordsHolder = styled(Grid)`
  flex: 1 1 auto;

  overflow-y: scroll;
  overflow-x: hidden;
`;

export const RecordsGrid = styled(Box)`
  border-left: thin solid
    ${({ theme }) => customColors[theme.palette.mode].borderColor};
  border-right: thin solid
    ${({ theme }) => customColors[theme.palette.mode].borderColor};
`;

export const Record = styled(Grid)`
  padding: 12px 24px;

  display: flex;
  flex-direction: row;
  align-items: center;

  &.selected {
    background-color: ${({ theme }) =>
      customColors[theme.palette.mode].actionColor}10;
  }

  &:hover {
    background-color: ${({ theme }) =>
      customColors[theme.palette.mode].additionalStroke};
  }

  border-bottom: thin solid
    ${({ theme }) => customColors[theme.palette.mode].borderColor};
  cursor: pointer;
`;

export const RecordItem = styled(Grid)`
  padding: 2px 5px 2px 5px;
  color: ${customColors.text};
  font-family: Inter;
  font-style: normal;
  font-weight: 400;

  word-wrap: break-word;

  ${responsiveFontSize}
`;

export const Header = styled(Grid)`
  padding: 16px 40px 16px 24px;

  display: flex;
  flex-direction: row;
  align-items: center;

  border: thin solid
    ${({ theme }) => customColors[theme.palette.mode].borderColor};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  font-weight: bold;

  &:hover {
    color: ${customColors.selector};
  }
`;

export const HeaderItemWithoutSorter = styled(Stack)`
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  padding: 0 5px 0 5px;

  font-family: "Inter Semi Bold";
  font-style: normal;
  line-height: 18px; /* 112.5% */

  color: ${customColors.gridHeaderText};
  user-select: none;

  font-family: "Inter Semi Bold";

  word-wrap: break-word;
  word-break: break-all;

  ${responsiveFontSize}
`;

export const HeaderItem = styled(HeaderItemWithoutSorter)`
  cursor: pointer;

  &:hover {
    color: ${customColors.mainColor};
  }
`;

export const PaginationContainer = styled(Stack)`
  padding: 5px 20px;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  border: thin solid
    ${({ theme }) => customColors[theme.palette.mode].borderColor};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const SelectedRowsCounter = styled(Stack)``;

export const RecordsPerPageSelector = styled(Stack)`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;

  font-family: Inter;
  font-size: 12px;
  font-style: normal;
`;

export const NoDataHolder = styled(Stack)`
  flex: 1 1 auto;
  height: 50%;
  min-height: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  color: ${customColors.grayText};
`;
