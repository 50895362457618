import React, { useState } from "react";
import { Stack } from "@mui/material";
import * as SC from "SC";
import { useDataGrid } from "hooks/useDataGrid";
import { useAppSelector } from "store/hooks";
import { DataGrid } from "UI/DataGrid";
import * as FP from "utils/fp-js";
import {
  getInactiveVehicles,
  getKericoVehicles,
  getVehicles,
} from "api/vehicleApi";
import { CarDetails } from "components/CarDetails";
import { CloseWindowButton } from "UI/CloseWindowButton";
import { FleetTableModeSelector } from "components/FleetTableModeSelector";
import {
  Content,
  privateCarsColumns,
  getPrivateCarsShortColumns,
  kericoCarsColumns,
  getKericoCarsShortColumns,
  inactiveCarsColumns,
  getInactiveCarsShortColumns,
} from "./FleetPage.data";
import { useNavigationData } from "hooks/useNavigationData";

/** @type {import("types/commonTypes").FleetMode} */
const defaultMode = "PRIVATE_CARS";

export const FleetPage = () => {
  const userId = useAppSelector((state) => state.user.id);
  const [selectedCarId, setSelectedCarId] = useState("");
  const [mode, setMode] = useState(
    /** @type {import("types/commonTypes").FleetMode} */ defaultMode
  );

  const privateCarsShortColumns = getPrivateCarsShortColumns(selectedCarId);
  const privateCarsDataGrid = useDataGrid({
    apiHandler: getVehicles,
    defaultSorter: {
      name: "FULL_NAME",
      order: "ASC",
    },
    defaultLimit: 25,
    gridConfigIndex: `${userId}-fleet`,
  });

  const kericoCarsShortColumns = getKericoCarsShortColumns(selectedCarId);
  const kericoCarsDataGrid = useDataGrid({
    apiHandler: getKericoVehicles,
    defaultSorter: {
      name: "FULL_NAME",
      order: "ASC",
    },
    defaultLimit: 25,
    gridConfigIndex: `${userId}-kerico-fleet`,
  });

  const inactiveCarsShortColumns = getInactiveCarsShortColumns(selectedCarId);
  const inactiveCarsDataGrid = useDataGrid({
    apiHandler: getInactiveVehicles,
    defaultSorter: {
      name: "FULL_NAME",
      order: "ASC",
    },
    defaultLimit: 25,
    gridConfigIndex: `${userId}-inactive-fleet`,
  });

  const handleRowClick = (row) => {
    if (selectedCarId === row?.carId) {
      setSelectedCarId("");
      return;
    }

    setSelectedCarId(row?.carId ?? "");
  };

  useNavigationData({ setMode, setSearch: inactiveCarsDataGrid.setSearch });

  return (
    <SC.Container>
      <Stack direction={"row"} height={"100%"} position={"relative"}>
        <SC.Layout sx={{ flex: 1 }}>
          {/* <Stack direction={"row"} alignItems={"center"}>
            <SC.Title>Fleet Management</SC.Title>
          </Stack> */}
          <Content>
            {FP.match(mode)
              .on(
                "PRIVATE_CARS",
                <DataGrid
                  columns={
                    selectedCarId ? privateCarsShortColumns : privateCarsColumns
                  }
                  handleRowClick={handleRowClick}
                  {...privateCarsDataGrid}
                  selectedId={selectedCarId}
                  keyName="carId"
                  actions={
                    <FleetTableModeSelector
                      mode={mode}
                      setMode={setMode}
                      privateCarsDataGrid={privateCarsDataGrid}
                      kericoCarsDataGrid={kericoCarsDataGrid}
                      inactiveCarsDataGrid={inactiveCarsDataGrid}
                      setSelectedCarId={setSelectedCarId}
                    />
                  }
                />
              )
              .on(
                // @ts-ignore
                "KERICO_CARS",
                <DataGrid
                  columns={
                    selectedCarId ? kericoCarsShortColumns : kericoCarsColumns
                  }
                  handleRowClick={handleRowClick}
                  {...kericoCarsDataGrid}
                  selectedId={selectedCarId}
                  keyName="carId"
                  actions={
                    <FleetTableModeSelector
                      mode={mode}
                      setMode={setMode}
                      privateCarsDataGrid={privateCarsDataGrid}
                      kericoCarsDataGrid={kericoCarsDataGrid}
                      inactiveCarsDataGrid={inactiveCarsDataGrid}
                      setSelectedCarId={setSelectedCarId}
                    />
                  }
                />
              )
              .otherwise(
                <DataGrid
                  columns={
                    selectedCarId
                      ? inactiveCarsShortColumns
                      : inactiveCarsColumns
                  }
                  handleRowClick={handleRowClick}
                  {...inactiveCarsDataGrid}
                  selectedId={selectedCarId}
                  keyName="carId"
                  actions={
                    <FleetTableModeSelector
                      mode={mode}
                      setMode={setMode}
                      privateCarsDataGrid={privateCarsDataGrid}
                      kericoCarsDataGrid={kericoCarsDataGrid}
                      inactiveCarsDataGrid={inactiveCarsDataGrid}
                      setSelectedCarId={setSelectedCarId}
                    />
                  }
                />
              )}
          </Content>
        </SC.Layout>
        {selectedCarId && (
          <>
            <CloseWindowButton
              top={-35}
              right={-35}
              closeHandler={() => setSelectedCarId("")}
            />
            <CarDetails
              carId={selectedCarId}
              closeDetails={() => setSelectedCarId("")}
              refreshList={inactiveCarsDataGrid.loadData}
              refreshKericoList={kericoCarsDataGrid.loadData}
            />
          </>
        )}
      </Stack>
    </SC.Container>
  );
};
