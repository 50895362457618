import {
  Box,
  Button,
  Stack,
  Typography,
  styled,
  css,
  InputLabel as MuiLabel,
  Paper,
  IconButton,
} from "@mui/material";
import { customColors } from "models/customColors";
import { Link as RouterLink } from "react-router-dom";

export const globalStyles = css`
  @font-face {
    font-family: "Inter";
    font-weight: 400;
    src: url(/fonts/Inter-Regular.ttf) format("truetype");
  }

  @font-face {
    font-family: "Inter Medium";
    font-weight: 500;
    src: url(/fonts/Inter-Medium.ttf) format("truetype");
  }

  @font-face {
    font-family: "Inter Semi Bold";
    font-weight: 600;
    src: url(/fonts/Inter-SemiBold.ttf) format("truetype");
  }

  @font-face {
    font-family: "Inter Bold";
    font-weight: 700;
    src: url(/fonts/Inter-Bold.ttf) format("truetype");
  }

  body {
    font-family: "Inter", sans-serif;
  }
`;

export const OutletContainer = styled(Stack)`
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 28px;

  overflow-y: auto;
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].tableBackground};
`;

export const DialogButton = styled(Button)`
  font-size: 1.2rem;
  min-height: 3rem;
  width: 100%;
`;

export const EmptySpace = styled(Box)`
  height: 1rem;
`;

export const Title = styled(Box)`
  font-family: "Inter Bold";
  font-size: 24px;
  letter-spacing: 0em;
  text-align: left;
`;

export const HighlightedText = styled(`span`)`
  margin: 0 0.3rem;
  color: ${customColors.light.text.highlighted};
`;

export const InactiveText = styled(Box)`
  color: ${customColors.light.text.inactive};
`;

export const PlainText = styled("span")`
  color: ${customColors.light.text.plain};
  font-size: 18px;
  font-family: Inter;
  font-weight: 400;
  text-align: center;
  word-wrap: break-word;

  color: ${({ theme }) => customColors[theme.palette.mode].greyText};
`;

export const TitleSx = {
  height: 40,
  fontSize: {
    lg: 24,
    md: 22,
    sm: 17,
    xs: 17,
  },
};

export const BackButtonContent = styled(Stack)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  & path {
    fill: ${({ theme }) => customColors[theme.palette.mode].greyText};
  }
`;

export const BackButtonContentSx = {
  fontSize: {
    lg: 23,
    md: 20,
    sm: 17,
    xs: 17,
  },
};

export const Text = styled(Box)`
  color: #284a70;
  font-weight: normal;
  opacity: 0.6;
  font-size: 1.1rem;
`;

export const TextSx = {
  fontSize: {
    lg: 20,
    md: 16,
    sm: 16,
    xs: 16,
  },
};

export const ControlButton = styled(Button)`
  padding: 5px 30px;
  font-size: 1.1rem;
  min-height: 2.8rem;
  width: 100%;
`;

export const AuthFormContainer = styled(Stack)`
  margin-left: 10%;
  margin-right: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;

  color: ${({ theme }) => theme.palette.primary.main};

  @media screen and (orientation: portrait) and (max-device-width: 1024px) {
    margin: 0 1rem;
    width: calc(100% - 2rem);
  }
`;

export const AuthTitle = styled(Title)`
  width: 100%;
  text-align: center;
  font-size: 36px;
  color: ${({ theme }) => customColors[theme.palette.mode].textBody};
  word-wrap: break-word;
`;

export const AuthTitleSx = {
  marginBottom: 1,
  minHeight: 40,
  fontFamily: "Inter",
};

export const AuthLabel = styled(Box)`
  @media screen and (orientation: portrait) and (max-device-width: 1024px) {
    font-size: 0.8rem;
  }
`;

export const Terms = styled(RouterLink)`
  text-decoration: unset;
  color: #1173f5;

  @media screen and (orientation: portrait) and (max-device-width: 1024px) {
    font-size: 0.8rem;
  }
`;

export const AuthLink = styled(RouterLink)`
  text-decoration: unset;
  color: #1173f5;
`;

export const DialogMainButton = styled(Button)`
  font-size: 15px;
  height: 48px;
  padding: 12px 80px;
  border-radius: 12px;
  gap: 10px;

  @media screen and (orientation: portrait) and (max-device-width: 1024px) {
    padding: 12px;
    width: 100%;
  }
`;

export const MainButton = styled(DialogMainButton)`
  width: 400px;
  padding: 12px 24px 12px 24px;

  @media screen and (orientation: portrait) and (max-device-width: 1024px) {
    padding: 12px;
    width: 100%;
  }
`;

export const MainButtonLabel = styled(Typography)`
  font-size: 15px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

export const Header = styled(Box)`
  font-size: 32px;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 0.30000001192092896px;
  text-align: center;
`;

export const Message = styled(Box)`
  max-width: 433px;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
`;

export const CommonButton = styled(Button)`
  /* font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em; */
  /* text-align: left; */
`;

export const SimpleButton = styled(Button)`
  /* font-size: 1rem; */
  align-self: flex-start;
  /* font-weight: normal; */
`;

export const AddItemButton = styled(CommonButton)`
  /* font-size: 14px;
  text-align: left; */
  color: #007aff;
`;

export const EditItemButton = styled(Button)`
  margin: 0;
  padding: 4px 10px;
  /* font-size: 14px;
  font-weight: normal; */
`;

export const EditMobileItemButton = styled(Button)`
  margin: 0;
  padding: 5px 7px;
  font-size: 0.75rem;
  font-weight: normal;
`;

export const DeleteItemButton = styled(AddItemButton)`
  margin-top: 0;
  color: red;
`;

export const OutletHeader = styled(Stack)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  @media screen and (orientation: portrait) and (max-device-width: 1024px) {
    font-size: 0.8rem;
  }
`;

export const AddNewItemButton = styled(Button)`
  flex: 1 1 auto;

  border-radius: 12px;
  & p {
    font-weight: normal;
  }
`;

export const ContentPanel = styled(Box)`
  border: solid thin ${customColors.light.border};
  border-radius: 15px;

  background-color: white;
`;

export const Info = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 1em;
`;

export const InputLabel = styled(MuiLabel)`
  margin-left: 0.3em;
`;

export const MoveOutDateTime = styled(MuiLabel)`
  margin-top: 0.4em;
  margin-left: 0.3em;
  color: ${customColors.light.text.highlighted};
`;

export const Container = styled(Paper)`
  flex: 1 1 auto;
  padding: 40px;
  width: calc(100% - 80px);
  height: calc(100% - 80px);

  border-radius: 20px;
`;

export const OverflowedContainer = styled(Paper)`
  flex: 1 0 auto;
  padding: 40px;
  width: calc(100% - 80px);
  min-height: calc(100% - 80px);

  border-radius: 20px;
`;

export const Submenu = styled(Stack)`
  align-items: flex-start;

  padding: 14px 21px;

  font-family: "Inter Semi Bold";
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: left;

  color: ${({ theme }) => customColors[theme.palette.mode].iconColor};
  border-left: 4px solid transparent;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: ${({ theme }) => customColors[theme.palette.mode].actionColor};
  }
`;

export const ActiveSubmenu = styled(Submenu)`
  align-items: flex-start;

  color: ${({ theme }) => customColors[theme.palette.mode].menu.active};
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].menu.activeBackground};
  border-left: 4px solid
    ${({ theme }) => customColors[theme.palette.mode].menu.active};

  &:hover {
    color: ${({ theme }) => customColors[theme.palette.mode].menu.active};
  }

  cursor: default;
`;

export const Link = styled(RouterLink)`
  text-decoration: none;
`;

export const Layout = styled(Stack)`
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  height: 100%;

  gap: 28px;
`;

export const ContentLoading = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].strokeColor};
  border-radius: 20px;
  overflow: hidden;
`;

export const HalfScreenPanel = styled(Stack)`
  flex: 0 0 auto;
  width: calc(50% - 20px);
`;

export const ActionButton = styled(IconButton)`
  padding: 0 4px;
  flex: 0 0 auto;
  min-width: 32px;
  height: 32px;

  font-size: 0.75rem;

  border: thin solid
    ${({ theme }) => customColors[theme.palette.mode].borderColor};
  border-radius: 6px;

  & path {
    fill: ${({ theme }) => customColors[theme.palette.mode].placeHolder};
  }

  &.active path {
    fill: ${({ theme }) => customColors[theme.palette.mode].actionColor};
  }
`;
