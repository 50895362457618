import { Box, Stack, styled } from "@mui/material";
import { customColors } from "models/customColors";

export const Container = styled(Stack)`
  justify-content: space-between;
  align-items: stretch;

  width: 100%;
  height: 100%;

  font-size: 14px;
  overflow: hidden;
`;

export const Chat = styled(Stack)`
  flex: 1 1 auto;
  gap: 5px;

  padding: 0 10px;
  height: 450vh;

  overflow-y: auto;
  overflow-x: hidden;
`;

export const IncomingHolder = styled(Stack)`
  margin-top: 5px;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  gap: 5px;
`;

export const OutgoingHolder = styled(Stack)`
  justify-content: flex-start;
  align-items: flex-end;
  text-align: left;
  gap: 3px;
`;

export const Message = styled(Box)`
  padding: 10px 30px;
  width: fit-content;
  max-width: 75%;

  font-size: 1rem;
  text-align: inherit;

  border-radius: 16px;

  word-wrap: break-word;
  overflow-wrap: anywhere;
`;

export const IncomingMessage = styled(Message)`
  border-top-left-radius: 0;
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].delayed}33;
`;

export const OutgoingMessage = styled(Message)`
  border-top-right-radius: 0;
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].additionalStroke};
`;

export const Time = styled(Box)`
  color: ${({ theme }) => customColors[theme.palette.mode].placeHolder};
`;

export const SystemMessage = styled(Stack)`
  font-size: 0.8rem;
  font-style: italic;
  text-align: left;
  color: ${({ theme }) => customColors[theme.palette.mode].placeHolder};
  background-color: unset;
`;

export const Avatar = styled(Box)`
  width: 21px;
  height: 21px;

  border-radius: 50%;

  background-repeat: "no-repeat";
  background-position: "center";

  cursor: pointer;
`;

export const ChatAvatar = styled(Stack)`
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;

  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].additionalStroke};
`;

export const ChatDivider = styled(Box)`
  flex: 0 0 auto;
  margin-top: 10px;
  width: 100%;
  height: 3px;
  background-color: ${({ theme }) =>
    customColors[theme.palette.mode].additionalStroke};
`;

export const ChatTitle = styled(Box)`
  font-family: Inter;
  font-size: 21px;
  font-weight: 500;
  line-height: 25.41px;
  text-align: left;
`;

export const Members = styled(Box)`
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;

  color: ${({ theme }) => customColors[theme.palette.mode].placeHolder};
`;